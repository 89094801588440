const moment = require('moment');
const onlyNumberRegex = /^\d+$/;
const onlyTextRegex = /^[a-zA-Z!@#\$%\^\&*\)\(+=._-]+$/;
const urlRegex = /^((ftp|http|https):\/\/)?([A-z0-9]+)\.([A-z]{2,})/;
const priceRegex = /^\d{1,6}(\.\d{1,2})?$/;
const dateRegex = "^(1[0-2]|0[1-9])/(3[01]|[12][0-9]|0[1-9])/[0-9]{4}$";
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationHelper = {

  validate: (validationRules, inputs, errors) => {

    let error = false;
    if (validationRules.required) {
      validationRules.required.map((inputKey) => {
        if ((!inputs[inputKey] || (inputs[inputKey] && String(inputs[inputKey]).trim() === '')) && !errors[inputKey].error) {
          const displayName = errors[inputKey].display.charAt(0).toUpperCase() + errors[inputKey].display.slice(1);
          errors[inputKey].error = displayName + ' cannot be empty';
          error = true;
        }
      });
    }
    if (validationRules.maxLimit) {
      validationRules.maxLimit.map((inputKey) => {
        if (inputs[inputKey.name].length > inputKey.length && !errors[inputKey.name].error) {
          errors[inputKey.name].error = 'Invalid ' + errors[inputKey.name].display;
          error = true;
        }
      });
    }
    if (validationRules.exactLimit) {
      validationRules.exactLimit.map((inputKey) => {
        if (inputs[inputKey.name].length !== inputKey.length && !errors[inputKey.name].error) {
          errors[inputKey.name].error = 'Invalid ' + errors[inputKey.name].display;
          error = true;
        }
      });
    }
    if (validationRules.email) {
      validationRules.email.map((inputKey) => {
        if (inputs[inputKey] && !emailRegex.test(inputs[inputKey]) && !errors[inputKey].error) {
          errors[inputKey].error = 'Invalid ' + errors[inputKey].display;
          error = true;
        }
      });
    }
    if (validationRules.number) {
      validationRules.number.map((inputKey) => {
        if (inputs[inputKey] && !onlyNumberRegex.test(inputs[inputKey]) && !errors[inputKey].error) {
          errors[inputKey].error = 'Invalid ' + errors[inputKey].display;
          error = true;
        }
      });
    }
    if (validationRules.price) {
      validationRules.price.map((inputKey) => {
        if (inputs[inputKey] && !priceRegex.test(inputs[inputKey]) && !errors[inputKey].error) {
          errors[inputKey].error = 'Invalid ' + errors[inputKey].display;
          error = true;
        }
      });
    }
    if (validationRules.textOnly) {
      validationRules.textOnly.map((inputKey) => {
        if (inputs[inputKey] && !onlyTextRegex.test(inputs[inputKey]) && !errors[inputKey].error) {
          errors[inputKey].error = 'Invalid ' + errors[inputKey].display;
          error = true;
        }
      });
    }
    if (validationRules.url) {
      validationRules.url.map((inputKey) => {
        if (inputs[inputKey] && !urlRegex.test(inputs[inputKey]) && !errors[inputKey].error) {
          errors[inputKey].error = 'Invalid ' + errors[inputKey].display;
          error = true;
        }
      });
    }
    if (validationRules.date) {
      validationRules.date.map((inputKey) => {
        if(inputs[inputKey] && !moment(inputs[inputKey]).isValid()) {
          errors[inputKey].error = 'Invalid ' + errors[inputKey].display;
          error = true;
        }
      });
    }

    if (validationRules.maxToday) {
      validationRules.maxToday.map((inputKey) => {
        const today = moment().format('MM/DD/YYYY');
        const birthday = moment(inputs[inputKey]).format('MM/DD/YYYY');
        if (inputs[inputKey] && moment(today).diff(moment(birthday), 'days') < 0) {
          errors[inputKey].error = 'Invalid ' + errors[inputKey].display;
          error = true;
        }
      });
    }
    return { error, errors };
  },

  reset: (errors) => {

    const keys = Object.keys(errors);
    keys.map((inputKey) => {
      errors[inputKey].error = null;
    });
    return errors;
  }
}

module.exports = validationHelper;