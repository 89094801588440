import React, { Component } from 'react'
import {Label, FormGroup, Table, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import SearchIcon from "mdi-react/SearchIcon";
import HeadingText from '../../shared/components/form/HeadingText';
import CustomSelectComponent from "../../shared/components/form/CustomSelectComponent";
import TableFilterComponent from "../../shared/components/form/TableFilterComponent";
import MessageModal  from '../../shared/components/Modals/MessageModal';
import config from '../../config/app.config';
import Pagination from '../../shared/components/pagination/Pagination';
import localStorage from '../../libs/storageHelper';
import constant from '../../constants/constants';
import commonHelper from '../../libs/commonHelper';

const REGEX_DIGIT = /[^\d]+/g
const REGEX_PHONE = /(\d{3})(\d{3})(\d{4})/
const FILTER_STATUS = 'status';
const FILTER_SUBSCRIBE = 'subscribe';

export default class RefferalDoctorTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      facilityList: [],
      sortField: 'name',
      sortOrder: 1,
      doctorList: [],
      subscribeStatus: [],
      status: [],
      facility: [],
      search: '',
      cnfAlertType: '',
      cnfMsg: '',
      cnfShow: false,
      cnfType: '',
      changeStatus: null,
      page:1,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      offset: 0,
      loggedUser: localStorage.getFromStorage('loggedUser')
    }

    this.heads = [
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'facilityDisplayName',
        name: 'Facility',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'phone',
        name: 'Phone',
        sortable: false,
        filterable: false,
      },
      {
        key: 'email',
        name: 'Email',
        sortable: false,
        filterable: false,
      },
      {
        key: 'subscribeStatus',
        name: 'Subscribe Status',
        sortable: false,
        filterable: true,
        filterValue: [{'pending':'pending'}, {'subscribed':'subscribed'},{'unsubscribed':'unsubscribed'}],
        capitalize: true,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: false,
        filterable: true,
        filterValue: [{'active':'active'},{'inactive':'inactive'}],
        capitalize: true,
      }
    ];


  }


  toggleForm = (status) => {
    this.props.toggleForm(status);
  }
  
  componentWillReceiveProps(np) {
    const { facilityList, doctorList, changeStatus, reinvited } = np;
    const { changeStatus: statusText } =  this.state;

    this.setState({ doctorList, total: doctorList.total})
    if(facilityList) {
      const list = [];
      facilityList.map( (data) => {
          list.push({
            id:data.id,
            name:data.name
          });
        });
      this.setState({facilityList: list});
    }

    if(changeStatus) {
      this.setState({
        cnfType: 'alert',
        cnfMsg: `This profile is ${statusText} now`,
      }, () => this.loadCustomData());
    }

    if(reinvited) {
      this.setState({
        cnfType: 'alert',
        cnfMsg: `Invitation sent successfully `,
      });
    }

  }

  handleGridSort = (params) => {
    const { sortOrder } = this.state;
    let order = sortOrder === 1 ? -1 : 1;

    this.setState({ sortOrder: order, sortField: params, page:1, offset:0 }, () => {
      this.loadCustomData();
    })
  }

  handleFilter = (params, type=null) => {
    let {subscribeStatus, status, facility} =  this.state;
    switch (type) {
      case FILTER_SUBSCRIBE:
        subscribeStatus = params;
        break;
      case FILTER_STATUS:
        status = params;
        break;
      case null:
        facility = params;
        break;
      default:
        break;
    }

    this.setState({subscribeStatus, status, facility, page:1, offset:0 }, () => {
      this.loadCustomData();
    });


  }
  
  changeStatus = (changeStatus, id) => {

    let type = changeStatus === 'active' ? 'Activate' : 'Deactivate';

    if(changeStatus === 'remove' ) {
      type = 'remove';
    }
    this.setState({ 
      cnfItem: type,
      cnfShow: true,
      cnfType: 'confirm',
      cnfMsg: `Are you sure you want to ${type} this profile?`,
      changeStatus,
      selectedId: id
    });
  }
  
  onSuccess = () => {
    const { changeStatus, selectedId, doctorId, facilityId } = this.state;

    if(changeStatus !== 'reinvite') {
      const data = {
        doctorFacilityId: selectedId,
        status: changeStatus
      }
      this.props.updateStatus(data);
    } else {
      this.props.reinvite({doctorId, facilityId});
    }

  }
  
  reinvite = (params) => {
    const { doctorId,facilityId, name } = params;

    this.setState({ 
      cnfShow: true,
      cnfType: 'confirm',
      cnfMsg: `Are you sure you want to reinvite ${name}? `,
      changeStatus: 'reinvite',
      doctorId,
      facilityId
    });
    
  }
  

  headers = (params) => {
    const { sortOrder,sortField, loggedUser } = this.state;

    return this.heads.map((head, key) => {
      if (head) {
          const _sortField = head.key;
          return ((head.sortable && !head.filterable) ?
                  <th key={key}>
                      {(sortField === head.key) ?

                          (sortOrder === 1) ?
                              <a  onClick={() => { this.handleGridSort(_sortField) }}>
                                  <p className="header-text">{head.name}</p><span className="triangle-up"></span></a>
                              :
                              <a onClick={() => {  this.handleGridSort(_sortField) }}><p
                                  className="header-text">{head.name}</p><span
                                  className="triangle-down"></span></a>
                          :
                           <a onClick={() => { this.handleGridSort(_sortField) }}><p
                              className="header-text">{head.name}</p>
                              {loggedUser.user.type !== constant.userRole.admin && <span className="diamond-narrow"></span>}
                              </a>
                      }
                  </th>
                  :
                  (head.key === 'status') ?
                    <th key={key}>
                        <TableFilterComponent
                            allFilters={head.filterValue}
                            action={(list) => {
                                this.handleFilter(list, FILTER_STATUS)
                            }}
                            label={head.name}
                        />
                    </th>
                    :
                    (head.key === 'subscribeStatus') ?
                    <th key={key}>
                        <TableFilterComponent
                            allFilters={head.filterValue}
                            action={(list) => {
                                this.handleFilter(list, FILTER_SUBSCRIBE)
                            }}
                            label={head.name}
                        />
                    </th>:
                    <th key={key}>
                        <p className="header-text">{head.name}</p>
                    </th>
          )
      }
      
  });
  }

  tableRows = () => {
    
    const { doctorList } = this.state;
    const headText = this.heads.map((head, key) => {
      return head.key;
    });
    const capitalizedText = this.heads.map((head, key) => {
      if (head.capitalize && head.capitalize === true) {
        return head.key;
      }
    });
    if(doctorList.data) {
      
      const values = [];

      doctorList.data.forEach(element => {
        values.push({
          name: element.name,
          facilityName: element.facilityName,
          facilityDisplayName: element.facilityDisplayName,
          phone: element.phone.replace(REGEX_DIGIT, '').replace(REGEX_PHONE, '($1) $2-$3'),
          email: element.email,
          isdeleteAvailable: element.isdeleteAvailable,
          subscribeStatus: element.subscribeStatus.replace(/^\w/, c => c.toUpperCase()),
          status: element.status.replace(/^\w/, c => c.toUpperCase()),
          id: element._id,
          doctorId: element.doctorId,
          facilityId: element.facilityId,
          preferredStatus: element.preferredStatus
        })
      });

      return values.map((row, key) => {
   
          const _row = Object.keys(row).map((value, key) => {
              if (headText.includes(value)) {
                const capitalizeClass = (capitalizedText.includes(value)) ? 'text-capitalize' : '';
                return (<td key={key} className={`status-col ${capitalizeClass}`}>{row[value]}</td>);
              }
              return true;
          });
          const subscribeStatus = row.subscribeStatus === 'Pending';
          const reinvite = row.subscribeStatus !== 'Subscribed';

          return (
              <tr className="body-tr" key={key}>
                  {_row}
                  <td>
                      <UncontrolledDropdown>
                          <DropdownToggle>
                              <span className="six-layers"></span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown__menu">
                              <DropdownItem tag="div">
                                <a
                                  onClick={() => this.viewDetails(row.doctorId, row.facilityId) }
                                  >
                                  View Profile
                                </a>
                              </DropdownItem>
                              <DropdownItem tag="div">
                                <a
                                  onClick={() => this.changeStatus((row.status === 'Active') ? 'inactive' : 'active', row.id)}
                                  >
                                  {(row.status === 'Active') ? "Deactivate" : "Reactivate"}
                                </a>
                              </DropdownItem>
                              {reinvite &&  <DropdownItem tag="div">
                                <a
                                  onClick={() => this.reinvite(row) }
                                  >
                                  Reinvite
                                </a>
                              </DropdownItem>}
                              {row.isdeleteAvailable &&  <DropdownItem tag="div">
                                <a
                                  onClick={() => this.changeStatus('remove',row.id) }
                                  >
                                  Remove
                                </a>
                              </DropdownItem>}
                          </DropdownMenu>
                      </UncontrolledDropdown>
                  </td>
              </tr>
          );
      });     
    }
  

  }

  viewDetails = (doctorId, facilityId) => {
    this.props.toggleForm(true, doctorId, facilityId);
  }
  
  handleSearch = (e) => {
    this.setState({ search: e.target.value, page:1, offset:0 }, () => {
      this.loadCustomData();
    });
    
  }
  
  loadCustomData = () => {
    const { search, subscribeStatus, status, facility, page, limit, sortOrder, sortField } = this.state;

    const sort = {
      field: sortField,
      order: sortOrder
    }
    const data = {
      search,
      sort,
      subscribeStatus,
      status,
      facility,
      page,
      limit
    }

    this.props.getDoctors(data);
  }

  onClose = () => {
    this.setState({ cnfShow: false, cnfAlertType: null})
  }

  prevPage = (params) => {
    let { page, offset, limit } = this.state;
    --page;
    offset = (page-1)*limit;
    this.setState({
      page,
      offset
    }, () => {
      this.loadCustomData();
    }); 
  }

  nextPage = (params) => {
    let { page, offset, limit } = this.state;
    ++page;
    offset = (page-1)*limit;
    this.setState({
      page,
      offset
    }, () => {
      this.loadCustomData();
    }); 
  }

  gotoPage = (params) => {
    let { page, offset, limit } = this.state;
    page = params+1;
    offset = (page-1)*limit;
 
    this.setState({
      page,
      offset
    }, () => {
      this.loadCustomData();
    }); 
  }
  
  handlePageLimit = (limit) => {
    this.setState({
      limit,
      page:1,
      offset: 0
    }, () => {
      this.loadCustomData();
    }); 
  }
  
  render() {

    const { facilityList, doctorList, cnfAlertType, cnfShow, cnfType, cnfMsg, total, offset, limit, loggedUser } = this.state;
    const isSuperAdmin = commonHelper.isSuperAdmin();
    const heading = isSuperAdmin ? 'Doctors' : 'Manage Referring';

    return (
      <div>
        <MessageModal 
          show={cnfShow}
          type={cnfType}
          message={cnfMsg}
          footer={true}
          onClose={this.onClose}
          onSuccess={this.onSuccess}
          alertType = { cnfAlertType}
        />

        <HeadingText text={heading} />
         <div className="row search form">
          <div className="col-sm-5">

            <div className="form__form-group">
              <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
              <div className="form__form-group-field custom-shadow custom-border-radius">
                <div className="form__form-group-icon form__form-group-icon--left form__form-group-search">
                  <SearchIcon />
                </div>
                <input
                  name="search"
                  id="search"
                  type="text"
                  onChange={this.handleSearch}
                  ref={input => this.searchVal = input}
                  placeholder="Search by doctor name"
                  className="form__custom-field form__custom-field--icon-left"
                />
              </div>
            </div>
          </div>
          {loggedUser.user.type !== constant.userRole.admin && <div className="col-sm-3">
            <FormGroup>
              <Label for="exampleSelect">Filter by Facility</Label>
              <CustomSelectComponent 
                allFilters={facilityList}
                filterTypeText="Facility"
                filterTypeTextPlural="Facilities"
                action={this.handleFilter}/>
            </FormGroup>
          </div>}
          <div className="col-sm-4">
            <FormGroup>
              <button className="btn btn-outline-primary btn-new-referral-doctor" onClick={this.toggleForm.bind(this, true)}>Add New Referring Doctor</button>
            </FormGroup>
          </div>
        </div>

        <Table striped className="custom-table loyal-table">
            <thead>
            <tr className="header-tr">
                {this.headers()}
                <th className="text-center action"><p className="header-text action">Action</p></th>
            </tr>
            </thead>
            <tbody>
            {this.tableRows()}
            </tbody>

        </Table>

        {doctorList.data && doctorList.data.length === 0 && <div className="no-data-message">No results found</div>}

        <Pagination handlePageLimit={(limit) => {this.handlePageLimit(limit)}} nextPage={this.nextPage}
                    prevPage={this.prevPage} gotoPage={(i) => this.gotoPage(i)} limit={limit}
                    offset={offset} total={total}/>

      </div>
    )
  }
}
