import { handleRequest, handleUploadRequest } from '../services/APIService';
import config from "../../config/app.config";

export const GET_FACILITY_DATA = 'GET_FACILITY_DATA';
export const UPDATE_FACILITY_LOGO = 'UPDATE_FACILITY_LOGO';
export const UPDATE_FACILITY_ICON = 'UPDATE_FACILITY_ICON';
export const UPDATE_FACILITY_LOGO_ERROR = 'UPDATE_FACILITY_LOGO_ERROR';
export const UPDATE_FACILITY_ICON_ERROR = 'UPDATE_FACILITY_ICON_ERROR';
export const ACTIVATE_DEACTIVATE_FACILITY = 'ACTIVATE_DEACTIVATE_FACILITY';
export const ACTIVATE_DEACTIVATE_FACILITY_ERROR = 'ACTIVATE_DEACTIVATE_FACILITY_ERROR';
export const ADD_NEW_FACILITY = 'ADD_NEW_FACILITY';
export const ADD_NEW_FACILITY_ERROR = 'ADD_NEW_FACILITY_ERROR';
export const INITIATE_POPUP = 'INITIATE_POPUP';
export const GET_FACILITY_BY_ID = 'GET_FACILITY_BY_ID';
export const GET_FACILITY_BY_ID_ERROR = 'GET_FACILITY_BY_ID_ERROR';
export const UPDATE_FACILITY = 'UPDATE_FACILITY';
export const UPDATE_FACILITY_ERROR = 'UPDATE_FACILITY_ERROR';

export const getFacilityList = (data) => dispatch => {

  handleRequest('post', `facility/get`, true, data).then( (results) => {
    return dispatch ({
      type: GET_FACILITY_DATA,
      payload: results.data
    });
  })
};

export const getFacilityById = (id) => dispatch => {

  handleRequest('get', `facility/` + id, true).then( (results) => {

    return dispatch ({
      type: GET_FACILITY_BY_ID,
      payload: results.data
    });
  }).catch((error) => {
    return dispatch ({
      type: GET_FACILITY_BY_ID_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const updateFacilityStatus = (data) => dispatch => {

  handleRequest('post', `facility/change-status`, true, data).then( (results) => {
    return dispatch ({
      type: ACTIVATE_DEACTIVATE_FACILITY,
      payload: results.data
    });
  }).catch ((error) => {
    return dispatch ({
      type: ACTIVATE_DEACTIVATE_FACILITY_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const initiatePopups = () => dispatch => {

    return dispatch ({
      type: INITIATE_POPUP,
      payload: null,
    });
};

export const updateLogo = (file, imageType) => dispatch => {

  handleUploadRequest(file, config.PROFILE_IMAGE_FOLDER).then( (results) => {
    const type = (imageType === 'logo') ? UPDATE_FACILITY_LOGO : UPDATE_FACILITY_ICON;
    return dispatch ({
      type,
      payload: results.data
    });
  }).catch ((error) => {
    const type = (imageType === 'logo') ? UPDATE_FACILITY_LOGO_ERROR : UPDATE_FACILITY_ICON_ERROR;
    return dispatch ({
      type,
      payload: error
    });
  })
};

export const addNewFacility = (data) => dispatch => {

  handleRequest('post', 'facility', true, data).then((results) => {
    return dispatch ({
      type: ADD_NEW_FACILITY,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: ADD_NEW_FACILITY_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const updateFacility = (data, id) => dispatch => {
  handleRequest('put', 'facility/' + id, true, data).then((results) => {
    return dispatch ({
      type: UPDATE_FACILITY,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: UPDATE_FACILITY_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};
