import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { Table, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import TableStatusFilterComponent from "../../shared/components/form/TableFilterComponent";
import StarIcon from 'mdi-react/StarIcon';
import {toggleLoading} from "../../redux/actions/commonActions";
import {connect} from "react-redux";

class AdminTableComponent extends Component {
    static propTypes = {
        heads: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            sortable: PropTypes.bool,
        })).isRequired,
        rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};

    }

    componentWillMount() {
        this.props.toggleLoading(true);
    }

    componentWillUnmount(){
        if(this.props.common.loading){
            this.props.toggleLoading(false);
        }
    }

    handleStatusFilter = (list) => {
        this.props.handleGridFilter('status', list);
    };

    render() {
        const {rows, heads, sortField, sortOrder} = this.props;

        const headText = heads.map((head, key) => {
            return head.key;
        });

        const capitalizedText = heads.map((head, key) => {
            if (head.capitalize && head.capitalize === true) {
                return head.key;
            }
        });

        const tableRows = rows.map((row, key) => {

            const _row = Object.keys(row).map((value, key) => {
                if (headText.includes(value)) {

                    const capitalizeClass = (capitalizedText.includes(value)) ? 'text-capitalize' : '';
                    return (
                      <td key={key} className={`status-col ${capitalizeClass}`}>
                        {row[value]}
                        {
                          row.isPrimary && value === 'name' && <StarIcon className="star-icon"/>
                        }
                      </td>
                    );
                }
                return true;
            });

            return (
                <tr className="body-tr" key={key}>
                    {_row}
                    <td>
                        <UncontrolledDropdown>
                            <DropdownToggle>
                                <span className="six-layers"></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                                <DropdownItem tag="div"><a
                                onClick={() => this.props.handleActiveStatus({type: 'view', data: row })}>
                                  View Profile
                                </a>
                                </DropdownItem>
                              {
                                !row.isPrimary &&
                                <DropdownItem tag="div">

                                  <a onClick={() => this.props.handleActiveStatus({
                                    type: 'changeStatus',
                                    data: { id: row.id, name: row.firstName, status: row.status }
                                  })}>
                                    {(row.status === 'active') ? "Deactivate" : "Reactivate"}
                                  </a>
                                </DropdownItem>
                              }
                                {(!row.joined) ?
                                    <Fragment>
                                        <DropdownItem tag="div"><a
                                            onClick={() => this.props.handleActiveStatus({
                                              type: 'reinvite',
                                              data: { id: row.id, name: row.firstName, status: row.status }
                                            })}>
                                            Reinvite

                                        </a></DropdownItem>
                                      {
                                       !row.isPrimary &&
                                       <DropdownItem tag="div"><a
                                         onClick={() => this.props.handleActiveStatus({
                                           type: 'remove',
                                           data: { id: row.id, name: row.firstName }
                                         })}>
                                         Remove
                                       </a></DropdownItem>
                                      }
                                    </Fragment>
                                    :
                                    ''
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    </td>
                </tr>
            );
        });
        const headers = heads.map((head, key) => {
            if (head) {
                const _sortField = head.sortKey;
                return ((head.sortable && !head.filterable) ?
                        <th key={key}>
                            {(sortField === head.sortKey) ?

                              (sortOrder === 1) ?
                                <a  onClick={() => { this.props.handleGridSort(_sortField) }}>
                                    <p className="header-text">{head.name}</p><span className="triangle-up"></span></a>
                                :
                                <a onClick={() => {  this.props.handleGridSort(_sortField) }}><p
                                  className="header-text">{head.name}</p><span
                                  className="triangle-down"></span></a>
                              :
                              <a onClick={() => { this.props.handleGridSort(_sortField) }}><p
                                className="header-text">{head.name}</p><span className="diamond-narrow"></span></a>
                            }
                        </th>
                        :
                    (head.key === 'status') ?
                      <th key={key}>
                        <TableStatusFilterComponent
                          allFilters={head.filterValue}
                          action={(list) => {
                            this.handleStatusFilter(list)
                          }}
                          label={head.name}
                        />
                      </th>
                      :
                      <th key={key}>
                        <p className="header-text">{head.name}</p>
                      </th>
                )
            }
            return true;
        });
        return (
            <div className="row">
                <div className="col-md-12">
                    <Table striped className="custom-table loyal-table">
                        <thead>
                        <tr className="header-tr">
                            {headers}
                            <th className="text-center action"><p className="header-text action">Action</p></th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableRows}
                        </tbody>

                    </Table>

                    {
                        rows.length == 0 && this.props.common.loading === false ?
                          <div className="no-data-message">No results found</div> : null
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = {
    toggleLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTableComponent)