import React from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import config from '../../config/app.config';

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '', readOnly: false };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleBlur = newAddress => {
    const {address} = this.state;

    this.props.getAddress({address});
  };

  handleSuccess = results => {

    let city, state, zipCode, placeId, streetNo;
    city = state = zipCode = placeId = streetNo = '';
    if (results.address_components && results.address_components.length) {
      results.address_components.map((element) => {
        if (element.types.includes('street_number')) {
          streetNo = element.long_name;
        }
        if (element.types.includes('route')) {
          streetNo = `${streetNo} ${element.long_name}`;
        }
        if (element.types.includes('locality')) {
          city = element.long_name;
        }
        if (element.types.includes('administrative_area_level_1')) {
          state = element.long_name;
        }
        if (element.types.includes('postal_code')) {
          zipCode = element.long_name;
        }
      });
      placeId = results.place_id;
    }
    this.props.getAddress({city, state, zipCode, placeId, streetNo});
  };

  handleSelect = address => {
    this.setState({ address });
    this.props.getAddress({address});
    geocodeByAddress(address)
      .then(results => {
        this.handleSuccess(results[0]);
      })
      .catch(error => console.error('Error', error));
  };

  componentWillReceiveProps(np){
    const readOnly = np.readOnly || false;
    const disabled = np.disabled || false;
    const address = np.value || '';
    this.setState({ readOnly, address, disabled });
  };

  render() {
    const { readOnly, disabled } = this.state;
    const _this = this;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        debounce={600}
        onBlur={this.handleSelect}
        onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="loyal-autocomplete-dropdown-main">
            <input
              {...getInputProps({
                placeholder: '',
                className: 'form-control',
                name: 'address',
                readOnly: readOnly,
                disabled: disabled,
                onBlur:_this.handleBlur
              })}
            />
            <div className="loyal-autocomplete-dropdown-container">
              {loading && <div className="suggestion-item">Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description} </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: config.GOOGLE_API_KEY,
  libraries: ["places"]
})(LocationSearchInput);
