import React, {Component, Fragment} from 'react';
import { Collapse, FormGroup } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import connect from "react-redux/es/connect/connect";
import {getDoctorsByFacility} from "../../../../redux/actions/referralDoctorAction";
import {getStaffDoctorsByFacility} from "../../../../redux/actions/staffActions";
import DatePickerComponent from "../../Common/DatePickerComponent";
import Error from "../../../../shared/components/form/Error";
import config from "../../../../config/app.config";
import MessageModal from "../../../../shared/components/Modals/MessageModal";
import DatePicker from 'react-datepicker';
import localStorage from "../../../../libs/storageHelper";

import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';

class CreateAppointmentForm extends Component{
  constructor(props) {
    super(props);
    this.state = {
      referringDoctorList: [],
      staffDoctorList: [],
      loadingRefDoctors: true,
      loadingDoctors: true,
      responseBox: {
        showResponse: false,
        responseType: '',
        responseAlertType: '',
        responseFooter: '',
        message: '',
        onSuccess: null,
        notified: false
      },
      appointmentTime: null,
    };
    this.handlePopup = this.handlePopup.bind(this);
    this.onClose = this.onClose.bind(this);
    this.isNotSubscribed = this.isNotSubscribed.bind(this);
    this.handleReferringDocChange = this.handleReferringDocChange.bind(this);
  }

  handleReferringDocChange(e){
    if (e && this.isNotSubscribed(e.value)) {
      this.props.onChangeSelect(e,'referringDoctor', true);
    } else {
      this.props.onChangeSelect(e,'referringDoctor');
    }
  }

  componentDidMount(){
    this.setState({
      loadingRefDoctors: true,
      loadingDoctors: true
    });
    this.props.getDoctorsByFacility();
    this.props.getStaffDoctorsByFacility();
  }

  getDoctorList(list, type){
    switch (type) {
      case 'staffDoctor':
        return list.map(value => ({ value: value.doctorId, label: value.name }));
        break;
      case 'referringDoctor':
        return list.map(value => ({ value: value.doctorId, label: value.name, subscriptionStatus: value.subscriptionStatus }));
        break;
    }
  }

  componentWillReceiveProps(np) {
    if(np.refDoctor && np.refDoctor.referringDoctorList){
      let referringDoctorList = this.getDoctorList(np.refDoctor.referringDoctorList, 'referringDoctor');
      this.setState({
        referringDoctorList,
        loadingRefDoctors: false
      });
    }

    if(np.staff && np.staff.staffDoctorsByFacility){
      let staffDoctorList = this.getDoctorList(np.staff.staffDoctorsByFacility, 'staffDoctor');
      this.setState({
        staffDoctorList,
        loadingDoctors: false
      });
    }

    if (np.createData && np.createData.appointmentTime) {
      let appointmentTime = np.createData.appointmentTime;
      this.setState({ appointmentTime });
    }
  }


  handlePopup(type){
    const {responseBox} = this.state;
    responseBox.showResponse = true;
    responseBox.responseFooter = true;
    responseBox.responseType = 'alert';
    responseBox.responseAlertType = 'info';
    switch (type) {
      case 'referringDoctor':
        responseBox.message = `Referring doctor you selected is now inactive. Please activate or select a new referring doctor to proceed.`;
        break;
      case 'doctor':
        responseBox.message = `Doctor you selected is now inactive. Please activate or select a new doctor to proceed.`;
        break;
      case 'referringDoctorAndDoctor':
        responseBox.message = `Both doctor and referring doctor you selected are now inactive. Please activate or select a new doctor and a referring doctor to proceed.`;
        break;
    }
    responseBox.onSuccess = this.onClose;
    this.setState({
      responseBox,
      notified: true,
      resetType: type
    });
  }

  onClose(){
    const { responseBox, resetType } = this.state;

    responseBox.showResponse = false;
    responseBox.responseType = '';
    responseBox.responseAlertType = '';
    responseBox.responseFooter = '';
    responseBox.message = '';

    this.setState({
      responseBox
    }, () => {
      this.props.resetData(resetType);
    });
  }

  isNotSubscribed(referringDoctor){
    const { referringDoctorList } = this.state;
    const currentItem = referringDoctorList.filter(item => item.value == referringDoctor);
    if (currentItem[0]) {
      return currentItem[0].subscriptionStatus !== config.SUBSCRIPTION_STATUS.SUBSCRIBED;
    }
    return false;
  }

  timePickerOnChange(e) {
    if (e) {
      this.setState({ appointmentTime: e });
      this.props.handleTimeChange(e.toDate());
    }
  }

  render(){
    const { referringDoctorList, staffDoctorList, loadingRefDoctors, loadingDoctors, responseBox, notified } = this.state;
    const { viewMode, reschedule } = this.props;
    const { appointmentDate, appointmentTime, time, appointmentType, appointmentTypeOther, doctor,
      referringDoctor, shareUpdates, errors, collapse} = this.props.createData;
    
    const offset = localStorage.getFromStorage('loggedUser').offset;
    const mappedDate = moment.utc().seconds(offset * 60);

    if (reschedule && !loadingRefDoctors && !notified && !loadingDoctors) {
      const refDocIds = referringDoctorList.map((currentValue) => {
        return currentValue.value;
      });
      const docIds = staffDoctorList.map((currentValue) => {
        return currentValue.value;
      });

      if ((referringDoctor && refDocIds.indexOf(referringDoctor) === -1) && (doctor && docIds.indexOf(doctor) === -1)) {
        this.handlePopup('referringDoctorAndDoctor');
      } else {
        if (referringDoctor && refDocIds.indexOf(referringDoctor) === -1) {
          this.handlePopup('referringDoctor');
        }

        if (doctor && docIds.indexOf(doctor) === -1) {
          this.handlePopup('doctor');
        }
      }
    }
    return(
      <Fragment>
        {
          responseBox.showResponse &&
          <MessageModal show={responseBox.showResponse}
                        type={responseBox.responseType}
                        alertType={responseBox.responseAlertType}
                        footer={responseBox.responseFooter}
                        onSuccess={responseBox.onSuccess}
                        onClose={this.onClose}
                        message={responseBox.message}/>
        }
      <div className="content-holder create">
        <div className="row">
          <div className="col-md-12">
            <h3 className={!collapse && 'loyal-disable-tab-text'}>
              {
                reschedule ? 'Appointment Details' : 'Create New Appointment'
              }
            </h3>
          </div>
          <div className="col-md-12">
            <Collapse isOpen={collapse}>
              <div className="row">
                <div className="col-md-3">
                  <div className="form__form-group loyal-custom-form-group">
                    <span className="form__form-group-label">Appointment Date <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <DatePickerComponent minDate={true} maxDate={false} viewMode={viewMode}
                                             date={appointmentDate} handleChange={this.props.handledateChange}/>
                        {errors.appointmentDate && <Error text={errors.appointmentDate}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form__form-group loyal-custom-form-group">
                    <span className="form__form-group-label">Time <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        {
                          reschedule ?
                            <TimePicker
                                showSecond={false}
                                className="form-control"
                                onChange={(e) => this.timePickerOnChange(e)}
                                format={'h:mm A'}
                                value={this.state.appointmentTime}
                                placeholder="HH:MM AM"
                                name="time"
                                clearIcon = {undefined}
                                inputReadOnly
                                use12Hours
                                minuteStep={15}
                                defaultOpenValue={moment(mappedDate)}
                            /> :
                            <TimePicker
                                showSecond={false}
                                className="form-control"
                                onChange={(e) => this.timePickerOnChange(e)}
                                format={'h:mm A'}
                                defaultValue={null}
                                value={this.state.appointmentTime}
                                placeholder="HH:MM AM"
                                name="time"
                                clearIcon = {undefined}
                                inputReadOnly
                                use12Hours
                                minuteStep={15}
                                defaultOpenValue={moment(mappedDate)}
                            />
                        }
                        {errors.time && <Error text={errors.time}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form__form-group loyal-custom-form-group">
                    <span className="form__form-group-label">Appointment Type <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <select name="appointmentType" id="species"
                                className="form-control form-select"
                                disabled={viewMode}
                                value={appointmentType}
                                onChange = {this.props.handleChange}>
                          <option value="">Select Type</option>
                          <option value="wellness visit">Wellness Visit</option>
                          <option value="follow-up visit">Follow-up Visit</option>
                          <option value="vaccinations">Vaccinations</option>
                          <option value="diagnostics">Diagnostics</option>
                          <option value="surgery">Surgery</option>
                          <option value="minor procedure">Minor Procedure</option>
                          <option value="treatment">Treatment</option>
                          <option value="dental cleaning">Dental Cleaning</option>
                          <option value="grooming">Grooming</option>
                          <option value="nail trim">Nail Trim</option>
                          <option value="boarding">Boarding</option>
                          <option value="specialist">Specialist</option>
                          <option value="other">Other</option>
                        </select>
                        {errors.appointmentType && <Error text={errors.appointmentType}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                {
                  appointmentType === config.appointmentTypes.other &&
                  <div className="col-md-3">
                    <div className="form__form-group loyal-custom-form-group">
                      <span className="form__form-group-label">If Other, input appointment type</span>
                      <div className="form__form-group-field">
                        <FormGroup>
                          <input type="text" className="form-control" name="appointmentTypeOther"
                                 disabled={viewMode}
                                 value={appointmentTypeOther} onChange = {this.props.handleChange}/>
                          {errors.appointmentTypeOther && <Error text={errors.appointmentTypeOther}/>}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form__form-group loyal-custom-form-group">
                    <span className="form__form-group-label">Doctor </span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <Select
                          value={doctor}
                          onChange={(e) => {this.props.onChangeSelect(e,'doctor')}}
                          disabled={viewMode}
                          className={'custom-select-control'}
                          options={staffDoctorList}
                          placeholder={'Select Doctor'}
                          clearable={true}
                          name="doctor"
                        />
                        {errors.doctor && <Error text={errors.doctor}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form__form-group loyal-custom-form-group">
                    <span className="form__form-group-label">
                      Referring Doctors
                    </span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <Select
                          value={referringDoctor}
                          onChange={this.handleReferringDocChange}
                          disabled={viewMode}
                          className={'custom-select-control'}
                          options={referringDoctorList}
                          placeholder={'Select Referring Doctor'}
                          name="referringDoctor"
                        />
                        {errors.referringDoctor && <Error text={errors.referringDoctor}/>}
                        <div className="form__form-group loyal-custom-form-group clearfix">
                          <div className="float-right">
                            <label className="custom-toggle-switch pull-right">
                              <input type="checkbox" disabled={viewMode || referringDoctor === "" || !referringDoctor || this.isNotSubscribed(referringDoctor)} name="shareUpdates"
                                     checked={shareUpdates}
                                     onChange={ this.props.handleShareUpdates }/>
                              <span className="custom-toggle-slider round"></span>
                            </label>
                          </div>
                          <div className="float-right">
                            <p className="form__form-group-label share-updates">Share Updates </p>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getDoctorsByFacility,
  getStaffDoctorsByFacility
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppointmentForm);
