import {
  SAVE_DOCTOR,
  SAVE_DOCTOR_FAILED,
  GET_DOCTORS,
  GET_DOCTORS_FAILED,
  GET_DOCTOR_BY_ID,
  GET_DOCTOR_BY_ID_FAILED,
  UPDATE_DOCTOR,
  UPDATE_DOCTOR_FAILED,
  CHANG_STATUS,
  CHANG_STATUS_FAILED,
  REINVITE,
  REINVITE_FAILED,
  GET_DOCTORS_BY_FACILITY,
  GET_DOCTORS_BY_FACILITY_FAILED,
  REF_DOCTOR_SUBSCRIPTION,
  REF_DOCTOR_SUBSCRIPTION_ERROR
} from '../actions/referralDoctorAction';

const initialState = {
  invited: false,
  invitedError:false,
  doctorDetails: null,
  updated: false,
  statusChanged: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_DOCTOR:
      return {
        ...state,
        invited: true,
        invitedError: false
      }
    case SAVE_DOCTOR_FAILED:
      return {
        ...state,
        invitedError: true,
        invitedErrorMessage: action.payload,
        invited: false
      }
    case UPDATE_DOCTOR:
      return {
        ...state,
        updated: true
      }
    case UPDATE_DOCTOR_FAILED:
      return {
        ...state,
        updated: false
      }
    case GET_DOCTORS:
      return {
        ...state,
        doctorList: action.payload,
        updated: false,
        invited: false,
        statusChanged: false,
        reinvited: false
      }
    case GET_DOCTORS_FAILED:
      return {
        ...state,
        doctorList: []
      }
    case GET_DOCTOR_BY_ID:
      return {
        ...state,
        doctorDetails: action.payload,
        invitedError: false
      }
    case GET_DOCTOR_BY_ID_FAILED:
      return {
        ...state,
        doctorDetails: null
      }
    case CHANG_STATUS:
      return {
        ...state,
        statusChanged: true
      }
    case CHANG_STATUS_FAILED:
      return {
        ...state,
        statusChanged: false
      }
    case REINVITE:
      return {
        ...state,
        reinvited: true
      }
    case REINVITE_FAILED:
      return {
        ...state,
        reinvited: false
      }
    case GET_DOCTORS_BY_FACILITY:
      return {
        ...state,
        referringDoctorList: action.payload,
      }
    case GET_DOCTORS_BY_FACILITY_FAILED:
      return {
        ...state,
        referringDoctorList: []
      }
    case REF_DOCTOR_SUBSCRIPTION:
      return {
        ...state,
        subscription: true
      }
    case REF_DOCTOR_SUBSCRIPTION_ERROR:
      return {
        ...state,
        subscription: false
      }
      default:
      return state;
  }
}