import React, { Component } from 'react'
import config from '../../../config/app.config';
import { Button, Row, Col, Container } from 'reactstrap';
import ImageCrop from '../../../shared/components/Modals/ImageCrop';
import ResponseMessages from '../../../constants/responseMessages';
const defautAvatar = `${process.env.PUBLIC_URL}/img/defaultAvatar.png`;

const SUPER_ADMIN = config.USER_TYPES.SUPER_ADMIN;
const STAFF = config.USER_TYPES.STAFF;

export default class AvatarSection extends Component {
  constructor(props){
    super(props);

    this.state = {
      showImageCrop: false
    }

  }

  componentWillReceiveProps(np){
    if(np.profile) {
      const { type, firstName, lastName, designation, facility, avatar } = np.profile;
      this.setState({ type, firstName, lastName, designation, facility, avatar });
    }
  }

  selectFile = () => {
    this.refs.inputUpload.click();
  }

  closeUploadModal = () => {
    this.setState({
      showImageCrop: false
    });
  }

  openCropImage = (e, _this) => {
    if (e.target.files && e.target.files.length > 0) {
      const file =   e.target.files[0];
      let width = 0;
      let height = 0;
      let fileType = file.type;
      const extension = file.name.split('.')[1];
      const fileTypes = ['jpeg', 'jpg', 'png', 'JPEG','JPG', 'PNG'];

      if ((fileType !== 'image/jpeg' && fileType !== 'image/png') || !fileTypes.includes(extension)) {
        this.props.showErrors(ResponseMessages.ERRORS.MSG_IMAGE_INVALID)
      } else {
        let image = new Image();
        const url = window.URL || window.webkitURL;
        image.onload = function(){
          width = this.width;
          height = this.height;

          if(file.size >= 9000000) {
            _this.props.showErrors(ResponseMessages.ERRORS.MSG_MAX_IMAGE);
          } else if (width < ResponseMessages.ERRORS.MIN_IMAGE_RESOLUTION || height < ResponseMessages.ERRORS.MIN_IMAGE_RESOLUTION) {
            _this.props.showErrors(ResponseMessages.ERRORS.MSG_MIN_IMAGE);
          } else if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
            _this.props.showErrors(ResponseMessages.ERRORS.MSG_IMAGE_INVALID);
          } else {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
              _this.setState({ src: reader.result, showImageCrop: true })
            );
            reader.readAsDataURL(file);
          }
        };
        image.src = url.createObjectURL(file);
      }
    }
  }

  onFileClick = (e) => {
    e.target.value = null
  }

  cropAndUpload = async (img) => {
    let blob = await fetch(img).then(r => r.blob());
    const imageFile = new File([blob], "filename", {type: "image/jpeg"});
    this.props.upload(imageFile);
    this.setState({
      showImageCrop: false
    });
  }
  
  render() {

    const { type, firstName, lastName, designation, facility, avatar, src, showImageCrop } = this.state;
    let title = designation && designation.name;
    let hospital = facility && facility.name;
    if(type !== STAFF) {
      title = type === SUPER_ADMIN ? 'Super Admin': 'Admin';
    }
    const avatarImg = avatar || defautAvatar;

    return (
      <div className="user-profile__avatar-sestion">
      <ImageCrop src={src} show={showImageCrop} onConfirm={this.cropAndUpload} onCancel={this.closeUploadModal}/>
      <Container>
        <Row>
          <Col xs="1">
            <img className="topbar__avatar-img user-profile__avatar-section--img" src={avatarImg} alt="avatar" />
          </Col>  
          <Col xs="10">
            <div className="user-profile__avatar-details-section">
              <p className="user-profile__avatar-details-section--name" >{firstName} {lastName}</p>
              <p className="user-profile__avatar-details-section--post">{title}</p>
              {type === STAFF && <p className="user-profile__avatar-details-section--hospital"> {hospital}</p>}
              <input accept="image/png, image/jpeg" onClick={this.onFileClick} onChange={(e) => { this.openCropImage(e, this); }} ref="inputUpload" type="file" style={{display:'none'}} />
              <Button onClick = {this.selectFile} className="user-profile__avatar-details-section--upload-btn" color="primary"> <span class="lnr lnr-plus-circle"></span> <span className="text">Upload Photo</span></Button>
            </div>
          </Col>  
        </Row>
      </Container>
       
        
      </div>
    )
  }
}
