import React from "react";
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;
const errorTick = `${process.env.PUBLIC_URL}/img/error-tick.svg`;

function SignupError() {
  return (
    <div className="parent-signup-success text-center">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="logo-wrapper">
              <img src={icon} alt="" className="logo" />
            </div>
            <h1 className="title">Welcome to Loyal</h1>
            <h4 className="subtitle">
              Track the Pets You Love
            </h4>
            <div className="success-wrapper error">
              <a href="" className="close"></a>
              <div className="message">
                <img src={errorTick} alt="success-icon" />
                <p>Link has expired</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupError;
