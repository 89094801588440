import { handleRequest, handleUploadRequest } from '../services/APIService';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_AVATAR_ERROR = 'UPDATE_USER_AVATAR_ERROR';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const VERIFY_RESET_PASSWORD = 'VERIFY_RESET_PASSWORD';
export const VERIFY_RESET_PASSWORD_ERROR = 'VERIFY_RESET_PASSWORD_ERROR';
export const VERIFY_SIGNUP_TOKEN = 'VERIFY_SIGNUP_TOKEN';
export const VERIFY_SIGNUP_TOKEN_ERROR = 'VERIFY_SIGNUP_TOKEN_ERROR';
export const PARENT_SIGN_UP = 'PARENT_SIGN_UP';
export const PARENT_SIGN_UP_ERROR = 'PARENT_SIGN_UP_ERROR';

export const getUserProfile = (id) => dispatch => {
  handleRequest('get', `user/profile/${id}`, true).then( (results) => {
    return dispatch ({
      type: FETCH_USER_PROFILE,
      payload: results.data
    });
  })
};

export const updateUserProfile = (data) => dispatch => {
  handleRequest('put', `user/profile/`, true, data).then( (results) => {
    return dispatch ({
      type: UPDATE_USER_PROFILE,
      payload: true
    });
  })
};

export const updatePassword = (data) => dispatch => {
  handleRequest('put', `user/profile/`, true, data).then( (results) => {
    return dispatch ({
      type: UPDATE_USER_PROFILE,
      payload: true,
      resetPassword: true
    });
  })
};

export const updateAvatar = (file) => dispatch => {

  handleUploadRequest(file, 'profile').then( (results) => {
    return dispatch ({
      type: UPDATE_USER_AVATAR,
      payload: results.data
    });
  }).catch ((error) => {
    return dispatch ({
      type: UPDATE_USER_AVATAR_ERROR,
      payload: error
    });
  })
}

export const login = (data) => dispatch => {
  handleRequest('post', `login`, null, data).then( (results) => {
    return dispatch ({
      type: LOGIN,
      payload: results.data
    });
  }).catch((error) => {
    return dispatch ({
      type: LOGIN_ERROR,
      payload: JSON.parse(error.response)
    });
  });
};

export const changePassword = (data) => dispatch => {
  handleRequest('post', `change-password`, null, data).then( (results) => {
    return dispatch ({
      type: CHANGE_PASSWORD,
      payload: results
    });
  }).catch((error) => {
    return dispatch ({
      type: CHANGE_PASSWORD_ERROR,
      payload: JSON.parse(error.response)
    });
  });
};

export const sendForgetPasswordLink = (data) => dispatch => {
  handleRequest('post', `forgot-password`, null, data).then( (results) => {
    return dispatch ({
      type: FORGOT_PASSWORD,
      payload: results
    });
  }).catch((error) => {
    return dispatch ({
      type: FORGOT_PASSWORD_ERROR,
      payload: JSON.parse(error.response)
    });
  });
};

export const resetPassword = (data) => dispatch => {
  handleRequest('post', `reset-password`, null, data).then( (results) => {
    return dispatch ({
      type: RESET_PASSWORD,
      payload: results
    });
  }).catch((error) => {
    return dispatch ({
      type: RESET_PASSWORD_ERROR,
      payload: JSON.parse(error.response)
    });
  });
};

export const verifyResetPassword = (data) => dispatch => {
  handleRequest('post', `verify-change-password`, null, data).then( (results) => {
    return dispatch ({
      type: VERIFY_RESET_PASSWORD,
      payload: results
    });
  }).catch((error) => {
    return dispatch ({
      type: VERIFY_RESET_PASSWORD_ERROR,
      payload: JSON.parse(error.response)
    });
  });
};

export const logout = (data) => dispatch => {
  handleRequest('post', `logout`, true, data).then( (results) => {
    return dispatch ({
      type: LOGOUT,
      payload: results
    });
  }).catch((error) => {
    return dispatch ({
      type: LOGOUT,
      payload: null
    });
  });
};

export const verifySignUpToken = (id) => dispatch => {
  handleRequest('get', `verify-signup-token/${id}`, null).then( (results) => {
    return dispatch ({
      type: VERIFY_SIGNUP_TOKEN,
      payload: results.data
    });
  }).catch((error) => {
    return dispatch ({
      type: VERIFY_SIGNUP_TOKEN_ERROR,
      payload: JSON.parse(error.response)
    });
  });
}

export const parentSignup = (data) => dispatch => {
  handleRequest('post', `parent-signup`, null, data).then( (results) => {
    return dispatch ({
      type: PARENT_SIGN_UP,
      payload: results
    });
  }).catch((error) => {
    return dispatch ({
      type: PARENT_SIGN_UP_ERROR,
      payload: null
    });
  });
};