import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Table, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import TableAdminFilterComponent from "../../shared/components/form/TableFilterComponent";
import TableStatusFilterComponent from "../../shared/components/form/TableFilterComponent";
import {toggleLoading} from "../../redux/actions/commonActions";
import {connect} from "react-redux";

class FacilityTableComponent extends Component {
    static propTypes = {
        heads: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            sortable: PropTypes.bool,
        })).isRequired,
        rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};

    }

    componentWillMount() {
        this.props.toggleLoading(true);
    }

    componentWillUnmount(){
        if(this.props.common.loading){
            this.props.toggleLoading(false);
        }
    }

    handleStatusFilter = (list) => {
        this.props.handleGridFilter('status', list);
    };

    handleAdminFilter = (list) => {
        this.props.handleGridFilter('admin', list);
    };

    viewProfile = (data) => {
        this.props.viewProfile(data);
    };

    render() {
        const {rows, heads, sortField, sortOrder} = this.props;

        const headText = heads.map((head, key) => {
            return head.key;
        });

        const capitalizedText = heads.map((head, key) => {
            if (head.capitalize && head.capitalize === true) {
                return head.key;
            }
        });

        const tableRows = rows.map((row, key) => {

            const _row = Object.keys(row).map((value, key) => {
                if (headText.includes(value)) {
                    const capitalizeClass = (capitalizedText.includes(value)) ? 'text-capitalize' : '';
                    return (<td key={key} className={`status-col ${capitalizeClass}`}>{row[value]}</td>);
                }
                return true;
            });

            return (
                <tr className="body-tr" key={key}>
                    {_row}
                    <td>
                        <UncontrolledDropdown>
                            <DropdownToggle>
                                <span className="six-layers"></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                                <DropdownItem tag="div">
                                    <a onClick={() => this.props.viewProfile(row)}>
                                        View Profile
                                    </a>
                                </DropdownItem>
                                <DropdownItem tag="div">
                                    <a onClick={() => this.props.handleActiveStatus((row.status === 'Active') ? 'inactive' : 'active', row.id)}>
                                    {(row.status === 'Active') ? "Deactivate" : "Reactivate"}
                                    </a>
                                </DropdownItem>
                                {(row.removePossibility === true)?
                                    <DropdownItem tag="div"><a
                                        onClick={() => this.props.handleActiveStatus('removed', row.id)}>
                                        Remove
                                    </a></DropdownItem>
                                    :
                                    ''
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    </td>
                </tr>
            );
        });
        const headers = heads.map((head, key) => {
            if (head) {
                const _sortField = head.key;
                return ((head.sortable && !head.filterable) ?
                        <th key={key}>
                            {(sortField == head.key) ?

                                (sortOrder === 1) ?
                                    <a onClick={() => this.props.handleGridSort(_sortField)}>
                                        <p className="header-text">{head.name}</p><span className="triangle-up"></span></a>
                                    :
                                    <a onClick={() => this.props.handleGridSort(_sortField)}><p
                                        className="header-text">{head.name}</p><span
                                        className="triangle-down"></span></a>
                                :
                                <a onClick={() => this.props.handleGridSort(_sortField)}><p
                                    className="header-text">{head.name}</p><span className="diamond-narrow"></span></a>
                            }
                        </th>
                        :
                        (head.key === 'admin') ?
                            <th key={key}>
                                <TableAdminFilterComponent
                                    allFilters={head.filterValue}
                                    action={(list) => {
                                        this.handleAdminFilter(list)
                                    }}
                                    label={head.name}
                                />
                            </th> :

                            (head.key === 'status') ?
                                <th key={key}>
                                    <TableStatusFilterComponent
                                        allFilters={head.filterValue}
                                        action={(list) => {
                                            this.handleStatusFilter(list)
                                        }}
                                        label={head.name}
                                    />
                                </th>
                                :
                                <th key={key}>
                                    <p className="header-text">{head.name}</p>
                                </th>
                )
            }
            return true;
        });
        return (
            <div className="row">
                <div className="col-md-12">
                    <Table striped className="custom-table loyal-table">
                        <thead>
                        <tr className="header-tr">
                            {headers}
                            <th className="text-center action"><p className="header-text action">Action</p></th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableRows}
                        </tbody>

                    </Table>

                    {
                        tableRows.length == 0 && this.props.common.loading === false ?
                          <div className="no-data-message">No results found</div> : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = {
    toggleLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityTableComponent)
