import React, { PureComponent } from 'react';
import { Card, CardBody, Button, Modal, ModalHeader, ModalBody, FormGroup, Row, Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import Error from '../../../../shared/components/form/Error';
import { getBreedsForSpecies, addNewParent, clearClientProps } from '../../../../redux/actions/petActions';
import { toggleLoading } from '../../../../redux/actions/commonActions';
import config from "../../../../config/app.config";
import { validate, reset } from '../../../../libs/validationHelper';
import Select from "react-select";
import DatePickerComponent from "../../Common/DatePickerComponent";

class AddFacility extends PureComponent {
  constructor() {
    super();

    this.state = {
      modal: false,
      inputs: {
        name: '',
        species: '',
        breed: '',
        gender: '',
        birthday: ''
      },
      refreshState: false,
      speciesList: [],
      breedList: [],
      genderList: [
        <option key='' value=''>Select Gender</option>,
        <option key='female' value='female'>Female</option>,
        <option key='male' value='male'>Male</option>,
        <option key='male_neutered' value='male_neutered'>Male Neutered</option>,
        <option key='female_neutered' value='female_neutered'>Female Neutered</option>,
        <option key='unknown' value='unknown'>Unknown</option>,
      ],
      error: false,
      errors: {
        name: { error: null, display: 'name' },
        species: { error: null, display: 'species' },
        breed: { error: null, display: 'breed' },
        gender: { error: null, display: 'gender' },
        birthday: { error: null, display: 'birthday' },
      },
      successResponse: null,
      errorResponse: null,
    };
  };

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    let { inputs, refreshState, breedList } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    if (property === 'species') {
      breedList =  [{ value: '', label: 'Select Breeds' }];
      this.props.getBreedsForSpecies(value);
    }
    this.setState({
      inputs, refreshState, breedList
    });
  };

  onSubmit = () => {
    const { inputs } = this.state;
    const { parent } = this.props;
    this.setState({errorResponse: null, successResponse: null});
    if (!this.validate(inputs)) {
      this.props.toggleLoading(true);
      const data = {};
      data.pets = [inputs];
      data.parentId = parent._id;
      this.props.addNewParent(data);
    }
  };

  closeModal = () => {
    let { inputs, refreshState } = this.state;
    inputs = {
      name: '',
      species: '',
      breed: '',
      gender: '',
      birthday: ''
    };
    let { errors } = this.state;
    const error = false;
    errors = reset(errors);
    this.props.closeNewPetPopup();
    this.setState({
      modal: false, error, errors, successResponse: null, errorResponse: null,
      activeTab: '1', inputs, refreshState: !refreshState
    });
  };

  validate = (inputs) => {
    const { refreshState } = this.state;
    let { errors, activeTab } = this.state;
    const validationPetRules = {
      required: ['name', 'species'],
      date: ['birthday'],
      maxToday: ['birthday'],
    };
    errors = reset(errors);
    const validationResponse = validate(validationPetRules, inputs, errors);
    const error = validationResponse.error;
    errors = validationResponse.errors;

    this.setState({errors, error, errorResponse: null, successResponse: null, refreshState: !refreshState });
    return error;
  };

  onChangeCalender = (e) => {
    let { inputs, refreshState } = this.state;
    inputs.birthday = e;
    this.setState({
      inputs, refreshState: !refreshState,
    });
  };

  onChangeSelect = (e) => {
    if (e) {
      let {inputs, refreshState} = this.state;
      inputs.breed = e.value;
      this.setState({
        inputs, refreshState: !refreshState,
      });
    }
  };

  toggle() {
    let { errors } = this.state;
    const error = false;
    errors = reset(errors);
    this.setState({
      modal: !this.state.modal, error, errors, successResponse: null, errorResponse: null,
    });
  };


  componentDidMount() {
    const { inputs } = this.state;
    this.setState({ inputs, successResponse: null, errorResponse: null });
  };

  componentWillReceiveProps(np) {
    const { view } = np;
    const { inputs } = this.state;
    if (np.pets.breedList && np.pets.breedList.species === inputs.species) {
      let breedList = [];
      if ( np.pets.breedList.breeds.length > 0) {
        breedList = np.pets.breedList.breeds.map((value) => {
          return (
            { value: value._id, label: value.name }
          );
        });
        breedList.unshift({ value: '', label: 'Select Breeds' });
      }
      this.setState({
        breedList
      });
    }

    if (np.pets.newParentResponse) {
      if (np.pets.newParentResponse.data && np.pets.newParentResponse.data.status) {
        const errorResponse = null;
        const successResponse = 'New pet added successfully';
        this.props.clearClientProps();
        this.closeModal();
        this.setState({ errorResponse, successResponse });
      }
    }
    if (np.pets.newParentErrorResponse) {
      if (np.pets.newParentErrorResponse.errorMessage) {
        this.setState({errorResponse: np.pets.newParentErrorResponse.errorMessage, successResponse: null});
      }
    }
    if (view === true && this.state.modal === false) {
      this.setState({
        modal: true,
        successResponse: null,
        errorResponse: null
      });
    }
  };

  render() {
    const { inputs, errors, successResponse, errorResponse, breedList, genderList, refreshState
    } = this.state;
    const { view, speciesList } = this.props;
    const form = (
      <Card>
        <CardBody>
          {successResponse !== null &&
          <div className="alert alert-success fade show" role="alert">
            <div className="alert__content"><p>{successResponse}</p></div>
          </div>
          }
          {errorResponse !== null &&
          <div className="alert alert-danger fade show" role="alert">
            <div className="alert__content"><p>{errorResponse}</p></div>
          </div>
          }
          <form className="form" autoComplete={'off'}>

            <div className="content-holder add-new-pet-wrapper">
              <Collapse isOpen={true}>
                <Row>
                  <div className="col-md-2">
                    <img className="loyal-parent-image" src="/img/default-animal-black.png" alt="avatar"/>
                  </div>
                  <div className="col-md-3">
                    <div className="form__form-group loyal-custom-form-group">
                      <span className="form__form-group-label">Pet Name <span className={'required'}>*</span></span>
                      <div className="form__form-group-field">
                        <FormGroup>
                          <input
                            className="form-control"
                            name="name"
                            type="text"
                            placeholder={'Type pet name'}
                            value={inputs.name}
                            maxLength={50}
                            onChange = {this.onChangeFields}
                          />
                          {errors.name.error && <Error text={errors.name.error}/>}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form__form-group loyal-custom-form-group">
                      <span className="form__form-group-label">Species <span className={'required'}>*</span></span>
                      <div className="form__form-group-field">
                        <FormGroup>
                          <select name="species" id="species"
                                  className="form-control form-select"
                                  value={inputs.species}
                                  onChange = {this.onChangeFields}>
                            {speciesList}
                          </select>
                          {errors.species.error && <Error text={errors.species.error}/>}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form__form-group loyal-custom-form-group">
                      <span className="form__form-group-label">Breed</span>
                      <div className="form__form-group-field">
                        <FormGroup>
                          <Select
                            value={inputs.breed}
                            onChange={this.onChangeSelect}
                            className={'custom-select-control'}
                            options={breedList}
                            placeholder={'Select Breed'}
                            matchProp="label"
                            isClearable={false}
                            name="breed"
                            id="breed"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-2"> </div>
                  <div className="col-md-3">
                    <div className="form__form-group loyal-custom-form-group">
                      <span className="form__form-group-label">Gender</span>
                      <div className="form__form-group-field">
                        <FormGroup>
                          <select name="gender" id="gender"
                                  className="form-control form-select"
                                  value={inputs.gender}
                                  onChange = {this.onChangeFields}>
                            {genderList}
                          </select>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form__form-group loyal-custom-form-group form-group">
                      <span className="form__form-group-label">Birthday</span>
                      <div className="form__form-group-field">
                        <FormGroup>
                          <DatePickerComponent
                            viewMode={ false } maxDate={true} minDate={false} is={true}
                            refreshState={ refreshState }
                            date={ inputs.birthday }
                            handleChange={this.onChangeCalender}/>
                          {errors.birthday.error && <Error text={errors.birthday.error}/>}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                  </div>
                </Row>
                <div className="row">
                  <div className="col-md-6">
                  </div>
                  <div className="col-md-6 text-right">
                    <Button className={'clear'} color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                    <Button className={'save-update'} color="primary" onClick={this.onSubmit}>Save</Button>
                  </div>
                </div>
              </Collapse>
            </div>
          </form>
        </CardBody>
      </Card>
    );

    return (
      <Modal isOpen={view}
             backdrop={'static'}
             className={'model-form'}>
        <ModalHeader className={'header-form'}>Add New Pet</ModalHeader>
        <ModalBody>
          {form}
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  toggleLoading, getBreedsForSpecies, addNewParent, clearClientProps
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFacility)
