import React, { PureComponent } from 'react';
import {Card, CardBody, Col, FormGroup, Label} from 'reactstrap';
import TableComponent from './TableComponent';
import Pagination from '../../../shared/components/pagination/Pagination';
import  { connect } from 'react-redux';
import moment from 'moment';
import humanize from  "underscore.string/humanize";
import { getPatientList, getSpecies, getBreeds, updatePetStatus, clearUpdatePetStatus } from '../../../redux/actions/petActions';
import { toggleLoading } from '../../../redux/actions/commonActions';
import config from "../../../config/app.config";
import SearchIcon from "mdi-react/SearchIcon";
import MessageModal from "../../../shared/components/Modals/MessageModal";
import HeadingText from "../../../shared/components/form/HeadingText";
import { formatPhone } from "../../../libs/commonHelper";
import TimeFilter from "../Common/TimeFilter";
import { DebounceInput } from "../Common/DebounceInput";

class ManagePatients extends PureComponent {
  constructor() {
    super();
    this.heads = [
      {
        key: 'petName',
        name: 'Pet Name',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'parentName',
        name: 'Parent Name',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'speciesName',
        name: 'Species',
        sortable: false,
        filterable: true,
        filterValue: [],
        capitalize: true,
      },
      {
        key: 'breedName',
        name: 'Breed',
        sortable: false,
        filterable: true,
        filterValue: [],
        capitalize: true,
      },
      {
        key: 'gender',
        name: 'Gender',
        sortable: false,
        filterable: true,
        filterValue: [{ 'male': 'male' },{ 'female': 'female' }, { 'male_neutered': 'male neutered' }, { 'female_neutered': 'female neutered' },{ 'unknown': 'unknown' }],
        capitalize: true,
      },
      {
        key: 'phoneEmail',
        name: 'Mobile Number',
        sortable: false,
        filterable: false,
        capitalize: false,
      },
      {
        key: 'createdAt',
        name: 'Registered date',
        sortable: false,
        filterable: false,
        capitalize: false,
      },
      {
        key: 'lastAppointmentDate',
        name: 'Latest Appointment Date',
        sortable: false,
        filterable: false,
        capitalize: false,
      },
      {
        key: 'status',
        name: 'Pet Status',
        sortable: false,
        filterable: true,
        filterValue: [{ 'active': 'active' },{ 'inactive': 'inactive' }],
        capitalize: true,
      },
    ];

    this.state = {
      rows: [],
      refreshState: false,
      inputs: {
        search: '',
        sort: {
          field: 'createdAt',
          order: -1
        },
        filters: { timeFilter: null },
        page: 1,
        limit: config.DEFAULT_STAFF_PAGINATION_LENGTH,
        offset: 0,
        total: 0,
      },
      timeFilterInitial: null,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseAlertType: '',
        responseType: '',
        responseTitle: '',
        pet: null,
        status: null,
        confirmType: null
      },
    };

    this.handleTimeFilterChange = this.handleTimeFilterChange.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
  }

  componentDidMount(){
    window.Beacon('init', 'd808c23f-0e57-44c3-8130-9ca84e5c88c7');
    const { inputs } = this.state;
    this.props.getSpecies();
    this.props.getBreeds();
    this.props.getPatientList(inputs);
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.pets.patientList) {
      this.setPatientData(nextProps.pets.patientList);
    }
    if (nextProps.pets.speciesList) {
      const { refreshState } = this.state;
      const speciesList = nextProps.pets.speciesList.map(species => ({ [species._id]: species.name }));
      this.heads[2].filterValue = speciesList;
      this.setState({ refreshState : !refreshState });
    }
    if (nextProps.pets.allBreedList) {
      const { refreshState } = this.state;
      const breedList = nextProps.pets.allBreedList.map(breed => ({ [breed._id]: breed.name }));
      this.heads[3].filterValue = breedList;
      this.setState({ refreshState : !refreshState });
    }
    if (nextProps.pets.petUpdateStatus) {
      const { refreshState, confirmPopup } = this.state;
      if (nextProps.pets.petUpdateStatus.errorMessage) {
        confirmPopup.showResponse = true;
        confirmPopup.responseType = 'alert';
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage = nextProps.pets.petUpdateStatus.errorMessage;
        this.setState({ confirmPopup,  refreshState: !refreshState });
        this.props.clearUpdatePetStatus();
      } else {
        if (nextProps.pets.petUpdateStatus.status && nextProps.pets.petUpdateStatus.status === 'confirm') {
          confirmPopup.showResponse = true;
          confirmPopup.responseMessage = nextProps.pets.petUpdateStatus.message;
          confirmPopup.responseType = 'confirm';
          confirmPopup.confirmType = 'request_confirmed';
          this.setState({ confirmPopup,  refreshState: !refreshState });
          this.props.clearUpdatePetStatus();
        } else {
          confirmPopup.showResponse = true;
          confirmPopup.responseMessage = `${confirmPopup.pet.petName} ${(confirmPopup.status === 'active') ? 'activated' : 'deactivated'}`;;
          confirmPopup.responseType = 'alert';
          confirmPopup.responseAlertType = 'success';
          this.setState({ confirmPopup,  refreshState: !refreshState });
          this.reFreshTableData();
        }
      }
      this.setState({ confirmPopup,  refreshState: !refreshState });
    }
  };

  setPatientData = (data) => {
    const { inputs } = this.state;
    inputs.total = data.total;
    this.setState({ inputs });
    this.createRows(data.data);
  };

  handleActiveStatus = (status, row) => {
    const statusMessage = `Are you sure you want to ${(status === 'inactive') ? 'deactivate' : 'activate' } ${row.petName}?`;
    const confirmPopup =  {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      pet: row,
      footer: true,
      confirmType: 'change_status',
      status
    };
    this.setState({ confirmPopup });
  };

  onSuccess = () => {
    const { confirmPopup, refreshState } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.toggleLoading(true);
      this.props.updatePetStatus({ status: confirmPopup.status, id: confirmPopup.pet.id });
    }
    if (confirmPopup.confirmType === 'request_confirmed') {
      this.props.toggleLoading(true);
      this.props.updatePetStatus({ status: confirmPopup.status, id: confirmPopup.pet.id, confirmed: true });
    }
    confirmPopup.showResponse = false;
    this.setState({
      refreshState: !refreshState,
      confirmPopup
    })
  };

  closePopup = () => {
    const { confirmPopup, refreshState } = this.state;
    confirmPopup.showResponse = false;
    confirmPopup.confirmType = null;
    this.setState({ confirmPopup, refreshState: !refreshState });
  };

  createRows = (data) => {
    const rows = [];
    data.forEach(function(row) {
      rows.push({
        petName:row.petName,
        parentName:row.parentName,
        speciesName: row.speciesName,
        breedName: row.breedName || 'N/A',
        gender: humanize(row.gender) || 'N/A',
        phoneEmail: (row.email) ? row.email : formatPhone(row.phone,  '(###) ###-####'),
        createdAt: row.createdAt || 'N/A',
        lastAppointmentDate: row.lastAppointmentDate || 'N/A',
        status: row.status,
        action: '',
        parentId: row.parentId,
        id: row._id,
      });
    });
    this.setState({ rows });
  };

  handleSearch = (e) => {
    let { inputs } = this.state;
    inputs.search = e.target.value;
    inputs.offset = 0;
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  nextPage = () => {
    let { inputs } = this.state;
    inputs.offset = parseInt(inputs.offset + inputs.limit);
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  prevPage = () => {
    let { inputs } = this.state;
    inputs.offset = parseInt(inputs.offset - inputs.limit);
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  gotoPage = (i) => {
    let { inputs } = this.state;
    inputs.offset = parseInt(inputs.limit * i);
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  handleGridSort = (field) => {
    let { inputs } = this.state;
    if (field === inputs.sort.field) {
      inputs.sort.order = (inputs.sort.order === 1) ? -1 : 1;
    } else {
      inputs.sort.field = field;
    }
    inputs.offset = 0;
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  handleGridFilter = (header, value) => {
    let { inputs } = this.state;
    inputs.filters[header] = value;
    inputs.offset = 0;
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  handlePageLimit = (perPage) => {
    let { inputs } = this.state;
    inputs.limit = perPage;
    inputs.offset = 0;
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  reFreshTableData = () => {
    const { inputs } = this.state;
    this.props.getPatientList(inputs);
  };

  viewProfile = (selectedRow) => {
    this.props.history.push(`/client-list/profile/${selectedRow.parentId}`);
  };

  handleTimeFilterChange(timeFilter) {

    const { inputs } = this.state;
    const timeFilterInitial = timeFilter;
    this.setState({
      timeFilterInitial
    }, () => {
    });
    inputs.timeFilter = timeFilter;
    delete (inputs.filters.timeStart);
    delete (inputs.filters.timeEnd);
    this.setState({
      inputs
    }, () => {
      this.props.getPatientList(inputs);
    });
  };

  setDateRange(range) {
    const { inputs } = this.state;
    inputs.filters.timeFilter = 'range';
    inputs.filters.timeStart = moment(range.startDate).format('MM/DD/YYYY');
    inputs.filters.timeEnd = moment(range.endDate).format('MM/DD/YYYY');
    const timeFilterInitial = 'range';
    this.setState({
      inputs, timeFilterInitial
    }, () => {
      if(range.startDate !== range.endDate){
        this.props.getPatientList(inputs);
      }
    });
  };

  goToAppointment = () => {
    this.props.history.push('/new-appointment');
  }

  render() {
    const { rows, confirmPopup, inputs, timeFilterInitial } = this.state;
    return (
      <Col md={12} lg={12}  className="manage-patient-container admin-container">
        <Card>
          <CardBody>
            <HeadingText text="Client List" />
            <div className="row search form">
              <div className="col-sm-7">

                <div className="form__form-group">
                  <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
                  <div className="form__form-group-field custom-shadow custom-border-radius loyal-staff-search">
                    <div className="form__form-group-icon form__form-group-icon--left form__form-group-search">
                      <SearchIcon />
                    </div>
                    <DebounceInput className="form__custom-field form__custom-field--icon-left"
                                   placeholder="Search by pet name or parent name, mobile number"
                                   name="search"
                                   id="search"
                                   onChange={this.handleSearch}
                                   value={inputs.search}
                                   debounceTimeout={100}/>
                  </div>
                </div>
              </div>
              <div className="col-sm-5">
              <button className={'btn btn-outline float-right new-appointment-action schedule'} onClick={this.goToAppointment}>
                <span className="lnr lnr-plus-circle"></span> Create Appointment</button>
              </div>
            </div>
            <div className="row time-filter-wrapper">
              <div className="col-8">
                <TimeFilter changeFilter={this.handleTimeFilterChange}
                            selected={timeFilterInitial} setDateRange={this.setDateRange}/>
              </div>
            </div>
            <TableComponent sortField={inputs.sort.field} sortOrder={inputs.sort.order}
                                    heads={this.heads} rows={rows}
                                    viewProfile = {(field) => this.viewProfile(field)}
                                    handleGridSort={(field) => this.handleGridSort(field)}
                                    handleGridFilter={(header, field) => this.handleGridFilter(header, field)}
                                    handleActiveStatus={(status,id, joined, firstName) => this.handleActiveStatus(status,id, joined, firstName)} />
            <Pagination handlePageLimit={(limit) => this.handlePageLimit(limit)}
                        nextPage={this.nextPage}
                        prevPage={this.prevPage} gotoPage={(i) => this.gotoPage(i)} limit={inputs.limit}
                        offset={inputs.offset} total={inputs.total}/>
          </CardBody>
        </Card>
        <MessageModal show={confirmPopup.showResponse}
                      type={confirmPopup.responseType}
                      alertType={confirmPopup.responseAlertType}
                      footer={confirmPopup.footer}
                      onSuccess={this.onSuccess}
                      onClose={this.closePopup}
                      message={confirmPopup.responseMessage}/>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getPatientList, getSpecies, getBreeds, updatePetStatus, toggleLoading, clearUpdatePetStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePatients)

