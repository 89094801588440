import React, {Component, Fragment} from 'react';
import moment from 'moment';
import { Card, CardBody, Col } from 'reactstrap';
import { connect } from 'react-redux';
import io from 'socket.io-client';

import FindParent from '../../Common/FindParent';
import SelectPet from '../../Common/SelectPet';
import Create from './CreateAppointmentForm';
import { getParent, clearPetList } from '../../../../redux/actions/petActions';
import { createAppointment, clearAppointmentStore } from '../../../../redux/actions/appointmentAction';
import { reinvite, clearInviteStore } from '../../../../redux/actions/clientActions';
import MessageModal from "../../../../shared/components/Modals/MessageModal";
import config from '../../../../config/app.config';
import { setTimeout } from 'timers';

const { SOCKET_URL } = config;
const socket = io(SOCKET_URL);


class CreateAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectedFromList: false,
      isFindParentOpen: true,
      petCollapse: false,
      selectedParent: null,
      defaultParent: null,
      selectedPet: '',
      shareUpdates: false,
      appointmentDate: null,
      appointmentTime: null,
      time: '',
      appointmentType: '',
      appointmentTypeOther: '',
      doctor: '',
      referringDoctor:'',
      pet: null,
      errors: {
        appointmentDate: null,
        time: null,
        appointmentType: null,
        appointmentTypeOther: null,
        doctor: null,
        referringDoctor: null
      },
      responseBox: {
        showResponse: false,
        responseType: '',
        responseAlertType: '',
        responseFooter: '',
        message: '',
        onSuccess: null,
        appointment: null
      },
      isCreateOpen: false,
      saveSuccess: false
    }

    this.setSelectedParent = this.setSelectedParent.bind(this);
    this.registerNew = this.registerNew.bind(this);
    this.selectPet = this.selectPet.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShareUpdates = this.handleShareUpdates.bind(this);
    this.handledateChange = this.handledateChange.bind(this);
    this.submit = this.submit.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.validate = this.validate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.createAppointment = this.createAppointment.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.props.clearPetList();
  }

  componentWillReceiveProps(np) {
    if (np.history.location && np.history.location.state) {
      this.setState({
        selectedParent: np.history.location.state.user,
        isRedirectedFromList: true,
        petCollapse: true,
        defaultParent: np.history.location.state.user
      });
    }
    const { responseBox, selectedParent, saveSuccess } = this.state;
    if(np.appointments && np.appointments.createAppointment && !saveSuccess && selectedParent){

      responseBox.showResponse = true;
      responseBox.responseFooter = true;
      responseBox.responseType = 'alert';
      responseBox.message = `Appointment successfully created. We've notified ${selectedParent.name}`;
      responseBox.onSuccess = this.onClose;

      this.setState({
        saveSuccess: true,
        responseBox
      });

      const data = np.appointments.createAppointment.data;
      data.pet = {
        _id: data.pet
      }
      socket.emit('phaseUpdated',{facilityId: data.facility, appointment: data });
      setTimeout(() => {
        this.props.history.push('/empty');
        this.props.history.push('/new-appointment');
      }, 2000);
    }
    if(np.client.statusUpdated && selectedParent){
      responseBox.showResponse = true;
      responseBox.responseFooter = true;
      responseBox.responseType = 'alert';
      responseBox.message = `Invitation resent to ${selectedParent.name}`;
      responseBox.onSuccess = this.onClose;

      this.setState({
        responseBox
      });
    }
  }

  handleChange(e){
    const fieldName = e.target.name;
    this.setState({
      [fieldName]: e.target.value
    });
  }

  selectPet(selectedPet){
    const { selectedParent } = this.state;
    if (selectedParent) {
      this.setState({
        pet: selectedPet,
        selectedPet: selectedPet._id,
        isCreateOpen: true
      });
    }
  }

  setSelectedParent(selectedParent){
    this.setState({
      selectedParent,
      petCollapse: true,
      selectedPet: '',
      isCreateOpen: false
    });
  }

  registerNew() {
    this.props.history.push({
      pathname: '/add-clients',
      openNewParent: true,
    });
  }

  handleShareUpdates() {
    this.setState({
      shareUpdates: !this.state.shareUpdates
    });
  }

  handledateChange(appointmentDate) {
    this.setState({
      appointmentDate
    });
  }

  handleTimeChange = (time) => {
    if (time) {
      const appointmentTime = moment(time);
      this.setState({
        appointmentTime
      });
    }
  }

  onChangeSelect(e, type, resetShareUpdates = false) {
    const state = this.state;
    if (!e) {
      state[type] = null;
      state.shareUpdates = false; 
    } else {
      if (type === 'referringDoctor') {
        state.shareUpdates = !resetShareUpdates;
      }
      state[type] = e.value;
    }

    this.setState(state);
  }

  validate() {
    const { selectedParent: parentId, selectedPet: petId, shareUpdates: shareUpdate,
      appointmentDate, appointmentTime, time, appointmentType, appointmentTypeOther, doctor, referringDoctor, errors} = this.state;

    let dateTime = moment(appointmentDate);

    if (appointmentTime) {
      const dt = moment(appointmentDate).format('MM/DD/YYYY');
      const apDate = `${dt} ${appointmentTime.format('hh:mm A')}`;

      dateTime = moment(apDate);
    }

    const difference = dateTime.diff(moment.now());

    if(!appointmentDate){
      errors.appointmentDate = "Appointment date cannot be empty";
      this.setState({
        errors
      });
      return true;
    } else if (!dateTime.isValid()) {
      errors.appointmentDate = "Appointment date is invalid";
      this.setState({
        errors
      });
      return true;
    } else if (difference < 0) {
      errors.appointmentDate = "Appointment cannot be a past time";
      this.setState({
        errors
      });
      return true;
    } else {
      errors.appointmentDate = null;
      this.setState({
        errors
      });
    }

    if(!appointmentTime){
      errors.time = "Appointment time cannot be empty";
      this.setState({
        errors
      });
      return true;
    } else {
      errors.time = null;
      this.setState({
        errors
      });
    }

    if(!appointmentType){
      errors.appointmentType = "Appointment type cannot be empty";
      this.setState({
        errors
      });
      return true;
    } else {
      errors.appointmentType = null;
      this.setState({
        errors
      });
    }

    if(appointmentType === config.appointmentTypes.other && (!appointmentTypeOther || appointmentTypeOther.trim() === "")){
      errors.appointmentTypeOther = "Appointment type cannot be empty";
      this.setState({
        errors
      });
      return true;
    } else {
      errors.appointmentTypeOther = null;
      this.setState({
        errors
      });
    }
  }

  createAppointment(){
    const { selectedParent: parentId, selectedPet: pet, shareUpdates,
      appointmentDate, appointmentTime, time, appointmentType: type, doctor, referringDoctor, responseBox, appointmentTypeOther: typeOther} = this.state;

    const data = {
      parentId: parentId._id,
      pet,
      appointmentDate: moment(appointmentDate).format('MM/DD/YYYY'),
      time: appointmentTime.format('hh:mm A'),
      type,
      typeOther: typeOther.trim(),
      doctor: doctor === "" ? null : doctor,
      referringDoctor: referringDoctor === "" ? null : referringDoctor,
      shareUpdates,
    }

    this.onClose();
    this.props.createAppointment(data);

  }

  cancel() {
    if(this.state.isRedirectedFromList){
      this.props.history.push({
        pathname: `client-list/profile/${this.state.selectedParent._id}`,
        activeTab: '3'
      });
    } else {
      this.setState(
        {
          petCollapse: false,
          selectedParent: null,
          selectedPet: '',
          shareUpdates: false,
          appointmentDate: null,
          appointmentTime: null,
          time: '',
          appointmentType: '',
          appointmentTypeOther: '',
          doctor: '',
          referringDoctor:'',
          errors: {
            appointmentDate: null,
            time: null,
            appointmentType: null,
            doctor: null,
            referringDoctor: null
          },
          responseBox: {
            showResponse: false,
            responseType: '',
            responseAlertType: '',
            responseFooter: '',
            message: '',
            onSuccess: null
          },
          isCreateOpen: false,
          saveSuccess: false,
          resetParent: true
        },
        () => {
          this.setState({
            resetParent: false
          });
        }
      );
    }
  }

  getUserData(){
    const {selectedParent, appointmentDate, appointmentTime, pet } = this.state;
    return {
      appointmentDate,
      pet: pet.name,
      appointmentTime:  moment(appointmentTime,'HH:mm').format('hh:mm A'),
      parent: `${selectedParent.firstName} ${selectedParent.lastName}`,
    }
  }
  submit() {
    const { responseBox} = this.state;

    if(this.validate()){
      return;
    }

    responseBox.showResponse = true;
    responseBox.responseFooter = true;
    responseBox.responseType = 'confirm';
    responseBox.message = 'Do you want to go ahead and create this appointment?';
    responseBox.onSuccess = this.createAppointment;
    responseBox.appointment = this.getUserData()

    this.setState({
      responseBox
    });
  }

  onClose(){
    const { responseBox } = this.state;

    responseBox.showResponse = false;
    responseBox.responseType = '';
    responseBox.responseAlertType = '';
    responseBox.responseFooter = '';
    responseBox.message = '';
    responseBox.appointment = null

    this.setState({
      responseBox
    });

    this.props.clearInviteStore();
    this.props.clearAppointmentStore();

  }

  render() {
    const { isFindParentOpen, selectedParent, petCollapse, selectedPet, appointmentDate, appointmentTime,
      time, appointmentType, doctor, referringDoctor, shareUpdates, errors, responseBox, isCreateOpen,
      saveSuccess, defaultParent, isRedirectedFromList } = this.state;

    const createData = {
      shareUpdates,
      appointmentDate,
      appointmentTime,
      time,
      appointmentType,
      doctor,
      referringDoctor,
      errors,
      collapse: isCreateOpen
    };
    return (
      <Fragment>
        <MessageModal show={responseBox.showResponse}
                      type={responseBox.responseType}
                      alertType={responseBox.responseAlertType}
                      footer={responseBox.responseFooter}
                      onSuccess={responseBox.onSuccess}
                      onClose={this.onClose}
                      appointment={responseBox.appointment}
                      message={responseBox.message}/>

        <div className="create-appointment">
          <Col md={12} lg={12} >
            <Card>
              <CardBody>
                <label className={`heading-text ${saveSuccess && 'highlight-green'}`}>
                  {saveSuccess ? 'Appointment Successfully Created' : 'New Appointment'}
                </label>
                <div className="content-wrapper">
                  <FindParent collapse={isFindParentOpen}
                              defaultParent={defaultParent}
                              setSelectedParent={this.setSelectedParent}
                              reset={this.state.resetParent}
                              registerNew={this.registerNew}
                              reInvite={this.props.reinvite}
                              viewMode={saveSuccess}/>
                  <SelectPet selectedParent={selectedParent} collapse={petCollapse}
                             selectPet={this.selectPet}
                             selectedPet={selectedPet} viewMode={saveSuccess}/>
                  <Create handleChange={this.handleChange}
                          reschedule={false}
                          createData={createData}
                          handledateChange={this.handledateChange}
                          handleTimeChange={(e) => this.handleTimeChange(e)}
                          onChangeSelect={this.onChangeSelect}
                          handleShareUpdates={this.handleShareUpdates}
                          appointmentDate={this.state.appointmentDate}
                          appointmentTime={this.state.appointmentTime}
                          viewMode={saveSuccess}/>
                  <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6 text-right">
                      {
                        petCollapse &&
                        (
                          !saveSuccess ?
                            <Fragment>
                              <button disabled={!petCollapse} className={'btn loyal-btn-clear'} onClick={this.cancel}>Cancel</button>{' '}
                              <button disabled={!isCreateOpen} className={'btn loyal-btn-save-update'} onClick={this.submit}>Create Appointment</button>
                            </Fragment> :
                            <Fragment>
                              <button disabled={!petCollapse} className={'btn loyal-btn-clear'} onClick={this.cancel}>Cancel</button>{' '}
                              <button className={'btn loyal-btn-save-update'} onClick={() => { this.props.history.push({
                                pathname: `client-list/profile/${selectedParent._id}`,
                                activeTab: '3'
                              })}}>View Appointments</button>
                            </Fragment>

                        )
                      }
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Fragment>

    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getParent,
  createAppointment,
  reinvite,
  clearInviteStore,
  clearAppointmentStore,
  clearPetList
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppointment)
