import { handleRequest } from '../services/APIService';

export const FETCH_ADMINS = 'FETCH_ADMINS';
export const FETCH_ADMINS_ERROR = 'FETCH_ADMINS_ERROR';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_STATUS_ERROR = 'CHANGE_STATUS_ERROR';
export const IS_PRIMARY = 'IS_PRIMARY';
export const IS_PRIMARY_ERROR = 'IS_PRIMARY_ERROR';
export const INVITE_ADMIN = 'INVITE_ADMIN';
export const INVITE_ADMIN_ERROR = 'INVITE_ADMIN_ERROR';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';
export const REINVITE_ADMIN = 'REINVITE_ADMIN';
export const REINVITE_ADMIN_ERROR = 'REINVITE_ADMIN_ERROR';

export const getAdmins = (data) => dispatch => {
  handleRequest('post', `manage-admins/get-list`, true, data, )
  .then( (results) => {
    return dispatch ({
      type: FETCH_ADMINS,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: FETCH_ADMINS,
      payload: error
    });
  })
};


export const changeStatus = (data) => dispatch => {
  handleRequest('post', `manage-admins/change-status`, true, data, )
  .then( (results) => {
    return dispatch ({
      type: CHANGE_STATUS,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: CHANGE_STATUS_ERROR,
      payload: false
    });
  })
};

export const changePrimary = (facilityId) => dispatch => {
  handleRequest('get', `manage-admins/is-primary-admin-exist?facilityId=${facilityId}`, true )
  .then( (results) => {
    return dispatch ({
      type: IS_PRIMARY,
      payload: results.data.status
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: IS_PRIMARY_ERROR,
      payload: error
    });
  })
};


export const saveAndInvite = (data) => dispatch => {
  handleRequest('post', `manage-admins/invite-admin`, true, data )
  .then( (results) => {
    return dispatch ({
      type: INVITE_ADMIN,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: INVITE_ADMIN_ERROR,
      payload: error
    });
  })
};

export const update = (data) => dispatch => {
  handleRequest('post', `manage-admins/update-admin`, true, data )
  .then( (results) => {
    return dispatch ({
      type: UPDATE_ADMIN,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: UPDATE_ADMIN_ERROR,
      payload: error
    });
  })
};

export const reinvite = (data) => dispatch => {
  handleRequest('post', `manage-admins/reinvite-admin`, true, data )
  .then( (results) => {
    return dispatch ({
      type: REINVITE_ADMIN,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: REINVITE_ADMIN_ERROR,
      payload: error
    });
  })
};