import React, { PureComponent } from 'react';
import {Card, CardBody, Col, FormGroup, Label} from 'reactstrap';
import FacilityTableComponent from './FacilityTableComponent';
import Pagination from '../../shared/components/pagination/Pagination';
import commonHelper from '../../libs/commonHelper';
import  { connect } from 'react-redux';

import { getFacilityList, updateFacilityStatus } from '../../redux/actions/facilityActions';
import config from "../../config/app.config";
import SearchIcon from "mdi-react/SearchIcon";
import PlusIcon from "mdi-react/PlusCircleOutlineIcon";
import AddFacility from "./AddFacility";
import MessageModal from "../../shared/components/Modals/MessageModal";
import HeadingText from "../../shared/components/form/HeadingText";
import UpdateFacility from "./UpdateFacility";
import { toggleLoading } from "../../redux/actions/commonActions";

class Facility extends PureComponent {
  constructor() {
    super();
    this.heads = [
      {
        key: 'displayName',
        name: 'Facility Name',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'city',
        name: 'City',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'zipCode',
        name: 'Zip Code',
        sortable: false,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'admin',
        name: 'Facility Admin',
        sortable: false,
        filterable: true,
        filterValue: [],
        capitalize: true,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: false,
        filterable: true,
        filterValue: [{'active':'active'},{'inactive':'inactive'}],
        capitalize: true,
      },
    ];

    this.state = {
      rows: [],
      filters:{},
      sortField: 'name',
      sortOrder: 1,
      search: '',
      offset: 0,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseTitle: '',
        clientId: null,
        status: null,
        confirmType: null
      },
      newFacilityPopup: false,
      updateFacilityPopup: false,
      selectedFacility: {},
      selectedId: null,
    };
  }

  componentWillMount() {
    this.props.toggleLoading(true);
  }

  componentDidUpdate(np){
    if(np.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  componentDidMount(){
    const { offset, limit, sortField, sortOrder } = this.state;
    this.props.getFacilityList({offset, limit, sortField, sortOrder});
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.facility.facility) {
      const propsData = nextProps.facility.facility;
      this.setState({ total: propsData.total});
      this.createRows(propsData.facilities);
      this.heads[3].filterValue = propsData.adminList;
    }
    if (nextProps.facility.statusUpdated) {
      const confirmPopup =  {
        showResponse: true,
        responseMessage: '',
        responseType: 'alert',
        responseAlertType: null,
        footer: true,
      };
      if (nextProps.facility.statusUpdated.errorMessage) {
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage = nextProps.facility.statusUpdated.errorMessage;
      } else {
        let alertMessage = '';
        switch (nextProps.facility.statusUpdated.status) {
          case 'active':
            alertMessage = `This profile is activated now`;
            break;
          case 'inactive':
            alertMessage = `This profile is deactivated now`;
            break;
          case 'removed':
            alertMessage = `This profile is removed now`;
            break;
        }
        confirmPopup.responseMessage = alertMessage;
      }
      const _this = this;
      this.setState({ confirmPopup,  showResponse: true }, function () {
        _this.reFreshTableData();
      });
    }
  }

  handleActiveStatus = (status,_id) =>{
    let statusMessage = '';
    switch (status) {
      case 'active':
        statusMessage = `Are you sure you want to activate this facility profile? `;
        break;
      case 'inactive':
        statusMessage = `Are you sure you want to deactivate this facility profile? `;
        break;
      case 'removed':
        statusMessage = `Are you sure you want to remove this facility profile? `;
        break;
    }
    const confirmPopup =  {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      clientId: _id,
      footer: true,
      confirmType : 'change_status',
      status
    };
    this.setState({ confirmPopup,  showResponse: true });
  };

  onSuccess = () => {
    const { confirmPopup } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.updateFacilityStatus({ status:confirmPopup.status, id: confirmPopup.clientId });
    }
    confirmPopup.showResponse = false;
    this.setState({
      showResponse: false,
      confirmPopup
    });
  }

  closePopup = () => {
    const { confirmPopup } = this.state;
    confirmPopup.showResponse = false;
    confirmPopup.confirmType = null;
    this.setState({ confirmPopup, showResponse: false });
  };

  createRows = (data) => {
    const rows = [];
    data.forEach(function(row) {
      rows.push({
        name:row.name,
        displayName:row.displayName,
        city: row.city,
        zipCode: row.zipCode,
        admin: (row.adminName !== null) ?row.adminName : 'N/A',
        status: (row.status).replace(/^\w/, c => c.toUpperCase()),
        action: '',
        id: row._id,
        adminName: row.adminName,
        removePossibility: row.removePossibility
      });
    });
    this.setState({ rows });
  };

  nextPage = () => {
    let { offset, limit, sortField, sortOrder, search } = this.state;
    offset = parseInt(offset + limit);
    this.setState({ offset}, () => {
      this.props.getFacilityList({offset, limit, sortField, sortOrder, search});
    });
  }

  prevPage = () => {
    let { offset, limit, sortField, sortOrder, search } = this.state;
    offset = parseInt(offset - limit);
    this.setState({ offset}, () => {
      this.props.getFacilityList({ offset, limit, sortField, sortOrder, search});
    });
  }

  gotoPage = (i) => {
    let { offset, limit, sortField, sortOrder, search } = this.state;
    offset = parseInt(limit * i);
    this.setState({ offset}, () => {
      this.props.getFacilityList({ offset, limit, sortField, sortOrder, search});
    });
  }

  handleGridSort = (field) => {
    let {limit, sortField, sortOrder, search, filters} = this.state;
    let order = (sortOrder === 1)? -1 : 1;
    sortField = field;
    sortOrder = order;

    this.setState({sortField, sortOrder, offset: 0}, () => {
      this.props.getFacilityList({offset: 0, limit, sortField: field, sortOrder: order, search, filters});
    });
  };

  handleGridFilter = (header, value) => {
    let {limit, sortField, sortOrder, search, filters} = this.state;
    filters[header] = value;
    this.setState({ filters, offset: 0}, () => {

      this.props.getFacilityList({offset: 0, limit, sortField, sortOrder, search, filters});
    });
  };

  handleSearch = () => {
    let { limit, sortField, sortOrder, search } = this.state;
    search = this.searchVal.value;
    this.setState({ search, offset: 0}, () => {
      this.props.getFacilityList({offset: 0, limit, sortField, sortOrder, search: this.searchVal.value});
    });
  };

  handlePageLimit = (perPage) => {
    let { sortField, sortOrder, search } = this.state;
    this.setState({ limit: perPage, offset: 0}, () => {
      this.props.getFacilityList({offset: 0, limit: perPage, sortField, sortOrder, search});
    });
  };

  newFacilityPopup = () => {
    this.setState({ newFacilityPopup: true });
  };

  closeFacilityPopup = () => {
    const offset = 0;
    this.setState({ newFacilityPopup: false, selectedId: null, offset }, function () {
      this.reFreshTableData();
    });
  };

  closeViewFacilityPopup = () => {
    const offset = 0;
    this.setState({ updateFacilityPopup: false, selectedId: null, offset }, function () {
      this.reFreshTableData();
    });
  };

  reFreshTableData = () => {

    const { offset, limit, sortField, sortOrder, search, filters } = this.state;
    this.props.getFacilityList({offset, limit, sortField, sortOrder, search, filters});
  };

  viewFacility = (selectedFacility) => {
    this.setState({ selectedId: selectedFacility.id, updateFacilityPopup: true, selectedFacility });
  };

  render() {
    const { rows, total, offset, limit, search, sortField, sortOrder, confirmPopup,
      newFacilityPopup, updateFacilityPopup, selectedId } = this.state;
    const staticData = {};
    const isSuperAdmin = commonHelper.isSuperAdmin();
    const heading = isSuperAdmin ? 'Facilities' : 'Manage Facilities';
    return (
      <Col md={12} lg={12}  className="manage-staff-container admin-container">
        <Card>
          <CardBody>
            <HeadingText text={heading} />
            <div className="row search form">
              <div className="col-sm-8">

                <div className="form__form-group">
                  <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
                  <div className="form__form-group-field custom-shadow custom-border-radius">
                    <div className="form__form-group-icon form__form-group-icon--left form__form-group-search">
                      <SearchIcon />
                    </div>
                    <input
                        name="search"
                        id="search"
                        type="text"
                        onChange={this.handleSearch}
                        ref={input => this.searchVal = input}
                        value={search}
                        placeholder="Search by facility name"
                        className="form__custom-field form__custom-field--icon-left"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
                  <button className="btn btn-outline-primary pull-right top-panel-button"
                          onClick={this.newFacilityPopup}> <PlusIcon /> Add New Facility</button>
                </FormGroup>
              </div>
            </div>
            <FacilityTableComponent sortField={sortField} sortOrder={sortOrder}
                                     heads={this.heads} rows={rows}
                                     viewProfile = {(field) => this.viewFacility(field)}
                                     handleGridSort={(field) => this.handleGridSort(field)}
                                     handleGridFilter={(header, field) => this.handleGridFilter(header, field)}
                                     handleActiveStatus={(status,id, joined, firstName) => this.handleActiveStatus(status,id, joined, firstName)} />
            <Pagination handlePageLimit={(limit) => this.handlePageLimit(limit)} nextPage={this.nextPage} prevPage={this.prevPage} gotoPage={(i) => this.gotoPage(i)} limit={limit} offset={offset} total={total}/>
            <AddFacility view={newFacilityPopup} closeFacilityPopup={this.closeFacilityPopup} data={staticData}/>
            <UpdateFacility view={updateFacilityPopup} closeFacilityPopup={this.closeViewFacilityPopup}
                            selectedId={selectedId} data={staticData}/>
          </CardBody>
        </Card>
        <MessageModal show={confirmPopup.showResponse}
                      type={confirmPopup.responseType}
                      alertType={confirmPopup.responseAlertType}
                      footer={confirmPopup.footer}
                      onSuccess={this.onSuccess}
                      onClose={this.closePopup}
                      message={confirmPopup.responseMessage}/>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getFacilityList,
  updateFacilityStatus,
  toggleLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Facility)

