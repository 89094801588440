import React, { Component } from 'react'


const PetCard = props => {

  return (
    <div className="card pet-card-container">
      <div className={`name ${props.weAreHomeClass}`} style={{ borderColor: props.color, background:'white'}}>
        <span className="first" title={props.firstName}><span>{props.firstName}</span></span>
        <span className="second" title={props.lastName}><span>{props.lastName}</span></span>
      </div>
      <div className={`date-time ${props.weAreHomeClass}`}>
        <span className="first" title={props.time}>{props.time}</span>
      </div>
    </div>
  )
}

export default PetCard;