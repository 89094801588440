import { handleRequest } from '../services/APIService';

export const GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const getClientList = (data) => dispatch => {

  handleRequest('post', `client/get`, true, data).then( (results) => {
    return dispatch ({
      type: GET_CLIENT_DATA,
      payload: results.data
    });
  })
};

export const REINVITE_CLIENT = 'REINVITE_CLIENT';
export const reinvite = (data) => dispatch => {

  handleRequest('post', `client/reinvite`, true, data).then( (results) => {
    return dispatch ({
      type: REINVITE_CLIENT,
      payload: results.data
    });
  })
};

export const FETCH_PARENTS = 'FETCH_PARENTS';
export const FETCH_PARENTS_ERROR = 'FETCH_PARENTS_ERROR';
export const getParents = (search) => dispatch => {
  handleRequest('get', `parent/search/${search}`, true )
    .then( (results) => {
      return dispatch ({
        type: FETCH_PARENTS,
        payload: results.data
      });
    })
    .catch( (error) => {
      return dispatch ({
        type: FETCH_PARENTS_ERROR,
        payload: error
      });
    })
};

export const FETCH_FACILITIES_FOR_CLIENT = 'FETCH_FACILITIES_FOR_CLIENT';
export const FETCH_FACILITIES_FOR_CLIENT_ERROR = 'FETCH_FACILITIES_FOR_CLIENT_ERROR';
export const getClientFacilities = (clientId) => dispatch => {
  handleRequest('get', `/client/get/facilities/${clientId}`, true )
    .then( (results) => {
      return dispatch ({
        type: FETCH_FACILITIES_FOR_CLIENT,
        payload: results.data
      });
    })
    .catch( (error) => {
      return dispatch ({
        type: FETCH_FACILITIES_FOR_CLIENT_ERROR,
        payload: error
      });
    })
};

export const SAVE_FACILITIES_FOR_CLIENT = 'SAVE_FACILITIES_FOR_CLIENT';
export const SAVE_FACILITIES_FOR_CLIENT_ERROR = 'SAVE_FACILITIES_FOR_CLIENT_ERROR';
export const saveClientFacility = (data) => dispatch => {
  handleRequest('post', `/client/save/facilities`, true, data )
    .then( (results) => {
      return dispatch ({
        type: SAVE_FACILITIES_FOR_CLIENT,
        payload: results.data
      });
    })
    .catch( (error) => {
      return dispatch ({
        type: SAVE_FACILITIES_FOR_CLIENT_ERROR,
        payload: error
      });
    })
};

export const CLEAR_CLIENT_PROPS = 'CLEAR_CLIENT_PROPS';
export const clearUpdateProps = () => dispatch => {

  return dispatch ({
    type: CLEAR_CLIENT_PROPS,
    payload: null,
  });
};

export const CLEAR_INVITE = 'CLEAR_INVITE';
export const clearInviteStore = () => dispatch => {
  return dispatch ({
    type: CLEAR_INVITE,
    payload: null,
  });
}
