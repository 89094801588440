const storageHelper =  require('./storageHelper');
const config = require('../config/app.config');

const commonHelper = {
  getParameterByName: (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },

  formatPhone: (phoneNumber, pattern) => {
    phoneNumber = phoneNumber.replace(/[^0-9]/g, '')
    phoneNumber = phoneNumber.toString().split('');
    phoneNumber = pattern.replace(/#/g, () => phoneNumber.shift());
    return phoneNumber.replace(/undefined/g, '');
  },

  isSuperAdmin: () => {
    const loggedUser = storageHelper.getFromStorage('loggedUser');
    if (!loggedUser) {
      return false;
    }
    return loggedUser.user.type === config.USER_TYPES.SUPER_ADMIN;
  },
};

module.exports = commonHelper;
