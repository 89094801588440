import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import { refDoctorSubscription } from '../../../redux/actions/referralDoctorAction';

const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;


export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityObjId:this.props.match.params.facilityObjId,
      type:this.props.match.params.type,
      facilityName:this.props.match.params.facilityName.replace('+', ' '),
      subscribed: true
    }
  }
  
  componentDidMount() {
    const { facilityObjId, type } = this.state;
    this.setState({ subscribed: type === 'subscribed'})
    const data = {
      facilityObjId,
      type
    }
    this.props.refDoctorSubscription(data)
  }
  
  componentWillReceiveProps(np) {
   
  }
  
  render() {
    const { subscribed, facilityName } = this.state;

    return (
      <div className="account user-flow unsubscribe">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title">
                  <span className="account__logo">
                  <img src={icon} alt=""/>
                </span>
                </h2>
              </div>

              { subscribed ?
                <div className="body-wrapper">
                <h3 className="subheading">Successfully Subscribed</h3>
                <div className="p-first">Thank you for registering as a Referring Doctor with Loyal at {facilityName}. </div>
                <div className="p-first">You will now receive real-time email updates for your referred patients during their care at this facility. </div>
                <div className="p-first">Click Unsubscribe at the bottom of the update emails if you wish to discontinue. </div>
                <div className="your">
                  - Your friends at Loyal
                </div>
              </div>
              :
              <div className="body-wrapper">
                <h3 className="subheading">Successfully Unsubscribed </h3>
                <div className="p-first">You will no longer receive real-time email updates for your referred patients during their care at this facility.</div>
                <div className="p-first">You will receive an email confirming the unsubscription. You may re-subscribe by clicking the subscribe button on this email.</div>
                <div className="your">
                  - Your friends at Loyal
                </div>
              </div>
              }
                
             
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  refDoctorSubscription
};

export default connect(mapStateToProps, mapDispatchToProps)(index)
