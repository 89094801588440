const Config = {
  BASE_URL: 'http://localhost:3001/v1/',
  SOCKET_URL: 'http://localhost:3002',
  GOOGLE_API_KEY: 'AIzaSyDjvozCTfdncI-xtXFpEYRlkMwjvkhrnJM',
  STRIPE_URL: 'https://dashboard.stripe.com/login',
  CLOUDNARY_URL: 'https://api.cloudinary.com/v1_1/dktj00acj/image/upload',
  SUPPORT_TICKETS_URL: 'https://secure.helpscout.net/dashboard/',
  FAQ_URL: 'https://loyal.helpscoutdocs.com/',
  CLOUDNARY_VIEW_URL: 'https://res.cloudinary.com/dktj00acj/image/upload/',
  PET_PROFILE_IMAGE_PATH: 'https://res.cloudinary.com/dktj00acj/image/upload/pet/profile/',
  PARENT_PROFILE_IMAGE_PATH: 'https://res.cloudinary.com/dktj00acj/image/upload/profile/',
  FACILITY_PROFILE_IMAGE_PATH: 'https://res.cloudinary.com/dktj00acj/image/upload/c_pad,h_78,w_138/profile/',
  FACILITY_PROFILE_ICON_IMAGE_PATH: 'https://res.cloudinary.com/dktj00acj/image/upload/c_pad,h_140,w_140/profile/',
  PET_PROFILE_PICTURE_PATH: 'https://res.cloudinary.com/dktj00acj/image/upload/w_160,c_fill,ar_1:1,g_auto,r_max/pet/profile/',
  PET_PROFILE_ADDITIONAL_DATA_IMAGE_PATH: 'https://res.cloudinary.com/dktj00acj/image/upload/pet/snaps/',
  PROFILE_IMAGE_FOLDER: 'profile',
  USER_TYPES: {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    STAFF: 'staff'
  },
  DEFAULT_PAGINATION_LENGTH: 20,
  DEFAULT_STAFF_PAGINATION_LENGTH: 20,
  CLIENT_STATUS:{
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  },
  CO_PARENT_STATUS:{
    INVITED: 'invited',
    JOINED: 'accepted'
  },
  POPUP_TIMEOUT_TIME: 2000,
  RESPONSE: {
    UNAUTHORIZED: 401
  },
  appointmentTypes: {
    wellness_visit: 'wellness visit',
    follow_up_visit: 'follow-up visit',
    vaccinations: 'vaccinations',
    diagnostics: 'diagnostics',
    surgery: 'surgery',
    minor_procedure: 'minor procedure',
    treatment: 'treatment',
    dental_cleaning: 'dental cleaning',
    grooming: 'grooming',
    nail_trim: 'nail trim',
    boarding: 'boarding',
    specialist: 'specialist',
    other: 'other',
  },
  appointmentActions: {
    remove: 'remove',
    cancel: 'cancel',
    reschedule: 'reschedule'
  },
  PHASES: {
    1: 'Expected',
    2: 'Check-In',
    3: 'Diagnostics',
    4: 'Preparation',
    5: 'Procedure',
    6: 'Dental',
    7: 'Surgery',
    8: 'Recovery',
    9: 'Hospitalized',
    10: 'Boarding',
    11: 'Discharge',
    12: 'Completed',
  },
  SUBSCRIPTION_STATUS: {
    SUBSCRIBED: 'subscribed',
    UNSUBSCRIBED: 'unsubscribed'
  },
};

module.exports = Config;
