import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import {getParent} from "../../../redux/actions/petActions";
import connect from "react-redux/es/connect/connect";
import PlusIcon from "mdi-react/PlusCircleOutlineIcon";
import humanize from  "underscore.string/humanize";
import constants from "../../../constants/constants";
import config from "../../../config/app.config";

const defaultAvatar = `${process.env.PUBLIC_URL}/img/default-animal-black.png`;

class SelectPet extends Component{
  constructor(props) {
    super(props);
    this.state = {
      parentId: null,
      petList: []
    };
  };

  componentWillReceiveProps(np) {
    if(!np.selectedParent) {
      this.setState({
        petList: []
      });
    }
    if(np.selectedParent && np.selectedParent._id !== this.state.parentId){
      this.setState({
        parentId: np.selectedParent._id
      }, () => {
        this.props.getParent(np.selectedParent._id);
      });
    }
    if(np.selectedParent && np.selectedParent.pets){
      this.autoSelectPet(np.selectedParent.pets);
      this.setState({
        petList: np.selectedParent.pets
      });
    }
    if(np.pets && np.pets.petList){
      this.autoSelectPet(np.pets.petList);
      this.setState({
        petList: np.pets.petList || [],
      });
    }
    if (!this.props.collapse) {
      this.setState({
        parentId: null,
      });
    }
  }

  autoSelectPet(petList) {
    let filterList = petList.filter((pet) => {
      if (pet.status === constants.STATUS.ACTIVE) {
        return pet;
      }
    });
    if (filterList.length === 1) {
      const pet = filterList.shift();
      if (this.props.selectPet) {
        this.props.selectPet(pet);
      }
    }
    return true;
  }

  render(){
    const { collapse, selectedPet, selectPet, viewMode, selectedParent } = this.props;
    const { parent, petList } = this.state;
    const title = this.props.title || 'Select Pet';
    const showNewPetButton = this.props.showNewPetButton || false;
    const petCards = petList.map((pet) => {
      return(
        (viewMode ? (selectedPet === pet._id ?
          <div className={ 'pet-card' } key={pet._id}>
            <div className="row">
              <div className="col-md-3">
                <img className="pet-image"
                     src={ (pet.image) ? `${config.PET_PROFILE_IMAGE_PATH}${pet.image}` : defaultAvatar }
                     alt="avatar"/>
              </div>
              <div className="col-md-9">
                <p className="pet-name">{pet.name}</p>
                <p className="pet-details species">{pet.species}, {pet.breed || 'N/A'}</p>
                <p className="pet-details">{humanize(pet.gender) || 'N/A'}</p>
              </div>
            </div>
            <div className="row">
              <div className="offset-md-3 col-md-9">
                <span className={`status-label ${pet.status === 'active' ? 'invited': 'inactive'}`}>{pet.status}</span>
              </div>
            </div>
          </div> : null) :
          <div
            className={"pet-card " + ((pet.status === constants.STATUS.ACTIVE && selectPet && !viewMode) ? 'not-view-mode ' : '') + ((this.props.selectedPet === pet._id ? 'selected-pet ' : ''))}
            key={pet._id}
            onClick={() =>
              (pet.status === constants.STATUS.ACTIVE && selectPet && !viewMode) ? this.props.selectPet(pet) : false
              }>
            {
              pet.status === constants.STATUS.ACTIVE && selectPet && !viewMode &&
              <div className="select-wrapper">
                <input id={pet._id} type="radio"
                       checked={this.props.selectedPet == pet._id}
                       onChange={() => {
                  this.props.selectPet(pet)
                }}/>
                <label htmlFor={pet._id} className="checkmark"></label>
              </div>
            }
            <div className="row">
              <div className="col-md-3">
                <img className="pet-image"
                     src={ (pet.image) ? `${config.PET_PROFILE_IMAGE_PATH}${pet.image}` : defaultAvatar }
                     alt="avatar"/>
              </div>
              <div className="col-md-9">
                <p className="pet-name"><span className="pet">{pet.name}</span></p>
                <p className="pet-details species">{pet.species}, {pet.breed || 'N/A'}</p>
                <p className="pet-details">{humanize(pet.gender) || 'N/A'}</p>
              </div>
            </div>
            <div className="row">
              <div className="offset-md-3 col-md-9">
                <span className={`status-label ${pet.status === 'active' ? 'invited': 'inactive'}`}>{pet.status}</span>
              </div>
            </div>
          </div>
        )
      );
    });

    return(
      <div className="content-holder find-parent-wrapper">
        <div className="row">
          <div className="col-md-6">
            <h3 className={ !selectedParent || (collapse && petList.length === 0 && !showNewPetButton) || (!collapse && showNewPetButton) ? 'loyal-disable-tab-text' : ''}>{title}</h3>
          </div>
          <div className="col-md-6">
            {(showNewPetButton && collapse) &&
                <button className="btn pull-right add-new-pet-register-btn"
                        disabled={(!collapse)}
                        onClick={this.props.showNewPetAction}><span className="lnr lnr-plus-circle"></span> Add New Pet
                </button>
            }
          </div>
        </div>
        <Collapse isOpen={(collapse && petList.length > 0) || (collapse && showNewPetButton)}>
          <div className="row">
            <div className="col-md-12">
              {petCards}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getParent
};

SelectPet.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPet);
