import { handleRequest } from '../services/APIService';
import config from '../../../src/config/app.config';
import {GET_DOCTORS_BY_FACILITY, GET_DOCTORS_BY_FACILITY_FAILED} from "./referralDoctorAction";

export const GET_STAFF_LIST = 'GET_STAFF_LIST';
export const GET_ALL_FACILITY_LIST = 'GET_ALL_FACILITY_LIST';
export const GET_ALL_DESIGNATION_LIST = 'GET_ALL_DESIGNATION_LIST';
export const ADD_NEW_STAFF = 'ADD_NEW_STAFF';
export const ADD_NEW_STAFF_ERROR = 'ADD_NEW_STAFF_ERROR';
export const UPDATE_STAFF_STATUS = 'UPDATE_STAFF_STATUS';
export const REINVITE_STAFF = 'REINVITE_STAFF';
export const GET_STAFF_MEMBER = 'GET_STAFF_MEMBER';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const UPDATE_STAFF_ERROR = 'UPDATE_STAFF_ERROR';
export const RESET_UPDATE_POPUP = 'CLOSE_UPDATE_POPUP';
export const GET_STAFF_DOCTORS_BY_FACILITY = 'GET_STAFF_DOCTORS_BY_FACILITY';
export const GET_STAFF_DOCTORS_BY_FACILITY_FAILED = 'GET_STAFF_DOCTORS_BY_FACILITY_FAILED';

export const getStaffList = (data) => dispatch => {
  const offset = data.offset || 0;
  const limit = data.limit || config.DEFAULT_PAGINATION_LENGTH;

  const page = (offset / limit) + 1;
  const params = {
    page,
    limit,
    search: data.search || null,
    facility: data.facilities || [],
    status: data.status || [],
    sort: {
      field: data.sortField || 'name',
      order: data.sortOrder || 1,
    },
  };

  handleRequest('post', 'staff/get', true, params).then( (results) => {
    return dispatch ({
      type: GET_STAFF_LIST,
      payload: results.data,
    });
  });
};

export const getAllFacilities = (data) => dispatch => {
  handleRequest('get', 'facility-all', true, data).then( (results) => {
    return dispatch ({
      type: GET_ALL_FACILITY_LIST,
      payload: results.data,
    });
  });
};

export const getAllDesignations = (data) => dispatch => {
  handleRequest('get', 'designation-all', true, data).then((results) => {
    return dispatch ({
      type: GET_ALL_DESIGNATION_LIST,
      payload: results.data,
    });
  });
};

export const addNewStaffMember = (data) => dispatch => {
  handleRequest('post', 'staff', true, data).then((results) => {
    return dispatch ({
      type: ADD_NEW_STAFF,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: ADD_NEW_STAFF_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const updateStaffStatus = (id, data) => dispatch => {
  handleRequest('post', 'staff/change-status/' + id, true, data).then( (results) => {
    return dispatch ({
      type: UPDATE_STAFF_STATUS,
      payload: results.data,
    });
  });
};

export const reinviteStaff = (data) => dispatch => {
  handleRequest('post', 'staff/reinvite', true, data).then( (results) => {
    return dispatch ({
      type: REINVITE_STAFF,
      payload: results.data,
    });
  });
};

export const getStaffMember = (id) => dispatch => {
  handleRequest('get', 'staff/' + id, true, {}).then( (results) => {
    return dispatch ({
      type: GET_STAFF_MEMBER,
      payload: results.data,
    });
  });
};

export const updateStaffMember = (id, data) => dispatch => {
  handleRequest('put', 'staff/' + id, true, data).then((results) => {
    return dispatch ({
      type: UPDATE_STAFF,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: UPDATE_STAFF_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const resetUpdatePopupNP = () => dispatch => {
    return dispatch ({
      type: RESET_UPDATE_POPUP,
      payload: {},
    });
};

export const getStaffDoctorsByFacility = () => dispatch => {
  handleRequest('get', `staff-doctors/by-facility`, true )
    .then( (results) => {
      return dispatch ({
        type: GET_STAFF_DOCTORS_BY_FACILITY,
        payload: results.data
      });
    })
    .catch( (error) => {
      return dispatch ({
        type: GET_STAFF_DOCTORS_BY_FACILITY_FAILED,
        payload: error
      });
    })
};



