import {handleRequest} from "../services/APIService";

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const CREATE_APPOINTMENT_ERROR = 'FETCH_PETS_ERROR';
export const CLEAR_APPOINTMENT = 'CLEAR_APPOINTMENT';
export const GET_APPOINTMENTS_BY_PARENT = 'GET_APPOINTMENTS_BY_PARENT';
export const GET_APPOINTMENTS_BY_PARENT_ERROR = 'GET_APPOINTMENTS_BY_PARENT_ERROR';
export const CANCEl_APPOINTMENT = 'CANCEl_APPOINTMENT';
export const CANCEl_APPOINTMENT_ERROR = 'CANCEl_APPOINTMENT_ERROR';
export const REMOVE_APPOINTMENT = 'REMOVE_APPOINTMENT';
export const REMOVE_APPOINTMENT_ERROR = 'REMOVE_APPOINTMENT_ERROR';
export const RESCHEDULE_APPOINTMENT = 'RESCHEDULE_APPOINTMENT';
export const RESCHEDULE_APPOINTMENT_ERROR = 'RESCHEDULE_APPOINTMENT_ERROR';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const GET_APPOINTMENT_ERROR = 'GET_APPOINTMENT_ERROR';
export const UNSUBSCRIBE_SHARE_UPDATE = 'UNSUBSCRIBE_SHARE_UPDATE';
export const UNSUBSCRIBE_SHARE_UPDATE_ERROR = 'UNSUBSCRIBE_SHARE_UPDATE_ERROR';
export const SUBSCRIBE_SHARE_UPDATE = 'SUBSCRIBE_SHARE_UPDATE';
export const SUBSCRIBE_SHARE_UPDATE_ERROR = 'SUBSCRIBE_SHARE_UPDATE_ERROR';

export const createAppointment = (data) => dispatch => {
  handleRequest('post', 'appointment', true, data).then((results) => {
    return dispatch ({
      type: CREATE_APPOINTMENT,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: CREATE_APPOINTMENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const clearAppointmentStore = () => dispatch => {
  return dispatch ({
    type: CLEAR_APPOINTMENT,
    payload: null,
  });
}

export const getAppointmentsByParent = (data) => dispatch => {
  handleRequest('post', 'appointment/by-parent', true, data).then((results) => {
    return dispatch ({
      type: GET_APPOINTMENTS_BY_PARENT,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: GET_APPOINTMENTS_BY_PARENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const cancelAppointment = (data) => dispatch => {
  handleRequest('put', 'appointment/cancel', true, data).then((results) => {
    return dispatch ({
      type: CANCEl_APPOINTMENT,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: CANCEl_APPOINTMENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const removeAppointment = (data) => dispatch => {
  handleRequest('delete', 'appointment/remove', true, data).then((results) => {
    return dispatch ({
      type: REMOVE_APPOINTMENT,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: REMOVE_APPOINTMENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const rescheduleAppointment = (data) => dispatch => {
  handleRequest('put', 'appointment/reschedule', true, data).then((results) => {
    return dispatch ({
      type: RESCHEDULE_APPOINTMENT,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: RESCHEDULE_APPOINTMENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const getAppointmentById = (data) => dispatch => {
  handleRequest('get', `appointment/get/${data}`, true).then((results) => {
    return dispatch ({
      type: GET_APPOINTMENT,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: GET_APPOINTMENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const unsubscribeDoctor = (data) => dispatch => {
  handleRequest('get', `appointment/unsubscribe/${data}`, false).then((results) => {
    return dispatch ({
      type: UNSUBSCRIBE_SHARE_UPDATE,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: UNSUBSCRIBE_SHARE_UPDATE_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const subscribe = (data) => dispatch => {
  handleRequest('get', `appointment/subscribe/${data}`, false).then((results) => {
    return dispatch ({
      type: SUBSCRIBE_SHARE_UPDATE,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: SUBSCRIBE_SHARE_UPDATE_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};
