import React, { Component } from 'react';
import ResetPasswordForm from "./components/Form";
import CommonHelper from '../../../libs/commonHelper';
import MessageModal from "./../../../shared/components/Modals/MessageModal";
import connect from "react-redux/es/connect/connect";
import { resetPassword, verifyResetPassword } from '../../../redux/actions/userActions';
import constants from '../../../constants/constants';
import localStorage from "../../../libs/storageHelper";
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

export class ResetPassword extends Component{
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      showResponse: false,
      responseMessage: '',
      responseType: '',
      responseAlertType: '',
      user: {},
      redirect: false,
    }
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount(){
    const token = CommonHelper.getParameterByName('token');
    this.setState({token});
    this.props.verifyResetPassword({token});
  }

  componentWillReceiveProps(np){
    if (np.user.verifyResetPasswordDataError) {
      this.setState({
        showResponse: true,
        responseMessage: 'Your reset link expired after 96 hours or has already been used',
        responseType: 'alert',
        responseAlertType: 'danger',
        redirect: 'forgot-password'
      });
    } else if (np.user.resetPasswordDataError) {
      const userData = np.user;
      this.setState({user: userData});
    } else if (np.user.resetPasswordData) {

      const { user } = np.user.resetPasswordData.data;

      localStorage.addToStorage('loggedUser', np.user.resetPasswordData.data);
      const redirectTo = user.type === constants.userRole.staff ? 'tracking-board' : (CommonHelper.isSuperAdmin() ? 'metrics' : 'metrics-dashboard');

      this.setState({
        showResponse: true,
        responseMessage: 'Password reset successful',
        responseType: 'alert',
        responseAlertType: 'success',
        redirect: redirectTo
      });
    }
  }

  closePopup = () => {
    this.setState({
      showResponse: false
    }, () => {
      const { redirect } = this.state;
      if (redirect) {
        this.props.history.push(redirect);
      }
    });
  };

  resetPassword(state){
    const value = {
      token:this.state.token,
      password:state.password,
      confirmPassword:state.confirmPassword
    }
    this.props.resetPassword(value);
  }

  render(){
    const { showResponse, responseMessage, responseType, responseAlertType} = this.state;
    return(
      <div className="account user-flow password-reset">
        <MessageModal
          show={showResponse}
          type={responseType}
          alertType={responseAlertType}
          onClose={this.closePopup}
          footer='true'
          message={responseMessage}
        />
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title clearfix">
                <span className="account__logo float-right">
                  <img src={icon} alt="" />
                </span>
                </h2>
              </div>
              <h3 className="account__form-heading subheading">New Password</h3>
              <h4 className="account__form-subheading">Please enter a new password</h4>
              <ResetPasswordForm handleSubmit={this.resetPassword} user={this.state.user}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  resetPassword,
  verifyResetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
