import React, {PureComponent} from 'react';
import {Button, Modal, ButtonToolbar} from 'reactstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

export default class ImageCrop extends PureComponent {

    constructor() {
        super();
        this.state = {
            crop: {
              unit: 'px',
              width: 161,
              minWidth: 161,
              minHeight: 161, 
              aspect: 1,
            },
            croppedImageUrl: null
        };
    }

    onImageLoaded = image => {
      this.imageRef = image;
    };

    onCropComplete = crop => {
      this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
      // You could also use percentCrop:
      // this.setState({ crop: percentCrop });
      this.setState({ crop });
    };

    async makeClientCrop(crop) {
      if (this.imageRef && crop.width && crop.height) {
        const croppedImageUrl = await this.getCroppedImg(
          this.imageRef,
          crop,
          'newFile.jpeg'
        );
        this.setState({ croppedImageUrl });
      }
    }

    getCroppedImg(image, crop, fileName) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');
  
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
  
      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        }, 'image/jpeg');
      });
    }

    render() {
      const { src } = this.props;
      const { croppedImageUrl, crop } = this.state;
        return(
          <Modal
            isOpen={this.props.show}
            className={`modal-dialog modal-dialog--confirm modal-dialog--header alert-modal crop-images`}>
              <div className="modal__body">
              {src && (
          <ReactCrop
            src={src}
            crop={crop}
            locked={false}
            ruleOfThirds
            circularCrop={true}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
        {croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} className="cropped-image"/>
        )}
              </div>
              <ButtonToolbar className="modal__footer">
                  <Button className="btn btn-no btn-secondary pull-left" onClick={() => { this.props.onCancel() }}>Cancel</Button>
                  <Button className="btn btn-yes btn-success pull-right" onClick={() => { this.props.onConfirm(croppedImageUrl) }}>Crop</Button>
              </ButtonToolbar>
          </Modal>
        );
    }
}