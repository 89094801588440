import React, { PureComponent } from 'react';
import EmailOutlineIcon from "mdi-react/ChevronLeftIcon";
import {withRouter} from 'react-router-dom';

class BackButtonComponent extends PureComponent {

  constructor(props) {
    super(props);
  }

  render(){

    return(
      <div className="back-btn-wrapper">
        <a href="#" onClick={() => this.props.history.goBack()}>
          <div  className="back-btn-wrapper__back-btn">
          <EmailOutlineIcon />
          </div>
          <span className="back-btn-wrapper__label-text">Previous Screen</span>
        </a>
      </div>
    );
  }
}

export default withRouter(BackButtonComponent);