import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_ERROR,
  GET_PROCEDURE_UPDATE_MSG_DATA,
  GET_PROCEDURE_UPDATE_MSG_DATA_ERROR,
  GET_DASHBOARD_USER_DATA,
  GET_DASHBOARD_USER_DATA_ERROR,
  GET_DASHBOARD_PROCEDURE_DETAILS_DATA,
  GET_DASHBOARD_PROCEDURE_DETAILS_DATA_ERROR,
  GET_DASHBOARD_AVERAGE_TIME_DATA,
  GET_DASHBOARD_AVERAGE_TIME_DATA_ERROR
} from '../actions/dashboardActions';

const initialState = {
  averageTimeSpent:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: action.payload
      }
    case GET_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        dashboard: null
      }
    case GET_PROCEDURE_UPDATE_MSG_DATA:
      return {
        ...state,
        procedureUpdateMsgs: action.payload
      }
    case GET_PROCEDURE_UPDATE_MSG_DATA_ERROR:
      return {
        ...state,
        procedureUpdateMsgs: []
      }
    case GET_DASHBOARD_USER_DATA:
      return {
        ...state,
        newUser: action.payload
      }
    case GET_DASHBOARD_USER_DATA_ERROR:
      return {
        ...state,
        newUser: null
      }
    case GET_DASHBOARD_PROCEDURE_DETAILS_DATA:
      return {
        ...state,
        procedureDetail: action.payload
      }
    case GET_DASHBOARD_PROCEDURE_DETAILS_DATA_ERROR:
      return {
        ...state,
        procedureDetail: null
      }
    case GET_DASHBOARD_AVERAGE_TIME_DATA:
      return {
        ...state,
        averageTimeSpent: action.payload
      }
    case GET_DASHBOARD_AVERAGE_TIME_DATA_ERROR:
      return {
        ...state,
        averageTimeSpent: []      
      }
    default:
      return state;
  }
}

