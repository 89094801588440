import {
  FETCH_ADMINS,
  FETCH_ADMINS_ERROR,
  CHANGE_STATUS,
  CHANGE_STATUS_ERROR,
  IS_PRIMARY,
  IS_PRIMARY_ERROR,
  INVITE_ADMIN,
  INVITE_ADMIN_ERROR,
  UPDATE_ADMIN,
  UPDATE_ADMIN_ERROR,
  REINVITE_ADMIN,
  REINVITE_ADMIN_ERROR
} from '../actions/adminActions';

const initialState = {
  isPrimaryAdminExists: false,
  invited:false,
  updated:false,
  reinvited:false,
  adminStatusUpdated:false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMINS:
        return {
          ...state,
          adminList:action.payload,
          adminStatus:false,
          invited:false,
          updated: false,
          reinvited:false,
          adminStatusUpdated:null,
          isPrimaryAdminExists: false
        }
    case FETCH_ADMINS_ERROR:
        return {
          ...state,
          adminList:[]
        }
    case CHANGE_STATUS:
      return {
        ...state,
        adminStatusUpdated:action.payload,
        isPrimaryAdminExists: false
      }
    case CHANGE_STATUS_ERROR:
      return {
        ...state,
        adminStatusUpdated:action.payload,
        isPrimaryAdminExists: false
      }
    case IS_PRIMARY:
      return {
        ...state,
        isPrimaryAdminExists:action.payload,
      }
    case IS_PRIMARY_ERROR:
      return {
        ...state,
        isPrimaryAdminExists: false
      }
    case INVITE_ADMIN:
      return {
        ...state,
        invited:action.payload,
        inviteAdminError: null,
        adminStatusUpdated: null,
        isPrimaryAdminExists: false
        }
    case INVITE_ADMIN_ERROR:
      return {
        ...state,
        invited: false,
        inviteAdminError: JSON.parse(action.payload.response),
        isPrimaryAdminExists: false
      }
    case UPDATE_ADMIN:
      return {
          ...state,
          updated:action.payload,
          isPrimaryAdminExists: false
        }
    case UPDATE_ADMIN_ERROR:
      return {
        ...state,
        updated: false,
        isPrimaryAdminExists: false
      }
    case REINVITE_ADMIN:
      return {
          ...state,
        reinvited: action.payload,
        adminStatusUpdated:null,
        isPrimaryAdminExists: false
        }
    case REINVITE_ADMIN_ERROR:
      return {
        ...state,
        reinvited: false,
        isPrimaryAdminExists: false
      }
    default:
      return state;
  }
}

