import React, { Component } from 'react';
import SidebarLink from './SidebarLink';
import config from '../../../config/app.config';
import localStorage from '../../../libs/storageHelper';
import SidebarLinkCustomIcon from "./SidebarLinkCustomIcon";

const {  STAFF, ADMIN, SUPER_ADMIN } = config.USER_TYPES;
const { SUPPORT_TICKETS_URL, FAQ_URL } = config;

class SidebarContent extends Component {
  constructor(props){
    super(props);

    this.state= {
      userType: SUPER_ADMIN
    }
    this.sesssion = this.session = localStorage.getFromStorage('loggedUser');
  }

  render() {

    const { type: userType } = this.sesssion.user;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
        { userType == STAFF && <div className="sidebar__block--menu">
          <SidebarLinkCustomIcon title="Tracking Board" icon="ptb" route="/tracking-board" />
          <SidebarLinkCustomIcon title="New Appointment" icon="calender" route="/new-appointment" />
          <SidebarLink title="Add Clients" icon="user" route="/add-clients" />
          <SidebarLinkCustomIcon title="Client List" icon="schedule" route="/client-list" />
          <SidebarLinkCustomIcon title="FAQ" icon="ask-question" link={FAQ_URL} />
          </div>
        }

        { userType == ADMIN && <div className="sidebar__block--menu">
          <SidebarLinkCustomIcon title="Metrics Dashboard" icon="web-design" route="/metrics-dashboard" />
          <SidebarLinkCustomIcon title="Broadcast News" icon="broadcast-news" route="/broadcast-news" />
          <SidebarLinkCustomIcon title="Tracking Board" icon="ptb" route="/tracking-board" />
          <SidebarLinkCustomIcon title="New Appointment" icon="calender" route="/new-appointment" />
          <SidebarLink title="Add Clients" icon="user" route="/add-clients" />
          <SidebarLinkCustomIcon title="Manage Staff" icon="doctors-bag" route="/manage-staff" />
          <SidebarLinkCustomIcon title="Manage Referring" icon="doctor" route="/manage-referring" />
          <SidebarLinkCustomIcon title="Client List" icon="schedule" route="/client-list" />
          <SidebarLinkCustomIcon title="FAQ" icon="ask-question" link={FAQ_URL} />
        </div>
        }

        { userType == SUPER_ADMIN && <div className="sidebar__block--menu">
          <SidebarLinkCustomIcon title="Metrics" icon="web-design" route="/metrics" />
          <SidebarLinkCustomIcon title="Broadcast" icon="broadcast-news" route="/broadcast" />
          <SidebarLinkCustomIcon title="Facilities" icon="hospital-3" route="/facilities" />
          <SidebarLinkCustomIcon title="Admins" icon="admin-settings-male" route="/admins" />
          <SidebarLinkCustomIcon title="Staff" icon="doctors-bag" route="/staff" />
          <SidebarLinkCustomIcon title="Doctors" icon="doctor" route="/doctors" />
          <SidebarLink title="Breeds" icon="paw" route="/breeds" />
          <SidebarLink title="Users" icon="user" route="/users" />
          <SidebarLinkCustomIcon title="Support" icon="online-support" link={ SUPPORT_TICKETS_URL } />
         </div>
        }
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
