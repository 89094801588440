
import {
  CREATE_APPOINTMENT,
  CREATE_APPOINTMENT_ERROR,
  CLEAR_APPOINTMENT,
  GET_APPOINTMENTS_BY_PARENT,
  GET_APPOINTMENTS_BY_PARENT_ERROR,
  CANCEl_APPOINTMENT,
  CANCEl_APPOINTMENT_ERROR,
  REMOVE_APPOINTMENT,
  REMOVE_APPOINTMENT_ERROR,
  RESCHEDULE_APPOINTMENT,
  RESCHEDULE_APPOINTMENT_ERROR,
  GET_APPOINTMENT,
  GET_APPOINTMENT_ERROR,
  UNSUBSCRIBE_SHARE_UPDATE,
  UNSUBSCRIBE_SHARE_UPDATE_ERROR,
  SUBSCRIBE_SHARE_UPDATE,
  SUBSCRIBE_SHARE_UPDATE_ERROR
} from '../actions/appointmentAction';

const initialState = {
  createAppointment: null,
  createAppointmentError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_APPOINTMENT:
      return {
        ...state,
        createAppointment: action.payload,
        createAppointmentError: null,
      }
    case CREATE_APPOINTMENT_ERROR:
      return {
        ...state,
        createAppointment: null,
        createAppointmentError: action.payload
      }
    case UNSUBSCRIBE_SHARE_UPDATE:
      return {
        ...state,
        unsubscribe: action.payload.data,
        unsubscribeError: null,
      }
    case UNSUBSCRIBE_SHARE_UPDATE_ERROR:
      return {
        ...state,
        subscribe: null,
        subscribeError: action.payload,
      }
    case SUBSCRIBE_SHARE_UPDATE:
      return {
        ...state,
        subscribe: action.payload.data,
        subscribeError: null,
      }
    case SUBSCRIBE_SHARE_UPDATE_ERROR:
      return {
        ...state,
        unsubscribe: null,
        unsubscribeError: action.payload,
      }
    case CLEAR_APPOINTMENT:
      return {
        ...state,
        createAppointment: null,
        rescheduleAppointment: null,
        getAppointment: null,
      }
    case GET_APPOINTMENTS_BY_PARENT:
      return {
        ...state,
        appointmentsByParent: action.payload.data,
        cancelAppointment: null,
        removeAppointment: null,
        cancelAppointmentError: null,
        removeAppointmentError: null,
        appointmentsByParentError: null,
      }
    case GET_APPOINTMENTS_BY_PARENT_ERROR:
      return {
        ...state,
        appointmentsByParent: null,
        cancelAppointment: null,
        removeAppointment: null,
        cancelAppointmentError: null,
        removeAppointmentError: null,
        appointmentsByParentError: action.payload,
      }
    case CANCEl_APPOINTMENT:
      return {
        ...state,
        cancelAppointment: action.payload.data,
        cancelAppointmentError: null,
      }
    case CANCEl_APPOINTMENT_ERROR:
      return {
        ...state,
        cancelAppointment: null,
        cancelAppointmentError: action.payload,
      }
    case REMOVE_APPOINTMENT:
      return {
        ...state,
        removeAppointment: action.payload.data,
        removeAppointmentError: null,
      }
    case REMOVE_APPOINTMENT_ERROR:
      return {
        ...state,
        removeAppointment: null,
        removeAppointmentError: action.payload,
      }
    case RESCHEDULE_APPOINTMENT:
      return {
        ...state,
        rescheduleAppointment: action.payload.data,
        rescheduleAppointmentError: null,
        getAppointment: null,
      }
    case RESCHEDULE_APPOINTMENT_ERROR:
      return {
        ...state,
        rescheduleAppointment: null,
        rescheduleAppointmentError: action.payload,
        getAppointment: null,
      }
    case GET_APPOINTMENT:
      return {
        ...state,
        getAppointment: action.payload.data,
        getAppointmentError: null,
      }
    case GET_APPOINTMENT_ERROR:
      return {
        ...state,
        getAppointment: null,
        getAppointmentError: action.payload,
      }
    default:
      return state;
  }
}