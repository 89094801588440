import React, { Component } from 'react';
import { Collapse, Row, FormGroup, Col  } from 'reactstrap';
import { getBreedsForSpecies } from '../../../../redux/actions/petActions';
import connect from 'react-redux/es/connect/connect';
import MinusIcon from 'mdi-react/MinusCircleOutlineIcon';
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';
import DatePicker from "react-datepicker/es";
import Error from "../../../../shared/components/form/Error";
import DatePickerComponent from "../../Common/DatePickerComponent";

class RegisterNewPet extends Component{
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        name: '',
        species: '',
        breed: '',
        gender: '',
        birthday: ''
      },
      refreshState: false,
      speciesList: [],
      breedList: [],
      genderList: [
        <option key='' value=''>Select Gender</option>,
        <option key='female' value='female'>Female</option>,
        <option key='male' value='male'>Male</option>,
        <option key='male_neutered' value='male_neutered'>Male Neutered</option>,
        <option key='female_neutered' value='female_neutered'>Female Neutered</option>,
        <option key='unknown' value='unknown'>Unknown</option>,
      ]
    }
    this.removePet = this.removePet.bind(this);
  }

  componentWillReceiveProps(np) {

    const { inputs } = this.state;
    if(np.pets.breedList && np.pets.breedList.species === inputs.species){
      let breedList = [];
      if ( np.pets.breedList.breeds.length > 0) {
        breedList = np.pets.breedList.breeds.map((value) => {
          return (
            { value: value._id, label: value.name }
          );
        });
        breedList.unshift({ value: '', label: 'Select Breeds' });
      }
      this.setState({
        breedList
      });
    }
    if(np.petInputs){
      this.setState({
        inputs: np.petInputs
      });
    }
  }

  removePet(id) {
    this.props.removePetAction(id);
  };

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    let { inputs, refreshState, breedList } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    if (property === 'species') {
      breedList =  [{ value: '', label: 'Select Breeds' }];
      this.props.getBreedsForSpecies(value);
    }
    this.setState({
      inputs, refreshState, breedList
    });
    this.props.petInputsSet(inputs, this.props.index);
  };

  onChangeSelect = (e) => {
    if (e) {
      let {inputs} = this.state;
      inputs.breed = e.value;
      this.setState({
        inputs
      });
      this.props.petInputsSet(inputs, this.props.index);
    }
  };

  onChangeCalender = (e) => {
    let { inputs, refreshState } = this.state;
    inputs.birthday = e;
    this.setState({
      inputs, refreshState: !refreshState,
    });
    this.props.petInputsSet(inputs, this.props.index);
  };

  render(){
    const { collapse, selectedPet, speciesList, petErrors, pet } = this.props;
    const { parent, petList, genderList, breedList, inputs, refreshState } = this.state;
    return(
      <div className="content-holder find-parent-wrapper">
        <div className="row">
          <div className="col-md-6">
            <h3>Register New Pet</h3>
          </div>
          <div className="col-md-6">
            <button className="btn pull-right cancel-top-panel-button"
                    onClick={() => {this.removePet(this.props.pet.id)}}><MinusIcon/> Cancel
            </button>
          </div>
        </div>
        <Collapse isOpen={true}>
          <Row>
            <div className="col-md-2">
              <img className="loyal-parent-image" src="/img/default-animal-black.png" alt="avatar"/>
            </div>
            <div className="col-md-3">
              <div className="form__form-group loyal-custom-form-group">
                <span className="form__form-group-label">Pet Name <span className={'required'}>*</span></span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      placeholder={'Type pet name'}
                      value={inputs.name}
                      maxLength={50}
                      onChange = {this.onChangeFields}
                    />
                    {petErrors.name.error && <Error text={petErrors.name.error}/>}
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form__form-group loyal-custom-form-group">
                <span className="form__form-group-label">Species <span className={'required'}>*</span></span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <select name="species" id="species"
                            className="form-control form-select"
                            value={inputs.species}
                            onChange = {this.onChangeFields}>
                      {speciesList}
                    </select>
                    {petErrors.species.error && <Error text={petErrors.species.error}/>}
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form__form-group loyal-custom-form-group">
                <span className="form__form-group-label">Breed</span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <Select
                      value={inputs.breed}
                      onChange={this.onChangeSelect}
                      className={'custom-select-control'}
                      options={breedList}
                      placeholder={'Select Breed'}
                      matchProp="label"
                      isClearable={false}
                      name="breed"
                      id="breed"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-2"> </div>
            <div className="col-md-3">
              <div className="form__form-group loyal-custom-form-group">
                <span className="form__form-group-label">Gender</span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <select name="gender" id="gender"
                            className="form-control form-select"
                            value={inputs.gender}
                            onChange = {this.onChangeFields}>
                      {genderList}
                    </select>
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form__form-group loyal-custom-form-group form-group">
                <span className="form__form-group-label">Birthday</span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <DatePickerComponent
                      viewMode={ false } maxDate={true} minDate={false} is={true}
                      refreshState={ refreshState }
                      date={ inputs.birthday }
                      handleChange={this.onChangeCalender}/>
                    {petErrors.birthday.error && <Error text={petErrors.birthday.error}/>}
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            </div>
          </Row>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getBreedsForSpecies
};

RegisterNewPet.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterNewPet);