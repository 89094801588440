import React, {Fragment} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import localStorage from '../../libs/storageHelper';
import config from '../../config/app.config';

import LogIn from '../Account/LogIn/index';
import ParentSignup from '../Account/ParentSignup';
import ResetPassword from '../Account/ResetPassword';
import ChangePassword from '../Account/ChangePassword/index';
import Unsubscribe from '../Account/Unsubscribe/index';
import Subscribe from '../Account/Unsubscribe/Subscribe';
import RefDoctorSubscribe from '../Account/Unsubscribe/refDoctorSubscribe';

import ManageHospitalStaff from '../Staff/Staff';
import SendResetPasswordLink from '../Account/SendResetPasswordLink';
import ManageFacility from '../facility/Facility';
import ManageClient from '../client/Client';
import UserProfile from '../Account/Profile/index';
import ManageAdmins from '../admin';
import Dashboard from '../dashboard/Dashboard';
import ReferralDoctor from '../referralDoctor';
import ManageBreed from '../breed';
import RegisterParent from '../Web/RegisterParent';
import ParentProfile from '../Web/ParentProfile';
import CreateAppointment from '../Web/CreateAppointment';
import ManagePatients from '../Web/ManagePatients';
import PetProfile from '../Web/PetProfile';
import Broadcast from '../Web/Broadcast';


import PetTrackingBoard from '../Web/PetTrackingBoard'

let user = localStorage.getFromStorage('loggedUser');

const Auth = {
  authenticate() {
    user = localStorage.getFromStorage('loggedUser');
    return !!user;
  },
  isOnlyAdmin() {
    user = localStorage.getFromStorage('loggedUser');
    return user.user.type === config.USER_TYPES.ADMIN;
  },
  isAdmin() {
    user = localStorage.getFromStorage('loggedUser');
    return [config.USER_TYPES.SUPER_ADMIN, config.USER_TYPES.ADMIN].includes(user.user.type);
  },
  isSuperAdmin() {
    user = localStorage.getFromStorage('loggedUser');
    return user.user.type === config.USER_TYPES.SUPER_ADMIN
  },
  isStaff() {
    user = localStorage.getFromStorage('loggedUser');
    return user.user.type === config.USER_TYPES.STAFF;
  }
};

/**
 * Staff routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function LoggedRoute({component: Component, ...rest}) {
  return (
    Auth.authenticate() ?
      <Fragment>
        <Layout/>
        <div className="container__wrap">
          <Route {...rest} render={props => (<Component {...props} />)}/>
        </div>
      </Fragment>
      : (<Redirect to={{pathname: "/login"}}/>)
  );
}

/**
 * Staff routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function StaffRoute({component: Component, ...rest}) {
  return (
    Auth.authenticate() && Auth.isStaff() ?
      <Fragment>
        <Layout/>
        <div className="container__wrap">
          <Route {...rest} render={props => (<Component {...props} />)}/>
        </div>
      </Fragment>
      : (<Redirect to={{pathname: "/login"}}/>)
  );
}

/**
 * Super admin and admin routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function StaffOrAdminRoute({component: Component, ...rest}) {
  return (
    Auth.authenticate() && !Auth.isSuperAdmin() && (Auth.isAdmin() || Auth.isStaff()) ?
      <Fragment>
        <Layout/>
        <div className="container__wrap">
          <Route {...rest} render={props => (<Component {...props} />)} key={Math.random()}/>
        </div>
      </Fragment>
      : (<Redirect to={{pathname: "/login"}}/>)
  );
}

/**
 * Super admin and admin routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function PrivateRoute({component: Component, ...rest}) {
  return (
    Auth.authenticate() && Auth.isAdmin() ?
      <Fragment>
        <Layout/>
        <div className="container__wrap">
          <Route {...rest} render={props => (<Component {...props} />)} key={Math.random()}/>
        </div>
      </Fragment>
      : (<Redirect to={{pathname: "/login"}}/>)
  );
}

/**
 * Super admin only routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function PrivateProtectedRoute({component: Component, ...rest}) {
  return (
    Auth.authenticate() && Auth.isSuperAdmin() ?
      <Fragment>
        <Layout/>
        <div className="container__wrap">
          <Route {...rest} render={props => (<Component {...props} />)} key={Math.random()}/>
        </div>
      </Fragment>
      : (<Redirect to={{pathname: "/metrics-dashboard"}}/>)
  );
}

/**
 * Admin only routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function AdminOnlyRoute({component: Component, ...rest}) {
  return (
    Auth.authenticate() && Auth.isOnlyAdmin() ?
      <Fragment>
        <Layout/>
        <div className="container__wrap">
          <Route {...rest} render={props => (<Component {...props} />)} key={Math.random()}/>
        </div>
      </Fragment>
      : (<Redirect to={{pathname: "/metrics"}}/>)
  );
}

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn}/>
        <Route exact path="/login" component={LogIn}/>
        <Route exact path="/parent-signup/:token" component={ParentSignup}/>
        <Route exact path="/provider-login" component={LogIn}/>
        <Route path="/unsubscribe" component={Unsubscribe}/>
        <Route path="/Subscribe" component={Subscribe}/>
        <Route path="/RefDoctor/Subscribe/:facilityObjId/:type/:facilityName" component={RefDoctorSubscribe}/>
        <Route path="/change-password" component={ChangePassword}/>
        <Route path="/forgot-password" component={SendResetPasswordLink}/>
        <Route path="/reset-password" component={ResetPassword}/>
        <Route path="/empty" component={null}/>
        <Route path="/pet-profile/:petId" component={PetProfile}/>

        <LoggedRoute path="/user-profile" component={UserProfile}/>

        {/*admin and staff only routes*/}
        <StaffOrAdminRoute path="/tracking-board" component={PetTrackingBoard}/>
        <StaffOrAdminRoute path="/new-appointment" component={CreateAppointment}/>
        <StaffOrAdminRoute path="/client-list/profile/:parentId" component={ParentProfile}/>
        <StaffOrAdminRoute path="/client-list" component={ManagePatients}/>

        {/*admin only routes*/}
        <AdminOnlyRoute path="/metrics-dashboard" component={Dashboard}/>
        <AdminOnlyRoute path="/manage-staff" component={ManageHospitalStaff}/>
        <AdminOnlyRoute path="/manage-referring" component={ReferralDoctor}/>


        {/* super admin only routes */}
        <PrivateProtectedRoute path="/metrics" component={Dashboard}/>
        <PrivateProtectedRoute path="/staff" component={ManageHospitalStaff}/>
        <PrivateProtectedRoute path="/doctors" component={ReferralDoctor}/>
        <PrivateProtectedRoute path="/admins" component={ManageAdmins}/>
        <PrivateProtectedRoute path="/facilities" component={ManageFacility}/>
        <PrivateProtectedRoute path="/breeds" component={ManageBreed}/>


        {/* super admin, admin and user common route */}
        {
          Auth.authenticate() && Auth.isSuperAdmin() ?
            <PrivateRoute path="/broadcast" component={Broadcast}/>
            :
            <PrivateRoute path="/broadcast-news" component={Broadcast}/>
        }
        {
          Auth.authenticate() && Auth.isSuperAdmin() ?
            <PrivateProtectedRoute path="/users" component={ManageClient}/>
            :
            <StaffOrAdminRoute path="/add-clients" component={RegisterParent}/>
        }
      </Switch>
    </main>
  </MainWrapper>
);


export default Router;
