import React, {PureComponent} from 'react';
import {Button, Modal, ButtonToolbar} from 'reactstrap';

export default class ConfirmationBox extends PureComponent {

    constructor() {
        super();
        this.state = {
            show: false,
        };
    }

    render() {
        return(
          <Modal
            isOpen={this.props.show}
            className={`modal-dialog modal-dialog--confirm modal-dialog--header alert-modal`}>
              <div className="modal__body">
                  <p>The changes you have made will be lost if you navigate away from this page.
                      Are you sure you want to leave this page?</p>
              </div>
              <ButtonToolbar className="modal__footer">
                  <Button className="btn btn-no btn-secondary pull-left" onClick={() => { this.props.onCancel() }}>NO</Button>
                  <Button className="btn btn-yes btn-success pull-right" onClick={() => { this.props.onConfirm() }}>YES</Button>
              </ButtonToolbar>
          </Modal>
        );
    }
}