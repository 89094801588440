import React, { Component } from 'react';
import ClientJS from 'clientjs'
import LogInForm from './components/LogInForm';
import connect from "react-redux/es/connect/connect";
import { login } from '../../../redux/actions/userActions';
import localStorage from '../../../libs/storageHelper';
import config from '../../../config/app.config';
import constants from '../../../constants/constants';
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;


export class Login extends Component {
  constructor (props) {
    super(props);
    this.login = this.login.bind(this);
    this.state = {
      user: {}
    }
  }

  componentDidMount() {
    const user = localStorage.getFromStorage('loggedUser');
    if(user) {
      switch (user.user.type) {
        case constants.userRole.admin:
          this.props.history.push('metrics-dashboard');
          break;
        case constants.userRole.superAdmin:
          this.props.history.push('metrics');
          break;
        case constants.userRole.staff:
        default:
          this.props.history.push('tracking-board');
          break;
      }
    }
    const location = this.props.history.location.pathname;
    const formType = (location === '/provider-login') ? constants.userRole.staff : constants.userRole.admin;
    if(formType === constants.userRole.staff) {
      window.Beacon('init', 'd808c23f-0e57-44c3-8130-9ca84e5c88c7');
    }
  }

  componentWillReceiveProps(np){
    if (np.user.loginData) {
      const userData = np.user.loginData;
      localStorage.addToStorage('loggedUser', userData);
      if(userData.user.type === config.USER_TYPES.ADMIN && !userData.user.loggedFirstTime){
        np.history.push('change-password');
      } else if (userData.user.type === config.USER_TYPES.SUPER_ADMIN) {
        np.history.push('metrics');
      } else if (userData.user.type === config.USER_TYPES.ADMIN) {
        np.history.push('metrics-dashboard');
      }  else if (userData.user.type === config.USER_TYPES.STAFF && userData.user.loggedFirstTime) {
        np.history.push('tracking-board');
      } else if (userData.user.type === config.USER_TYPES.STAFF) {
        if (userData.user.privateCredentials) {
          np.history.push('change-password');
        } else {
          np.history.push('user-profile');
        }
      }
    } else if (np.user.loginErrorData) {
      this.setState({ user: np.user})
    } else {
      this.setState({ user: {}});
    }
  }

  login(state){
    const location = this.props.history.location.pathname;
    const formType = (location === '/provider-login') ? constants.userRole.staff : constants.userRole.admin;
    const client = new ClientJS();

    const value = {
      email:state.username,
      password:state.password,
      formType,
      data: {
        os:client.getOS().name,
        browser:client.getBrowser().name
      }
    };
    this.props.login(value);
  }

  render () {
    const location = this.props.history.location.pathname;
    const formType = (location === '/provider-login') ? constants.userRole.staff : constants.userRole.admin;

    return (
      <div className="account user-flow">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title">Welcome to
                  <span className="account__logo">
                  <img src={icon} alt="" />
                </span>
                </h2>
              </div>
              <h3 className="account__form-heading subheading">
                {
                  formType === constants.userRole.admin ? 'Admin Login' : 'Provider Login'
                }
              </h3>
              <h4 className="account__subhead subhead">Login to your account</h4>
              <LogInForm handleSubmit={this.login} user={this.state.user} formType={formType} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  login
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
