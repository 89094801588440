import React, {Component} from 'react';
import {Modal, ModalBody, Row, Col, Button, Collapse} from 'reactstrap';
import Moment from 'react-moment';
import config from '../../../config/app.config';
import {Scrollbars} from "react-custom-scrollbars";
import createDOMPurify from 'dompurify';
import { JSDOM } from 'jsdom';
import mixpanel from 'mixpanel-browser';
import TimePicker from 'rc-time-picker';
import Select from "react-dropdown-select";
import localStorage from '../../../libs/storageHelper';
import constants from '../../../constants/constants'
import moment from 'moment';
import humanize from  "underscore.string/humanize";

const defautAvatar = `${process.env.PUBLIC_URL}/img/animal_avatar.png`;

const window = (new JSDOM('')).window;
const DOMPurify = createDOMPurify(window);

export default class PhaseNotificationModel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapseID: 0,
      showCustomText: true,
      selectedCheckboxIds: [],
      selectedMessageIds: [],
      selectedMessages: [],
      customTextId: 1,
      customText: '',
      inputError: '',
      updateError: '',
      isEmptyTextError: false,
      isEmptyTimeError: false,
      isEmptyTextError2: false,
      isEmptyDropdownError: false,
      emptyTextError: '',
      errorMessages: [],
      textField1: '',
      textField2: '',
      multiSelect: [],
      selectedValue: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(id) {
    const { collapseID } = this.state;

    if (collapseID !== id) {
      this.setState({collapseID: id});
    } else {
      this.setState({collapseID: ''});
    }
  }

  componentDidMount() {
    this.setState({
      errorMessages: [],
      isEmptyTextError: false,
      isEmptyTextError2: false,
      isEmptyTimeError: false,
      isEmptyDropdownError: false,
      emptyTextError: '',
    })
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.show) {
      this.setState({
        selectedCheckboxIds: [],
        selectedMessageIds: [],
        selectedMessages: [],
        customText: '',
        collapseID: 0,
        btnDisable: false,
      });
    }
  }

  isMessageSelected = id => {
    const {selectedCheckboxIds} = this.state;
    return selectedCheckboxIds.indexOf(id) === -1 ? false : true;
  };

  handleSelectMessage = (id, message, isCustom, notificationType, control = null, value = null, placeHolder = null) => {
    const {selectedMessages, selectedMessageIds, selectedCheckboxIds, errorMessages} = this.state;
    let {isEmptyTextError, isEmptyTimeError,isEmptyTextError2, isEmptyDropdownError, multiSelect} = this.state;
    const selectedIndex = selectedMessageIds.indexOf(id);
    let multiController = (control === 'textField') ? ((placeHolder.split(",").length === 2) ? true : false) : false;

    const timeIndex = errorMessages.indexOf("isEmptyTimeError");
    const dropdownIndex = errorMessages.indexOf("isEmptyDropdownError");
    const textIndex = errorMessages.indexOf("isEmptyTextError");


    if (value !== null) {
      multiSelect = [];
      value.split(",").map(option => {
        multiSelect.push({value: option, label: option, id: option});
        return true;
      });
    }

    if (!isCustom) {
      if (selectedIndex === -1) {
        selectedMessageIds.push(id);
        selectedMessages.push({id, message, isCustom: false, notificationType, control });
      } else {
        selectedMessageIds.splice(selectedIndex, 1);
        selectedMessages.splice(selectedIndex, 1);
      }

      switch (control) {
        case 'timePicker':
          const isTimePicker = selectedMessages.find(message => message.control === 'timePicker');
          isEmptyTimeError = (selectedIndex === -1) ? true : false;
          if (selectedIndex === -1 && !errorMessages.includes("isEmptyTimeError")) {
            errorMessages.push("isEmptyTimeError")
          } else if (selectedIndex !== -1 && typeof isTimePicker === 'undefined') {
            errorMessages.splice(timeIndex, 1);
          }
          break;
        case 'dropDown':
          isEmptyDropdownError = (selectedIndex === -1) ? true : false;
          (selectedIndex === -1) ? errorMessages.push("isEmptyDropdownError") : errorMessages.splice(dropdownIndex, 1);
          break;
        case 'textField':
          isEmptyTextError = (selectedIndex === -1) ? true : false;
          if(selectedIndex === -1){
            (multiController)? errorMessages.push("isEmptyTextError", "isEmptyTextError2") : errorMessages.push("isEmptyTextError")
          } else {
            if (multiController){
              errorMessages.splice(textIndex, 1);
              const textIndex2 = errorMessages.indexOf("isEmptyTextError2");
              errorMessages.splice(textIndex2, 1);
            } else {
              errorMessages.splice(textIndex, 1);
            }
          }
          break;
        default:
          break;
      }

      this.setState({
        selectedMessageIds,
        selectedMessages
      });
    } else {
      if (selectedIndex !== -1) {
        selectedMessageIds.splice(selectedIndex, 1);
        selectedMessages.splice(selectedIndex, 1);
      }
      this.setState({
        selectedMessageIds,
        selectedMessages,
        isEmptyTextError: (message.length === 0) ? true : false,
      });
    }

    const selectedCheckboxIndex = selectedCheckboxIds.indexOf(id);
    if (selectedCheckboxIndex === -1) {
      selectedCheckboxIds.push(id);
    } else {
      selectedCheckboxIds.splice(selectedCheckboxIndex, 1);
    }

    this.setState({
      selectedCheckboxIds,
      updateError: '',
      selectedValue: [],
      multiSelect,
      isEmptyTimeError,
      isEmptyDropdownError,
      isEmptyTextError,
      errorMessages,
    });
  }

  getText(el) {
    return el.innerText || this.getTextForFirefox(el);
  }

  getTextForFirefox(el) {
    // Taken from http://stackoverflow.com/a/3908094
    var text = "";
    if (typeof window.getSelection != "undefined") {
      var sel = window.getSelection();
      var tempRange = sel.getRangeAt(0);
      sel.removeAllRanges();
      var range = document.createRange();
      range.selectNodeContents(el);
      sel.addRange(range);
      text = sel.toString();
      sel.removeAllRanges();
      sel.addRange(tempRange);
    }

    return text;
  }

  handleUpdateMessage = (e, id) => {
    const {selectedMessages, selectedMessageIds} = this.state;

    const text = this.getText(e.target).trim();
    const selectedIndex = selectedMessageIds.indexOf(id);
    if (!(text && text.length > 0)) {
      this.setState({
        isEmptyTextError: true
      });
    } else {
      this.setState({
        isEmptyTextError: false,
        updateError: ''
      });
    }

    selectedMessages[selectedIndex]['message'] = text;
    this.setState({
      selectedMessages
    });
  }

  handleCustomTextEdit = (e, id) => {
    const {selectedMessages, selectedMessageIds} = this.state;
    const text = e.target.value;
    const selectedIndex = selectedMessageIds.indexOf(id);

    if (text.length <= 250) {
      selectedMessages[selectedIndex]['message'] = text;
      this.setState({
        selectedMessages
      });
    }
    if (text && text.length > 0) {
      this.setState({
        isEmptyTextError: false,
        updateError: ''
      });
    } else {
      this.setState({
        isEmptyTextError: true
      });
    }
  }

  handleCustomMessage = () => {
    this.setState({showCustomText: !this.state.showCustomText, customText: '', inputError: ''});
  }

  handleApply = () => {
    const {selectedMessages, selectedMessageIds, selectedCheckboxIds} = this.state;
    let {customTextId, customText} = this.state;

    customText = customText.trim();
    if (customText !== '') {
      selectedMessageIds.push(customTextId);
      selectedCheckboxIds.push(customTextId);
      selectedMessages.push({
        id: customTextId,
        message: customText,
        isCustom: true,
        notificationType: null
      });
      customTextId = customTextId + 1;
      this.setState({
        selectedMessages,
        selectedCheckboxIds,
        customTextId,
        customText: '',
        showCustomText: true,
        inputError: '',
        updateError: ''
      });
    } else {
      this.setState({inputError: 'Please add a custom message.', updateError: ''});
    }
  }

  handleCustomText = e => {
    const customText = e.target.value;
    if (customText.length <= 250) {
      this.setState({customText});
    }
  }

  update = () => {
    const {selectedMessages, isEmptyTextError, isEmptyTimeError, isEmptyDropdownError, selectedCheckboxIds, customTextId, errorMessages} = this.state;
    const {drag} = this.props;

    let {customText} = this.state;
    customText = customText.trim();


    if (customText !== '') {
      this.setState({updateError: 'Please save custom message\n'});
    } else {

      if (selectedCheckboxIds.length !== 0) {
        if(errorMessages.length > 0){
          if (errorMessages.includes("isEmptyTextError") || errorMessages.includes("isEmptyTextError2")) {
            this.setState({updateError: 'Message cannot be empty\n'});
          } else if (errorMessages.includes("isEmptyTimeError")) {
            this.setState({updateError: 'Select a time\n'});
          } else if (errorMessages.includes("isEmptyDropdownError")) {
            this.setState({updateError: 'Select at least one option\n'});
          }
        }else {
          const isMessagesEmpty = selectedMessages.some(message => message.message.trim() === "");
          if (isEmptyTextError || isMessagesEmpty) {
            this.setState({updateError: 'Message cannot be empty\n'});
          } else if (isEmptyTimeError) {
            this.setState({updateError: 'Select a time\n'});
          } else if (isEmptyDropdownError) {
            this.setState({updateError: 'Select at least one option\n'});
          } else {
            this.setState({updateError: '', inputError: ''}, function () {
              const selectedMessageList = selectedMessages.filter(message => selectedCheckboxIds.includes(message.id));

              if (drag) {
                if (drag.laneId !== constants.appointmentPhases.expected.id) {
                  selectedMessageList.push({
                    id: customTextId,
                    isCustom: true,
                    message: this.loadDescription(drag),
                    notificationType: null,
                    isDragMessage: true
                  });
                }

                


              }
              this.setState({btnDisable: true});
              this.props.handleMessageSubmit(selectedMessageList, drag);
            });
          }
        }
      } else {
        if (!drag) {
          this.setState({updateError: 'Please select at least one message to send an update notification.\n'});
        } else {
          const message = [];
          if (drag.laneId !== constants.appointmentPhases.expected.id) {
            message.push({
              id: customTextId,
              isCustom: true,
              message: this.loadDescription(drag),
              notificationType: null,
              isDragMessage: true
            });
          }
          const phases = config.PHASES;
          mixpanel.init(localStorage.getConfig('mpt'));
          mixpanel.track('Card Move', {
            appointmentId: drag.cardId,
            phaseId: drag.laneId,
            phaseName: phases[drag.laneId],
          });
          this.setState({btnDisable: true});
          this.props.handleMessageSubmit(message, drag);
        }
      }
    }
  }

  handClosePTBModal = () => {
    this.setState({
      selectedMessages: [],
      selectedCheckboxIds: [],
      selectedMessageIds: [],
      customText: '',
      updateError: '',
      inputError: '',
      isEmptyTextError: false,
      showCustomText: true,
      errorMessages: [],
      isEmptyTimeError: false,
      isEmptyDropdownError: false,
      emptyTextError: '',
    }, function () {
      this.props.closePTBModal();
    });
  }

  formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  loadDescription(data) {
    const { facility, petSummary } = this.props;

    const preopType = data.type === 'surgery' ? 'Surgery' : 'Procedure';
    const facilityName = facility.name;
    const { name } = petSummary;

    switch (data.laneId) {
      case 2:
        return `${name} has been checked-in at the ${facilityName} veterinary hospital and is waiting to be seen by the doctor.`;
      case 3:
        return `${name} is now undergoing diagnostic evaluation.`;
      case 4:
        return `${name} is now in the Preparation phase before the ${preopType} begins.`;
      case 5:
        return `${name} is now undergoing their Treatment / Procedure in the good hands of the hospital.`;
      case 6:
        return `${name}’s Dental Procedure is now underway in the good hands of the hospital.`;
      case 7:
        return `${name} is now in the Surgery Room. Updates will follow.`;
      case 8:
        return `${name} is now in the Recovery phase after their successful procedure. Updates will follow, stay tuned.`;
      case 9:
        return `${name} has been admitted for overnight stay at the hospital.`;
      case 10:
        return `${name} is officially boarding with ${facilityName} and will be lovingly cared for during their stay. Updates will follow.`;
      case 11:
        return `${name} has started the Discharge / Exit Phase from ${facilityName}. Care guidelines and any updated pickup information will follow.`;
      case 12:
        return `${name} has been fully discharged by ${facilityName} into the care of a responsible party. Thank you for letting us take care of your pet.`;
      default:
        return true;
    }
  }

  handeReplace(value, occurrence, replaceValue){
    let nth = 0;

    return value.replace(/textField/g, (match, i, original) => {
      nth++;
      return (nth === occurrence) ? replaceValue : match;
    });

  }

  handleChange = (text, id, message, type, order = null) => {
    const {selectedMessages, selectedMessageIds, textField1, textField2, errorMessages} = this.state;
    let {isEmptyTextError, isEmptyTimeError, isEmptyDropdownError} = this.state;
    let messageText = '';

    switch (type) {
      case 'timePicker':
        messageText = (text !== null) ? message.replace(/timePicker/g, text.format('hh:mm A')) : message;
        isEmptyTimeError = false;

        const isTimePicker = selectedMessages.find(message => message.control === 'timePicker' && message.message.includes("ENTER TIME"));
        const timeIndex = errorMessages.indexOf("isEmptyTimeError");
        if (errorMessages.includes("isEmptyTimeError") && text !== null && typeof isTimePicker === 'undefined') {
          errorMessages.splice(timeIndex, 1);
        } else if(!errorMessages.includes("isEmptyTimeError") && text === null) {
          errorMessages.push("isEmptyTimeError");
        }
        break;
      case 'dropDown':
        isEmptyDropdownError = false;
        messageText = message.replace(/dropDown/g, text);
        const dropdownIndex = errorMessages.indexOf("isEmptyDropdownError");
        if (errorMessages.includes("isEmptyDropdownError")) {
          errorMessages.splice(dropdownIndex, 1);
        }

        break;
      case 'textField':
        isEmptyTextError = false;
        const textIndex = errorMessages.indexOf("isEmptyTextError");
        if (order === null) {
          messageText = message.replace(/textField/, text);
          if (errorMessages.includes("isEmptyTextError")) {
            errorMessages.splice(textIndex, 1)
          }
        } else if (order === 1) {
          messageText = message.replace(/textField/, text);
          messageText = this.handeReplace(messageText, 2, textField2);
          if (errorMessages.includes("isEmptyTextError") && text !== '') {
            errorMessages.splice(textIndex, 1);
          } else if(!errorMessages.includes("isEmptyTextError") && text === '') {
            errorMessages.push("isEmptyTextError");
          }
          this.setState({
            textField1: text
          });
        } else if (order === 2) {
          const textIndex2 = errorMessages.indexOf("isEmptyTextError2");
          if (errorMessages.includes("isEmptyTextError2") && text !== '') {
            errorMessages.splice(textIndex2, 1);
          } else if(!errorMessages.includes("isEmptyTextError2") && text === '') {
            errorMessages.push("isEmptyTextError2");
          }

          messageText = this.handeReplace(message, 2, text);
          messageText = messageText.replace(/textField/, textField1);
          this.setState({
            textField2: text
          });
        }
        break;
      default:
        break;
    }

    const selectedIndex = selectedMessageIds.indexOf(id);
    selectedMessages[selectedIndex]['message'] = messageText;
    this.setState({
      selectedMessages,
      isEmptyTextError,
      isEmptyDropdownError,
      isEmptyTimeError,
      errorMessages,
    });
  }

  setValues(optionsList, id, message) {
    const {selectedValue} = this.state;
    if (optionsList.length > 0) {
      let result = optionsList.map((item) => {
        return item.value;
      });
      result = result.toString();

      this.handleChange(result, id, message, 'dropDown');

      selectedValue.push(optionsList);
      this.setState({selectedValue});
    }
  }

  render() {
    const {petSummary, phaseMessages, facility, ptbMessages, show, drag} = this.props;
    const {collapseID, showCustomText, customText, selectedMessages, inputError, updateError, multiSelect, selectedValue, btnDisable} = this.state;
    const wordCount = (250 - ((customText !== null) ? customText.length : 0));
    const avatar = (petSummary && petSummary.image) ? `${config.PET_PROFILE_IMAGE_PATH}${petSummary.image}` : defautAvatar;
    let ptbMessageList = '';
    let messages = '';
    let customMessages = '';
    let dragMessage = '';

    if (ptbMessages) {
      ptbMessageList = ptbMessages.map((ptbMessage, key) => {
        const html = DOMPurify.sanitize(ptbMessage.message);

        if (ptbMessage.phaseMessageId !== null) {
          return (
            <div className="disable-phase-wrapper clearfix" key={ptbMessage._id}>
              <span className="heading" dangerouslySetInnerHTML={{__html: html}}></span>
              <span className="dateTime-wrapper">
              <span className="time">{ moment(ptbMessage.updatedAt).utc().seconds(facility.utcOffset * 60).format('hh:mm A')}</span>
              <span className="date">{ moment(ptbMessage.updatedAt).utc().seconds(facility.utcOffset * 60).format('MM/DD/YYYY')}</span>
              </span>
            </div>
          );
        } else {
          return (
            <div
              className={ptbMessage.isDragMessage ? "disable-custom-wrapper disable-custom-wrapper-drag" : "disable-custom-wrapper"}
              key={ptbMessage._id}>
              <span className="heading">{ptbMessage.isDragMessage ? 'Phase Change Update' : 'Custom'} Message</span>
              <span onClick={() => this.toggle(ptbMessage._id)}
                    className={(collapseID === ptbMessage._id) ? "collapse-btn show" : "collapse-btn"}></span>
              <span className="time">{ moment(ptbMessage.updatedAt).utc().seconds(facility.utcOffset * 60).format('hh:mm A')}</span>
              <span className="date">{ moment(ptbMessage.updatedAt).utc().seconds(facility.utcOffset * 60).format('MM/DD/YYYY')}</span>
              <Collapse
                key={ptbMessage._id}
                isOpen={collapseID === ptbMessage._id}>
                <span dangerouslySetInnerHTML={{__html: html}}></span>
              </Collapse>
            </div>
          );
        }
      });
    }
    if (phaseMessages) {
      if (drag && drag.laneId !== constants.appointmentPhases.expected.id) {

        dragMessage = (
          <div className="active-wrapper drag-message">
            <div className="states-block">
              <label className="checkbox-label">{this.loadDescription(drag)}</label>
            </div>
          </div>
        )
      }

      messages = phaseMessages.map((phaseMessage, key) => {

        const petName = (petSummary && petSummary.name) ? (petSummary.name.charAt(0).toUpperCase() + petSummary.name.slice(1)) : '';
        let message = (petSummary) ? phaseMessage.message.replace(/PetName/g, petName) : phaseMessage.message;
        let controlMessage = (petSummary && phaseMessage.controlMessage) ? phaseMessage.controlMessage.replace(/PetName/g, petName) : phaseMessage.message;
        if (phaseMessage.fetchable) {
          switch (phaseMessage.fetchableValue) {
            case 'FacilityName':
              message = message.replace(/FacilityName/g, facility.displayName);
              controlMessage = controlMessage.replace(/FacilityName/g, facility.displayName);
              break;
            case 'PhoneNumber':
              let phone = this.formatPhoneNumber(facility.phone);
              message = message.replace(/PhoneNumber/g, phone);
              controlMessage = controlMessage.replace(/PhoneNumber/g, phone);
              break;
            default:
              break;
          }
        }

        const html = DOMPurify.sanitize(message);
        controlMessage = DOMPurify.sanitize(controlMessage);
        if (phaseMessage.status == "tab") {
          return (
            <div className="disable-phase-wrapper clearfix" key={message._id}>
              <span className="heading" dangerouslySetInnerHTML={{__html: html}}></span>
              <span className="dateTime-wrapper">

              </span>
            </div>
          );
        } else {

          let editableContent = '';
          if (this.isMessageSelected(phaseMessage._id) && phaseMessage.editable && phaseMessage.control) {
            switch (phaseMessage.control) {
              case 'timePicker':
                editableContent = (<div className="editable-div">
                  {controlMessage.split("timePicker")[0]}
                  <TimePicker showSecond={false} placeholder="HH:MM AM"
                              onChange={(e) => this.handleChange(e, phaseMessage._id, controlMessage, "timePicker")}
                              format={'h:mm A'} use12Hours inputReadOnly minuteStep={15}/>
                  {controlMessage.split("timePicker")[1]}
                </div>);
                break;
              case 'textField':
                if (controlMessage.split("textField").length > 2) {
                  editableContent = (<div className="editable-div">
                    {controlMessage.split("textField")[0]}
                    <input type="text" placeholder="textField" placeholder={phaseMessage.placeholder.split(",")[0]}
                           onChange={(e) => this.handleChange(e.target.value.trim(), phaseMessage._id, controlMessage, "textField", 1)}/>
                    {controlMessage.split("textField")[1]}
                    <input type="text" placeholder="textField" placeholder={phaseMessage.placeholder.split(",")[1]}
                           onChange={(e) => this.handleChange(e.target.value.trim(), phaseMessage._id, controlMessage, "textField", 2)}/>
                    {controlMessage.split("textField")[2]}
                  </div>);
                } else {
                  editableContent = (<div className="editable-div">
                    {controlMessage.split("textField")[0]}
                    <input type="text" placeholder="textField" placeholder={phaseMessage.placeholder}
                           onChange={(e) => this.handleChange(e.target.value.trim(), phaseMessage._id, controlMessage, "textField")}/>
                    {controlMessage.split("textField")[1]}
                  </div>);
                }
                break;
              case 'dropDown':
                editableContent = (<div className="editable-div">
                  {controlMessage.split("dropDown")[0]}
                  <Select
                    options={multiSelect}
                    values={selectedValue}
                    searchable={false}
                    multi={true}
                    placeholder="Select diagnostic evaluation"
                    onChange={(values) => this.setValues(values, phaseMessage._id, controlMessage)}
                  />
                  {controlMessage.split("dropDown")[1]}
                </div>);
                break;
              default:
                break;
            }
          } else if (this.isMessageSelected(phaseMessage._id) && phaseMessage.editable) {
            editableContent = (<div className="editable-div" contentEditable="true"
                                    ref={"content" + phaseMessage._id}
                                    onInput={(e, id) => {
                                      this.handleUpdateMessage(e, phaseMessage._id);
                                    }}
                                    dangerouslySetInnerHTML={{__html: html}}></div>);
          } else {
            editableContent = null;
          }
          ;

          return (
            <div className="active-wrapper">
              <div className="states-block" key={phaseMessage._id}>
                <input
                  type="checkbox"
                  className="checkbox-input"
                  id={phaseMessage._id}
                  checked={this.isMessageSelected(phaseMessage._id)}
                  onChange={() => {
                    this.handleSelectMessage(phaseMessage._id, message, false, phaseMessage.notificationType, phaseMessage.control, phaseMessage.value, phaseMessage.placeholder);
                  }}
                />
                <label htmlFor={phaseMessage._id}
                       className={(this.isMessageSelected(phaseMessage._id) && phaseMessage.editable) ? "checkbox-label custom-text" : "checkbox-label"}>
                  {(this.isMessageSelected(phaseMessage._id) && phaseMessage.editable) ?
                    '.'
                    :
                    <p dangerouslySetInnerHTML={{__html: html}}></p>
                  }
                </label>
                {editableContent}
              </div>
            </div>
          );
        }
      });
    }

    if (selectedMessages) {
      customMessages = selectedMessages.map((selectedMessage, key) => {
        const html = DOMPurify.sanitize(selectedMessage.message);

        if (selectedMessage.isCustom) {
          return (
            <div className="active-wrapper">
              <div className="states-block" key={selectedMessage.id}>
                <input
                  type="checkbox"
                  className="checkbox-input"
                  id={selectedMessage.id}
                  checked={this.isMessageSelected(selectedMessage.id)}
                  onChange={(id, mes) => {
                    this.handleSelectMessage(selectedMessage.id, selectedMessage.message, true, null, selectedMessage.control, null, null);
                  }}
                />
                <label htmlFor={selectedMessage.id}
                       className={(this.isMessageSelected(selectedMessage.id)) ? "checkbox-label custom-text" : "checkbox-label"}>
                  {(this.isMessageSelected(selectedMessage.id)) ?
                    <p></p>
                    :
                    <p dangerouslySetInnerHTML={{__html: html}}></p>
                  }
                </label>
                {(this.isMessageSelected(selectedMessage.id)) ?
                  <textarea name="text" className="text-area edit"
                            value={selectedMessage.message}
                            onChange={(e) => {
                              this.handleCustomTextEdit(e, selectedMessage.id);
                            }} rows="5"/>

                  :
                  null
                }
              </div>
            </div>
          );
        }
      });
    }

    return (
      <div className="row ">
        <div className="col-md-12">
          <Modal isOpen={show} className={'model-form modal-lg patient-tracking-popup'}>

            <ModalBody>
              <div className="summary-wrapper">
                <Row>
                  <Col xs="2">
                    <img className="topbar__avatar-img user-profile__avatar-section--img" src={avatar}
                         alt="avatar"/>
                  </Col>
                  <Col>
                    <div className="summary-contant">
                      <div className={"summary-contant-inner"}>
                        <span className="petname"
                              title={petSummary && petSummary.name}>{petSummary && petSummary.name}</span><span
                        className="pet-parent"
                        title={petSummary && petSummary.petParentName}>{petSummary && petSummary.petParentName}</span>
                      </div>
                      <div>
                        {(petSummary) ?
                          <span className="text">
                          {petSummary.petSpeciesName}
                            {(petSummary.petBreedName) ? `, ${petSummary.petBreedName}` : ''}
                            {(petSummary.gender) ? `, ${humanize(petSummary.gender)}` : ''}
                        </span>
                          :
                          ''
                        }

                        {(petSummary && petSummary.birthday) ?
                          <span>
                            <span className="gender-icon-male"></span>
                            <span className="text">
                            <Moment format="MM/DD/YYYY">{petSummary.birthday}</Moment>
                            </span>
                              </span>
                          :
                          ""
                        }
                      </div>
                      <div className={"supporter-summary"}>
                        <span
                          className="text">{(petSummary && petSummary.coParents) ? (petSummary.coParents.length + 1) : 1} Parent, {petSummary && petSummary.supporters && petSummary.supporters.length} Supporters</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="message-container">
                <Scrollbars style={{width: "100%", minWidth: "200px"}}>
                  <div className="blocks-wrapper">
                    {dragMessage}
                    {messages}
                    {customMessages}
                    {ptbMessageList}

                    {/*active custom message*/}
                    <div className={(showCustomText) ? "custom-button-wrapper" : "custom-button-wrapper hidden"}>
                      <div className="states-block" key="custom-btn-5">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          id="custom-btn-5"
                          checked={!showCustomText}
                          onChange={() => {
                            this.handleCustomMessage();
                          }}
                        />
                        <label htmlFor="custom-btn-5" className="checkbox-label custom-massage">
                          Add Custom Message
                        </label>
                      </div>
                    </div>
                    <div className={(showCustomText) ? "custom-text-wrapper hidden" : "custom-text-wrapper"}>
                      <div className="states-block text" key="custom-txt-5">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          id="custom-txt-5"
                          checked={!showCustomText}
                          onChange={() => {
                            this.handleCustomMessage();
                          }}
                        />
                        <label htmlFor="custom-txt-5" className="checkbox-label custom-text">.</label>
                        <textarea name="text" className="text-area" id="exampleText"
                                  value={customText}
                                  onChange={(e) => {
                                    this.handleCustomText(e);
                                  }} placeholder="Add custom message…" rows="5"/>
                        <span className="word-count">{wordCount} characters remaining
                         <span className="apply-btn" onClick={() => this.handleApply()}></span>
                        </span>
                        <div className="error-text error">{inputError}</div>
                      </div>
                    </div>
                  </div>
                </Scrollbars>
              </div>
              <div className="action-button-wrapper">
                <div className="error-text error">{updateError}</div>
                <Button className={'clear btn-popup-clear'} color="secondary" onClick={() => {
                  this.handClosePTBModal()
                }}>Cancel</Button>{' '}
                <Button disabled={btnDisable} className={'save-update'} color="primary" onClick={() => {
                  this.update()
                }}>Update</Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
