import React, { Component } from 'react';
import { Card, CardBody, Col, FormGroup, Row, TabPane, TabContent,
  NavLink, NavItem, Nav } from 'reactstrap';
import { connect } from 'react-redux';
import HeadingText from "../../../shared/components/form/HeadingText";
import MessageModal from "../../../shared/components/Modals/MessageModal";
import { formatPhone } from "../../../libs/commonHelper";
import { getProfile, clearPetProfile } from "../../../redux/actions/petActions";
import { reinvite, clearUpdateProps } from '../../../redux/actions/clientActions';
import { updateParentStatus, updateParent, getSpecies } from '../../../redux/actions/petActions';
import { toggleLoading } from '../../../redux/actions/commonActions';
import Error from '../../../shared/components/form/Error';
import UpdatePets from "./Components/UpdatePets";
import config from "../../../config/app.config";
import ConfirmationBox from "../../../shared/components/Modals/ConfirmationBox";
import NavigationPrompt from "react-router-navigation-prompt";
import Appointments from './Components/Appointments';
import {reset, validate} from "../../../libs/validationHelper";
import AddNewPet from "./Components/AddNewPet";

class ParentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileId: this.props.match.params.parentId,
      profile: {},
      parentData: null,
      profileAvailable: true,
      profileUpdateEnable: false,
      refreshState: true,
      activeTab: '1',
      inputs: {
        firstName: '',
        lastName: '',
      },
      error: false,
      errors: {
        firstName: {error: null, display: 'first name'},
        lastName: {error: null, display: 'last name'},
      },
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseAlertType: '',
        responseType: '',
        responseTitle: '',
        pet: null,
        status: null,
        confirmType: null
      },
      inputChanged: false,
      anyPetInputChanged: false,
      petInputChanged: [],
      newPetPopup: false,
      speciesList: []
    };
  };

  newPetPopup = () => {
    this.setState({ newPetPopup: true });
  };

  closeNewPetPopup = () => {
    this.setState({ newPetPopup: false }, function () {
      this.props.getProfile(this.props.match.params.parentId);
    });
  };

  changeTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  reInvite = (params) => {
    this.props.toggleLoading(true);
    this.props.reinvite(params);
  };

  closePopup = () => {
    const { confirmPopup } = this.state;
    let { refreshState } = this.state;
    confirmPopup.showResponse = false;
    refreshState = !refreshState;
    this.setState({ confirmPopup, refreshState });
    this.props.clearUpdateProps();
  };

  onSuccess = () => {
    const { confirmPopup, profile, refreshState } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.toggleLoading(true);
      this.props.updateParentStatus({ status: confirmPopup.status, id: profile._id });
    }
    confirmPopup.showResponse = false;
    this.setState({
      confirmPopup,
    });
  };

  changeStatus = (status) => {
    const { profile } = this.state;
    let statusMessage = '';
    switch (status) {
      case 'active':
      default:
        statusMessage = `Are you sure you want to activate ${profile.firstName} ${profile.lastName}?`;
        break;
      case 'inactive':
        statusMessage = `Are you sure you want to deactivate ${profile.firstName} ${profile.lastName}?`;
        break;
    }
    const confirmPopup =  {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      footer: true,
      confirmType: 'change_status',
      status
    };
    this.setState({ confirmPopup });
  };

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    let { profile } = this.state;
    let { inputs, refreshState, profileUpdateEnable, inputChanged } = this.state;
    inputChanged = false;
    refreshState = !refreshState;
    inputs[property] = value;
    profileUpdateEnable = inputs.firstName !== profile.firstName || inputs.lastName !== profile.lastName;
    if (profileUpdateEnable) {
      inputChanged = true;
    }
    this.setState({
      inputs, refreshState, profileUpdateEnable, inputChanged
    });
  };

  validate = (inputs) => {
    const { refreshState } = this.state;
    let { errors } = this.state;
    errors = reset(errors);
    const validationPetRules = {
      required: ['firstName', 'lastName'],
    };
    const validationPetResponse = validate(validationPetRules, inputs, errors);
    errors = validationPetResponse.errors;
    const error = validationPetResponse.error;
    this.setState({ errors, error, refreshState: !refreshState });
    return error;
  };

  updateParent = () => {
    const { inputs, profile } = this.state;
    if (!this.validate(inputs)) {
      this.props.toggleLoading(true);
      this.props.updateParent(inputs, profile._id);
    }
  };

  inputChangeStatus = (id, status) => {
    const { petInputChanged } = this.state;
    petInputChanged[id] = status;
    const valueList = Object.keys(petInputChanged).map(function(key) {
      return petInputChanged[key]
    });
    let anyPetInputChanged = valueList.includes(true);
    this.setState({
      petInputChanged, anyPetInputChanged
    });
  };

  componentDidMount() {
    this.props.getProfile(this.props.match.params.parentId);
    this.props.getSpecies();
  };

  componentWillReceiveProps(np) {
    if (np.pets.profile) {
      const { inputs } = this.state;
      let { profileAvailable, profileUpdateEnable } = this.state;
      const profile = np.pets.profile;
      inputs.firstName = profile.firstName;
      inputs.lastName = profile.lastName;
      if (np.pets.profile.error) {
        profileAvailable = false;
      }
      profileUpdateEnable = false;
      const parentData = {
        avatar: profile.avatar,
        email: profile.email,
        firstName: profile.firstName,
        invited: profile.invited,
        lastName: profile.lastName,
        loggedFirstTime: profile.loggedFirstTime,
        loggedFirstTimeDate: profile.loggedFirstTimeDate,
        name: `${profile.firstName} ${profile.lastName}`,
        phone: profile.phone,
        status: profile.status,
        username: profile.username,
        _id: profile._id
      };
      this.setState({ profile, profileAvailable, inputs, profileUpdateEnable, parentData });
      this.props.clearPetProfile();
    }
    if(np.client.statusUpdated){
      const { confirmPopup, profile } = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `Invitation resent to ${profile.firstName} ${profile.lastName}`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      confirmPopup.okText = 'OK';
      confirmPopup.cancelText = 'OK';
      confirmPopup.footer = true;
      confirmPopup.action = '';
      this.setState({
        confirmPopup
      });
    }
    if (np.pets.parentUpdateStatus) {
      const { refreshState, confirmPopup, profile } = this.state;
      const _this = this;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `${profile.firstName} ${profile.lastName} ${(confirmPopup.status === 'active') ? 'activated' : 'deactivated'}`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      if (np.pets.parentUpdateStatus.errorMessage) {
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage = np.pets.parentUpdateStatus.errorMessage;
      } else {
        profile.status = confirmPopup.status;
      }
      this.setState({ confirmPopup, profile,  refreshState: !refreshState }, function () {
        _this.props.clearPetProfile();
      });
    }
    if (np.pets.parentProfileUpdateResponse) {
      const { refreshState, confirmPopup, profile, inputs } = this.state;
      let { profileUpdateEnable, inputChanged } = this.state;
      const _this = this;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `Parent profile updated`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      if (np.pets.parentProfileUpdateResponse.errorMessage) {
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage = np.pets.parentProfileUpdateResponse.errorMessage;
      } else {
        inputChanged = false;
        profileUpdateEnable = false;
        profile.firstName = inputs.firstName;
        profile.lastName = inputs.lastName;
      }
      this.setState({ confirmPopup,  refreshState: !refreshState, profileUpdateEnable,
        inputChanged, profile }, function () {
        _this.props.clearPetProfile();
      });
    }

    if (this.props.location && this.props.location.activeTab && !this.state.isSetActiveTab) {
      this.setState({
        activeTab: this.props.location.activeTab,
        isSetActiveTab: true
      });
    }
    if (np.pets.speciesList) {
      let speciesList = [];
      if ( np.pets.speciesList.length > 0) {
        speciesList = np.pets.speciesList.map((value) => {
          return (
            <option key={value._id} value={value._id}>{value.name}</option>
          );
        });
        speciesList.unshift(<option key='' value=''>Select Species</option>);
      }
      this.setState({
        speciesList
      });
    }
  };

  handleRedirect = (data) => {
    this.props.history.push(data);
  };

  render() {
    const { profile, confirmPopup, inputs, errors, profileAvailable, profileUpdateEnable
      , inputChanged, anyPetInputChanged, parentData, newPetPopup, speciesList } = this.state;
    const defaultAvatar = `${process.env.PUBLIC_URL}/img/default.png`;
    const parentImage = (profile.avatar) ? `${config.PARENT_PROFILE_IMAGE_PATH}${profile.avatar}` : defaultAvatar;
    const unSavePopup = inputChanged || anyPetInputChanged;
    const profileHeader = (profile && profile.firstName) ? `${profile.firstName} ${profile.lastName}'s Profile` : '';
    const parentName = (profile && profile.firstName) ? `${profile.firstName} ${profile.lastName}` : '';

    const tabContent = (
      <div className="row">
        <div className="col-md-12">
          {(this.state.activeTab === '2') &&
            <button className={'btn btn-outline float-right new-appointment-action left-align-btn'}
                  onClick={this.newPetPopup}>
              <span className="lnr lnr-plus-circle"></span> Add new pet</button>
          }
          {(this.state.activeTab === '3') &&
          <button className="btn btn-outline float-right new-appointment-action"
                  disabled={!(profile.status === 'active')}
                  onClick={() => {
                    this.handleRedirect({
                      pathname: '/new-appointment',
                      state: {
                        user: parentData
                      }
                    })
                  }}>
            <span className="lnr lnr-plus-circle"></span>
            Add New Appointment
          </button>
          }
          <div className={'loyal-profile-tab-container'}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={{ active: this.state.activeTab === '1' }}
                  onClick={() => { this.changeTab('1'); }}>Parent Information</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={{ active: this.state.activeTab === '2' }}
                  onClick={() => { this.changeTab('2'); }}>Pet Information</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={{ active: this.state.activeTab === '3' }}
                  onClick={() => { this.changeTab('3'); }}>Appointments</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {/* Parent Information tab */}
              <TabPane tabId="1" className={'default-wrapper'}>
                <Row>
                  <Col sm="12">
                    <div className="row find-parent-wrapper">
                      <div className="col-md-6 parent-collapse ">
                        <div className="parent-card">
                          <div className="row">
                            <div className="col-md-3">
                              <img className="parent-image"
                                   src={parentImage}
                                   alt="avatar" />
                            </div>
                            <div className="col-md-9">
                              <p className="parent-name">{parentName} </p>
                              <p className="parent-email">{profile.email}</p>
                              <p className="parent-tel">{profile.phone
                                ? formatPhone(profile.phone,  '(###) ###-####') : ''}</p>
                            </div>
                          </div>
                          { profile.firstName &&
                            <div className="row">
                              <div className="offset-md-3 col-md-9">
                                <div className="status-label parent-actions invited">
                                  {(profile.joined) ? 'Joined' : 'Invited'}
                                </div>
                                {
                                  !profile.joined &&
                                  <div className="action-wrapper parent-actions">
                                    <button className="action" onClick={() => {
                                      this.reInvite({id: profile._id, facility: profile.facility})
                                    }}>
                                      Reinvite
                                    </button>
                                  </div>
                                }
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                      </div>
                    </div>
                    <div className="row content-holder">
                      <div className="col-md-3">
                        <div className="form__form-group loyal-custom-form-group">
                          <span className="form__form-group-label">First Name <span className={'required'}>*</span></span>
                          <div className="form__form-group-field">
                            <FormGroup>
                              <input
                                className="form-control"
                                name="firstName"
                                type="text"
                                placeholder={'First Name'}
                                value={inputs.firstName}
                                maxLength={50}
                                onChange = {this.onChangeFields}
                              />
                              {errors.firstName.error && <Error text={errors.firstName.error}/>}
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form__form-group loyal-custom-form-group">
                          <span className="form__form-group-label">Last Name  <span className={'required'}>*</span></span>
                          <div className="form__form-group-field">
                            <FormGroup>
                              <input
                                className="form-control"
                                name="lastName"
                                type="text"
                                value={inputs.lastName}
                                placeholder={'Last Name'}
                                maxLength={50}
                                onChange = {this.onChangeFields}
                              />
                              {errors.lastName.error && <Error text={errors.lastName.error}/>}
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6"> </div>
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="row">
                      <div className="col-md-6 offset-md-6 text-right">
                        <button className={'btn loyal-btn-save-update'}
                                disabled={!profileUpdateEnable}
                                onClick={this.updateParent}>Update</button>
                        <button className={`btn ${ (profile.status === 'active') ? 'loyal-btn-deactivate' : 'loyal-btn-activate'}`}
                                onClick={() => {this.changeStatus((profile.status === 'active') ? 'inactive' : 'active')}}>
                          { (profile.status === 'active') ? 'Deactivate' : 'Reactivate'}
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              {/* Parent Information tab */}
              {/* Pet Information tab */}
              <TabPane tabId="2">
                <UpdatePets profile={profile} inputChangeStatus={this.inputChangeStatus} />
              </TabPane>
              {/* Pet Information tab */}
              {/* Appointments tab */}
              <TabPane tabId="3" className={'default-wrapper'}>
                <Row>
                  <Col sm="12">
                    {
                      parentData &&
                      <Appointments parentId={this.state.profileId}
                                    handleRedirect={this.handleRedirect}
                                    profile={profile}
                                    parent={parentData}/>
                    }
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </div>
        <AddNewPet view={newPetPopup} closeNewPetPopup={this.closeNewPetPopup}
                     parent={parentData}
                     speciesList={speciesList}/>
      </div>);
    return (
      <div className="manage-patient">
        <Col md={12} lg={12}  className="manage-patient-container admin-container">
          <Card>
            <CardBody>
              <HeadingText text={profileHeader}/>
              {profileAvailable &&
                <div className="content-wrapper">
                  {tabContent}
                </div>
              }
              {!profileAvailable &&
                <div className="alert alert-danger fade show" role="alert">
                  <div className="alert__content"><p>No parent record found.</p></div>
                </div>
              }
            </CardBody>
          </Card>
          <NavigationPrompt when={unSavePopup}>
            {({onConfirm, onCancel}) => (
              <ConfirmationBox show={true} onCancel={onCancel} onConfirm={onConfirm}/>
            )}
          </NavigationPrompt>
          <MessageModal show={confirmPopup.showResponse}
                        type={confirmPopup.responseType}
                        alertType={confirmPopup.responseAlertType}
                        footer={ true }
                        onSuccess={this.onSuccess}
                        onClose={this.closePopup}
                        message={confirmPopup.responseMessage}/>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getProfile, reinvite, toggleLoading, clearUpdateProps,
  updateParentStatus, updateParent, clearPetProfile, getSpecies
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentProfile)
