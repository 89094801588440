import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import Error from '../../shared/components/form/Error';
import { updateStaffMember, resetUpdatePopupNP } from '../../redux/actions/staffActions';
import MaskedInput from "react-maskedinput";
import config from '../../config/app.config';

class ViewStaff extends PureComponent {
  constructor() {
    super();

    this.state = {
      modal: false,
      facilityOptions: [],
      designationOptions: [],
      selectedMember: {},
      inputs: {
        firstName: '',
        lastName: '',
        email: '',
        facility: '',
        designation: '',
        username: '',
        phone: '',
        extension: '',
        privateCredentials: false
      },
      error: false,
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        facility: null,
        designation: null,
        username: null,
        phone: null,
        extension: null,
        privateCredentials: null
      },
      successResponse: null,
      errorResponse: null,
      refreshState: true,
    };
  };

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    this.setState({
      inputs, refreshState
    });
  };

  onSubmit = () => {
    if(!this.validate(false)){
      const inputs = this.state.inputs;
      inputs.phone = (inputs.phone) ? inputs.phone.replace(/[^A-Z0-9]+/ig, '') : inputs.phone;
      this.props.updateStaffMember(this.state.selectedId, inputs);
    }
  };

  closeModal = () => {
    const inputs = {
      firstName: '',
      lastName: '',
      email: '',
      facility: '',
      designation: '',
      username: '',
      phone: '',
      extension: '',
      privateCredentials: false
    };
    this.setState({
      inputs
    });
    this.toggle();
  };

  validate = (element) => {
    const { inputs } = this.state;
    const onlyNumberRegex = /^\d+$/;
    const errors = {
      firstName: null,
      lastName: null,
      email: null,
      facility: null,
      designation: null,
      username: null,
      phone: null,
      extension: null,
      privateCredentials: null
    }
    let error = false;

    if(!inputs.firstName) { errors.firstName = 'First name cannot be empty'; error = true }
    else if(/\s/.test(inputs.firstName)) { errors.firstName = 'Invalid first name'; error = true}
    else if(inputs.firstName.length>50) { errors.firstName = 'Invalid first name'; error = true}

    if(!inputs.lastName) { errors.lastName = 'Last name cannot be empty'; error = true }
    else if(/\s/.test(inputs.lastName)) { errors.lastName = 'Invalid last name'; error = true}
    else if(inputs.lastName.length>50) { errors.firstName = 'Invalid last name'; error = true}

    if (inputs.privateCredentials === true) {
      if (!inputs.email) {
        errors.email = 'Email cannot be empty';
        error = true
      }
    }
    if (inputs.email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputs.email)) {
      errors.email = 'Invalid email';
      error = true
    }
    if(!inputs.facility) { errors.facility = 'Facility cannot be empty'; error = true }

    if(inputs.phone) {
      const rawNumber = inputs.phone.replace(/[^A-Z0-9]+/ig, '');
      if(rawNumber.length !== 10) { errors.phone = 'Invalid phone number'; error = true }
      else if(!onlyNumberRegex.test(rawNumber)) { errors.phone = 'Invalid phone number'; error = true}
    }

    if(inputs.extension) {
      if(inputs.extension.length > 5) { errors.extension = 'Invalid extension'; error = true }
      else if(!onlyNumberRegex.test(inputs.extension)) { errors.extension = 'Invalid extension'; error = true}
    }

    if(!inputs.designation) { errors.designation = 'Designation cannot be empty'; error = true }

    if(!inputs.username) { errors.username = 'Username cannot be empty'; error = true }

    this.setState({errors, error, errorResponse: null, successResponse: null })
    return error;
  };

  toggle() {
    const error = false;
    const errors = {
      firstName: null,
      lastName: null,
      email: null,
      facility: null,
      designation: null,
      username: null,
      phone: null,
      extension: null,
      privateCredentials: null
    };
    this.setState({
      modal: !this.state.modal, error, errors, successResponse: null, errorResponse: null
    });
  };

  componentDidMount(){
  };

  componentWillReceiveProps(np){

    const {
      facilityOptions,
      designationOptions
    } = np.data;
    const { selectedId, selectedMember, view } = np;

    if(np.staff.updateStaffResponse){

      this.setState({
        successResponse: 'Staff member updated successfully',
        selectedMember: this.state.input,
        errorResponse: null
      });
      setTimeout(() => {
        this.closeModal();
      }, config.POPUP_TIMEOUT_TIME);
    }
    if(np.staff.updateStaffErrorResponse){
      if (np.staff.updateStaffErrorResponse.errorMessage) {
        this.setState({errorResponse: np.staff.updateStaffErrorResponse.errorMessage, successResponse: null});
      }
    }
    if (view === true && this.state.modal === false) {

      const inputs = {
        firstName: selectedMember.firstName,
        lastName: selectedMember.lastName,
        email: selectedMember.email,
        facility: selectedMember.facilityId,
        designation: selectedMember.designationId,
        username: selectedMember.username,
        phone: selectedMember.phone,
        extension: selectedMember.extension,
        privateCredentials: selectedMember.privateCredentials
      };
      this.props.resetUpdatePopupNP();
      this.setState({
        facilityOptions,
        designationOptions,
        selectedId,
        selectedMember,
        inputs,
        modal: true,
        successResponse: null,
        errorResponse: null
      });
    }
  };

  render() {
    const { facilityOptions, inputs, designationOptions, errors, successResponse, errorResponse,
      } = this.state;
    const { handleSubmit } = this.props;
    const form = (
      <Card>
        <CardBody>
          {successResponse !== null &&
          <div className="alert alert-success fade show" role="alert">
            <div className="alert__content"><p>{successResponse}</p></div>
          </div>
          }
          {errorResponse !== null &&
          <div className="alert alert-danger fade show" role="alert">
            <div className="alert__content"><p>{errorResponse}</p></div>
          </div>
          }
          <form className="form" onSubmit={handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">First Name <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="firstName"
                          type="text"
                          maxLength={50}
                          value={inputs.firstName}
                          onChange = {this.onChangeFields}
                        />
                        {errors.firstName && <Error text={errors.firstName}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Last Name <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="lastName"
                          type="text"
                          value={inputs.lastName}
                          maxLength={50}
                          onChange = {this.onChangeFields}
                        />
                        {errors.lastName && <Error text={errors.lastName}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email {(inputs.privateCredentials === true)  && <span className={'required'}>*</span>}</span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="email"
                          type="text"
                          disabled={true}
                          value={inputs.email}
                          onChange = {this.onChangeFields}
                        />
                        {errors.email && <Error text={errors.email}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Facility <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <select name="facility" id="facility"
                                className="form-control form-select"
                                value={inputs.facility}
                                disabled={true}
                                onChange = {this.onChangeFields}>
                          {facilityOptions}
                        </select>
                        {errors.facility && <Error text={errors.facility}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Phone</span>
                        <div className="form__form-group-field">
                          <FormGroup>
                            <MaskedInput mask="(111) 111-1111"
                                         className="form-control"
                                         name="phone"
                                         value={inputs.phone || ''}
                                         type="text"
                                         placeholder={''}
                                         maxLength={10}
                                         onChange = {this.onChangeFields}/>
                            {errors.phone && <Error text={errors.phone}/>}
                          </FormGroup>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Ext:</span>
                        <div className="form__form-group-field">
                          <FormGroup>
                            <MaskedInput mask="11111"
                                         name="extension"
                                         type="text"
                                         value={inputs.extension || ''}
                                         maxLength={5}
                                         className="form-control ext"
                                         onChange = {this.onChangeFields}
                                         placeholder={''} />
                            {errors.extension && <Error text={errors.extension}/>}
                          </FormGroup>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Designation <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <select name="designation" id="designation"
                                value={inputs.designation}
                                className="form-control form-select"
                                onChange = {this.onChangeFields}>
                          {designationOptions}
                        </select>
                        {errors.designation && <Error text={errors.designation}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">User Name <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="username"
                          type="text"
                          disabled={true}
                          value={inputs.username}
                          onChange = {this.onChangeFields}
                        />
                        {errors.username && <Error text={errors.username}/>}
                      </FormGroup>
                    </div>

                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Password <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="password"
                          type="text"
                          disabled={inputs.privateCredentials}
                          value={inputs.password}
                          onChange = {this.onChangeFields}
                        />
                        {errors.password && <Error text={errors.password}/>}
                      </FormGroup>
                    </div>

                  </div>
                </div>
                <div className="col-md-4">
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 text-left">
                  <div className="checkbox-wrapper">
                    <label className="checkbox-container terms-and-conditions">
                      <input
                        id="privateCredentials"
                        className="custom-checkbox"
                        type="checkbox"
                        disabled={true}
                        name="privateCredentials"
                        checked={inputs.privateCredentials}
                        onChange={this.onChangeFields}
                      />
                      <span className="checkmark loyal-check-mark-disable"/>
                      <label className="form-check-label" htmlFor="privateCredentials">Private Credentials</label>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-right">
                  <Button className={'clear'} color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                  <Button className={'save-update'} color="primary" onClick={this.onSubmit}>Update</Button>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    );

    return (
      <Modal isOpen={this.state.modal}
             size={'lg'}
             backdrop={'static'}
             className={'model-form'}>
        <ModalHeader className={'header-form'}>View Staff Member</ModalHeader>
        <ModalBody>
          {form}
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  updateStaffMember, resetUpdatePopupNP
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewStaff)
