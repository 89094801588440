import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import Table from './table';
import Modal from './modal';
import { getAllFacilities } from '../../redux/actions/staffActions';
import { toggleLoading } from '../../redux/actions/commonActions';
import { saveDoctor, getDoctors, getDoctorById, updateDoctor, updateStatus, reinvite } from '../../redux/actions/referralDoctorAction';
import constants from '../../constants/responseMessages';
import { ChartHistogramIcon } from 'mdi-react';
import config from "../../config/app.config";

let filterData = {}

class ReferralDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityList: [],
      doctorList: [],
      doctorDetails: null,
      doctorId: null,
      facilityId: null,
      toggleForm: false,
      modalSuccessResponse: false,
      modalFailedResponse: false,
      isUpdate: false,
      changeStatus: null,
    }

  }

  componentDidMount() {
    this.props.getAllFacilities();
    this.props.getDoctors();

  }

  componentWillReceiveProps(np) {
    const { refDoctor, staff } = np;

    if(staff.facilityAll) {
      this.setState({ facilityList: staff.facilityAll, modalFailedResponse: null })
    }

    if(refDoctor.doctorList) {
      this.setState({ doctorList: refDoctor.doctorList, changeStatus: false});
    }

    if(refDoctor.doctorDetails && !refDoctor.updated) {
      this.setState({ doctorDetails: refDoctor.doctorDetails, modalFailedResponse: null, modalSuccessResponse: null});
    }

    if(refDoctor.statusChanged) {
      this.setState({ changeStatus: true})
    };

    if(refDoctor.reinvited) {
      this.setState({ reinvited: true})
    };

    if(refDoctor.invited) {
      this.setState({ modalSuccessResponse: constants.SUCCESS.INVITATION_SUCCESS });
      setTimeout(() => {
        this.props.getDoctors(filterData);
        this.setState({toggleForm: false});
      }, config.POPUP_TIMEOUT_TIME);
    }

    if(refDoctor.updated) {
      this.setState({ modalSuccessResponse: constants.SUCCESS.REF_DOCTOR_UPDATE, modalFailedResponse: null });
      setTimeout(() => {
        this.props.getDoctors(filterData);
        this.setState({toggleForm: false});
      }, config.POPUP_TIMEOUT_TIME);
    }

    if(refDoctor.invitedError) {
      this.setState({ modalFailedResponse: refDoctor.invitedErrorMessage.errorMessage, modalSuccessResponse: null });
    }
  }

  toggleForm = (status, doctorId= null, facilityId=null) => {
    let isUpdate = doctorId ? true: false;
    this.setState({toggleForm: status, isUpdate, doctorId, facilityId, modalSuccessResponse: null, modalFailedResponse: null});
  }

 save = (params, isUpdate) => {
  const { doctorId } =  this.state;

  if(isUpdate) {
    delete params.email;
    this.props.updateDoctor(params, doctorId);
  } else {
    this.props.saveDoctor(params);
    this.props.toggleLoading(true);
  }

 }

 getdoctorDetails = () => {
    if(this.state.isUpdate) {
      this.props.getDoctorById(this.state.doctorId);
    }
 }

 getDoctors = (params) => {
  filterData = params;
   this.props.getDoctors(params);
 }

 updateStatus = (params) => {
   filterData = params;
   this.props.updateStatus(params);
 }

 reinvite = (params) => {
   filterData = params;
   this.props.reinvite(params);
 }


  render() {
    const { changeStatus, reinvited, facilityList,facilityId, isUpdate, doctorList, toggleForm, modalSuccessResponse,modalFailedResponse, doctorDetails } = this.state;

    return (
      <div className="referral-doctor">
        <Col md={12} lg={12} >
        <Card>
          <CardBody>
               <Table
                facilityList={facilityList}
                toggleForm={this.toggleForm}
                doctorList={doctorList}
                getDoctors={this.getDoctors}
                updateStatus={this.updateStatus}
                changeStatus={changeStatus}
                reinvite={this.reinvite}
                reinvited={reinvited}
               />
               { toggleForm &&
                  < Modal
                  toggleForm={this.toggleForm}
                  facilityList={facilityList}
                  modalSuccessResponse={modalSuccessResponse}
                  modalFailedResponse={modalFailedResponse}
                  save={this.save}
                  isUpdate={isUpdate}
                  getdoctorDetails={this.getdoctorDetails}
                  doctorDetails= {doctorDetails}
                  facilityId={facilityId}
                  />
               }
          </CardBody>
        </Card>
        </Col>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getAllFacilities,
  saveDoctor,
  toggleLoading,
  getDoctors,
  getDoctorById,
  updateDoctor,
  updateStatus,
  reinvite
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralDoctor)
