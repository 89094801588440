import React, { Component } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { toggleMessageModel } from '../../../redux/actions/commonActions';
import connect from "react-redux/es/connect/connect";
import config from "../../../config/app.config";
import ConfirmPopupDetails from '../../../containers/Web/CreateAppointment/Components/ConfirmPopupDetails'

const TYPE = { ALERT: 'alert' };

class MessageModal extends Component{
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount(){
    this.setState({
      show: this.props.show,
    });
  }

  componentWillReceiveProps(np){
    this.setState({
      show: np.show,
    }, () => {
      if (np.show && np.type === TYPE.ALERT) {
        setTimeout(() => {
          this.props.onClose();
        }, config.POPUP_TIMEOUT_TIME);
      }
    });
  }

  toggle() {
    this.setState({
      show: !this.state.show,
    });
  }

  onClose = () => {
    this.props.onClose();
  }

  onSuccess = () => {
    this.props.onSuccess();
  }

  render(){
    
    // type : confirm | alert
    const {
      type, alertType, message, okText, cancelText, colored = false, header = true, footer = false, appointment = null
    } = this.props;
    let checkImage = `${process.env.PUBLIC_URL}/img/check-line-icon.png`;
    let Icon;

    switch (type) {
      case 'confirm':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'alert':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        switch (alertType) {
          case 'danger':
            checkImage = `${process.env.PUBLIC_URL}/img/error_icon.png`;
            break;
          case 'info':
            checkImage = `${process.env.PUBLIC_URL}/img/info_icon.png`;
            break;
          default:
            checkImage = `${process.env.PUBLIC_URL}/img/check-line-icon.png`;
            break;
        }
        break;
      case 'ok':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        switch (alertType) {
          case 'danger':
            checkImage = `${process.env.PUBLIC_URL}/img/error_icon.png`;
            break;
          case 'info':
            checkImage = `${process.env.PUBLIC_URL}/img/info_icon.png`;
            break;
          default:
            checkImage = `${process.env.PUBLIC_URL}/img/check-line-icon.png`;
            break;
        }
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    let closeButtonText = cancelText || ((type === 'alert' || type === 'ok') ? 'OK' : 'No');
    let closeButtonClass = (type === 'alert' || type === 'ok') ? 'btn-yes' : 'btn-no';
    let okButtonText = okText || 'Yes';

    return(
      <Modal
        isOpen={this.state.show}
        toggle={this.onClose}
        className={`modal-dialog--${type} ${modalClass} alert-modal`}>
        <div className="modal__body">
          {(type === 'alert' || type === 'ok') &&
            <img className="" src={checkImage} alt="check"/>
          }
          {appointment && <ConfirmPopupDetails {...appointment} />}
          <p>{message}</p>
        </div>
        {
          footer &&
          <ButtonToolbar className="modal__footer">
            <Button className={closeButtonClass} onClick={this.onClose}>{closeButtonText}</Button>{' '}
            {(type === 'confirm') &&
              <Button className={'btn-yes'}
                    onClick={this.onSuccess}>{okButtonText}</Button>
            }
          </ButtonToolbar>
        }
      </Modal>
    );
  }

}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  toggleMessageModel
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal)
