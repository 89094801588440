import React, {Component, Fragment} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import TimeFilter from '../../Common/TimeFilter';
import TableComponent from './TableComponent';
import { getAppointmentsByParent, cancelAppointment, removeAppointment } from '../../../../redux/actions/appointmentAction';
import MessageModal from "../../../../shared/components/Modals/MessageModal";
import Pagination from "../../../../shared/components/pagination/Pagination";
import config from "../../../../config/app.config";

const { SOCKET_URL } = config;
const socket = io(SOCKET_URL);

class Appointments extends Component{
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      petNameList: [],
      timeFilter: null,
      petName: [],
      total: 0,
      sortOrder: -1,
      sordField: 'createdAt',
      offset: 0,
      type: [],
      limit: config.DEFAULT_PAGINATION_LENGTH,
      status: [],
      range: {},
      responseBox: {
        showResponse: false,
        responseType: null,
        responseAlertType: null,
        responseFooter: false,
        message: null
      },
      confirmType: null,
    }
    this.handleTimeFilterChange = this.handleTimeFilterChange.bind(this);
    this.handleActions = this.handleActions.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.getAppointments = this.getAppointments.bind(this);
    this.handleStatusFilter = this.handleStatusFilter.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.handlePageLimit = this.handlePageLimit.bind(this);
    this.gotoPage = this.gotoPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  componentDidMount(){
    this.getAppointments();
  }

  componentWillReceiveProps(np){
    if (np.appointments.appointmentsByParent) {
      const appointment = np.appointments.appointmentsByParent.data || [];
      const petList = np.appointments.appointmentsByParent.petList || [];
      const list = [];
      petList.map(pet => {
        const filters = {};
        filters[pet._id] =  pet.name;
        list.push(filters);
      });
      this.setState({
        rows: appointment,
        petNameList: list,
        total: np.appointments.appointmentsByParent.total
      });
    }

    if (np.appointments.removeAppointment === 'success') {
      let responseBox = {
        showResponse: true,
        responseType: 'alert',
        responseAlertType: 'success',
        responseFooter: true,
        message: 'Appointment removed'
      }
      this.setState({
        responseBox
      }, () => {
        this.getAppointments();
      });
    }

    if (np.appointments.cancelAppointment && np.appointments.cancelAppointment.success === 'success') {
      let responseBox = {
        showResponse: true,
        responseType: 'alert',
        responseAlertType: 'success',
        responseFooter: true,
        message: 'Appointment cancelled'
      }
      this.setState({
        responseBox
      }, () => {
        this.getAppointments();
        const appointmentObj = np.appointments.cancelAppointment.appointment;
        appointmentObj.pet = {
          _id: np.appointments.cancelAppointment.appointment.pet
        }
        socket.emit('phaseUpdated', { facilityId: np.appointments.cancelAppointment.appointment.facility, appointment: appointmentObj });
      });
    }
  }

  /**
   * Get all appointments
   */

  getAppointments(){
    let status = this.state.status;
    status = status.length > 0 ? status : null;

    let type = this.state.type;
    type = type.length > 0 ? type : null;

    this.props.getAppointmentsByParent({
      id: this.props.parentId,
      sort: {
        field: this.state.sordField,
        order: this.state.sortOrder
      },
      status: status,
      type: type,
      timeFilter: {
        type: this.state.timeFilter,
        startDate: moment(this.state.range.startDate).format('MM/DD/YYYY'),
        endDate: moment(this.state.range.endDate).format('MM/DD/YYYY'),
      },
      petNameFilter: this.state.petName,
      limit: this.state.limit,
      offset: this.state.offset
    });
  }

  /**
   * Handle Table actions
   */

  handleActions(type, data){
    let message = '';
    let confirmType = null;

    if(type === config.appointmentActions.reschedule) {
      if (data.petStatus === 'inactive') {
        const responseBox = {
          showResponse: true,
          responseType: 'alert',
          responseAlertType: 'danger',
          responseFooter: true,
          message: `${data.petName} is inactive. Please activate pet to reschedule appointment.`
        }
        this.setState({
          responseBox
        });
      } else if (data.parentStatus === 'inactive') {
        const responseBox = {
          showResponse: true,
          responseType: 'alert',
          responseAlertType: 'danger',
          responseFooter: true,
          message: `${data.parentName} is inactive. Please activate parent to reschedule appointment.`
        }
        this.setState({
          responseBox
        });
      } else {
        this.props.handleRedirect({
          pathname: '/new-appointment',
          reschedule: true,
          appointmentId: data.id,
        });
      }

      return;
    }

    if(type === config.appointmentActions.remove){
      message = 'Are you sure you want to remove this appointment?';
      confirmType = 'remove';
    } else if (type === config.appointmentActions.cancel) {
      message = 'Are you sure you want to cancel this appointment?';
      confirmType = 'cancel';
    }
    const responseBox = {
      showResponse: true,
      responseType: 'confirm',
      responseAlertType: null,
      responseFooter: true,
      message
    }
    this.setState({
      selectedRowId: data,
      confirmType,
      responseBox
    });
  }

  handleTimeFilterChange(timeFilter) {
    if (timeFilter === 'range') {
      this.setState({
        timeFilter
      });
    } else {
      this.setState({
        timeFilter,
        offset: 0
      }, () => {
        this.getAppointments();
      });
    }
  }

  handleGridSort (field) {
    let { sordField, sortOrder, offset } = this.state;
    if (field === sordField) {
      sortOrder = (sortOrder === 1) ? -1 : 1;
    } else {
      sordField = field;
    }
    offset = 0;
    this.setState({
      sortOrder,
      sordField,
      offset
    }, () => {
      this.getAppointments();
    });
  };

  handleStatusFilter(type, filters){
    const state = this.state;
    state[type] = filters;
    state.offset = 0;
    this.setState(state, () => {
      this.getAppointments();
    });
  }

  setDateRange(range){
    this.setState({
      range,
      offset: 0
    }, () => {
      const startDateStr = moment(range.startDate).format('MM/DD/YYYY');
      const endDateStr = moment(range.endDate).format('MM/DD/YYYY');

      if(startDateStr !== endDateStr){
        this.getAppointments();
      }
    });
  }

  /**
   * Handle modal Actions
   */

  onSuccess(){
    const { confirmType, status, selectedRowId } = this.state;

    if(confirmType === 'cancel') {
      this.props.cancelAppointment({appointmentId : selectedRowId});
    }

    if(confirmType === 'remove') {
      this.props.removeAppointment({appointmentId : selectedRowId});
    }

    this.onClose();
  }

  onClose (){
    const responseBox = {
      showResponse: false,
      responseType: null,
      responseAlertType: null,
      responseFooter: false,
      message: null
    }
    this.setState({
      responseBox
    });
  }

  /**
   * pagination Actions
   */
  handlePageLimit (newLimit) {
    this.setState({limit: newLimit, offset: 0}, () => {
      this.getAppointments();
    });
  }

  nextPage (){
    const { offset, limit } = this.state;
    const skip = offset + limit;

    this.setState({
      offset: skip
    }, () => {
      this.getAppointments();
    });
  }

  prevPage () {
    const { offset, limit } = this.state;
    const skip = offset - limit;

    this.setState({
      offset: skip
    }, () => {
      this.getAppointments();
    });
  }

  gotoPage (page){
    const { limit } = this.state;
    const skip = limit * page;
    this.setState({
      offset: skip
    }, () => {
      this.getAppointments();
    });
  }

  render () {
    const { timeFilter, responseBox, limit, offset, total, petNameList } = this.state;
    const  heads = [
      {
        key: 'dateTime',
        name: 'Date',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'time',
        name: 'Time',
        sortable: false,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'petName',
        name: 'Pet Name',
        sortable: true,
        filterable: true,
        filterValue: petNameList,
        capitalize: true,
      },
      {
        key: 'type',
        name: 'Appointment Type',
        sortable: false,
        filterable: true,
        filterValue: [
          { 'wellness visit': 'Wellness Visit' },
          { 'follow-up visit': 'Follow-up Visit' },
          { 'vaccinations': 'Vaccinations' },
          { 'diagnostics': 'Diagnostics' },
          { 'surgery': 'Surgery' },
          { 'minor procedure': 'Minor Procedure' },
          { 'treatment': 'Treatment' },
          { 'dental cleaning': 'Dental Cleaning' },
          { 'grooming': 'Grooming' },
          { 'nail trim': 'Nail Trim' },
          { 'boarding': 'Boarding' },
          { 'specialist': 'Specialist' },
          { 'other': 'Other' },
          ],
        capitalize: true,
      },
      {
        key: 'doctor',
        name: 'Doctor',
        sortable: false,
        filterable: false,
        filterValue: [],
        capitalize: true,
      },
      {
        key: 'referringDoctor',
        name: 'Referring Doctor',
        sortable: false,
        filterable: false,
        capitalize: false,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: false,
        filterable: true,
        filterValue: [
          { 'upcoming': 'Upcoming' },
          { 'ongoing': 'Ongoing' },
          { 'past': 'Past' },
          { 'no show': 'No Show' },
          { 'cancelled': 'Cancelled' },
          { 'expired': 'Expired' },
          ],
        capitalize: true,
      },
      ];
    const { parent, profile } = this.props;
    return(
      <Fragment>
        <MessageModal show={responseBox.showResponse}
                      type={responseBox.responseType}
                      alertType={responseBox.responseAlertType}
                      footer={responseBox.responseFooter}
                      onSuccess={this.onSuccess}
                      onClose={this.onClose}
                      message={responseBox.message}/>
        <div className="profile-appointments">
          <div className="row">
            <div className="col-8">
              <TimeFilter changeFilter={this.handleTimeFilterChange} selected={timeFilter} setDateRange={this.setDateRange}/>
            </div>
            <div className="col-4">

            </div>
          </div>
          <TableComponent heads={heads} rows={this.state.rows} handleActions={this.handleActions}
                          handleGridSort={this.handleGridSort}
                          handleGridFilter={this.handleStatusFilter}/>
          <Pagination handlePageLimit={this.handlePageLimit} nextPage={this.nextPage} prevPage={this.prevPage} gotoPage={(i) => this.gotoPage(i)} limit={limit} offset={offset} total={total}/>
        </div>
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getAppointmentsByParent,
  cancelAppointment,
  removeAppointment
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);