import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import { unsubscribeDoctor, subscribe } from '../../../redux/actions/appointmentAction';
import CommonHelper from '../../../libs/commonHelper';

const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;


export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      pet: {
        name: '',
      },
      subscribeToken: '',
      showResponse: false,
    }
  }
  
  componentDidMount() {
    const token = CommonHelper.getParameterByName('token');
    this.props.unsubscribeDoctor(token);
    this.setState({token});
  }
  
  componentWillReceiveProps(np) {
    if (np.appointments && np.appointments.unsubscribe && np.appointments.unsubscribeError === null) {
      this.setState({pet: np.appointments.unsubscribe.pet, showResponse: true, subscribeToken: np.appointments.unsubscribe.unsubscribeToken});
    }
  }
  
  render() {
    const {pet, showResponse, subscribeToken} = this.state;
    
    return (
      <div className="account user-flow unsubscribe">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title">
                  <span className="account__logo">
                  <img src={icon} alt=""/>
                </span>
                </h2>
              </div>
              {(showResponse) ?
                <div className="body-wrapper">
                  <h3 className="subheading"> Unsubscribe Successful </h3>
                  
                  <div className="p-first">You have successfully unsubscribed to {pet.name}'s procedure updates.</div>
                  
                  <div className="p-second">If you didn't mean to unsubscribe, click on below link to resubscribe.</div>
                  <a className="btn btn-primary account__btn" href={"/subscribe?token="+subscribeToken} >Subscribe</a>
                  
                  <div className="your">
                    - Your friends at Loyal
                  </div>
                </div>
                :
                <div className="body-wrapper">
                  <h3 className="subheading"> Unsubscribe Failed </h3>
                  <div className="p-first">You have already unsubscribed or the token is invalid.</div>
                  <div className="your">
                    - Your friends at Loyal
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  unsubscribeDoctor,
  subscribe
};

export default connect(mapStateToProps, mapDispatchToProps)(index)
