import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {FormGroup, Input, Label} from 'reactstrap';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar
} from 'recharts';
import DashboardFacilityFilterComponent from "../../shared/components/form/DashboardFacilityFilterComponent";
import { getProcedureUpdateMsgsData } from '../../redux/actions/dashboardActions';

class ProcedureUpdateMessageComponent extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      phases: [
        {
          _id: 1,
          displayName: 'Expected'
        },
        {
          _id: 2,
          displayName: 'Check-In'
        },
        {
          _id: 3,
          displayName: 'Diagnostics'
        },
        {
          _id: 4,
          displayName: 'Preparation'
        },
        {
          _id: 5,
          displayName: 'Procedure'
        },
        {
          _id: 6,
          displayName: 'Dental'
        },
        {
          _id: 7,
          displayName: 'Surgery'
        },
        {
          _id: 8,
          displayName: 'Recovery'
        },
        {
          _id: 9,
          displayName: 'Hospitalized'
        },
        {
          _id: 10,
          displayName: 'Boarding'
        },
        {
          _id: 11,
          displayName: 'Discharge'
        },
        {
          _id: 12,
          displayName: 'Completed'
        }
      ],
      type: 'daily',
      selectedPhase: [],
      selectedFacilities: null,
      timeFilter: 'daily',
      total: 0,
      avg: 0
    };
    this.getChartData = this.getChartData.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleType = this.handleType.bind(this);
    this.isFacilityArraysNotEquals = this.isFacilityArraysNotEquals.bind(this);
  }
  
  componentDidMount() {
    this.getChartData();
  }

  getChartData(){
    const { selectedFacilities: facility, selectedPhase: phase, timeFilter } = this.state;
    const filters = {
      facilityIds: facility,
      phases: phase,
      type: timeFilter
    }
    this.props.getProcedureUpdateMsgsData({
      ...filters
    });
  }

  isFacilityArraysNotEquals(newList, oldList){

    const newListSize = newList.length;

    if ((newListSize === 0 && (!oldList || oldList.length > 0)) || newListSize > oldList.length || newListSize !== oldList.length) {
      return true;
    }

    return newList.some((value) => !oldList.includes(value));
  }
  
  componentWillReceiveProps(nextProps) {
    const isArraysEquals = this.isFacilityArraysNotEquals(nextProps.facilities, this.state.selectedFacilities);
    if (isArraysEquals) {
      this.setState({
        selectedFacilities: [...nextProps.facilities]
      }, () => {
        console.log(this.state.selectedFacilities);
        this.getChartData();
      });
    }

    if (nextProps.dashboard.procedureUpdateMsgs) {
      this.setState({
        data: nextProps.dashboard.procedureUpdateMsgs.result,
        total: nextProps.dashboard.procedureUpdateMsgs.total,
        avg: nextProps.dashboard.procedureUpdateMsgs.avg,
      });
    }

  }
  
  handleType(e) {
    this.setState({
      timeFilter: e.target.value
    }, () => {
      this.getChartData();
    });
  }
  
  customTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].dataKey} : ${payload[0].value}`}</p>
        </div>
      );
    }
    
    return null;
  };

  handleFilter(selected){
    this.setState({
      selectedPhase: selected
    }, () => {
      this.getChartData();
    });
  }
  
  render() {
    const { data, type, phases, total, avg, timeFilter } = this.state;
    const { processedDate } = this.props;
    let totalCount = 30;
    switch (type) {
      case 'daily':
        totalCount = 30;
        break;
      case 'weekly':
        totalCount = 12;
        break
      case 'monthly':
        totalCount = 6;
        break
    }
    
    return (
      <div className="total-average new-user">
        <div className="header-container">
          <h3>Procedure Update Message Sent</h3>
          <FormGroup>
            <Input className="with-custom-arrow" type="select" name="select" value={timeFilter} onChange={this.handleType}>
              <option value="daily">Daily (Last 30 days)</option>
              <option value="weekly">Weekly (Last 12 weeks)</option>
              <option value="monthly">Monthly (Last 6 months)</option>
            </Input>
          </FormGroup>
          <p className={'loyal-last-process-date'}>Last updated { processedDate }</p>
        </div>
        <div className="sub-header-container clearfix">
          <div className="wrapper">
            <div className="facility-wrapper">
              <Fragment>
                <Label>Phase</Label>
                <DashboardFacilityFilterComponent facilities={phases} handleFilter={this.handleFilter} filterTypeTextPlural="Phases" filterTypeText="Phase"/>
              </Fragment>
            </div>
          </div>
        </div>
        <div className="chart">
          <ResponsiveContainer width='100%' height={240}>
            <BarChart width='100%' height={240} data={data}
                      margin={{top: 5, right: 30, left: 20, bottom: 5}}>
              <CartesianGrid strokeDasharray="0 0" vertical={false}/>
              <XAxis dataKey="name"/>
              <YAxis/>
              <Tooltip/>
              <Bar dataKey="value" fill="#0631b4" />
            </BarChart>
          </ResponsiveContainer>
        
        </div>
        <div className="total-average-wrapper msg-sent clearfix">
          <div className="total-wrapper">
            <div className="label">
              <span> Total</span>
            </div>
            <div className="no-padding provider procedure-messages">
              <span>{ total }</span>
            </div>
          </div>
          <div className="avg-wrapper">
            <div className="label procedure-messages">
              <span> Average</span>
            </div>
            <div className="no-padding provider procedure-messages">
              <span>{ avg }</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getProcedureUpdateMsgsData
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureUpdateMessageComponent);
