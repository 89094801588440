
import {
  FETCH_SPECIES,
  FETCH_SPECIES_FAILED,
  SAVE_BREED,
  SAVE_BREED_FAILED,
  UPDATE_BREED,
  UPDATE_BREED_FAILED,
  FETCH_BREEDS,
  FETCH_BREEDS_FAILED,
  DELETE_BREED,
  DELETE_BREED_FAILED
} from '../actions/breedAction';

const initialState = {
  species:[],
  breedAdded: false,
  breedUpdated: false,
  breeds: [],
  breedDeleted: false,
  breedDeletedError: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SPECIES:
      return {
        ...state,
        species: action.payload
      }
    case FETCH_SPECIES_FAILED:
      return {
        ...state,
        species: []
    }
    case FETCH_BREEDS:
      return {
        ...state,
        breeds: action.payload,
        breedAdded: false,
        breedUpdated: false,
        breedDeletedError: false,
        breedDeleted: false
      }
    case FETCH_BREEDS_FAILED:
      return {
        ...state,
        breeds: [],
        breedAdded: false,
        breedUpdated: false,
    }
    case SAVE_BREED:
      return {
        ...state,
        breedAdded: true
      }
    case SAVE_BREED_FAILED:
      return {
        ...state,
        breedAdded: false
    }
    case UPDATE_BREED:
      return {
        ...state,
        breedUpdated: true
      }
      case DELETE_BREED:
      return {
        ...state,
        breedDeleted: true,
        breedDeletedError: false
    }
    case DELETE_BREED_FAILED:
      return {
        ...state,
        breedDeletedError: true,
        breedDeleted: false
      }
    case UPDATE_BREED_FAILED:
      return {
        ...state,
        breedUpdated: false
    }
    default:
    return state;
  }
}