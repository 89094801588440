import React, {Component} from 'react';
import {connect} from 'react-redux';
import io from 'socket.io-client';
import TrelloBoard from 'react-trello';
import PetCard from './PetCard';
import constant from '../../../constants/constants';
import { dragCard, cancelDragCard, deletePhaseMessage } from '../../../redux/actions/pTBActions';
import MessageModal from '../../../shared/components/Modals/MessageModal';
import moment from 'moment';


import config from '../../../config/app.config';
import ResponseMessages from '../../../constants/responseMessages';


const { SOCKET_URL } = config;
const socket = io(SOCKET_URL, {transports: ['websocket']});

let eventBus = undefined

class Board extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleClass:'minimize',
      targetLaneId: null,
      cardId: null,
      cardDetails: null,
      sourceLaneId: null,
      ptbClass:'',
      utcOffset: 0,
      eventData: {
        lanes: [
          {
            id: constant.appointmentPhases.expected.id,
            title: constant.appointmentPhases.expected.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.expected.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.checkIn.id,
            title: constant.appointmentPhases.checkIn.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.checkIn.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.diagnostics.id,
            title: constant.appointmentPhases.diagnostics.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.diagnostics.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.preparation.id,
            title: constant.appointmentPhases.preparation.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.preparation.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.procedure.id,
            title: constant.appointmentPhases.procedure.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.procedure.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.dental.id,
            title: constant.appointmentPhases.dental.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.dental.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.surgery.id,
            title: constant.appointmentPhases.surgery.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.surgery.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.recovery.id,
            title: constant.appointmentPhases.recovery.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.recovery.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.hospitalized.id,
            title: constant.appointmentPhases.hospitalized.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.hospitalized.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.boarding.id,
            title: constant.appointmentPhases.boarding.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.boarding.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.discharge.id,
            title: constant.appointmentPhases.discharge.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.discharge.color}`, height: `100vh`},
            cards: []
          },
          {
            id: constant.appointmentPhases.completed.id,
            title: constant.appointmentPhases.completed.name,
            style: {borderTop: `5px solid ${constant.appointmentPhases.completed.color}`, height: `100vh`},
            cards: []
          },

        ]
      },
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseAlertType: '',
        footer: false,
      }
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(np) {

    const {toggleClass, events, user} = np;
    if (user && user.profile && user.profile.facility) {
      this.setState({ utcOffset: user.profile.facility.utcOffset })
    }
    if (toggleClass) {
      this.setState({toggleClass})
    }
    this.setState({ events }, () => {
      this.mapEvents();
    });
  }

  mapEvents = () => {

    const { events, eventData, targetLaneId, utcOffset } = this.state;
    const lanes = eventData.lanes.map((lane) => {
      const cards = [];
      events.map((event) => {
        if(lane.id == event.phase) {
          let facility = event.parent.facilities.find(obj => obj.facilityId === event.facility);
          let date = (lane.id === 1) ? event.dateTime : event.updatedAt;
          date = moment(date).utc().seconds(utcOffset * 60);
          const weAreHomeClass = event.weAreHome ? 'turquoise' : '';
          cards.push({
            id: event._id,
            firstName: event.pet.name,
            lastName: (event.parent)? event.parent.lastName : null,
            date: moment(date).format('L'),
            time: moment(date).format('LT'),
            color: this.getCardColor(lane.id),
            laneId:lane.id,
            weAreHomeClass,
            metadata: {
              petId: event.pet._id,
              type: event.type,
              petStatus: event.pet.status,
              petName: event.pet.name,
              petParentStatus: facility.status,
              petParentName: `${event.parent.firstName} ${event.parent.lastName}`,
              facilityId: event.facility
            }
          })
        }
      } );
      lane.cards = cards;
      return lane;
    });

    const updatedLane = lanes && lanes.filter( lane => lane.id === targetLaneId);
    const newEvents = { lanes };
    if(updatedLane.length) {
      eventBus.publish({type: 'UPDATE_LANES', lanes})
    }
    this.setState({ eventData: newEvents });

  }

  setEventBus = (handle) => {
    eventBus = handle
  }

  getCardColor (laneId) {

    const { expected, checkIn, diagnostics, preparation, procedure, dental, surgery, recovery, hospitalized, boarding, discharge, completed   } = constant.appointmentPhases;
    switch (laneId) {
      case expected.id:
        return expected.color;
      case checkIn.id:
        return checkIn.color;
      case diagnostics.id:
        return diagnostics.color;
      case preparation.id:
        return preparation.color;
      case procedure.id:
        return procedure.color;
      case completed.id:
        return completed.color;
      case dental.id:
        return dental.color;
      case surgery.id:
        return surgery.color;
      case recovery.id:
        return recovery.color;
      case hospitalized.id:
        return hospitalized.color;
      case boarding.id:
        return boarding.color;
      case discharge.id:
        return discharge.color;
      default:
        break;
    }
  }

  minimize = (params) => {
    this.props.minimize();
  }

  alertMessage(metadata) {
    const { petStatus, petParentStatus, petParentName, petName } = metadata;
    if (petStatus === constant.STATUS.INACTIVE && petParentStatus === constant.STATUS.INACTIVE) {
      return `${petParentName} and ${petName} are inactive. Please activate both parent and pet to update the procedure.`;
    } else if(petParentStatus === constant.STATUS.INACTIVE) {
      return `${petParentName} is inactive. Please activate parent to update the procedure.`;
    } else {
      return `${petName} is inactive. Please activate pet to update the procedure.`;
    }
  }

  handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
    const { confirmPopup, events } = this.state;
    this.setState({ targetLaneId, cardId, cardDetails, sourceLaneId });

    const startOfTheDay = moment().startOf('date').valueOf();
    const appointment = events.filter(event => event._id === cardId).shift();
    const appointmentTime = moment(appointment.dateTime).valueOf();

    if (targetLaneId === 1 && startOfTheDay > appointmentTime) {
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = ResponseMessages.WARN.CAN_NOT_MOVE_TO_EXPECTED;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'info';

      this.setState({ confirmPopup });
      return false;
    }


    if (cardDetails.weAreHomeClass) {
      this.props.cancelDragCard({ cardId, sourceLaneId });
      return false;
    }

    if (cardDetails.metadata.petStatus === constant.STATUS.INACTIVE || cardDetails.metadata.petParentStatus === constant.STATUS.INACTIVE) {
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = this.alertMessage(cardDetails.metadata);
      confirmPopup.responseType = 'ok';
      confirmPopup.responseAlertType = 'danger';

      this.setState({ confirmPopup });
      return false;
    }

    if (targetLaneId < sourceLaneId) {
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = ResponseMessages.WARN.RESET_PHASE_MESSAGES;
      confirmPopup.responseType = 'confirm';
      confirmPopup.responseAlertType = 'success';

      this.setState({ confirmPopup });
    } else if (targetLaneId !== sourceLaneId) {
      this.props.openPetModel(cardId, cardDetails.metadata, targetLaneId, true);
      // this.props.dragCard({appointmentId:cardId, phase: targetLaneId});
    }

    this.setState({ ptbClass: '' })
  };

  onCardClick = (cardId, metadata, laneId) => {
    const { confirmPopup } = this.state;
    if (metadata.petStatus === constant.STATUS.INACTIVE || metadata.petParentStatus === constant.STATUS.INACTIVE) {
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = this.alertMessage(metadata);
      confirmPopup.responseType = 'ok';
      confirmPopup.responseAlertType = 'danger';

      this.setState({ confirmPopup });
      return false;
    }
    this.props.openPetModel(cardId, metadata, laneId)
  }
  
  /*scrollToRight = () => { //TODO
    const element = document.getElementsByClassName('react-trello-board');
    element[0].scrollLeft += 250;
  }
  scrollToLeft = () => { //TODO 
    const element = document.getElementsByClassName('react-trello-board');
    element[0].scrollLeft -= 250;
  }*/

  onCardMoveBack = () => {
    const { targetLaneId, cardId, cardDetails } = this.state;

    this.props.openPetModel(cardId, cardDetails.metadata, targetLaneId, true);
    // this.props.dragCard({ appointmentId: cardId, phase: targetLaneId });
    this.props.deletePhaseMessage({ phaseId: targetLaneId, appointmentId: cardId });

    const confirmPopup = {
      showResponse: false,
      responseMessage: '',
      responseType: '',
      responseAlertType: '',
      footer: false,
    };
    this.setState({ confirmPopup });
  };

  onConfirmClose = () => {
    const { sourceLaneId, cardId } = this.state;

    const confirmPopup = {
      showResponse: false,
      responseMessage: '',
      responseType: '',
      responseAlertType: '',
      footer: false,
    };

    this.props.cancelDragCard({ cardId, sourceLaneId });
    this.setState({ confirmPopup });
  };

  handleDragStart = (cardId, laneId) => {
    this.setState({ptbClass: 'ptb-board'})
  }



  render() {
    const { toggleClass, eventData, confirmPopup, ptbClass } = this.state;

    return (
      <div className={`board ${toggleClass}`}>
      {toggleClass === 'maximize' && <div className="icon-minimize">
        <span onClick={this.minimize} class="lnr lnr-frame-contract"></span>
      </div>}
           <TrelloBoard
            draggable={true}
            laneDraggable={false}
            handleDragStart={this.handleDragStart}
            handleDragEnd={this.handleDragEnd}
            onCardClick={this.onCardClick}
            eventBusHandle={this.setEventBus}
            dragClass="dragClass"
            className={`react-trello-board ${ptbClass}` }
            data={eventData} customCardLayout>

              <PetCard />
            </TrelloBoard>
            <MessageModal
                show={confirmPopup.showResponse}
                type={confirmPopup.responseType}
                alertType={confirmPopup.responseAlertType}
                footer={true}
                onSuccess={this.onCardMoveBack}
                onClose={this.onConfirmClose}
                message={confirmPopup.responseMessage}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  dragCard,
  cancelDragCard,
  deletePhaseMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Board)
