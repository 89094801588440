import React, { Fragment } from "react";
import ClientJS from "clientjs";
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;
const successTick = `${process.env.PUBLIC_URL}/img/success-tick.svg`;

function SignupSuccess(props){
  const client = new ClientJS();
  const os = client.getOS().name;

  let downloads = null;



  setTimeout(
    () => {
      if (os === 'iOS') {
        window.location.href = 'https://apps.apple.com/us/app/loyal-pet/id1479669611';
      } else if (os === 'Android') {
        window.location.href = 'https://play.google.com/store/apps/details?hl=en_US&id=pet.loyal.client';
      }
    },
    2000
  );


  switch (os) {
    case 'iOS':
      downloads = <a className="btn-download apple-store" href="https://apps.apple.com/us/app/loyal-pet/id1479669611"></a>;
      break;
    case 'Android':
      downloads = <a className="btn-download single-item google-play" href="https://play.google.com/store/apps/details?hl=en_US&id=pet.loyal.client"></a>;
      break;
      default:
        downloads = (
          <Fragment>
            <a className="btn-download google-play" href="https://play.google.com/store/apps/details?hl=en_US&id=pet.loyal.client"></a>
            <a className="btn-download apple-store" href="https://apps.apple.com/us/app/loyal-pet/id1479669611"></a>
          </Fragment>
        );
  }

  return (
    <div className="parent-signup-success text-center">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="logo-wrapper">
              <img src={icon} alt="" className="logo" />
            </div>
            <h1 className="title">Welcome to Loyal</h1>
            <h4 className="subtitle">
              Track the Pets You Love
            </h4>
            <div className="success-wrapper">
              <a href="" className="close"></a>
              <div className="message">
                <img src={successTick} alt="success-icon" />
                <p>Success!</p>
              </div>
              <div className="app-download">
                { downloads }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupSuccess;
