import React, { Component, Fragment } from 'react';
import { Label, FormGroup, Table, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SearchIcon from "mdi-react/SearchIcon";
import HeadingText from '../../shared/components/form/HeadingText';
import CustomSelectComponent from "../../shared/components/form/CustomSelectComponent";
import TableFilterComponent from "../../shared/components/form/TableFilterComponent";
import MessageModal  from '../../shared/components/Modals/MessageModal';
import Pagination from '../../shared/components/pagination/Pagination';
import config from '../../config/app.config';
import commonHelper from '../../libs/commonHelper';

export default class BreedTable extends Component {
  constructor(props) {
    super(props);
    this.heads = [
      {
        key: 'breed',
        name: 'Breed',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'species',
        name: 'Species',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: false,
        filterable: true,
        filterValue: [{'active':'active'},{'inactive':'inactive'}],
        capitalize: true,
      }
    ];

    this.state = {
      speciesList: [],
      sortField: 'breed',
      sortOrder: 1,
      breeds: [],
      search: null,
      page:1,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      offset: 0,
      sort: null,
      filter: {
        status: null,
        species: null
      },
      cnfShow: false,
      cnfType: '',
      cnfMsg: '',
      cnfItem: '',
      cnfAlertType: '',
      selectedStatus: null,
      selectedId: null
    }

  }

  componentDidMount() {
    this.setState({ cnfMsg: false});
  }

  componentWillReceiveProps(np) {
    const { species, breeds, breedRemove } = np;

    if(breeds) {
      this.setState({breeds, total: breeds.total})
    }

    if(breedRemove === 1) {
      this.setState({
        cnfMsg: `This breed is removed now`,
        cnfType: 'alert',
        cnfAlertType: '',
        cnfShow: true
      })
    }
    if (breedRemove === 2) {
      this.setState({
        cnfMsg: `This breed is in use`,
        cnfType: 'alert',
        cnfAlertType: 'danger',
        cnfShow: true
      })
    }

    if(species) {
      const list = [];
        species.map( (data) => {
          list.push({
            id:data._id,
            name:data.name
          });
        });
      this.setState({speciesList: list});
    }
  }

  handleFilter = (value) => {
    let {filter} = this.state;

    if(value.length > 0) {
      filter.species = value

    } else {
      filter.species = null
    }

   this.setState({ filter, page:1, offset:0 }, () => {
    this.loadCustomData();
   });
  }

  toggleForm = (status) => {
    this.props.toggleForm(status);
  }

  handleStatusFilter = (value) => {
    let {filter} = this.state;
    if(value.length > 0) {
      filter.status = value;
    } else {
      filter.status = null
    }

   this.setState({ filter, page:1, offset:0 }, () => {
    this.loadCustomData();
   });
  }

  viewDetails = (params) => {
    this.setState({ cnfMsg: false});
    this.props.toggleForm(true, params);
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value, offset:0 }, () => {
      this.loadCustomData();
    });

  }

  handleGridSort = (params) => {
    const { sortOrder } = this.state;
    let order = sortOrder === 1 ? -1 : 1;

    this.setState({ sortOrder: order, sortField: params, offset:0, page:1 }, () => {
      this.loadCustomData();
    })
  }

  loadCustomData = () => {
    const { search, filter, page, limit, sortOrder, sortField } = this.state;

    const sort = {
      field: sortField,
      order: sortOrder
    }
    const data = {
      search,
      filter,
      page,
      limit,
      sort
    }

    this.props.loadBreeds(data);
  }

  remove = (id) => {

    this.setState({
      cnfItem: 'delete',
      cnfShow: true,
      cnfType: 'confirm',
      cnfMsg: `Are you sure you want to remove this breed?`,
      selectedId: id
    });


  }

  ChangeStatus = (status, id) => {
    const type = status === 'active' ? 'Activate' : 'Deactivate';

    this.setState({
      cnfItem: type,
      cnfShow: true,
      cnfType: 'confirm',
      cnfMsg: `Are you sure you want to ${type} this breed?`,
      selectedStatus: status,
      selectedId: id
    });



  }

  onClose = () => {
    this.setState({ cnfShow: false, cnfAlertType: null})
  }

  onSuccess = () => {
    const { cnfItem, selectedId: id, selectedStatus: status } = this.state;
    let cnfMsg = '';
    let cnfType = '';

    if(cnfItem === 'Activate' || cnfItem === 'Deactivate') {
       this.props.save({id, status}, true);
      cnfMsg = cnfItem === 'Activate' ? 'Activated' : 'deactivated';
      cnfMsg = `This breed is ${cnfMsg} now`;
      cnfType = 'alert';
      this.setState({
        cnfMsg,
        cnfType
      });
    } else if (cnfItem === 'delete') {
      this.props.delete(id);
    }
    setTimeout(() => {
      this.loadCustomData();
    }, config.POPUP_TIMEOUT_TIME);



  }

  prevPage = (params) => {
    let { page, offset, limit } = this.state;
    --page;
    offset = (page-1)*limit;
    this.setState({
      page,
      offset
    }, () => {
      this.loadCustomData();
    });
  }

  nextPage = (params) => {
    let { page, offset, limit } = this.state;
    ++page;
    offset = (page-1)*limit;
    this.setState({
      page,
      offset
    }, () => {
      this.loadCustomData();
    });
  }

  gotoPage = (params) => {
    let { page, offset, limit } = this.state;
    page = params+1;
    offset = (page-1)*limit;

    this.setState({
      page,
      offset
    }, () => {
      this.loadCustomData();
    });
  }

  handlePageLimit = (limit) => {
    this.setState({
      limit,
      page:1
    }, () => {
      this.loadCustomData();
    });
  }

  headers = (params) => {
    const { sortOrder,sortField } = this.state;

    return this.heads.map((head, key) => {
      if (head) {
          const _sortField = head.key;
          return ((head.sortable && !head.filterable) ?
                  <th key={key}>
                      {(sortField == head.key) ?

                          (sortOrder == 1) ?
                              <a  onClick={() => { this.handleGridSort(_sortField) }}>
                                  <p className="header-text">{head.name}</p><span className="triangle-up"></span></a>
                              :
                              <a onClick={() => {  this.handleGridSort(_sortField) }}><p
                                  className="header-text">{head.name}</p><span
                                  className="triangle-down"></span></a>
                          :
                          <a onClick={() => { this.handleGridSort(_sortField) }}><p
                              className="header-text">{head.name}</p><span className="diamond-narrow"></span></a>
                      }
                  </th>
                  :
                  (head.key === 'status') ?
                    <th key={key}>
                        <TableFilterComponent
                            allFilters={head.filterValue}
                            action={(list) => {
                                this.handleStatusFilter(list)
                            }}
                            label={head.name}
                        />
                    </th>
                    :
                    <th key={key}>
                        <p className="header-text">{head.name}</p>
                    </th>
          )
      }

  });
  }


  tableRows = () => {

    const { breeds } = this.state;
    const headText = this.heads.map((head, key) => {
      return head.key;
    });

    const capitalizedText = this.heads.map((head, key) => {
      if (head.capitalize && head.capitalize === true) {
        return head.key;
      }
    });

    if(breeds.breed) {

      const values = [];

      breeds.breed.forEach(element => {
        values.push({
          breed: element.name,
          species: element.species.name,
          status: element.status.replace(/^\w/, c => c.toUpperCase()),
          id: element._id
        })
      });

      return values.map((row, key) => {

          const _row = Object.keys(row).map((value, key) => {
              if (headText.includes(value)) {
                const capitalizeClass = (capitalizedText.includes(value)) ? 'text-capitalize' : '';
                return (<td key={key} className={`status-col ${capitalizeClass}`}>{row[value]}</td>);
              }
              return true;
          });

          return (
              <tr className="body-tr" key={key}>
                  {_row}
                  <td>
                      <UncontrolledDropdown>
                          <DropdownToggle>
                              <span className="six-layers"></span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown__menu">
                              <DropdownItem tag="div">
                                <a
                                  onClick={() => this.viewDetails(row) }>
                                  View Details
                                </a>
                              </DropdownItem>
                              <DropdownItem tag="div">
                                <a
                                  onClick={() => this.ChangeStatus((row.status === 'Active') ? 'inactive' : 'active', row.id)}>
                                  {(row.status === 'Active') ? "Deactivate" : "Reactivate"}
                                </a>
                              </DropdownItem>
                              <DropdownItem tag="div">
                                <a
                                  onClick={() => this.remove(row.id) }>
                                  Remove
                                </a>
                              </DropdownItem>
                          </DropdownMenu>
                      </UncontrolledDropdown>

                  </td>
              </tr>
          );
      });
    }


  }


  render() {

    const { speciesList, breeds, cnfMsg, cnfType, cnfShow, cnfAlertType, total, limit, offset } = this.state;
    const isSuperAdmin = commonHelper.isSuperAdmin();
    const heading = isSuperAdmin ? 'Breeds' : 'Manage Breeds';

    return (
      <div>

        <MessageModal
          show={cnfShow}
          type={cnfType}
          message={cnfMsg}
          footer={true}
          onClose={this.onClose}
          onSuccess={this.onSuccess}
          alertType = { cnfAlertType}
        />

         <HeadingText text={heading} />
         <div className="row search form">
          <div className="col-sm-6">

            <div className="form__form-group">
              <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
              <div className="form__form-group-field custom-shadow custom-border-radius">
                <div className="form__form-group-icon form__form-group-icon--left form__form-group-search">
                  <SearchIcon />
                </div>
                <input
                  name="search"
                  id="search"
                  type="text"
                  onChange={this.handleSearch}
                  placeholder="Search by breed"
                  className="form__custom-field form__custom-field--icon-left"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="exampleSelect">Filter by species</Label>
              <CustomSelectComponent allFilters={speciesList}
                                     filterTypeText="Species"
                                     filterTypeTextPlural="Species"
                                     action={this.handleFilter}/>
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <button className="btn btn-outline-primary btn-new-breed" onClick={this.toggleForm.bind(this, true)}>Add New Breed</button>
            </FormGroup>
          </div>
        </div>

        <Table striped className="custom-table loyal-table">
            <thead>
            <tr className="header-tr">
                {this.headers()}
                <th className="text-center action"><p className="header-text action">Action</p></th>
            </tr>
            </thead>
            <tbody>
            {this.tableRows()}
            </tbody>

        </Table>
          {breeds.breed && breeds.breed.length === 0 && <div className="no-data-message">No results found</div>}

        <Pagination handlePageLimit={(limit) => {this.handlePageLimit(limit)}} nextPage={this.nextPage}
                    prevPage={this.prevPage} gotoPage={(i) => this.gotoPage(i)} limit={limit}
                    offset={offset} total={total}/>

      </div>
    )
  }
}
