import React, { Component } from 'react';
import { Collapse, Row, FormGroup, Col  } from 'reactstrap';
import {getParent} from "../../../../redux/actions/petActions";
import connect from "react-redux/es/connect/connect";
import Error from "../../../../shared/components/form/Error";
import MaskedInput from "react-maskedinput";

class RegisterParent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      parentId: null,
      refreshState: false,
      inputs: {
        firstName: '',
        lastName: '',
        phoneEmail: '',
      }
    }
  }

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    this.setState({
      inputs, refreshState
    });
    this.props.parentInputsSet(inputs);
  };

  componentWillReceiveProps(np) {
    if(np.parentInputs){
      this.setState({
        inputs: np.parentInputs
      });
    }
  }

  render(){
    const { collapse, parentErrors } = this.props;
    const { inputs } = this.state;

    const form = <div className="content-holder find-parent-wrapper">
      <div className="row">
        <div className="col-md-12">
          <h3 className={(!collapse ? 'loyal-disable-tab-text' : '')}>Register New Parent</h3>
        </div>
      </div>
      <Collapse isOpen={collapse}>
        <Row>
          <div className="col-md-2">
             <img className="loyal-parent-image" src="/img/default-black.png" alt="avatar"/>
          </div>

          <div className="col-md-3">
            <div className="form__form-group loyal-custom-form-group">
              <span className="form__form-group-label">First Name <span className={'required'}>*</span></span>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="firstName"
                    type="text"
                    placeholder={'Type First name'}
                    value={inputs.firstName}
                    maxLength={50}
                    onChange = {this.onChangeFields}
                  />
                  {parentErrors.firstName.error && <Error text={parentErrors.firstName.error}/>}
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form__form-group loyal-custom-form-group">
              <span className="form__form-group-label">Last Name <span className={'required'}>*</span></span>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="lastName"
                    type="text"
                    placeholder={'Type Last name'}
                    value={inputs.lastName}
                    maxLength={50}
                    onChange = {this.onChangeFields}
                  />
                  {parentErrors.lastName.error && <Error text={parentErrors.lastName.error}/>}
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form__form-group loyal-custom-form-group">
              <span className="form__form-group-label">Mobile Number. <span className={'required'}>*</span></span>
              <div className="form__form-group-field">
                <FormGroup>
                  <MaskedInput mask="(111) 111-1111"
                               className="form-control"
                               name="phoneEmail"
                               type="text"
                               placeholder={'Type Mobile number.'}
                               maxLength={10}
                               value={inputs.phoneEmail}
                               onChange = {this.onChangeFields}/>
                  {parentErrors.phoneEmail.error && <Error text={parentErrors.phoneEmail.error}/>}
                </FormGroup>
              </div>
            </div>
          </div>
        </Row>
      </Collapse>
    </div>

    return(
      form
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getParent
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterParent);