import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { Table, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import TableStatusFilterComponent from "../../../../shared/components/form/TableFilterComponent";
import config from '../../../../config/app.config';

export default class TableComponent extends Component {
    static propTypes = {
        heads: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            sortable: PropTypes.bool,
        })).isRequired,
        rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleStatusFilter = (list, key) => {
        this.props.handleGridFilter(key, list);
    };

    render() {
        const {rows, heads, sortField, sortOrder} = this.props;
        const headText = heads.map((head, key) => {
            return head.key;
        });
        const capitalizedText = heads.map((head, key) => {
            if (head.capitalize && head.capitalize === true) {
                return head.key;
            }
        });
        const tableRows = rows.map((row, key) => {
            const _row = Object.keys(row).map((value, key) => {
                if (headText.includes(value)) {
                    const capitalizeClass = (capitalizedText.includes(value)) ? 'text-capitalize' : '';
                    return (<td key={key} title={row[value]}
                                className={`status-col custom-no-over-flow-td ${capitalizeClass}`}>{row[value]}</td>);
                }
                return true;
            });
            const isDisabled = (row.status === 'ongoing' || row.status === 'cancelled' || row.status === 'expired' || row.status === 'no show');
            return (
                <tr className="body-tr" key={key}>
                    {_row}
                    <td>
                        <UncontrolledDropdown>
                            <DropdownToggle disabled={isDisabled}>
                                <span className={`six-layers ${isDisabled ? 'disabled' : ''}`}></span>
                            </DropdownToggle>
                          {
                            (row.status !== 'ongoing' || row.status !== 'cancelled') &&
                            <DropdownMenu className="dropdown__menu">
                              {
                                row.status === 'upcoming' &&
                                <Fragment>
                                  <DropdownItem tag="div">
                                    <a onClick={() => this.props.handleActions(config.appointmentActions.reschedule,row)}>
                                      Reschedule
                                    </a>
                                  </DropdownItem>
                                  <DropdownItem tag="div">
                                    <a onClick={() => this.props.handleActions(config.appointmentActions.cancel, row.id)}>
                                      Cancel
                                    </a>
                                  </DropdownItem>
                                </Fragment>
                              }
                              {
                                row.status === 'past' &&
                                <Fragment>
                                  <DropdownItem tag="div">
                                    <a onClick={() => this.props.handleActions(config.appointmentActions.remove, row.id)}>
                                      Remove
                                    </a>
                                  </DropdownItem>
                                </Fragment>
                              }
                            </DropdownMenu>
                          }
                        </UncontrolledDropdown>

                    </td>
                </tr>
            );
        });
        const headers = heads.map((head, key) => {
            if (head) {
                const _sortField = head.key;
                return ((head.sortable && !head.filterable) ?
                        <th key={key}>
                            {(sortField == head.key) ?
                                (sortOrder === 1) ?
                                    <a onClick={() => this.props.handleGridSort(_sortField)}>
                                        <p className="header-text">{head.name}</p><span className="triangle-up"> </span></a>
                                    :
                                    <a onClick={() => this.props.handleGridSort(_sortField)}><p
                                        className="header-text">{head.name}</p>
                                        <span className="triangle-down"> </span></a>
                                :
                                <a onClick={() => this.props.handleGridSort(_sortField)}><p
                                    className="header-text">{head.name}</p><span className="diamond-narrow"></span></a>
                            }
                        </th>
                        :
                        (['status', 'type', 'petName'].indexOf(head.key) !== -1) ?
                            <th key={key}>
                              <TableStatusFilterComponent
                                allFilters={head.filterValue}
                                action={(list) => {
                                  this.handleStatusFilter(list, head.key)
                                }}
                                label={head.name}
                              />
                            </th>
                            :
                            <th key={key}>
                                <p className="header-text">{head.name}</p>
                            </th>
                )
            }
            return true;
        });
        return (
            <div className="row">
                <div className="col-md-12">
                    <Table striped className="custom-table loyal-table">
                        <thead>
                        <tr className="header-tr">
                            {headers}
                            <th className="text-center action"><p className="header-text action">Action</p></th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableRows}
                        </tbody>
                    </Table>
                    {
                        rows.length == 0 ?
                            <div className="no-data-message">No Appointments</div> : null
                    }
                </div>
            </div>
        );
    }
}
