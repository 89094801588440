import React, { Component } from 'react';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import PropTypes from "prop-types";

export default class DateRangePickerComponent extends Component {
  static propTypes = {
    handleSelect: PropTypes.func.isRequired,
    selectionRange: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props);
  }

  render(){
    const {selectionRange}  = this.props;
    const startDate = moment(selectionRange.startDate).format('MM/DD/YYYY');
    const endDate = moment(selectionRange.endDate).format('MM/DD/YYYY');

    return(
      <div className={`date-range-picker-wrapper clearfix ${selectionRange.selected === 'range' ? 'selected' : ''}`}>
        <UncontrolledDropdown className="date-range-calender float-left">
          <DropdownToggle>
            {
              selectionRange.selected === 'range' ?
                <p className="float-left time-lable">{`${startDate} - ${endDate}`}</p> :
                <p className="float-left time-lable">Select Date Range</p>
            }
          </DropdownToggle>
          <DropdownMenu>
            <DateRange
              ranges={[selectionRange]}
              onChange={this.props.handleSelect}
              rangeColors={['']}
            />
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }

}