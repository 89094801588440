import {
  GET_STAFF_LIST, GET_ALL_FACILITY_LIST, GET_ALL_DESIGNATION_LIST,
  ADD_NEW_STAFF, ADD_NEW_STAFF_ERROR, UPDATE_STAFF_STATUS, REINVITE_STAFF, GET_STAFF_MEMBER,
  UPDATE_STAFF, UPDATE_STAFF_ERROR, RESET_UPDATE_POPUP, GET_STAFF_DOCTORS_BY_FACILITY,
  GET_STAFF_DOCTORS_BY_FACILITY_FAILED
} from '../actions/staffActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_STAFF_LIST:
      return {
        ...state,
        staffList: action.payload,
        staffUpdateStatus: null,
        updateStaffResponse: null,
        newStaffResponse: null,
        staffReinviteStatus: null,
      };
    case GET_ALL_FACILITY_LIST:
      return {
        ...state,
        facilityAll: action.payload,
      };
    case GET_ALL_DESIGNATION_LIST:
      return {
        ...state,
        designationAll: action.payload,
      };
    case ADD_NEW_STAFF:
      return {
        ...state,
        newStaffResponse: action.payload,
        newStaffErrorResponse: null,
      };
    case ADD_NEW_STAFF_ERROR:
      return {
        ...state,
        newStaffErrorResponse: action.payload,
        newStaffResponse: null,
      };
    case UPDATE_STAFF_STATUS:
      return {
        ...state,
        staffUpdateStatus: action.payload,
      };
      case REINVITE_STAFF:
      return {
        ...state,
        staffReinviteStatus: action.payload,
      };
    case GET_STAFF_MEMBER:
      return {
        ...state,
        selectedMember: action.payload,
      };
    case UPDATE_STAFF:
      return {
        ...state,
        updateStaffResponse: action.payload,
        updateStaffErrorResponse: null,
      };
    case UPDATE_STAFF_ERROR:
      return {
        ...state,
        updateStaffErrorResponse: action.payload,
        updateStaffResponse: null,
      };
    case RESET_UPDATE_POPUP:
      return {
        ...state,
        updateStaffErrorResponse: null,
        updateStaffResponse: null,
      };
    case GET_STAFF_DOCTORS_BY_FACILITY:
      return {
        ...state,
        staffDoctorsByFacility: action.payload,
      };
    case GET_STAFF_DOCTORS_BY_FACILITY_FAILED:
      return {
        ...state,
        staffDoctorsByFacility: [],
      };
    default:
      return state;
  }
}
