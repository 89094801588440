import React, {PureComponent} from 'react';
import {Card, CardBody, Col, Label} from 'reactstrap';
import ClientTableComponent from './ClientTableComponent';
import Pagination from '../../shared/components/pagination/Pagination';
import {connect} from 'react-redux';
import config from '../../config/app.config';
import commonHelper from '../../libs/commonHelper';

import { getClientList, reinvite, getClientFacilities, saveClientFacility, clearInviteStore } from '../../redux/actions/clientActions';
import SearchIcon from "mdi-react/SearchIcon";
import MessageModal from "../../shared/components/Modals/MessageModal";
import ActivateModal from "./ActivateModal";
import HeadingText from "../../shared/components/form/HeadingText";

class Client extends PureComponent {
  constructor() {
    super();
    this.heads = [
      {
        key: 'firstName',
        name: 'First Name',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'lastName',
        name: 'Last Name',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'email',
        name: 'Email / Phone',
        sortable: false,
        filterable: false,
      },
      {
        key: 'joinStatus',
        name: 'Join Status',
        sortable: false,
        filterable: true,
        filterValue: [{'joined': 'joined'}, {'invited': 'invited'}],
        capitalize: true,
      },
      {
        key: 'joinedDate',
        name: 'Joined Date',
        sortable: false,
        filterable: false,
        capitalize: true,
      },
    ];
    
    this.state = {
      rows: [],
      sortField: 'firstName',
      sortOrder: 1,
      filters: {},
      search: '',
      offset: 0,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseTitle: '',
        clientId: null,
        status: null,
        confirmType: null
      },
      activePopup: {
        show: false,
        data: [],
        status:'active'
      },
      successResponse: null,
      errorResponse: null,
    };
    this.closeActivatePopup = this.closeActivatePopup.bind(this);
  }
  
  componentDidMount() {
    const {offset, limit} = this.state;
    this.props.getClientList({offset, limit});
  }
  
  componentWillReceiveProps(nextProps) {
    const {offset, limit, sortField, sortOrder, activePopup, search, filters} = this.state;
    
    if (nextProps.client) {
      if (nextProps.client.client) {
        this.setState({total: nextProps.client.client.total});
        this.createRows(nextProps.client.client.clients);
      }
      
      if(nextProps.client.clientStatus){
        const confirmPopup = {
          showResponse: true,
          responseMessage: `Invitation sent successfully `,
          responseType: 'alert',
          responseAlertType: null,
          footer: true,
        };
        this.setState({ confirmPopup, showResponse: true });
        this.props.getClientList({offset, limit, sortField, sortOrder, search, filters});
      }
  
      if (nextProps.client.facilities && activePopup.popupWaiting === true) {
        const facilityData = nextProps.client.facilities.reduce((result, facility) => {
          if(facility.status === activePopup.status){
            result.push(facility.facilityId);
          }
          return result;
        }, []);
        activePopup.popupWaiting = false;
        activePopup.data = facilityData;
        activePopup.show = true;
        this.setState({ activePopup })
      }
      
      if(nextProps.client.facilityUpdated){
        const notUpdateFacilities = nextProps.client.facilityUpdated.notUpdate.length;
        const totalFacility = nextProps.client.facilityUpdated.totalFacility;
        const status = nextProps.client.facilityUpdated.status;
        this.setState(prevState => ({
          activePopup: {
            show: false,
            data: [],
            status: prevState.activePopup.status,
          }
        }), () => {
          let message = '';
          if (status === 'active') {
            message = 'This profile is activated for selected facilities';
          } else {
            if (notUpdateFacilities === 0) {
              message = `This profile is successfully deactivated from ${totalFacility} ${(totalFacility === 1) ? 'facility' : 'facilities'}`;
            } else if (notUpdateFacilities > 0 && notUpdateFacilities !== totalFacility) {
              const successCount = parseInt(totalFacility) - parseInt(notUpdateFacilities);
              message = `This profile is successfully deactivated from ${successCount} ${(successCount === 1) ? 'facility' : 'facilities'}. Deactivation unsuccessful in ${ parseInt(notUpdateFacilities)} ${(parseInt(notUpdateFacilities) === 1) ? 'facility' : 'facilities'} due to ongoing appointments`;
            } else {
              message = `Deactivation unsuccessful in ${notUpdateFacilities} ${(notUpdateFacilities === 1) ? 'facility' : 'facilities'} due to ongoing appointments.`;
            }
          }
          const confirmPopup = {
            showResponse: true,
            responseMessage: message,
            responseType: 'alert',
            responseAlertType: null,
            footer: true,
          };
          this.setState({confirmPopup}, () => {
            this.props.clearInviteStore();
          });
          
        });
      }
    }
  }
  
  createRows = (data) => {
    const rows = [];
    const _this = this;
    data.forEach(function (row) {
      rows.push({
        firstName: row.firstName,
        lastName: row.lastName,
        email: (row.email) ? row.email : row.phone,
        joinStatus: (row.loggedFirstTime) ? 'Joined' : 'Invited',
        joinedDate: (row.loggedFirstTimeDate) ? row.loggedFirstTimeDate : 'N/A',
        action: '',
        id: row._id
      });
    });
    this.setState({rows});
  };
  
  handleActivePopup = (status, id) => {
    const { activePopup } = this.state;
    activePopup.status = status;
    activePopup.popupWaiting = true;
    activePopup.id = id;
    
    this.setState({ activePopup }, () =>{
      this.props.getClientFacilities(id);
    });
  }
  
  handleActiveStatus = (status, _id, joined, firstName) => {
    let statusMessage = '';
    switch (status) {
     case 'reinvite':
        statusMessage = `Are you sure you want to reinvite ${firstName}? `;
        break;
      case 'removed':
        statusMessage = `Are you sure you want to remove this client profile? `;
        break;
    }
    const confirmType = (status === 'reinvite') ? 'reinvite' : 'change_status';
    const confirmPopup = {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      clientId: _id,
      footer: true,
      confirmType,
      status
    };
    this.setState({confirmPopup, showResponse: true});
  };
  
  onSuccess = () => {
    const {confirmPopup} = this.state;
    
    if (confirmPopup.confirmType === 'reinvite') {
      this.props.reinvite({id: confirmPopup.clientId});
    }
    confirmPopup.showResponse = false;
    this.setState({
      showResponse: false,
      confirmPopup
    })
  }
  
  saveFacility = (facilityIds) => {
    const { activePopup } = this.state;
    const status = (activePopup.status === 'active')? 'inactive': 'active';
    this.props.saveClientFacility({ clientId: activePopup.id, status, facilities: facilityIds });
  }
  
  closePopup = () => {
    this.setState(prevState => ({
      confirmPopup: {
        showResponse: false,
        confirmType: null,
        footer: prevState.confirmPopup.footer,
        responseType: prevState.confirmPopup.responseType,
        responseAlertType: prevState.confirmPopup.responseAlertType,
        responseMessage: '',
      }
    }));
  };
  
  closeActivatePopup = async () => {
    this.setState(prevState => ({
      activePopup: {
        show: false,
        data: [],
        status: prevState.activePopup.status,
      }
    }));
  };
  
  nextPage = () => {
    let {offset, limit, sortField, sortOrder, search, filters} = this.state;
    offset = parseInt(offset + limit);
    this.setState({offset}, () => {
      this.props.getClientList({offset, limit, sortField, sortOrder, search, filters});
    });
  }
  
  prevPage = () => {
    let {offset, limit, sortField, sortOrder, search, filters} = this.state;
    offset = parseInt(offset - limit);
    this.setState({offset}, () => {
      this.props.getClientList({offset, limit, sortField, sortOrder, search, filters});
    });
  }
  
  gotoPage = (i) => {
    let {offset, limit, sortField, sortOrder, search, filters} = this.state;
    offset = parseInt(limit * i);
    this.setState({offset}, () => {
      this.props.getClientList({offset, limit, sortField, sortOrder, search, filters});
    });
  }
  
  handleGridSort = (field) => {
    let {limit, sortField, sortOrder, search, filters} = this.state;
    let order = (sortOrder === 1) ? -1 : 1;
    sortField = field;
    sortOrder = order;
    
    this.setState({sortField, sortOrder, offset: 0}, () => {
      this.props.getClientList({offset: 0, limit, sortField: field, sortOrder: order, search, filters});
    });
  }
  
  handleGridFilter = (header, value) => {
    let {limit, sortField, sortOrder, search, filters} = this.state;
    filters[header] = value;
    this.setState({filters, offset: 0}, () => {
      
      this.props.getClientList({offset: 0, limit, sortField, sortOrder, search, filters});
    });
  }
  
  handleSearch = () => {
    let {limit, sortField, sortOrder, search, filters} = this.state;
    search = this.searchVal.value;
    this.setState({search, offset: 0}, () => {
      this.props.getClientList({offset: 0, limit, sortField, sortOrder, search: this.searchVal.value, filters});
    });
  }
  
  handlePageLimit = (perPage) => {
    let {sortField, sortOrder, search, filters} = this.state;
    this.setState({limit: perPage, offset: 0}, () => {
      this.props.getClientList({offset: 0, limit: perPage, sortField, sortOrder, search, filters});
    });
  }
  
  render() {
    const {rows, total, offset, limit, confirmPopup, search, sortField, sortOrder, activePopup, successResponse, errorResponse } = this.state;
    const isSuperAdmin = commonHelper.isSuperAdmin();
    const heading = isSuperAdmin ? 'Users' : 'Add Clients';
    return (
      <Col md={12} lg={12} className="manage-staff-container admin-container">
        <Card>
          <CardBody>
            <HeadingText text={heading}/>
            <div className="row search form">
              <div className="col-sm-7">
                
                <div className="form__form-group">
                  <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
                  <div className="form__form-group-field custom-shadow custom-border-radius">
                    <div
                      className="form__form-group-icon form__form-group-icon--left form__form-group-search">
                      <SearchIcon/>
                    </div>
                    <input
                      name="search"
                      id="search"
                      type="text"
                      onChange={this.handleSearch}
                      ref={input => this.searchVal = input}
                      value={search}
                      placeholder="Search by first/last name, phone number or email"
                      className="form__custom-field form__custom-field--icon-left"
                    />
                  </div>
                </div>
              </div>
            </div>
            <ClientTableComponent sortField={sortField} sortOrder={sortOrder} heads={this.heads} rows={rows}
                                  handleGridSort={(field) => this.handleGridSort(field)}
                                  handleGridFilter={(header, field) => this.handleGridFilter(header, field)}
                                  handleActiveStatus={(status, id, joined, firstName) => this.handleActiveStatus(status, id, joined, firstName)}
                                  handleActivePopup={(status, id) => this.handleActivePopup(status, id)}/>
            <Pagination handlePageLimit={(limit) => this.handlePageLimit(limit)} nextPage={this.nextPage}
                        prevPage={this.prevPage} gotoPage={(i) => this.gotoPage(i)} limit={limit}
                        offset={offset} total={total}/>
          </CardBody>
        </Card>
        <MessageModal show={confirmPopup.showResponse}
                      type={confirmPopup.responseType}
                      alertType={confirmPopup.responseAlertType}
                      footer={confirmPopup.footer}
                      onSuccess={this.onSuccess}
                      onClose={this.closePopup}
                      message={confirmPopup.responseMessage}/>
        <ActivateModal
          show={activePopup.show}
          facilities={activePopup.data}
          status={activePopup.status}
          errorResponse={errorResponse}
          successResponse={successResponse}
          closeActivatePopup={() => this.closeActivatePopup()}
          saveFacility={(facilityIds) => this.saveFacility(facilityIds)}/>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getClientList,
  reinvite,
  getClientFacilities,
  saveClientFacility,
  clearInviteStore
};

export default connect(mapStateToProps, mapDispatchToProps)(Client)

