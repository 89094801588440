import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { getUserProfile, logout } from '../../../redux/actions/userActions';
import { toggleLoading } from '../../../redux/actions/commonActions';
import localStorage from '../../../libs/storageHelper';
import config from '../../../config/app.config';
import ClientJS from "clientjs";

const SUPER_ADMIN = config.USER_TYPES.SUPER_ADMIN;
const STAFF = config.USER_TYPES.STAFF;

const defaultAvatar = `${process.env.PUBLIC_URL}/img/default.png`;

class profile extends Component {
  constructor(props) {
    super(props);
      this.state ={
        profile: null
      }
    this.sesssion = this.session = localStorage.getFromStorage('loggedUser');
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.props.getUserProfile(this.sesssion.user._id);
    this.props.toggleLoading(true);
  }

  componentWillReceiveProps(np) {
    this.setState({ profile: np.user.profile}, () => {

    })
    
  }

  componentDidUpdate(np){
    if(np.common.loading) {
      this.props.toggleLoading(false);
    }
    
  }

  logout(e) {
    e.preventDefault();
    const loggedUser = localStorage.getFromStorage('loggedUser');
    const client = new ClientJS();
    this.props.logout({user_id: loggedUser.user._id, data: {
        os:client.getOS().name,
        browser:client.getBrowser().name
      }});
    const userType = (loggedUser.user && loggedUser.user.type) ? loggedUser.user.type : STAFF;
    localStorage.removeFromStorage('loggedUser');
    const redirectLocation =  userType === STAFF ? '/provider-login' : 'login'
    window.location = redirectLocation;
  }

  render() {
    
    let avatarImg = '';
    let firstName = '';
    let avatar = '';
    let title = '';

    if(this.state.profile) {
      firstName = this.state.profile.firstName;
      avatar = this.state.profile.avatar;
      title = this.state.profile.designation && this.state.profile.designation.name;
      avatarImg = avatar || defaultAvatar
      
      if(this.state.profile.type !== STAFF) {
        title = this.state.profile.type === SUPER_ADMIN ? 'Super Admin': 'Admin';
      }

    }
   
    return (
      <div className="sidebar__profile">
        <Link to="/user-profile">
          <img className="topbar__avatar-img sidebar__profile--img" src={avatarImg} alt="avatar" />
          <div className="sidebar__profile--name">Welcome, {firstName}</div>
        </Link>
        <div className="sidebar__profile--post"> {title}</div>
        <button className="btn btn-primary logout_btn" onClick={this.logout}>Logout</button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = {
  getUserProfile,
  logout,
  toggleLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(profile)