import React, { Component } from 'react';
import { getSpecies } from '../../../../redux/actions/petActions';
import connect from 'react-redux/es/connect/connect';
import 'react-select/dist/react-select.min.css';
import PetItem from "./PetItem";

class UpdatePets extends Component{
  constructor(props) {
    super(props);
    this.state = {
      pets: [],
      speciesList: [],
      refreshState: false,
    }
  }

  componentDidMount() {
    this.props.getSpecies();
  }

  componentWillReceiveProps(np) {
    let { pets } = this.state;
    if (np.profile && np.profile.pets && (pets.length !== np.profile.pets.length)) {
      pets = np.profile.pets;
      this.setState({
        pets
      });
    }
    if (np.pets.speciesList) {
      let speciesList = [];
      if ( np.pets.speciesList.length > 0) {
        speciesList = np.pets.speciesList.map((value) => {
          return (
            <option key={value._id} value={value._id}>{value.name}</option>
          );
        });
        speciesList.unshift(<option key='' value=''>Select Species</option>);
      }
      this.setState({
        speciesList
      });
    }
  }

  render(){

    const { pets, speciesList } = this.state;
    const petCards = pets.map((pet) => {
      return(
        <PetItem pet={pet} key={pet._id}
                 inputChangeStatus={this.props.inputChangeStatus} speciesList={speciesList} />
      );
    });
    return(
      <div className="pet-update-card-wrapper">
        {
          pets.length > 0 ? petCards :
            <div className="no-pets">
              No Pets
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getSpecies
};

UpdatePets.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePets);