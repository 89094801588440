import React, {Component, Fragment} from 'react';
import CreateAppointment from './Components/CreateAppointment';
import RescheduleAppointment from './Components/RescheduleAppointment';

export default class Create extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.Beacon('init', 'd808c23f-0e57-44c3-8130-9ca84e5c88c7');
  }
  
  render() {
    const { appointmentId, reschedule } = this.props.location;
    return (
      !reschedule ?
        <CreateAppointment history={this.props.history}/> :
          <RescheduleAppointment history={this.props.history} appointmentId={appointmentId}/>
    )
  }
}
