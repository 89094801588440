import React, { Component } from "react";
import {FormGroup, Label, Input, ModalHeader, ModalBody, Modal} from "reactstrap";
import { parentSignup, verifySignUpToken} from '../../../redux/actions/userActions';
import connect from "react-redux/lib/connect/connect";
import Error from "../../../shared/components/form/Error";
import ResponseMessages from "../../../constants/responseMessages";
import constants from "../../../constants/constants";
import SignupError from "./error";
import SignupSuccess from "./success";
import EyeIcon from "mdi-react/EyeOffOutlineIcon";

const icon = `${process.env.PUBLIC_URL}/img/loyal-logo-white.svg`;
const errorTick = `${process.env.PUBLIC_URL}/img/error-tick.svg`;

export class ParentSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
      },
      tokenError: false,
      isSuccess: false,
      error: false,
      isLoading: false,
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
      },
      showResponse: false,
      tocModel: {
        isOpen: false,
        title: '',
        url: 'https://app.termly.io/document/terms-and-conditions/bbf8d959-cd13-4391-b0d7-cd611974da5e'
      }
    }
    this.parentSignup = this.parentSignup.bind(this);
  }

  componentDidMount(){
    if (this.props.match.params.token) {
      this.setState({isLoading: true});
      this.props.verifySignUpToken(this.props.match.params.token);
    }
  }

  componentWillReceiveProps(np){
    if (np.user.verifySignup) {
      this.setState({
        inputs: np.user.verifySignup,
        tokenError: false,
        isLoading: false,
      });
    } else if (np.user.verifySignupError === 'token_invalid') {
      this.setState({
        tokenError: true,
        isLoading: false,
      });
    }
    if (np.user.signup && np.user.signup.data) {
      this.setState({
        isSuccess: true,
        isLoading: false,
      });
    } else {
      this.setState({
        isSuccess: false,
        isLoading: false,
      });
    }
  }

  onChangeFields = (e) => {
    const { inputs } = this.state;
    const property = e.target.name;
    const value = e.target.value;
    inputs[property] = value;

    this.setState({ inputs });
  }

  parentSignup(e){
    e.preventDefault();
    if(!this.validate()){
      const { inputs } = this.state;
      delete inputs.confirmPassword;
      delete inputs._id;
      this.props.parentSignup(inputs);
    }

  }

  validate() {
    const { inputs } = this.state;
    let error = false;

    const errors =  {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirmPassword: null
    }

    if(!inputs.firstName) { errors.firstName = 'First name cannot be empty'; error = true }
    else if(inputs.firstName.length>50) { errors.firstName = 'Invalid first name'; error = true}
    else if(inputs.firstName.trim().length < 1) { errors.firstName = 'Invalid first name'; error = true}

    if(!inputs.lastName) { errors.lastName = 'Last name cannot be empty'; error = true }
    else if(inputs.lastName.length>50) { errors.lastName = 'Invalid last name'; error = true}
    else if(inputs.lastName.trim().length < 1) { errors.lastName = 'Invalid last name'; error = true}

    if (inputs.email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputs.email)) {
      errors.email = 'Invalid email address';
      error = true
    } else if(!inputs.email) { errors.email = ResponseMessages.ERRORS.EMPTY_EMAIL_ADDRESS; error = true }
    if(!inputs.password) { errors.password = ResponseMessages.ERRORS.PASSWORD_EMPTY; error = true }
    const passwordRegEx = new RegExp(constants.PASSWORD_PATTERN);

    if(!inputs.password) {
      errors.password = ResponseMessages.ERRORS.PASSWORD_EMPTY;
      error = true;
    }else if(!passwordRegEx.test(inputs.password)) {
      errors.password = ResponseMessages.ERRORS.PASSWORD_PATTERN;
      error = true;
    }

    if (!inputs.confirmPassword) {
      errors.confirmPassword = ResponseMessages.ERRORS.CONFIRM_PASSWORD_EMPTY;
      error = true;
    } else if (inputs.confirmPassword !== inputs.password) {
      errors.confirmPassword = ResponseMessages.ERRORS.PASSWORD_NOT_MATCH;
      error = true;
    }

    this.setState({ errors});
    return error;
  }

  formatPhoneNumber(phone) {
    const cleaned = ('' + phone).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  openTocModel(type) {
    const { tocModel } = this.state;

    if(type === 'pp'){
      tocModel.title = 'Privacy Policy';
      tocModel.url = "https://app.termly.io/document/privacy-policy/5921dd0c-6a30-49ed-9af6-f3929d8fd5db";
    } else {
      tocModel.title = 'Terms of Service';
      tocModel.url = "https://app.termly.io/document/terms-and-conditions/bbf8d959-cd13-4391-b0d7-cd611974da5e";
    }

    tocModel.isOpen = true;
    this.setState({ tocModel });
  }

  close() {
    const { tocModel } = this.state;
    tocModel.isOpen = false;
    tocModel.title = '';
    tocModel.url = '';
    this.setState({ tocModel });
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  showConfirmPassword(e) {
    e.preventDefault();
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }

  render() {
    const { errors, tokenError, inputs, tocModel, isSuccess, isLoading } = this.state;

    return (
      !isLoading && (
        tokenError ?
      <SignupError /> : (
        isSuccess ?
        <SignupSuccess /> :
        <div className="parent-signup">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-6 col-md-6 col-sm-5 col-xs-12">
              <div className="logo-wrapper">
                <img src={icon} alt="" className="logo" />
                <hr />
                <h1 className="title">Welcome to Loyal</h1>
                <h3 className="sub-title">Track the Pets You Love</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-7 col-xs-12">
                <div className="form-wrapper">
                  <h4 className="form-title">Sign Up</h4>
                    <form className="form" onSubmit={this.parentSignup}>
                      <FormGroup>
                        <Label for="phoneNumber">Mobile Number</Label>
                        <Input
                          type="text"
                          name="phone"
                          id="phone"
                          className={errors.phone ? "error readonly" : "readonly"}
                          placeholder={'Type Mobile number.'}
                          readOnly={true}
                          value={this.formatPhoneNumber(inputs.phone) || ''}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="email">Email</Label>

                        <Input
                          type="email"
                          name="email"
                          id="email"
                          className={errors.email ? "error email" : "email"}
                          placeholder="Email"
                          value={inputs.email || ''}
                          onChange={this.onChangeFields}
                        />
                        {errors.email && <Error text={errors.email}/>}
                      </FormGroup>
                      <FormGroup>
                        <Label for="firstName">First Name</Label>

                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          className={errors.firstName ? "error" : ""}
                          placeholder="First Name"
                          maxLength={50}
                          value={inputs.firstName || ''}
                          onChange={this.onChangeFields}
                        />
                        {errors.firstName && <Error text={errors.firstName}/>}
                      </FormGroup>
                      <FormGroup>
                        <Label for="lastName">Last Name</Label>

                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          className={errors.lastName ? "error" : ""}
                          placeholder="Last Name"
                          maxLength={50}
                          value={inputs.lastName || ''}
                          onChange={this.onChangeFields}
                        />
                        {errors.lastName && <Error text={errors.lastName}/>}
                      </FormGroup>
                      <FormGroup>
                        <Label for="newPassword">New Password</Label>

                        <div className="form__form-group-field password">
                          <input
                            name="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            placeholder="New Password"
                            id="password"
                            className={errors.password ? "error" : ""}
                            minLength={8}
                            value={inputs.password || ''}
                            onChange={this.onChangeFields}
                          />
                          <button
                            className={`form__form-group-button form__form-group-icon--right${this.state.showPassword ? ' active' : ''}`}
                            tabIndex = "-1"
                            onClick={e => this.showPassword(e)}
                          ><EyeIcon />
                          </button>
                        </div>

                        {errors.password && <Error text={errors.password}/>}
                      </FormGroup>
                      <FormGroup>
                        <Label for="confirmPassword">Confirm Password</Label>

                        <div className="form__form-group-field password">
                          <input
                            name="confirmPassword"
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            id="confirmPassword"
                            className={errors.confirmPassword ? "error" : ""}
                            minLength={8}
                            value={inputs.confirmPassword || ''}
                            onChange={this.onChangeFields}
                          />
                          <button
                            className={`form__form-group-button form__form-group-icon--right${this.state.showConfirmPassword ? ' active' : ''}`}
                            tabIndex = "-1"
                            onClick={e => this.showConfirmPassword(e)}
                          ><EyeIcon />
                          </button>
                        </div>
                        {errors.confirmPassword && <Error text={errors.confirmPassword}/>}
                      </FormGroup>
                      <p className="agreement text-center">
                        By clicking below, you consent to receive text messages from{" "}
                        <br/> Loyal Pet for use of the app. Reply STOP to opt-out.
                      </p>
                      <section className="tos-pp">
                        <a href="#" onClick={() => { this.openTocModel('toc')}}>
                          Terms of Service
                        </a>
                        |
                        <a href="#" onClick={() => { this.openTocModel('pp')}}>
                          Privacy Policy
                        </a>
                      </section>
                      <button className="btn btn-primary account__btn">
                        REGISTER
                      </button>
                    </form>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="footer-text text-center">
                Loyal Pet, Inc. Larkspur, CA <br />
                loyal.pet
              </p>
            </div>
          </div>
          <Modal isOpen={tocModel.isOpen}
                 size={'xl'}
                 backdrop={'static'}
                 className={'model-form signup-model'}>
            <header className="pp-model-header">
              {tocModel.title}
              <span className="pp-close-model" onClick={() => { this.close()}}> x </span>
            </header>
            <body className="pp-model-body">
            <iframe src={tocModel.url} width="100%" height="750px"></iframe>
            </body>
          </Modal>
        </div>
      </div>
      )
      )
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  parentSignup,
  verifySignUpToken
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentSignup)
