import {
  GET_PHASE_BY_ID,
  GET_PHASE_BY_ID_ERROR,
  SAVE_PHASE_MESSAGE,
  SAVE_PHASE_MESSAGE_ERROR,
  DELETE_PHASE_MESSAGE,
  DELETE_PHASE_MESSAGE_ERROR,
  GET_PTB_EVENTS,
  GET_PTB_EVENTS_ERROR,
  CARD_DRAG,
  CARD_DRAG_FAILED,
  CARD_DRAG_CANCELLED,
  CLEAR_STORE,
} from '../actions/pTBActions';

const initialState = {
  ptbEvents: [],
  loaded: false,
  cardDrag: false,
  cardDragData: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PHASE_BY_ID:
      return {
        ...state,
        phaseMessage: action.payload,
        ptbMessageSave: false,
        appointment: false,
      }
    case GET_PHASE_BY_ID_ERROR:
      return {
        ...state,
        ptbMessageSave: false,
        phaseMessage: [],
        appointment: false,
      }
    case GET_PTB_EVENTS:
      return {
        ...state,
        ptbEvents: action.payload,
        loaded:true,
        cardDrag: false,
        ptbMessageSave: false,
        appointment: false,
      }
    case GET_PTB_EVENTS_ERROR:
      return {
        ...state,
        ptbEvents: [],
        ptbMessageSave: false,
        loaded:true,
        appointment: false,
      }
    case CARD_DRAG:
      return {
        ...state,
        cardDrag: true,
        cardDragData: action.payload,
        ptbMessageSave: false,
        appointment: false,
      }
    case CARD_DRAG_FAILED:
      return {
        ...state,
        ptbEvents: [],
        cardDrag: false,
        cardDragData: null,
        ptbMessageSave: false,
        appointment: false,
      }
    case CARD_DRAG_CANCELLED:
      return {
        ...state,
        ptbEvents: state.ptbEvents.map(event => {
          if (event._id === action.payload.cardId) {
            event.phase = action.payload.sourceLaneId;
          }

          return event;
        }),
      };
      case SAVE_PHASE_MESSAGE:
      return {
        ...state,
        phaseMessage: [],
        ptbMessageSave: true, 
        cardDrag: action.payload.drag,
        appointment: action.payload.appointment,
      }
    case SAVE_PHASE_MESSAGE_ERROR:
      return {
        ...state,
        ptbMessageSave: [],
        appointment: false,
      }
    case DELETE_PHASE_MESSAGE:
      return {
        ...state,
        phaseMessage: [],
        ptbMessageDelete: true,
        appointment: false,
      };
    case DELETE_PHASE_MESSAGE_ERROR:
      return {
        ...state,
        ptbMessageDelete: [],
        appointment: false,
      };
      case CLEAR_STORE:
      return {
        ...state,
        ptbMessageSave: false,
        appointment: false,
        phaseMessage: false,
        cardDrag:false
      }
    default:
      return state;
  }
}