import {
  FETCH_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_AVATAR,
  UPDATE_USER_AVATAR_ERROR,
  LOGIN, LOGOUT, LOGIN_ERROR, CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR, FORGOT_PASSWORD, FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD, RESET_PASSWORD_ERROR, VERIFY_RESET_PASSWORD, VERIFY_RESET_PASSWORD_ERROR,
  VERIFY_SIGNUP_TOKEN, PARENT_SIGN_UP, PARENT_SIGN_UP_ERROR, VERIFY_SIGNUP_TOKEN_ERROR,
} from '../actions/userActions';

const initialState = {
  
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VERIFY_SIGNUP_TOKEN:
    return {
      ...state,
      verifySignup:action.payload,
    }
    case VERIFY_SIGNUP_TOKEN_ERROR:
    return {
      ...state,
      verifySignupError:action.payload.errorMessage,
    }
    case PARENT_SIGN_UP:
    return {
      ...state,
      signup:action.payload,
    }
    case PARENT_SIGN_UP_ERROR:
    return {
      ...state,
      signup:action.payload,
    }
    case FETCH_USER_PROFILE:
    return {
      ...state,
      profile:action.payload,
      profileUpdated: false,
      avatar: null
    }
    case UPDATE_USER_PROFILE:
    return {
      ...state,
      profileUpdated:action.payload,
      resetPassword: action.resetPassword,
      avatar: null
    };
    case UPDATE_USER_AVATAR:
    return {
      ...state,
      avatar:action.payload
    }
    case UPDATE_USER_AVATAR_ERROR:
    return {
      ...state,
      avatar:null
    }
    case LOGIN:
      return {
        ...state,
        loginData: action.payload,
        loginErrorData: null
      }
    case LOGOUT:
      return {
        ...state,
        loginData: action.payload,
        loginErrorData: null
      }
    case LOGIN_ERROR:
      return {
        ...state,
        loginErrorData: action.payload
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordData: action.payload,
        changePasswordErrorData: null
      }
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordErrorData: action.payload,
        changePasswordData: null
      }
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordData: action.payload,
        forgotPasswordDataError: null
      }
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordDataError: action.payload,
        forgotPasswordData: null
      }
    case VERIFY_RESET_PASSWORD:
      return {
        ...state,
        verifyResetPasswordData: action.payload,
        verifyResetPasswordDataError: null
      }
    case VERIFY_RESET_PASSWORD_ERROR:
      return {
        ...state,
        verifyResetPasswordDataError: action.payload,
        verifyResetPasswordData: null
      }
    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordData: action.payload,
        resetPasswordDataError: null
      }
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordDataError: action.payload,
        resetPasswordData: null
      }
    default:
      return state;
  }
}
