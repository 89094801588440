import React, { Component } from 'react';
import DateRangePickerComponent from './DateRangePickerComponent';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class TimeFilter extends Component{
  static propTypes = {
    changeFilter: PropTypes.func.isRequired,
    selected: PropTypes.string,
    setDateRange: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props);
    this.state = {
      filterList: ['today','this week', 'next week',],
      startDate: new Date(),
      endDate: new Date(),
    }
    this.handleSelect = this.handleSelect.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  handleSelect(ranges){
    this.props.changeFilter('');
    this.setState({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    }, () => {
      if (!moment(ranges.selection.startDate).isSame(moment(ranges.selection.endDate))) {
        this.props.changeFilter('range');
        this.props.setDateRange({
          startDate: ranges.selection.startDate,
          endDate: ranges.selection.endDate,
        });
      }
    });
  }

  changeFilter(value){
    if (value !== 'range') {
      this.setState({
        startDate: new Date(),
        endDate: new Date(),
      });
    }
    this.props.changeFilter(value);
  }

  clearAll() {
    this.changeFilter('');
  }


  render (){
    const { filterList, startDate, endDate } = this.state;
    const { selected } = this.props;
    const selectionRange = {
      startDate,
      endDate,
      key: 'selection',
      selected
    }

    const list = filterList.map(value =>
      <li key={value} className="time-filter-item" onClick={() => {this.changeFilter(value)}}>
        <span className={value === selected ? 'active' : null}>{value}</span>
      </li>
    );
    return(
      <div className="time-filters-wrapper">
        <ul className="time-filter-list clearfix">
          <li className="time-filter-item">
            Filter By
          </li>
          {list}
        </ul>
        <DateRangePickerComponent handleSelect={this.handleSelect} selectionRange={selectionRange}/>
        <a className="clear-filter" onClick={this.clearAll}>Clear</a>
      </div>
    );
  }

}