import React, { Component, Fragment } from 'react';
import { Collapse } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import { DebounceInput } from './DebounceInput';
import {
  getParents
} from "../../../redux/actions/clientActions";
import connect from "react-redux/es/connect/connect";
import { formatPhone } from '../../../libs/commonHelper';
import config from "../../../config/app.config";
import MessageModal from "../../../shared/components/Modals/MessageModal";
import localStorage from "../../../libs/storageHelper";

const defaultAvatar = `${process.env.PUBLIC_URL}/img/default.png`;
const initialState = {
  parent: null,
  searchKeyWord: '',
  isResultListOpen: false,
  parentList: [],
  isParentSelected: false,
  outSideClicked: false
}

class FindParent extends Component{
  constructor(props) {
    super(props);
    let user = localStorage.getFromStorage('loggedUser');
    this.state = {
      parent: null,
      searchKeyWord: '',
      isResultListOpen: false,
      parentList: [],
      key: 0,
      isParentSelected: false,
      userType: user.user.type,
      facilityId: '',
      responseBox: {
        showResponse: false,
        responseType: '',
        responseAlertType: '',
        responseFooter: '',
        message: '',
      },
    }
    this.doSearch = this.doSearch.bind(this);
    this.setParent = this.setParent.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        isResultListOpen: false,
        outSideClicked: true,
      });
    }
  }

  componentWillReceiveProps(np) {
    if (np.defaultParent) {
      this.setState({
        parent: np.defaultParent,
        isResultListOpen: false,
        isParentSelected: true,
        searchKeyWord: ''
      });
    }
    if (np.client && !this.state.outSideClicked) {
      const parentList = np.client.parentList ? np.client.parentList : [];
      this.setState({
        parentList
      }, () => {
        if (parentList.length > 0 && this.state.searchKeyWord !== '' && this.state.searchKeyWord !== null) {
          this.toggleResultList(true);
        } else {
          this.toggleResultList(false);
        }
      });
    }
    if(np.reset){
      const state = initialState;
      this.setState(
        state
      );
    }
    if (np.user && np.user.profile && np.user.profile.facility._id) {
      this.setState({ facilityId: np.user.profile.facility._id})
    }
  }

  toggleResultList(value) {
    this.setState({
      isResultListOpen: value
    });
  }

  doSearch(e) {
    this.setState({
      searchKeyWord: e.target.value,
      outSideClicked: false,
      isResultListOpen: false
    }, () => {
      if (this.state.searchKeyWord !== null & this.state.searchKeyWord.length > 0) {
        this.props.getParents(this.state.searchKeyWord);
      }
    });
  }

  setParent (key) {
    const { parentList, isParentSelected, responseBox } = this.state;
    if(!isParentSelected){
      this.setState({
        parent: parentList[key],
        isResultListOpen: false,
        isParentSelected: true,
        searchKeyWord:  ''
      }, () => {
        this.props.setSelectedParent(this.state.parent);
      });
    } else {
      responseBox.showResponse = true;
      responseBox.responseFooter = true;
      responseBox.responseType = 'confirm';
      responseBox.message = 'The changes you have made will be lost if you navigate away from this page.\n' +
        'Are you sure you want to leave this page?';
      this.setState({ responseBox, key, searchKeyWord: '' });
    }
  }

  onClose(){
    const { responseBox } = this.state;

    responseBox.showResponse = false;
    responseBox.responseType = '';
    responseBox.responseAlertType = '';
    responseBox.responseFooter = '';
    responseBox.message = '';

    this.setState({
      responseBox
    });
  }

  onSuccess(){
    const { parentList, key } = this.state;
    this.setState({
      parent: parentList[key],
      isResultListOpen: false,
      isParentSelected: true,
      searchKeyWord:  ''
    }, () => {
      this.props.setSelectedParent(this.state.parent);
      this.onClose();
    });
  }


  render() {
    const { collapse, viewMode } = this.props;
    const { parent, searchKeyWord, parentList, responseBox, userType, facilityId } = this.state;
    const parentImage = (parent && parent.avatar) ? `${config.PARENT_PROFILE_IMAGE_PATH}${parent.avatar}` : defaultAvatar;

    const dropDownItems = parentList.map((parent, key) => {
      return(
        <div className="list-item" key={parent._id} onClick={() => { this.setParent(key) }}>
          <p>{parent.name} <span className="email">{
            parent.email ? parent.email : formatPhone(parent.phone,  '(###) ###-####')
          }</span></p>
        </div>
      );
    });

    return(
      <div className="content-holder find-parent-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h3>{this.props.title || 'Find Parent'}</h3>
          </div>
        </div>
        <Collapse className="parent-collapse" isOpen={collapse}>
          <div className="row">
            <div className="col-md-5">
              {
                !viewMode &&
                <div className="search-wrapper" ref={node => this.node = node}>
                  <p className="search-by">Search by*</p>
                  <DebounceInput className="form-control"
                                 placeholder="Search by Parent/Pet Name, Mobile Number."
                                 onChange={this.doSearch}
                                 value={this.state.searchKeyWord}
                                 debounceTimeout={100}
                                />
                  {
                    this.state.isResultListOpen &&
                    <div className="list">
                      <Scrollbars
                        autoHide={dropDownItems.length <= 2}
                        renderTrackHorizontal={props => <div/>}
                        className="custom-scroll-bar"
                        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                        renderThumbVertical={props => <div className="thumb-vertical"/>}
                        autoHeightMax={125}
                        autoHeight>
                        {dropDownItems}
                      </Scrollbars>
                    </div>
                  }

                </div>
              }
            </div>
            <div className="col-md-6">
              {
                parent &&
                <div className="parent-card">
                  <div className="row">
                    <div className="col-md-3 hover-box">
                      <img className="parent-image  hover-box-layer_bottom"
                           src={parentImage}
                           alt="avatar" />
                      {(userType === config.USER_TYPES.STAFF) &&
                        <a href={`/client-list/profile/${parent._id}`}>
                          <div className="hover-box-layer_top">
                            <div className="hover-box-text">
                              View Profile
                            </div>
                          </div>
                        </a>
                      }
                    </div>
                    <div className="col-md-9">
                      <p className="parent-name">{parent.name} </p>
                      <p className="parent-email">{parent.email}</p>
                      <p className="parent-tel">{parent.phone ? formatPhone(parent.phone,  '(###) ###-####') : ''}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-md-3 col-md-9">
                      <div className="status-label parent-actions invited">
                        {(parent.joined) ? 'Joined' : 'Invited'}
                      </div>
                      {
                        !parent.joined &&
                        <div className="action-wrapper parent-actions">
                          {
                            !viewMode &&
                            <button className="action" onClick={() => {this.props.reInvite({id: parent._id, facility: facilityId})}}>
                              Reinvite
                            </button>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
              {
                !parent &&
                <div className="no-parent">
                  <div className="action-wrapper">
                    <button className="action" onClick={this.props.registerNew}>
                      <span className="lnr lnr-plus-circle"></span> Add New Parent
                    </button>
                  </div>
                  {
                    (searchKeyWord !== '') && (!this.state.parentList.length) &&
                    <label>No parent record found?</label>
                  }
                </div>
              }
            </div>

          </div>
        </Collapse>
        <MessageModal show={responseBox.showResponse}
                      type={responseBox.responseType}
                      alertType={responseBox.responseAlertType}
                      footer={responseBox.responseFooter}
                      onSuccess={this.onSuccess}
                      onClose={this.onClose}
                      message={responseBox.message}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getParents
};

FindParent.propTypes = {
  collapse: PropTypes.bool.isRequired,
  reset: PropTypes.bool.isRequired,
  setSelectedParent: PropTypes.func.isRequired,
  registerNew: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FindParent);
