import {handleRequest} from "../services/APIService";
import config from "../../config/app.config";

export const GET_PET_BY_ID = 'GET_PET_BY_ID';
export const GET_PET_BY_ID_ERROR = 'GET_PET_BY_ID_ERROR';
export const FETCH_PETS = 'FETCH_PETS';
export const FETCH_PETS_ERROR = 'FETCH_PETS_ERROR';
export const FETCH_SPECIES = 'FETCH_SPECIES';
export const FETCH_BREEDS = 'FETCH_BREEDS';
export const FETCH_BREED_LIST = 'FETCH_BREED_LIST';
export const ADD_NEW_PARENT = 'ADD_NEW_PARENT';
export const ADD_NEW_PARENT_ERROR = 'ADD_NEW_PARENT_ERROR';
export const CLEAR_CLIENT_PROPS = 'CLEAR_CLIENT_PROPS';
export const GET_PATIENT_LIST = 'GET_PATIENT_LIST';
export const ACTIVATE_DEACTIVATE_PET = 'ACTIVATE_DEACTIVATE_PET';
export const ACTIVATE_DEACTIVATE_PET_ERROR = 'ACTIVATE_DEACTIVATE_PET_ERROR';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';
export const ACTIVATE_DEACTIVATE_PARENT = 'ACTIVATE_DEACTIVATE_PARENT';
export const ACTIVATE_DEACTIVATE_PARENT_ERROR = 'ACTIVATE_DEACTIVATE_PARENT_ERROR';
export const UPDATE_PARENT = 'UPDATE_PARENT';
export const UPDATE_PARENT_ERROR = 'UPDATE_PARENT_ERROR';
export const CLEAR_PET_UPDATE_PROPS = 'CLEAR_PET_UPDATE_PROPS';
export const UPDATE_PET = 'UPDATE_PET';
export const UPDATE_PET_ERROR = 'UPDATE_PET_ERROR';
export const CLEAR_PET_PROFILE = 'CLEAR_PET_PROFILE';
export const CLEAR_PET_LIST = 'CLEAR_PET_LIST';
export const FETCH_PET_PROFILE = 'FETCH_PET_PROFILE';
export const FETCH_PET_PROFILE_ERROR = 'FETCH_PET_PROFILE_ERROR';

export const getParent = (parentId) => dispatch => {
  handleRequest('get', `parent/${parentId}`, true )
    .then( (results) => {
      return dispatch ({
        type: FETCH_PETS,
        payload: results.data.pets
      });
    })
    .catch( (error) => {
      return dispatch ({
        type: FETCH_PETS_ERROR,
        payload: error
      });
    })
};

export const clearPetList = () => dispatch => {
  return dispatch ({
    type: CLEAR_PET_LIST,
    payload: null
  });
};

export const getPetSummary = (id) => dispatch => {
  
  handleRequest('get', `pet/` + id, true).then( (results) => {
    return dispatch ({
      type: GET_PET_BY_ID,
      payload: results.data
    });
  }).catch((error) => {
    return dispatch ({
      type: GET_PET_BY_ID_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const getSpecies = () => dispatch => {
  handleRequest('get', 'species', true )
    .then( (results) => {
      return dispatch ({
        type: FETCH_SPECIES,
        payload: results.data
      });
    });
};

export const getBreeds = () => dispatch => {
  handleRequest('get', 'breeds', true )
    .then( (results) => {
      return dispatch ({
        type: FETCH_BREEDS,
        payload: results.data
      });
    });
};

export const getBreedsForSpecies = (id) => dispatch => {
  handleRequest('get', `breeds-by-species/${id}`, true )
    .then( (results) => {
      return dispatch ({
        type: FETCH_BREED_LIST,
        payload: results.data
      });
    });
};

export const addNewParent = (data) => dispatch => {
  handleRequest('post', 'parent', true, data).then((results) => {
    return dispatch ({
      type: ADD_NEW_PARENT,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: ADD_NEW_PARENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const clearClientProps = () => dispatch => {

  return dispatch ({
    type: CLEAR_CLIENT_PROPS,
    payload: null,
  });
};

export const getPatientList = (data) => dispatch => {
  data.offset = data.offset || 0;
  data.limit = data.limit || config.DEFAULT_PAGINATION_LENGTH;
  data.page = (data.offset / data.limit) + 1;
  data.search = data.search || null;
  data.status = data.status || [];
  data.filters.timeFilter = data.timeFilter;
  handleRequest('post', 'pet/get', true, data).then( (results) => {
    return dispatch ({
      type: GET_PATIENT_LIST,
      payload: results.data,
    });
  });
};

export const updatePetStatus = (data) => dispatch => {

  handleRequest('post', `pet/change-status`, true, data).then( (results) => {
    return dispatch ({
      type: ACTIVATE_DEACTIVATE_PET,
      payload: results.data
    });
  }).catch((error) => {
    return dispatch ({
      type: ACTIVATE_DEACTIVATE_PET_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const clearUpdatePetStatus = () => dispatch => {

  return dispatch ({
    type: CLEAR_PET_UPDATE_PROPS,
    payload: null
  });
};

export const updateParentStatus = (data) => dispatch => {

  handleRequest('post', `parent/change-status`, true, data).then( (results) => {
    return dispatch ({
      type: ACTIVATE_DEACTIVATE_PARENT,
      payload: results.data
    });
  }).catch((error) => {
    return dispatch ({
      type: ACTIVATE_DEACTIVATE_PARENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const getProfile = (parentId) => dispatch => {
  handleRequest('get', `parent/${parentId}`, true )
    .then( (results) => {
      return dispatch ({
        type: FETCH_PROFILE,
        payload: results.data
      });
    })
    .catch( (error) => {
      return dispatch ({
        type: FETCH_PROFILE_ERROR,
        payload: JSON.parse(error.response)
      });
    })
};

export const clearPetProfile = () => dispatch => {
  return dispatch ({
    type: CLEAR_PET_PROFILE,
    payload: null
  });
};

export const updateParent = (data, id) => dispatch => {

  handleRequest('put', `parent/${id}`, true, data).then( (results) => {
    return dispatch ({
      type: UPDATE_PARENT,
      payload: results.data
    });
  }).catch((error) => {
    return dispatch ({
      type: UPDATE_PARENT_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const updatePet = (data, id) => dispatch => {

  handleRequest('put', `pet/${id}`, true, data).then( (results) => {
    return dispatch ({
      type: UPDATE_PET,
      payload: results.data
    });
  }).catch((error) => {
    return dispatch ({
      type: UPDATE_PET_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};

export const getPetProfile = (petId) => dispatch => {
  handleRequest('get', `share/pet/${petId}`, true )
    .then( (results) => {
      return dispatch ({
        type: FETCH_PET_PROFILE,
        payload: results.data
      });
    })
    .catch( (error) => {
      return dispatch ({
        type: FETCH_PET_PROFILE_ERROR,
        payload: JSON.parse(error.response)
      });
    });
}