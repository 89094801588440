import React, { Component } from 'react'
import {  Modal, ModalHeader, ModalBody, Row, Col, Button, Alert } from 'reactstrap';
import Error from '../../shared/components/form/Error';
import constMessages from '../../constants/responseMessages';
import constants from '../../constants/constants';
import Checkbox from 'rc-checkbox';
import localStorage from '../../libs/storageHelper';
import constant from '../../constants/constants'

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const REGEX_DIGIT = /[^\d]+/g
const REGEX_PHONE = /(\d{3})(\d{3})(\d{4})/

export default class ReferringDoctorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      facility: '',
      credentials: '',
      email: '',
      name: '',
      phone: '',
      extension: '',
      option1: null, 
      option2: null,
      option3: null,
      option4: null,
      isUpdate: false,
      selectedId: null,
      preferredStatus: [],
      errors: {
        facility: '',
        email: '',
        name: '',
        phone: '',
        preferredStatus: null
      },
      facilityList: [],
      modalSuccessResponse: null,
      modalFailedResponse: null,
      doctorDetails: null,
      newRef: true,
      loggedUser: localStorage.getFromStorage('loggedUser')
    }

  }

  componentDidMount() {
    const { facilityList, isUpdate } =  this.props;
    const { loggedUser } = this.state;
    let { facilityId } =  this.props;

    if(loggedUser.user.type === constant.userRole.admin) {
      facilityId = loggedUser.user.facility;
    }

    this.setState({ updated: false, newRef: false, facilityList, isUpdate, facilityId, facility:facilityId, modalFailedResponse: null, modalSuccessResponse: null });

    if(isUpdate) {
      this.props.getdoctorDetails();
    }

  }

  componentWillReceiveProps(np) {
    const { modalSuccessResponse, modalFailedResponse, doctorDetails } = np;
    const { facilityId, isUpdate, newRef, updated } = this.state;

    if(doctorDetails && isUpdate && !updated) {

      const { doctor } = doctorDetails;

      const facility = doctor.facility.filter( (facility) => {
          return facility.facilityId._id === facilityId;
      });

      let option1, option2, option3, option4 = null;

      facility[0].preferredStatus.map( (checked) => {

        switch (parseInt(checked)) {
          case 1:
            option1 = true;
            break;
          case 2:
            option2 = true;
            break;
          case 3:
            option3 = true;
            break;
          case 4:
            option4 = true;
            break;
          default:
            break;
        }


      } )


      this.setState({
        name: doctor.name,
        email: doctor.email,
        credentials: doctor.credentials,
        phone: doctor.phone,
        extension: doctor.extension,
        facility: facilityId,
        option1,
        option2,
        option3,
        option4,
        doctorDetails,
      })


    }

    if( modalSuccessResponse) {
      this.setState({ modalSuccessResponse, modalFailedResponse: null });
    }
    if( modalSuccessResponse && newRef) {
      this.setState({ modalSuccessResponse: constMessages.SUCCESS.INVITATION_SUCCESS, modalFailedResponse: null, newRef: false });
    }
    if( modalFailedResponse) {
      this.setState({ modalFailedResponse, modalSuccessResponse: null });

    }
  }

  toggleForm = (status) => {
    this.props.toggleForm(status);
  }

  loadFacilities = () => {
    const { facilityList, facilityId } = this.state;
    const filterList = facilityList.filter(val => val.admin)
    return filterList.map( (option) => (
      <option value={option.id} selected={facilityId === option.id} disabled={option.status === constants.STATUS.INACTIVE}>{option.name}</option>
    ))
  }

  onChangeCheckbox = (e) => {
    const {checked, name, value} = e.target;
    const optionValue = checked ? value : null;
    this.setState({ [name]: parseInt(optionValue) })
  }

  onChangeFields = (e) => {
    const { facilityId } = this.state;
    const {name, value} = e.target;

    this.setState({ [name]: value }, () => {
      if(name === 'facility') {
        this.onSelecFacility();
      }

    } );

  }

  onSelecFacility = (params) => {
    const { doctorDetails, isUpdate, facility: id } = this.state;

    if(doctorDetails && isUpdate) {

      const { doctor } = doctorDetails;


      const facility = doctor.facility.filter( (facility) => (facility.facilityId._id === id) );

      const newRef = facility.length > 0 ? false : true;

      let option1, option2, option3, option4 = null;
      if (facility.length > 0) {

      facility[0].preferredStatus.map( (checked) => {

        switch (parseInt(checked)) {
          case 1:
            option1 = true;
            break;
          case 2:
            option2 = true;
            break;
          case 3:
            option3 = true;
            break;
          case 4:
            option4 = true;
            break;
          default:
            break;
        }


      } )



    } else {
      option1 = false;
      option2 = false;
      option3 = false;
      option4 = false;
    }
    this.setState({
      option1,
      option2,
      option3,
      option4,
      newRef
    })


    }


  }


  save = () => {

    const {
      isUpdate,
      facility,
      credentials,
      email,
      name,
      phone,
      extension,
      option1,
      option2,
      option3,
      option4,
    } = this.state;

    const preferredStatus = [];

    if(option1) {
      preferredStatus.push(1);
    }
    if(option2) {
      preferredStatus.push(2);
    }
    if(option3) {
      preferredStatus.push(3);
    }
    if(option4) {
      preferredStatus.push(4);
    }

    if(!this.validate()) {

      const data = {
        facility,
        credentials,
        email,
        name,
        phone,
        extension,
        preferredStatus
      };
      this.setState({updated: true});
      this.props.save(data, isUpdate);
    }

  }


  validate() {
    const { name, facility, email, phone, option1, option2, option3, option4 } = this.state;
    let error = false;

    const errors =  {
      facility: '',
      name: '',
      email: '',
      phone: '',
      option1: null,
      option2: null,
      option3: null,
      option4: null,
    }

    if(!facility) {
      errors.facility = constMessages.ERRORS.FACILITY_EMPTY;
      error = true
    }
    if(phone && phone.replace(REGEX_DIGIT, '').length < 10) {
      errors.phone = constMessages.ERRORS.PHONE_INVALID;
      error = true
    }
    if( !name.trim()) {
      errors.name = constMessages.ERRORS.NAME_EMPTY;
      error = true
    }
    if(!option1 && !option2 && !option3 && !option4) {
      errors.preferredStatus = constMessages.ERRORS.PREFERRED_STATUS_EMPTY;
      error = true
    }
    if(!email) {
      errors.email = constMessages.ERRORS.EMPTY_EMAIL;
      error = true
    } else if (!EMAIL_REGEX.test(email)) {
      errors.email = constMessages.ERRORS.INVALID_EMAIL;
      error = true
    }

    this.setState({ errors})
    return error;
  }


  render() {
    const {
      isUpdate,
      errors,
      credentials,
      email,
      name,
      phone,
      extension,
      modalFailedResponse,
      modalSuccessResponse,
      option1,
      option2,
      option3,
      option4,
      loggedUser
    } = this.state;

    const buttonText = isUpdate ? 'Update' : 'Save';
    let formatedPhone = phone;
    let formatedExtention = extension;

    if(phone) {
      formatedPhone =  phone.replace(REGEX_DIGIT, '').replace(REGEX_PHONE, '($1) $2-$3');
    }


    if(extension) {
      formatedExtention =  extension.replace(REGEX_DIGIT, '');
    }

    const title = isUpdate ? "Referring Doctor Profile" : "Invite New Referring Doctor"

    return (
      <div>
        <Modal isOpen={true} toggle={true} className={'model-form modal-lg modal-referral-doctor'}>
        <ModalHeader className={'header-form'} toggle={this.toggle}>{title}</ModalHeader>
        <ModalBody>
        { modalSuccessResponse &&
          <Alert color="success">{modalSuccessResponse}</Alert>
        }
         { modalFailedResponse &&
          <Alert color="danger">{modalFailedResponse}</Alert>
        }
          <div className="form">
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Facility<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <select name="facility" id="facility"
                      className="form-control form-select"
                      onChange = {this.onChangeFields}
                      disabled= {loggedUser.user.type === constant.userRole.admin}
                      >
                      <option value="">Select</option>
                      {this.loadFacilities()}
                    </select>
                  </div>
                  {errors.facility && <Error text={errors.facility}/>}
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Credentials</span>
                  <div className="form__form-group-field">
                    <input
                      name="credentials"
                      type="text"
                      placeholder="Credentials"
                      value={credentials}
                      maxLength="50"
                      onChange = {this.onChangeFields}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email<span className="loyal-required-star">*</span></span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={email}
                      maxLength="50"
                      disabled={ isUpdate }
                      onChange = {this.onChangeFields}
                    />
                  </div>
                  {errors.email && <Error text={errors.email}/>}
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Name<span className="loyal-required-star">*</span></span>
                  <div className="form__form-group-field">
                    <input
                      name="name"
                      type="text"
                      placeholder="Name"
                      value={name}
                      maxLength="50"
                      onChange = {this.onChangeFields}
                    />
                  </div>
                  {errors.name && <Error text={errors.name}/>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Preferred Status Updates<span className="loyal-required-star">*</span></span>
                  <div className="form__form-group-field checkbox-wrapper">

                  <div>
                    <Checkbox
                      name= "option1"
                      value= "1"
                      checked = { option1 }
                      onChange={this.onChangeCheckbox}
                      disabled={this.state.disabled}
                    />
                      &nbsp; Recovery Phase Arrival
                  </div>
                  <div>
                    <Checkbox
                      name= "option4"
                      value= "4"
                      checked = { option4 }
                      onChange={this.onChangeCheckbox}
                      disabled={this.state.disabled}
                    />
                      &nbsp; Discharge Order Given
                  </div>
                  <div>
                    <Checkbox
                      name= "option2"
                      value= "2"
                      checked = { option2 }
                      onChange={this.onChangeCheckbox}
                      disabled={this.state.disabled}
                    />
                      &nbsp; Hospitalized Admission
                  </div>
                  <div>
                    <Checkbox
                      name= "option3"
                      value= "3"
                      checked = { option3 }
                      onChange={this.onChangeCheckbox}
                      disabled={this.state.disabled}
                    />
                      &nbsp; Transfer Order Given
                  </div>
                  </div>
                  {errors.preferredStatus && <Error text={errors.preferredStatus}/>}
                </div>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Phone</span>
                      <div className="form__form-group-field">
                        <input
                          name="phone"
                          type="text"
                          placeholder="Phone"
                          value={formatedPhone}
                          maxLength="10"
                          onChange = {this.onChangeFields}
                        />
                      </div>
                      {errors.phone && <Error text={errors.phone}/>}
                    </div>
                  </Col>
                  <Col>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Ext:</span>
                      <div className="form__form-group-field">
                        <input
                          name="extension"
                          type="text"
                          placeholder="Ext"
                          value={formatedExtention}
                          maxLength="5"
                          onChange = {this.onChangeFields}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
            <Col className="div-button">
              <Button className="loyal-btn-dark" color="primary" onClick={this.toggleForm.bind(this, false)}>Cancel</Button>
              <Button color="primary" onClick={this.save}>{buttonText}</Button>
              </Col>
            </Row>
          </div>
         </ModalBody>
      </Modal>
      </div>
    )
  }
}
