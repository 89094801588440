import React, { Component } from 'react'
import { Card, CardBody, Col } from 'reactstrap';
import Table from './table';
import Modal from './modal';
import { connect } from 'react-redux';
import { getAllFacilities } from '../../redux/actions/staffActions';
import { getAdmins, changeStatus, changePrimary, saveAndInvite, update, reinvite } from '../../redux/actions/adminActions';
import { toggleMessageModel } from '../../redux/actions/commonActions';
import config from "../../config/app.config";

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      facilityAll:[],
      tableData: null,
      successResponse: null,
      adminStatusUpdateResponse: null,
      filterData: {}
    }
  }

  componentDidMount() {
    this.props.getAllFacilities();
    this.props.getAdmins();
  }

  toggleMessageModal = (status) => {
    this.props.toggleMessageModel(status);
  }

  componentWillReceiveProps(np) {
      const { rowData, filterData } = this.state;

    if(np.staff.facilityAll) {
      this.setState({ facilityAll:np.staff.facilityAll});
    }

    if(np.admin) {
      this.setState({
        tableData: np.admin.adminList,
        isPrimaryAdminExists: np.admin.isPrimaryAdminExists,
        isEmailExists: false,
        adminStatusUpdateResponse: null
      });

      if(np.admin.adminStatusUpdated) {
        this.setState({
          adminStatusUpdateResponse: np.admin.adminStatusUpdated
        });
        this.props.getAdmins(rowData);
      }

      if(np.admin.reinvited) {
        this.setState({
          adminStatusUpdateResponse: np.admin.reinvited
        });
        this.props.getAdmins(rowData);
      }

      if(np.admin.updated) {
        this.setState({ successResponse: np.admin.updated });
        setTimeout(() => {
          this.setState({ successResponse: null, isModalOpen: false});
        }, config.POPUP_TIMEOUT_TIME);
        this.props.getAdmins(filterData);
      }

      if(np.admin.invited) {
        this.setState({ successResponse: np.admin.invited.message});
        setTimeout(() => {
          this.setState({ successResponse: null, isModalOpen: false});
        }, config.POPUP_TIMEOUT_TIME);
        this.props.getAdmins(filterData);
      }

      if (np.admin.inviteAdminError && (np.admin.inviteAdminError.data === 'email exist' || np.admin.inviteAdminError.errorMessage === 'email exist')) {
        this.setState({
          isEmailExists: true
        });
      }
    }

  }


  toggleModal = (state, data) => {
    let updateData = null
    if(data) {
      updateData = data
    }

    this.setState({ isModalOpen: state, updateData })
  }

  getAdminList = (data) => {
    this.setState({filterData: data})
    this.props.getAdmins(data);
  }

  changeStatus = (status, id, rowData) => {
     this.setState({ rowData })
      this.props.changeStatus({status, id});
  }

  reinvite = (adminId, rowData) => {
    this.setState({ rowData })
     this.props.reinvite({adminId});
 }

  onChangeCheckbox = (facilityId) => {
     this.props.changePrimary(facilityId);
  }

  saveAndInvite = (data) => {
    this.props.saveAndInvite(data);
  }

  update = (data) => {
    this.props.update(data);
  }

  render() {

    const { isModalOpen, facilityAll, tableData, updateData,  isPrimaryAdminExists, isEmailExists, adminStatusUpdateResponse } = this.state;
    let modalData = {
      facilityAll,
      updateData,
      isPrimaryAdminExists,
      isEmailExists
    }
    let data = {
      facilityAll,
      tableData,
      adminStatusUpdateResponse
    }
    return (
      <div className="manage-admin">
      <Col md={12} lg={12} >
        <Card>
          <CardBody>
            <Table
              toggleModal={this.toggleModal}
              data={data}
              getAdminList={this.getAdminList}
              changeStatus={this.changeStatus}
              reinvite={this.reinvite}
              onChangeCheckbox={this.onChangeCheckbox}
            />
            { isModalOpen && <Modal
              toggleMessageModel={this.toggleMessageModal}
              toggleModal={this.toggleModal}
              successResponse={this.state.successResponse}
              data={modalData}
              onChangeCheckbox={this.onChangeCheckbox}
              saveAndInvite={this.saveAndInvite}
              update={this.update}
              /> }
          </CardBody>
        </Card>
        </Col>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getAllFacilities,
  getAdmins,
  changeStatus,
  changePrimary,
  saveAndInvite,
  update,
  reinvite
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
