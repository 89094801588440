import React, { Component } from 'react';
import { Collapse, Row, FormGroup, Col } from 'reactstrap';
import { getBreedsForSpecies, updatePetStatus,
  clearUpdatePetStatus, updatePet } from '../../../../redux/actions/petActions';
import { toggleLoading } from '../../../../redux/actions/commonActions';
import connect from 'react-redux/es/connect/connect';
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';
import DatePicker from "react-datepicker/es";
import Error from "../../../../shared/components/form/Error";
import { formatPhone } from "../../../../libs/commonHelper";
import config from "../../../../config/app.config";
import MessageModal from "../../../../shared/components/Modals/MessageModal";
import { reset, validate } from "../../../../libs/validationHelper";
import DatePickerComponent from "../../Common/DatePickerComponent";
import moment from "moment";

const defaultAvatar = `${process.env.PUBLIC_URL}/img/default-animal-black.png`;

class PetItem extends Component{
  constructor(props) {
    super(props);
    this.state = {
      pet: {},
      collapse: false,
      updateRequested: false,
      inputs: {
        name: '',
        nickName: '',
        species: '',
        breed: '',
        gender: '',
        birthday: ''
      },
      errors: {
        name: { error: null, display: 'pet name' },
        nickName: { error: null, display: 'nick name' },
        species: { error: null, display: 'species' },
        birthday: { error: null, display: 'birthday' },
      },
      error: false,
      refreshState: false,
      speciesList: [],
      breedList: [],
      genderList: [
        <option key='' value=''>Select Gender</option>,
        <option key='female' value='female'>Female</option>,
        <option key='male' value='male'>Male</option>,
        <option key='male_neutered' value='male_neutered'>Male Neutered</option>,
        <option key='female_neutered' value='female_neutered'>Female Neutered</option>,
        <option key='unknown' value='unknown'>Unknown</option>,
      ],
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseAlertType: '',
        responseType: '',
        responseTitle: '',
        pet: null,
        status: null,
        confirmType: null,
      },
    }
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    let { pet, inputs, speciesList } = this.state;
    pet = this.props.pet;
    inputs.name = pet.name;
    inputs.nickName = pet.nickName || '';
    inputs.species = pet.speciesId;
    inputs.breed = pet.breedId || '';
    inputs.birthday = pet.birthday || '';
    inputs.gender = pet.gender || '';
    this.props.getBreedsForSpecies(pet.speciesId);

    const supporters = pet.supporters;
    const activeSupporters = supporters.filter((supporter) => {
      if (supporter.status && supporter.status === config.CO_PARENT_STATUS.JOINED) {
        return supporter;
      }
    });
    pet.activeSuppoterCount = activeSupporters.length || 0;

    if (this.props.speciesList.length > 0) {
      speciesList = this.props.speciesList;
    }
    this.setState({
      pet, inputs, speciesList
    });
  }

  componentWillReceiveProps(np) {
    let { inputs, updateRequested } = this.state;
    if(np.pets.breedList && np.pets.breedList.species === inputs.species){
      let breedList = [];
      if ( np.pets.breedList.breeds.length > 0) {
        breedList = np.pets.breedList.breeds.map((value) => {
          return (
            { value: value._id, label: value.name }
          );
        });
        breedList.unshift({ value: '', label: 'Select Breeds' });
      }
      this.setState({
        breedList
      });
    }
    if (np.pets.petUpdateStatus && updateRequested) {
      const { refreshState, confirmPopup, pet } = this.state;

      confirmPopup.showResponse = true;
      if (np.pets.petUpdateStatus.errorMessage) {
        confirmPopup.responseType = 'alert';
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage = np.pets.petUpdateStatus.errorMessage;
      } else {
        if (np.pets.petUpdateStatus.status && np.pets.petUpdateStatus.status === 'confirm') {
          confirmPopup.responseMessage = np.pets.petUpdateStatus.message;
          confirmPopup.responseType = 'confirm';
          confirmPopup.confirmType = 'request_confirmed';
        } else {
          confirmPopup.responseMessage = `${pet.name} ${(confirmPopup.status === 'active') ? 'activated' : 'deactivated'}`;
          confirmPopup.responseType = 'alert';
          confirmPopup.responseAlertType = 'success';
          pet.status = confirmPopup.status;
        }
      }
      this.setState({ confirmPopup,  refreshState: !refreshState, updateRequested: false, pet });
    }
    if (np.pets.petProfileUpdateResponse && updateRequested) {
      const { refreshState, confirmPopup, pet } = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `Pet profile updated`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      if (np.pets.petProfileUpdateResponse.errorMessage) {
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage = np.pets.petProfileUpdateResponse.errorMessage;
      } else {
        this.props.inputChangeStatus(this.state.pet._id, false);
      }
      this.setState({ confirmPopup,  refreshState: !refreshState, updateRequested: false, pet });
    }
  }

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    let { inputs, refreshState, breedList } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    if (property === 'species') {
      breedList =  [{ value: '', label: 'Select Breeds' }];
      inputs.breed = '';
      this.props.getBreedsForSpecies(value);
    }
    this.props.inputChangeStatus(this.state.pet._id, true);
    this.setState({
      inputs, refreshState, breedList
    });
  };

  onChangeSelect = (e) => {
    let {inputs} = this.state;
    if (e) {
      inputs.breed = e.value;
      this.props.inputChangeStatus(this.state.pet._id, true);
    } else {
      inputs.breed = null;
    }
    this.setState({
      inputs
    });
  };

  onChangeCalender = (e) => {
    let { inputs } = this.state;
    inputs.birthday = e;
    this.props.inputChangeStatus(this.state.pet._id, true);
    this.setState({
      inputs
    });
  };

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  changeStatus = (status) => {
    const { pet } = this.state;
    let statusMessage = '';
    switch (status) {
      case 'active':
      default:
        statusMessage = `Are you sure you want to activate ${pet.name}?`;
        break;
      case 'inactive':
        statusMessage = `Are you sure you want to deactivate ${pet.name}?`;
        break;
    }
    const confirmPopup =  {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      footer: true,
      confirmType: 'change_status',
      status
    };
    this.setState({ confirmPopup });
  };

  closePopup = () => {
    const { confirmPopup } = this.state;
    let { refreshState } = this.state;
    confirmPopup.showResponse = false;
    refreshState = !refreshState;
    this.setState({ confirmPopup, refreshState });
    this.props.clearUpdatePetStatus();
  };

  onSuccess = () => {
    const { confirmPopup, pet, refreshState } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.toggleLoading(true);
      this.props.updatePetStatus({ status: confirmPopup.status, id: pet._id });
    }
    if (confirmPopup.confirmType === 'request_confirmed') {
      confirmPopup.requestConfirm = false;
      this.props.toggleLoading(true);
      this.props.updatePetStatus({ status: confirmPopup.status, id: pet._id, confirmed: true });
    }
    confirmPopup.showResponse = false;
    this.setState({
      confirmPopup, updateRequested: true,
    });
  };

  validate = (inputs) => {
    const { refreshState } = this.state;
    let { errors } = this.state;
    errors = reset(errors);
    const validationPetRules = {
      required: ['name', 'species'],
      date: ['birthday'],
      maxToday: ['birthday'],
    };
    const validationPetResponse = validate(validationPetRules, inputs, errors);
    errors = validationPetResponse.errors;
    const error = validationPetResponse.error;
    this.setState({ errors, error, refreshState: !refreshState });
    return error;
  };

  updatePet = () => {
    const {inputs, pet} = this.state;
    if (!this.validate(inputs)) {
      this.props.toggleLoading(true);
      this.props.updatePet(inputs, pet._id);
      this.setState({
        updateRequested: true,
      });
    }
  };

  render() {
    const { collapse, genderList, breedList, inputs, errors, pet, confirmPopup, speciesList } = this.state;
    const arrowImage = (collapse) ?
      `${process.env.PUBLIC_URL}/img/icon-arrow-up.png` : `${process.env.PUBLIC_URL}/img/icon-arrow-down.png`;
    const petImage = (pet.image) ? `${config.PET_PROFILE_IMAGE_PATH}${pet.image}` : defaultAvatar;
    if (moment(inputs.birthday).isValid()) {
      inputs.birthday = moment(inputs.birthday).format('MM/DD/YYYY');
    }
    return(
      <div className="content-holder pet-update-card">
        <div className="row pet-content-wrapper">
          <div className={'image-wrapper col-sm-12 text-center'}>
            <img className="loyal-parent-image" src={petImage} alt="avatar"/>
          </div>
          <div className={'form-wrapper'}>

            <Row>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="form__form-group loyal-custom-form-group">
                  <span className="form__form-group-label">Pet Name <span className={'required'}>*</span></span>
                  <div className="form__form-group-field">
                    <FormGroup>
                      <input
                        className="form-control"
                        name="name"
                        type="text"
                        placeholder={'Enter Name'}
                        value={inputs.name}
                        maxLength={50}
                        onChange = {this.onChangeFields}
                      />
                      {errors.name.error && <Error text={errors.name.error}/>}
                    </FormGroup>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="form__form-group loyal-custom-form-group">
                  <span className="form__form-group-label">Nick Name</span>
                  <div className="form__form-group-field">
                    <FormGroup>
                      <input
                        className="form-control"
                        name="nickName"
                        type="text"
                        placeholder={'Enter Nickname'}
                        value={inputs.nickName}
                        maxLength={50}
                        onChange = {this.onChangeFields}
                      />
                      {errors.name.error && <Error text={errors.nickName.error}/>}
                    </FormGroup>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="form__form-group loyal-custom-form-group form-group">
                  <span className="form__form-group-label">Birthday</span>
                  <div className="form__form-group-field">
                    <FormGroup>
                      <DatePickerComponent
                        viewMode={ false } maxDate={true} minDate={false} is={true}
                        date={ inputs.birthday }
                        handleChange={this.onChangeCalender}/>
                      {errors.birthday.error && <Error text={errors.birthday.error}/>}
                    </FormGroup>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="form__form-group loyal-custom-form-group">
                  <span className="form__form-group-label">Species <span className={'required'}>*</span></span>
                  <div className="form__form-group-field">
                    <FormGroup>
                      <select name="species" id="species"
                              className="form-control form-select"
                              value={inputs.species}
                              placeholder={'Select Species'}
                              onChange = {this.onChangeFields}>
                        {speciesList}
                      </select>
                      {errors.species.error && <Error text={errors.species.error}/>}
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="form__form-group loyal-custom-form-group">
                  <span className="form__form-group-label">Breed</span>
                  <div className="form__form-group-field">
                    <FormGroup>
                      <Select
                        value={inputs.breed}
                        onChange={this.onChangeSelect}
                        className={'custom-select-control'}
                        options={breedList}
                        placeholder={'Select Breed'}
                        isClearable={false}
                        matchProp="label"
                        name="breed"
                        id="breed"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="form__form-group loyal-custom-form-group">
                  <span className="form__form-group-label">Gender</span>
                  <div className="form__form-group-field">
                    <FormGroup>
                      <select name="gender" id="gender"
                              className="form-control form-select"
                              value={inputs.gender}
                              placeholder={'Select Gender'}
                              onChange = {this.onChangeFields}>
                        {genderList}
                      </select>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </Row>

          </div>
          <div className={'button-wrapper'}>
            <span className={`status-label ${(pet.status==='active') ? 'invited' : 'inactive'}`}>{pet.status}</span>
            <div className={'button-panel'}>
              <button className={`btn ${ (pet.status === 'active') ? 'loyal-btn-deactivate' : 'loyal-btn-activate'}`}
                      onClick={() => {this.changeStatus((pet.status === 'active') ? 'inactive' : 'active')}}>
                { (pet.status === 'active') ? 'Deactivate' : 'Reactivate'}
              </button>
              <button className={'btn loyal-btn-save-update'}
                      onClick={this.updatePet}>Update</button>
            </div>
          </div>
          <div className={`view-contact-label ${(collapse) ? 'expanded' : ''}`}>
            <label onClick={this.toggle}>View Contacts
              <img className="" src={arrowImage} alt="toggle"/>
            </label>
          </div>
        </div>
        <Collapse isOpen={collapse}>
          <div className="row co-parent-container">
            <div className={'col-md-5'}>
              <p>Co Parents</p>
              <table className="custom-table loyal-table table table-striped">
                <thead>
                <tr className="header-tr">
                  <th>Name</th>
                  <th>Mobile Number.</th>
                  <th>Request Status</th>
                </tr>
                </thead>
                <tbody>
                { pet.coParents &&
                  pet.coParents.map((coParent) => {
                    return (<tr key={coParent._id} className="body-tr">
                      <td className={'text-capitalize'}>{`${coParent.parent.firstName} ${coParent.parent.lastName}`}</td>
                      <td>{coParent.parent.phone ?
                        formatPhone(coParent.parent.phone,  '(###) ###-####') : coParent.parent.email}</td>
                      <td className={'text-capitalize'}>{coParent.status}</td>
                    </tr>)
                  })
                }
                { !(pet.coParents && pet.coParents.length) &&
                  <tr className="body-tr">
                    <td colSpan={3} className="text-capitalize">Co parent profiles not found</td>
                  </tr>
                }
                </tbody>
              </table>

            </div>
            <div className={'col-md-6 offset-md-1'}>
              <p>Supporters</p>
              { pet.activeSuppoterCount > 0 &&
                <p className={'support-count'}><span>{(pet.activeSuppoterCount < 10)
                  ? `0${pet.activeSuppoterCount}` : pet.activeSuppoterCount } </span>
                  active supporter{(pet.activeSuppoterCount > 1) ? 's' : ''}</p>
              }
              { pet.activeSuppoterCount === 0 &&
                <p className={'support-count'}>No active supporters</p>
              }
            </div>
          </div>
        </Collapse>

        <MessageModal show={confirmPopup.showResponse}
                      type={confirmPopup.responseType}
                      alertType={confirmPopup.responseAlertType}
                      footer={ true }
                      onSuccess={this.onSuccess}
                      onClose={this.closePopup}
                      message={confirmPopup.responseMessage}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getBreedsForSpecies, updatePetStatus, clearUpdatePetStatus, toggleLoading, updatePet
};

PetItem.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PetItem);
