import React, { Component, Fragment } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";

import { formatPhone } from "../../../../libs/commonHelper";
import config from "../../../../config/app.config";

const defaultAvatar = `${process.env.PUBLIC_URL}/img/default.png`;
const initialState = {
  parent: null,
  facilityId: '',
};

class ShowParent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      parent: null
    }
  };

  componentWillReceiveProps(np) {
    if (np.selectedParent) {
      this.setState({
        parent: np.selectedParent
      });
    }
    if (np.user && np.user.profile && np.user.profile.facility._id) {
      this.setState({ facilityId: np.user.profile.facility._id });
    }
  };

  render(){
    const { collapse } = this.props;
    const { parent, facilityId } = this.state;
    const parentImage = (parent && parent.avatar) ? `${config.PARENT_PROFILE_IMAGE_PATH}${parent.avatar}` : defaultAvatar;
    return(
      <div className="content-holder find-parent-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h3>Find Parent</h3>
          </div>
        </div>
        <Collapse className="parent-collapse" isOpen={collapse}>
          <div className="row">
            <div className="col-md-6">
              {
                parent &&
                <div className="parent-card">
                  <div className="row">
                    <div className="col-md-3">
                      <img className="parent-image"
                           src={parentImage}
                           alt="avatar" />
                    </div>
                    <div className="col-md-9">
                      <p className="parent-name">{parent.firstName} {parent.lastName}</p>
                      <p className="parent-email">{parent.email}</p>
                      <p className="parent-tel">{parent.phone ? formatPhone(parent.phone,  '(###) ###-####') : ''}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-md-3 col-md-9">
                      <div className="status-label parent-actions invited">
                        {(parent.joined) ? 'Joined' : 'Invited'}
                      </div>
                      {
                        !parent.joined &&
                        <div className="action-wrapper parent-actions">
                          <button className="action" onClick={() => {this.props.reInvite({id: parent._id, facility: facilityId})}}>
                            Reinvite
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-5">
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {

};

ShowParent.propTypes = {
  collapse: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowParent);
