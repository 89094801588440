import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {  Container, CardBody, Card } from 'reactstrap';
import AvatarSection from './AvatarSection';
import ProfileDetailsForm from './ProfileDetailsForm';
import ProfilePasswordResetForm from './ProfilePasswordResetForm';
import { getUserProfile, updateUserProfile, updateAvatar, updatePassword } from '../../../redux/actions/userActions';
import { toggleLoading } from '../../../redux/actions/commonActions';
import config from '../../../config/app.config';
import localStorage from '../../../libs/storageHelper';
import ResponseMessages from '../../../constants/responseMessages';
import MessageModal from '../../../shared/components/Modals/MessageModal';
import constants from '../../../constants/constants';

const SUPER_ADMIN = config.USER_TYPES.SUPER_ADMIN;


export class UserProfile extends Component {

  constructor(props){
    super(props);

    this.state= {
      userType: SUPER_ADMIN,
      profile: null,
      successResponse: null,
      errorResponse: null,
      loading:true,
      selectedFile: null,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseAlertType: '',
        footer: false,
      },
    };
    this.session = localStorage.getFromStorage('loggedUser');

  }
  componentDidMount(){
    this.props.toggleLoading(true);
    this.props.getUserProfile(this.session.user._id);
  }

  componentWillReceiveProps(np){
    const { confirmPopup } = this.state;
    if(np.user){
      if(np.user.profileUpdated) {
        if (np.user.resetPassword) {
          localStorage.removeFromStorage('loggedUser');

          confirmPopup.showResponse = true;
          confirmPopup.responseMessage = ResponseMessages.SUCCESS.PASSWORD_CHANGE_SUCCESS;
          confirmPopup.responseType = 'alert';
          confirmPopup.responseAlertType = 'success';

          this.setState({ confirmPopup });
        } else {
          this.props.getUserProfile(this.session.user._id);
          this.setState({successResponse: ResponseMessages.SUCCESS.PROFILE_UPDATE})
        }
      }
      if(np.user.avatar) {
        const value = {
          id:np.user.profile._id,
          data: {
            avatar: np.user.avatar.url
          }
        }
        this.props.updateUserProfile(value)
      }
      this.setState({ profile: np.user.profile, loading:false});
    }

  }

  update = (state) => {
    const value = {
      id:state._id,
      data: {
        firstName:state.firstName,
        lastName:state.lastName
      }
    }
    this.props.toggleLoading(true);
    this.props.updateUserProfile(value)
  }

  resetPw = (data) => {
    this.props.updatePassword(data);
  };

  closePopup = () => {
    const { confirmPopup } = this.state;
    confirmPopup.showResponse = false;
    this.setState({ confirmPopup });

    if (this.session.user.type === constants.userRole.staff) {
      window.location = "provider-login";
    } else {
      window.location = "login";
    }
  };

  upload = (file) => {
    this.props.toggleLoading(true);
    this.props.updateAvatar(file);
  }


  hideAlert = () =>  {
    const { successResponse, errorResponse } = this.state;

    if(successResponse || errorResponse) {
      setTimeout(() => {
       this.setState({successResponse: null, errorResponse: null})
      }, config.POPUP_TIMEOUT_TIME);
    }


  }

  setErrorResponses = (msg) => {
    this.setState({errorResponse: msg})
  }

  render() {
    const { profile, successResponse, errorResponse, confirmPopup } = this.state;

    this.hideAlert();

    return (
      <div>
        <Container className="user-profile">
            <Card>
            { successResponse && <div className="alert alert-success fade show" role="alert">
              <div className="alert__content"><p>{successResponse}</p></div>
            </div>}
            { errorResponse && <div className="alert alert-danger fade show" role="alert">
              <div className="alert__content"><p>{errorResponse}</p></div>
            </div>}
               <CardBody>
                < AvatarSection profile={profile} upload={this.upload} showErrors={this.setErrorResponses}/>
                < ProfileDetailsForm profile={profile} update={this.update}/>
                {this.session.user.type !== constants.userRole.staff && !this.session.user.privateCredentials && < ProfilePasswordResetForm profile={profile} resetPw={this.resetPw}/>}
                </CardBody>
            </Card>
        </Container>
        <MessageModal show={confirmPopup.showResponse}
                      type={confirmPopup.responseType}
                      alertType={confirmPopup.responseAlertType}
                      footer={true}
                      onClose={this.closePopup}
                      message={confirmPopup.responseMessage}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
})

const mapDispatchToProps = {
  getUserProfile,
  updateUserProfile,
  updateAvatar,
  toggleLoading,
  updatePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
