
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default (props) => {
    const { parent, appointmentDate, appointmentTime, pet } = props;

    return (
        <div className="appointment-confirm-details">
            <Container>
            <Row>
                <Col xs="6"><span className="title">Parent Name</span><span className="value">{parent}</span></Col>
                <Col xs="6"><span className="title">Date</span><span className="value">{appointmentDate}</span></Col>
            </Row>
            <Row>
                <Col xs="6"><span className="title">Pet Name</span><span className="value">{pet}</span></Col>
                <Col xs="6"><span className="title">Time</span><span className="value">{appointmentTime}</span></Col>
            </Row>
            </Container>
        </div>
    )
}
        
