import React, { Component } from 'react'
import {  Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import Error from '../../shared/components/form/Error';
import constants from '../../constants/responseMessages';

export default class BreedModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      speciesList: [],
      breed:'',
      species: '',
      selectedId: null,
      isUpdate:false,
      success: null,
      errors:{
        species: null,
        name: null
      }
    }
  }

  componentDidMount() {

    const {species, isUpdate, updateData } = this.props;
    this.setState({speciesList: species, isUpdate})

    if(isUpdate){
      this.setState({
        breed: updateData.breed,
        selectedSpecies: updateData.species,
        species: updateData.species,
        selectedId: updateData.id
      })
    }
  }

  componentWillReceiveProps(np) {

    if(np.success) {
      this.setState({success: np.success});
    }

    if(np.isUpdate) {
      this.setState({ isUpdate: true })
    }
  }

  loadSpecies() {
    const { speciesList,selectedSpecies } = this.state;

    return speciesList.map( (option) => (
      <option value={option._id} selected={selectedSpecies === option.name}>{option.name}</option>
    ))

  }

  toggleForm = (status) => {
    this.props.toggleForm(status);
  }

  onChangeFields = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }
  
  save = (params) => {
    const { species, selectedId, breed: name, isUpdate } = this.state;

    if(!this.validate()) {

      const data = {
        species,
        name
      }
      if (isUpdate) {
        data.id = selectedId;
        delete data.species;
      }
      this.props.save(data, isUpdate);
      
    }
  }
  
  validate() {
    const { species, breed } = this.state;
    let error = false;

    const errors =  {
      species: null,
      breed: null,
    }

    if(!species) { errors.species = constants.ERRORS.EMPTY_SPECIES; error = true }
    if(!breed.trim()) { errors.breed = constants.ERRORS.EMPTY_BREED; error = true }

    this.setState({ errors})
    return error;
  }
  render() {

    const { errors, breed, isUpdate, success } = this.state

    const  buttonText = isUpdate ? 'Update' : 'Save';
    const title = isUpdate ? 'Breed Details' : 'Add New Breed';


    return (
      <div>
        
          
        <Modal isOpen={true} toggle={true} className={'model-form modal-lg modal-breed'}>
        <ModalHeader className={'header-form'} toggle={this.toggle}>{title}</ModalHeader>
          {success && <div className="alert alert-success fade show" role="alert">
            <div className="alert__content"><p>{success}</p></div>
          </div>}
        <ModalBody>
          <div className="form">
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Species<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <select name="species" id="species"
                      className="form-control form-select"
                      onChange = {this.onChangeFields}
                      disabled={ isUpdate ? true : false } >
                      <option value="">Select</option>
                      {this.loadSpecies()}
                    </select>
                  </div>
                  {errors.species && <Error text={errors.species}/>}
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Breed<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <input
                      name="breed"
                      type="text"
                      placeholder="Breed Name"
                      value={breed}
                      maxLength="50"
                      onChange = {this.onChangeFields}
                    />
                  </div>
                  {errors.breed && <Error text={errors.breed}/>}
                </div>
              </Col>
            </Row>
            <Row>
            <Col className="div-button">
              <Button className="loyal-btn-dark" color="primary" onClick={this.toggleForm.bind(this, false)}>Cancel</Button>
              <Button color="primary" onClick={(!success)?this.save: ''}>{buttonText}</Button>
              </Col>
            </Row>
          </div>
         </ModalBody>
      </Modal>
      </div>
    )
  }
}
