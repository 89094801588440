import React, { Component } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';

import FindParent from '../Common/FindParent';
import RegisteredPets from '../Common/SelectPet';
import RegisterParent from './Components/RegisterParent';
import RegisterNewPet from './Components/RegisterNewPet';
import ShowParent from './Components/ShowParent';
import { getParent, getSpecies, addNewParent, clearClientProps, clearPetList } from '../../../redux/actions/petActions';
import { clearUpdateProps } from '../../../redux/actions/clientActions';
import { toggleLoading } from '../../../redux/actions/commonActions';
import { reinvite } from '../../../redux/actions/clientActions';
import {reset, validate} from "../../../libs/validationHelper";
import MessageModal from "../../../shared/components/Modals/MessageModal";
import localStorage from "../../../libs/storageHelper";
import config from "../../../config/app.config";
import { setTimeout } from 'timers';

class RegisterClient extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getFromStorage('loggedUser');
    this.state = {
      successResponse: null,
      errorResponse: null,
      speciesList: [],
      savedParent: {},
      freezeFindParentTab: false,
      showSavedParent: false,
      petCollapse: false,
      registerParentCollapse: false,
      registeredPetCollapse: false,
      registerPetCollapse: true,
      selectedParent: null,
      resetParent: false,
      saveCancelButton: false,
      showNewPetButton: true,
      newParent: false,
      userType: user.user.type,
      refresh:true,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseAlertType: '',
        action: null,
        parent: null,
        status: null,
        footer: false,
        okText: 'Yes',
        cancelText: 'No',
      },
      inputs: {
        parent: {
          firstName: '',
          lastName: '',
          phoneEmail: '',
        },
        pets: []
      },
      error: false,
      errors: {
        parent: {
          firstName: { error: null, display: 'first name' },
          lastName: { error: null, display: 'last name' },
          phoneEmail: { error: null, display: 'mobile number' },
        },
      },
    };
    this.setSelectedParent = this.setSelectedParent.bind(this);
    this.registerNew = this.registerNew.bind(this);
    this.cancel = this.cancel.bind(this);
    this.addNewPet = this.addNewPet.bind(this);
    this.removePet = this.removePet.bind(this);
    this.setToParentInput = this.setToParentInput.bind(this);
    this.setToPetInput = this.setToPetInput.bind(this);
  }

  componentDidMount() {
    window.Beacon('init', 'd808c23f-0e57-44c3-8130-9ca84e5c88c7');
    this.props.getSpecies();
    this.props.clearPetList();

    /**
     * if comes from create appointment new parent open register new section
     */
    const { openNewParent } = this.props.location;
    if (openNewParent) {
      this.registerNew();
    }

  }

  componentWillReceiveProps(np) {
    
    if (np.pets.speciesList) {
      let speciesList = [];
      if ( np.pets.speciesList.length > 0) {
        speciesList = np.pets.speciesList.map((value) => {
          return (
            <option key={value._id} value={value._id}>{value.name}</option>
          );
        });
        speciesList.unshift(<option key='' value=''>Select Species</option>);
      }
      this.setState({
        speciesList
      });
    }
    if (np.pets.newParentResponse && !this.state.refresh) {
      if (np.pets.newParentResponse.data && np.pets.newParentResponse.data.status) {
        const { confirmPopup } = this.state;
        let { showSavedParent, savedParent, petCollapse, selectedParent, registerParentCollapse,
          registerPetCollapse, showNewPetButton, inputs, errorResponse, successResponse, newParent } = this.state;
        errorResponse = null;
        successResponse = null;
        switch (np.pets.newParentResponse.data.status) {
          case 'parent-added-success':
            let responseMessage = 'Parent successfully saved and invited';
            if (!newParent && (np.pets.newParentResponse.data.newPets)) {
              responseMessage = 'Pet profiles created successfully';
              const newPetData = np.pets.newParentResponse.data.newPets.map(pet => ({...pet}));
              if (newPetData.length === 1) {
                const pet = newPetData.shift();
                responseMessage = `${pet.name}'s profile created successfully`;
              }
            }
            confirmPopup.showResponse = true;
            confirmPopup.responseMessage = responseMessage;
            confirmPopup.responseType = 'alert';
            confirmPopup.responseAlertType = 'success';
            confirmPopup.footer = true;
            confirmPopup.action = 'parent-added-success';
            confirmPopup.okText = 'OK';
            confirmPopup.cancelText = 'Ok';
            confirmPopup.parent = np.pets.newParentResponse.data.data;
            showNewPetButton = false;
            registerParentCollapse = false;
            registerPetCollapse = false;
            savedParent = np.pets.newParentResponse.data.data;
            newParent = false;
            inputs = {
              parent: {
                firstName: '',
                lastName: '',
                phoneEmail: '',
              },
              pets: []
            };
            this.props.clearClientProps();
            break;
          case 'parent-exist-facility-active':
            errorResponse = 'This parent already exists';
            break;
          case 'parent-exist-facility-inactive':
            confirmPopup.showResponse = true;
            confirmPopup.responseMessage = 'This parent already exists. Please activate by visiting parent profile';
            confirmPopup.responseType = 'confirm';
            confirmPopup.responseAlertType = 'info';
            confirmPopup.footer = true;
            confirmPopup.okText = 'View Profile';
            confirmPopup.cancelText = 'Cancel';
            confirmPopup.action = 'parent-exist-facility-inactive';
            confirmPopup.parent = np.pets.newParentResponse.data;
            break;
          case 'parent-exist-facility-not-exist':
            const existParent = np.pets.newParentResponse.data.data;
            confirmPopup.showResponse = true;
            confirmPopup.responseMessage = `${existParent.firstName} ${existParent.lastName} is already a member with Loyal. Would you like to add this contact to your facility?`;
            confirmPopup.responseType = 'confirm';
            confirmPopup.responseAlertType = 'info';
            confirmPopup.footer = true;
            confirmPopup.okText = 'Yes';
            confirmPopup.cancelText = 'No';
            confirmPopup.action = 'parent-exist-facility-not-exist';
            confirmPopup.parent = np.pets.newParentResponse.data;
            break;
          default:
            errorResponse = 'This parent already exists';
            break;
        }
        this.setState({ confirmPopup, showSavedParent, savedParent, petCollapse, selectedParent, newParent,
          registerParentCollapse, registerPetCollapse, showNewPetButton, inputs, errorResponse, successResponse });

        if(np.pets.newParentResponse.data.status === "parent-added-success"){
            setTimeout( () => this.clear(), 2000)
        }
      }
      
    }
    if (np.pets.newParentErrorResponse) {
      if (np.pets.newParentErrorResponse.errorMessage) {
        this.setState({errorResponse: np.pets.newParentErrorResponse.errorMessage, successResponse: null});
      }
    }
    if(np.client.statusUpdated){
      const { confirmPopup, selectedParent } = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `Invitation resent to ${selectedParent.firstName} ${selectedParent.lastName}`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      confirmPopup.okText = 'OK';
      confirmPopup.cancelText = 'OK';
      confirmPopup.footer = true;
      confirmPopup.action = '';
      this.setState({
        confirmPopup
      });
    }
  };

  submit = () => {
    
     window.scrollTo(0, 0);
    const { inputs, newParent, selectedParent } = this.state;
    this.setState({errorResponse: null, successResponse: null, refresh: false});
    if (!newParent && !inputs.pets.length) {
      this.setState({errorResponse: 'Cannot find any changes to save'});
    } else {
      inputs.parent.phoneEmail = (inputs.parent.phoneEmail) ? inputs.parent.phoneEmail.replace(/[^A-Z0-9]+/ig, '') : inputs.parent.phoneEmail;
      if (!this.validate(inputs)) {
        const data = Object.assign({}, inputs);
        if (!newParent) {
          data.parentId = selectedParent._id;
        }
        data.pets = data.pets.map(newPet => newPet.data);
        this.props.toggleLoading(true);
        this.props.addNewParent(data);
      }
    }
  };

  validate = (inputs) => {
    const { refreshState, newParent } = this.state;
    let { errors } = this.state;
    let error = false;
    if (newParent) {
      const validationParentRules = {
        required: ['firstName', 'lastName', 'phoneEmail'],
        exactLimit: [
          {name: 'phoneEmail', length: 10}
        ],
      };
      errors.parent = reset(errors.parent);
      const validationParentResponse = validate(validationParentRules, inputs.parent, errors.parent);
      error = validationParentResponse.error;
      errors.parent = validationParentResponse.errors;
    }
    const validationPetRules = {
      required: ['name', 'species'],
      date: ['birthday'],
      maxToday: ['birthday'],
    };
    if (inputs.pets && inputs.pets.length > 0) {
      inputs.pets = inputs.pets.map(function (newPet, index) {
        newPet.errors = reset(newPet.errors);
        const validationPetResponse = validate(validationPetRules, newPet.data, newPet.errors);
        newPet.errors = validationPetResponse.errors;
        error = error || validationPetResponse.error;
        return newPet;
      });
    }
    this.setState({ errors, error, errorResponse: null, successResponse: null, refreshState: !refreshState });
    return error;
  };

  clear() {
    window.scrollTo(0, 0);
    const _this = this;
    this.setState({
      resetParent: true,
    }, function () {
      _this.setState({
        successResponse: null,
        errorResponse: null,
        speciesList: [],
        savedParent: {},
        freezeFindParentTab: false,
        showSavedParent: false,
        petCollapse: false,
        registerParentCollapse: false,
        registeredPetCollapse: false,
        registerPetCollapse: true,
        selectedParent: null,
        resetParent: false,
        saveCancelButton: false,
        showNewPetButton: true,
        newParent: false,
        confirmPopup: {
          showResponse: false,
          responseMessage: '',
          responseType: '',
          responseAlertType: '',
          action: null,
          parent: null,
          status: null,
          footer: false,
        },
        inputs: {
          parent: {
            firstName: '',
            lastName: '',
            phoneEmail: '',
          },
          pets: []
        },
        error: false,
        errors: {
          parent: {
            firstName: { error: null, display: 'first name' },
            lastName: { error: null, display: 'last name' },
            phoneEmail: { error: null, display: 'mobile number' },
          },
        },
      });
      _this.props.clearClientProps();
    });
  };

  gotoHome() {
    this.props.history.push('/tracking-board');
  };

  cancel() {
    this.clear();
  };

  goToTrackingBoard = () => {

    const { confirmPopup, newParent, inputs } = this.state;
    if (newParent || inputs.pets.length) {
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = 'The changes you have made will be lost if you navigate away from this page.\n' +
        'Are you sure you want to leave this page?';
      confirmPopup.responseType = 'confirm';
      confirmPopup.responseAlertType = 'info';
      confirmPopup.footer = true;
      confirmPopup.okText = 'Yes';
      confirmPopup.cancelText = 'No';
      confirmPopup.action = 'clear-all';
      this.setState({confirmPopup});
    } else {
      this.gotoHome();
    }
  };

  setSelectedParent(selectedParent) {
    const { inputs } = this.state;
    if(!this.state.selectedParent){
      this.setState({
        selectedParent,
        petCollapse: true,
        saveCancelButton: true,
        freezeFindParentTab: false,
        registerParentCollapse: false
      });
    } else {
      const input = {
        parent: {
          firstName: '',
            lastName: '',
            phoneEmail: '',
        },
        pets: []
      };
      this.setState({ selectedParent, inputs: input, registerParentCollapse: false });
    }

  };

  removePet(id) {
    let { inputs } = this.state;
    inputs.pets = inputs.pets.filter(pet => (pet.id !== id));
    this.setState({
      inputs,
    });
  };

  addNewPet(){
    const { inputs } = this.state;
    if (inputs.pets.length < 2) {
      const newPet = {
        id: inputs.pets.length + 1,
        display: false,
        data: {
          name: '',
          species: '',
          breed: '',
          gender: '',
          birthday: ''
        },
        errors: {
          name: { error: null, display: 'pet name' },
          species: { error: null, display: 'species' },
          birthday: { error: null, display: 'birthday' },
        },
      };
      inputs.pets.push(newPet);
      this.setState({
        inputs
      }, function () {
        window.scrollTo(0,document.body.scrollHeight);
      });
    }
  };

  setToParentInput(parent) {
    this.setState({ inputs: { ...this.state.inputs, parent } });
  };

  setToPetInput(pet, index){
    const { inputs } = this.state;
    inputs.pets[index].data = pet;
    this.setState({
      inputs
    });
  };

  registerNew(){
    this.setState({
      registerParentCollapse: true,
      saveCancelButton: true,
      petCollapse: true,
      newParent: true,
      freezeFindParentTab: false,
    });
  };

  closePopup = () => {
    const { confirmPopup } = this.state;
    let { refreshState } = this.state;
    confirmPopup.showResponse = false;
    refreshState = !refreshState;
    this.setState({ confirmPopup, refreshState });
    this.props.clearUpdateProps();
  };

  onSuccessPopup = () => {
    const { confirmPopup, inputs } = this.state;
    const _this = this;
    confirmPopup.showResponse = false;
    if (confirmPopup.action && confirmPopup.action === 'parent-exist-facility-not-exist') {
      inputs.parent.addToCurrentAccount = true;
      const data = Object.assign({}, inputs);
      data.pets = data.pets.map(function (newPet, index) {
        return newPet.data;
      });
      this.props.addNewParent(data);
    }
    if (confirmPopup.action && confirmPopup.action === 'parent-exist-facility-inactive') {
      this.props.clearUpdateProps();
      this.props.history.push(`/client-list/profile/${confirmPopup.parent.data._id}`);
    }
    this.setState({
      showResponse: false,
      confirmPopup,
      inputs
    }, function () {
      if (confirmPopup.action && confirmPopup.action === 'clear-all') {
        _this.gotoHome();
      }
    });
  };

  gotoParentProfile = () => {
    const { selectedParent } = this.state;
    this.props.history.push(`/client-list/profile/${selectedParent._id}`);
  };

  reInvite = (params) => {
    this.props.toggleLoading(true);
    params['facility'] = this.props.user.profile.facility._id;
    this.props.reinvite(params);
  };

  render() {
    const { showSavedParent, selectedParent, petCollapse, registerParentCollapse, registerPetCollapse,
      resetParent, speciesList, inputs, errors, refreshState, freezeFindParentTab, userType,
      successResponse, errorResponse, confirmPopup, savedParent, saveCancelButton, showNewPetButton } = this.state;
    const _this = this;
    return (
      <div className="create-appointment">
        <Col md={12} lg={12} >
          <Card>
            <CardBody>

              {successResponse !== null &&
              <div className="alert alert-success fade show" role="alert">
                <div className="alert__content"><p>{successResponse}</p></div>
              </div>
              }
              {errorResponse !== null &&
              <div className="alert alert-danger fade show" role="alert">
                <div className="alert__content"><p>{errorResponse}</p></div>
              </div>
              }

              <label className="heading-text">Add Clients</label>
              <div className="content-wrapper">
                { !showSavedParent &&
                  <FindParent collapse={true}
                              setSelectedParent={this.setSelectedParent}
                              reInvite={this.reInvite}
                              reset={resetParent}
                              isDisabled={freezeFindParentTab}
                              registerNew={this.registerNew}/>
                }
                { showSavedParent &&
                  <ShowParent collapse={showSavedParent}
                              reInvite={this.reInvite}
                              selectedParent={savedParent}/>
                }
                {registerParentCollapse &&
                  <RegisterParent collapse={registerParentCollapse}
                                  refreshState={refreshState}
                                  parentInputs={inputs.parent}
                                  parentErrors={errors.parent}
                                  parentInputsSet={this.setToParentInput}
                  />
                }
                <RegisteredPets selectedParent={selectedParent} collapse={petCollapse}
                                refreshState={refreshState}
                                title={'Registered Pets'} showNewPetButton={showNewPetButton}
                                showNewPetAction={this.addNewPet} />
                {
                  inputs.pets.map(function (newPet, index) {
                    return <RegisterNewPet key={newPet.id}
                                           index={index}
                                           removePetAction={_this.removePet}
                                           speciesList={speciesList}
                                           refreshState={refreshState}
                                           pet={newPet}
                                           petInputs={newPet.data}
                                           petErrors={newPet.errors}
                                           petInputsSet={_this.setToPetInput}
                                           collapse={newPet.display}/>
                  })
                }
                <div className="row">
                  <div className="col-md-4 text-left">
                    {showSavedParent &&
                      <button className={'btn loyal-btn-new-parent'}
                            onClick={this.cancel}>
                        <span className="lnr lnr-plus-circle"></span>Add New Parent
                      </button>
                    }
                  </div>
                  <div className="col-md-8 text-right">
                    { saveCancelButton &&
                      <button className={'btn loyal-btn-clear'}
                              disabled={!saveCancelButton}
                              onClick={this.goToTrackingBoard}>Cancel</button>
                    } {' '}
                    {(!showSavedParent && saveCancelButton) &&
                      <button className={'btn loyal-btn-save-update'}
                              disabled={!saveCancelButton}
                              onClick={this.submit}>Save</button>
                    }
                    {showSavedParent && (userType===config.USER_TYPES.STAFF) &&
                      <button className={'btn loyal-btn-save-update'}
                              disabled={!saveCancelButton}
                              onClick={this.gotoParentProfile}>View Parent Profile</button>
                    }
                  </div>
                </div>
              </div>
            </CardBody>
            <MessageModal show={confirmPopup.showResponse}
                          type={confirmPopup.responseType}
                          alertType={confirmPopup.responseAlertType}
                          footer={confirmPopup.footer}
                          okText={confirmPopup.okText}
                          cancelText={confirmPopup.cancelText}
                          onSuccess={this.onSuccessPopup}
                          onClose={this.closePopup}
                          message={confirmPopup.responseMessage}/>
          </Card>
        </Col>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getParent, getSpecies, addNewParent, toggleLoading, clearClientProps, reinvite, clearUpdateProps, clearPetList
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterClient)
