import React, { Component } from 'react'
import {Label, FormGroup} from "reactstrap";
import SearchIcon from "mdi-react/SearchIcon";

import HeadingText from '../../shared/components/form/HeadingText';
import Pagination from '../../shared/components/pagination/Pagination';
import MessageModal from '../../shared/components/Modals/MessageModal';
import CustomSelectComponent from "../../shared/components/form/CustomSelectComponent";
import config from "../../config/app.config";
import AdminTableComponent from "./AdminTableComponent";
import commonHelper from '../../libs/commonHelper';

export default class AdminTable extends Component {
  constructor() {
    super();
    this.heads = [
      {
        key: 'name',
        name: 'Name',
        sortKey: 'name',
        sortable: true,
        capitalize: true,
      },
      {
        key: 'email',
        name: 'Email',
        sortable: false,
      },
      {
        key: 'facility',
        name: 'Facility',
        sortKey: 'facilityDisplayName',
        sortable: true,
        capitalize: true,
      },
      {
        key: 'status',
        name: 'Status',
        filterable: true,
        filterValue: [{'active':'Active'},{'inactive':'Inactive'}],
        sortable: false,
        capitalize: true,
      },
    ];

    this.state = {
      rows: [],
      sortField: 'name',
      sortOrder: 1,
      search: '',
      offset: 0,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      selectedFilters: [],
      faciltyList: [],
      statusFilters: [],
      responseBox: {
        showResponse: false,
        responseType: null,
        responseAlertType: null,
        responseFooter: false,
        message: null
      }
    };
  }

  componentWillReceiveProps(np) {
  
    if(np.data && np.data.tableData) {
      this.createRows(np.data.tableData);
    }

    if (np.data.facilityAll) {
      this.setState({
        faciltyList: np.data.facilityAll
      });
    }

    if(np.data.adminStatusUpdateResponse){
      let responseBox = {
        showResponse: true,
        responseType: 'alert',
        responseAlertType: 'success',
        responseFooter: true,
        message: np.data.adminStatusUpdateResponse
      }
      this.setState({
        responseBox
      });
    }
  }

  createRows = (data) => {

    if(data.admins) {
      const rows = data.admins.map((member) => {
        return (
          {
            name: member.firstName+" "+member.lastName,
            email: member.email,
            firstName: member.firstName,
            facility: member.facilityDisplayName,
            status: member.status,
            action: '',
            id: member._id,
            joined: member.joined,
            facilityId: member.facilityId,
            isPrimary: member.facilityAdmin === member._id
          }
        );
      });
      const { total, limit, offset } = data;
  
      this.setState({ rows, total, offset, limit });
    }
    
  };

  nextPage = () => {
      const { search, offset, limit, selectedFilters: facility, sortOrder, sortField } = this.state;
      const skip = offset + limit;
      const sort = {
        field: sortField,
        order: sortOrder
      }
      this.props.getAdminList({search, skip, limit, facility, sort});
  }
  
  prevPage = () => {
    const { search, offset, limit, selectedFilters: facility, sortOrder, sortField } = this.state;
    const skip = offset - limit;
    const sort = {
      field: sortField,
      order: sortOrder
    }
    this.props.getAdminList({search, skip, limit, facility, sort});
  }

  gotoPage = (page) => {
    const { search, limit, selectedFilters: facility, sortOrder, sortField } = this.state;
    const skip = limit * page;
    const sort = {
      field: sortField,
      order: sortOrder
    }
    this.props.getAdminList({search, skip, limit, facility, sort});
  }
  
  viewProfile = (data) => {
    this.props.toggleModal(true, data);
  }

  handleGridSort = (field) => {
    const { search, offset: skip, limit, selectedFilters: facility, sortOrder, sortField } = this.state;
    const order = sortField === field && sortOrder === 1 ? -1 : 1;

    const sort = {
      field: field,
      order: order
    }

    this.setState({
      sortField: field,
      sortOrder: order,
      offset: 0,
      skip: 0
    }, () => {
       this.props.getAdminList({search, sort, skip : 0, limit, facility});
    });
  }

  handleSearch = (e) => {
    const { selectedFilters: facility, limit } = this.state;
    const search = e.target.value;
    this.setState({ search, offset: 0 }, () => {
      this.props.getAdminList({ offset: 0, search, facility, limit: config.DEFAULT_PAGINATION_LENGTH});
    });
  };

  handleGridFilter = (header, value) => {
    
    this.setState({ statusFilters: value, offset: 0}, () => {
      const {limit, sortField, sortOrder, search, selectedFilters: facility, statusFilters: status } = this.state;
      this.props.getAdminList({offset: 0, limit, sortField, facility, sortOrder, search, status});
    });
  }

  handleFilter = (list) => {
    this.setState({
      selectedFilters: list
    },() => {
      const { search, limit, selectedFilters: facility, skip, statusFilters: status } = this.state;
      this.props.getAdminList({search, skip, limit, facility, status});
    });

  }

  handleActiveStatus = (status, id, joined, firstName) => {
    let msg = status === 'active'? 'activate': 'deactivate';
    let message = `Are you sure you want to ${msg} this admin profile?`;
    let confirmType = 'change_status';

    if(!joined) {
      message = `Are you sure you want to reinvite ${firstName}?`
      confirmType = 'reinvite';
    }

    const responseBox = {
      showResponse: true,
      responseType: 'confirm',
      responseAlertType: null,
      responseFooter: true,
      message
    }
    this.setState({
      status,
      selectedRowId: id,
      confirmType,
      responseBox
    })

  }

  handleReinvite = (id, status, name) => {
    const message = `Are you sure you want to reinvite ${name}?`;
    const confirmType = 'reinvite';
    const responseBox = {
      showResponse: true,
      responseType: 'confirm',
      responseAlertType: null,
      responseFooter: true,
      message
    }
    this.setState({
      selectedRowId: id,
      confirmType,
      responseBox
    })
  }

  handleActivateDeactivate = (id, status, name) => {
   const type = status === 'active'? 'deactivate': 'activate';
   const newStatus = status === 'active'? 'inactive': 'active';
   let message = `Are you sure you want to ${type} this admin profile?`;
   let confirmType = 'change_status';

    const responseBox = {
      showResponse: true,
      responseType: 'confirm',
      responseAlertType: null,
      responseFooter: true,
      message
    }

    this.setState({
      status: newStatus,
      selectedRowId: id,
      confirmType,
      responseBox
    });

  }

  handleRemove = (id, name) => {
    const message = `Are you sure you want to remove this admin profile?`;
    const confirmType = 'remove';

    const responseBox = {
      showResponse: true,
      responseType: 'confirm',
      responseAlertType: null,
      responseFooter: true,
      message
    }

    this.setState({
      status: 'removed',
      selectedRowId: id,
      confirmType,
      responseBox
    });
  }

  handleActions = (params) => {
    switch (params.type) {
      case 'view':
        this.viewProfile(params.data);
        break;
      case 'changeStatus':
        this.handleActivateDeactivate(params.data.id, params.data.status, params.data.name);
        break;
      case 'reinvite':
        this.handleReinvite(params.data.id, params.data.status, params.data.name);
        break;
      case 'remove':
        this.handleRemove(params.data.id, params.data.name);
        break;

    }
  }
  
  onSuccess = () => {
    const { confirmType, status, selectedRowId, search, skip, limit  } = this.state;
    const data = {search, skip, limit}

    if(confirmType === 'change_status') {
      this.props.changeStatus(status, selectedRowId, data);
    }

    if(confirmType === 'remove') {
      this.props.changeStatus(status, selectedRowId, data);
    }

    if(confirmType === 'reinvite') {
      this.props.reinvite(selectedRowId, data);
    }

    this.onClose();
  }

  onClose = () => {
    const responseBox = {
      showResponse: false,
      responseType: null,
      responseAlertType: null,
      responseFooter: false,
      message: null
    }
    this.setState({
      responseBox
    });
  }

  handlePageLimit = (newLimit) => {
    let {search, facility, sortField, sortOrder} = this.state;
    const sort = {
      field: sortField,
      order: sortOrder
    }
    this.setState({limit: newLimit}, () => {
      this.props.getAdminList({search, limit: newLimit, facility, sort});
    });
  }


  render() {
    const { rows, total, offset, limit, faciltyList, sortField, sortOrder, responseBox } = this.state;
    const isSuperAdmin = commonHelper.isSuperAdmin();
    const heading = isSuperAdmin ? 'Admins' : 'Manage Admins';
    return (
      <div>
        <MessageModal show={responseBox.showResponse}
                      type={responseBox.responseType}
                      alertType={responseBox.responseAlertType}
                      footer={responseBox.responseFooter}
                      onSuccess={this.onSuccess}
                      onClose={this.onClose}
                      message={responseBox.message}/>
        <HeadingText text={heading} />
        <div className="row search form">
          <div className="col-sm-6">

            <div className="form__form-group">
              <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
              <div className="form__form-group-field custom-shadow custom-border-radius">
                <div className="form__form-group-icon form__form-group-icon--left form__form-group-search">
                  <SearchIcon />
                </div>
                <input
                  name="search"
                  id="search"
                  type="text"
                  onChange={this.handleSearch}
                  ref={input => this.searchVal = input}
                  placeholder="Search by name, email or facility"
                  className="form__custom-field form__custom-field--icon-left"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="exampleSelect">Filter by facility</Label>
              <CustomSelectComponent allFilters={faciltyList}
                                     filterTypeText="Facility"
                                     filterTypeTextPlural="Facilities"
                                     action={this.handleFilter}/>
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
              <button className="btn btn-outline-primary pull-right top-panel-button"
                      onClick={this.props.toggleModal.bind(this,true, null)}>Invite New Admin</button>
            </FormGroup>
          </div>
        </div>
        <AdminTableComponent sortField={sortField} sortOrder={sortOrder} heads={this.heads} rows={rows}
                              handleGridSort={this.handleGridSort}
                              handleGridFilter={this.handleGridFilter}
                              handleActiveStatus={this.handleActions}/>
            <Pagination handlePageLimit={this.handlePageLimit} nextPage={this.nextPage} prevPage={this.prevPage} gotoPage={(i) => this.gotoPage(i)} limit={limit} offset={offset} total={total}/>
      </div>
    )
  }
}
