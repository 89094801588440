import {
  GET_CLIENT_DATA,
  REINVITE_CLIENT,
  FETCH_PARENTS,
  FETCH_PARENTS_ERROR,
  CLEAR_INVITE,
  FETCH_FACILITIES_FOR_CLIENT,
  FETCH_FACILITIES_FOR_CLIENT_ERROR,
  CLEAR_CLIENT_PROPS,
  SAVE_FACILITIES_FOR_CLIENT,
  SAVE_FACILITIES_FOR_CLIENT_ERROR,
} from '../actions/clientActions';

const initialState = {

};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_DATA:
      return {
        ...state,
        client:action.payload,
        statusUpdated: false,
        facilities: null,
        clientStatus: false,
        facilityUpdated: null
      }
    case REINVITE_CLIENT:
      return {
        ...state,
        clientStatus:action.payload,
        statusUpdated: true,
        facilityUpdated: null
      }
    case CLEAR_INVITE:
      return {
        ...state,
        clientStatus: null,
        statusUpdated: false,
        facilities: null,
        facilityUpdated: null
      }
    case FETCH_PARENTS:
      return {
        ...state,
        parentList: action.payload
      }
    case FETCH_PARENTS_ERROR:
      return {
        ...state,
        parentList:[]
      }
    case FETCH_FACILITIES_FOR_CLIENT:
      return {
        ...state,
        facilities: action.payload
      }
    case FETCH_FACILITIES_FOR_CLIENT_ERROR:
      return {
        ...state,
        facilities: null
      }
    case SAVE_FACILITIES_FOR_CLIENT:
      return {
        ...state,
        facilityUpdated: action.payload,
        clientStatus: null,
      }
    case SAVE_FACILITIES_FOR_CLIENT_ERROR:
      return {
        ...state,
        facilityUpdated: null,
        clientStatus: null,
      }
    case CLEAR_CLIENT_PROPS:
      return {
        ...state,
        statusUpdated: null,
        clientStatus: null,
        facilityUpdated: null,
      };
    default:
      return state;
  }
}

