import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Table, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import TableStatusFilterComponent from "../../shared/components/form/TableFilterComponent";
import localStorage from '../../libs/storageHelper';
import constant from '../../constants/constants'

import { toggleLoading } from "../../redux/actions/commonActions";
import {getAllFacilities} from "../../redux/actions/staffActions";
import {
    getDashboardAverageTimeData,
    getDashboardData,
    getDashboardNewUserData,
    getDashboardProcedureDetailsData
} from "../../redux/actions/dashboardActions";
import {connect} from "react-redux";

class StaffTableComponent extends Component {
    static propTypes = {
        heads: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            sortable: PropTypes.bool,
        })).isRequired,
        rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loggedUser: localStorage.getFromStorage('loggedUser')
        };
    }

    componentWillMount() {
        this.props.toggleLoading(true);
    }

    componentWillUnmount(){
        if(this.props.common.loading){
            this.props.toggleLoading(false);
        }
    }


    handleStatusFilter = (list) => {
        this.props.handleGridFilter('status', list);
    };

    viewProfile = (data) => {
        this.props.viewProfile(data);
    };

    render() {
        const {rows, heads, sortField, sortOrder} = this.props;
        const { loggedUser } = this.state;

        const headText = heads.map((head, key) => {
            return head.key;
        });

        const capitalizedText = heads.map((head, key) => {
            if (head.capitalize && head.capitalize === true) {
                return head.key;
            }
        });

        const tableRows = rows.map((row, key) => {

            const data = Object.keys(row).map((value, key) => {
                if (headText.includes(value)) {

                    const capitalizeClass = (capitalizedText.includes(value)) ? 'text-capitalize' : '';
                    return (<td key={key} className={`status-col ${capitalizeClass}`}>{row[value]}</td>);
                }
                return true;
            });

            return (
                <tr className="body-tr" key={key}>
                    {data}
                    <td>
                        <UncontrolledDropdown>
                            <DropdownToggle>
                                <span className="six-layers"></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                                <DropdownItem tag="div">
                                    <a onClick={() => this.props.viewProfile(row)}>
                                        View Profile
                                    </a>
                                </DropdownItem>
                                <DropdownItem tag="div">
                                    <a onClick={() => this.props.handleActiveStatus((row.status === 'Active') ? 'inactive' : 'active', row.id)}>
                                        {(row.status === 'Active') ? "Deactivate" : "Reactivate"}
                                    </a>
                                </DropdownItem>
                                <DropdownItem tag="div"><a
                                    onClick={() => this.props.handleActiveStatus('removed', row.id)}>
                                    Remove
                                </a></DropdownItem>
                                {(row.privateCredentials && !row.joined)?
                                  <DropdownItem tag="div">
                                      <a onClick={() => this.props.handleActiveStatus('reinvite', row.id, row.firstName)}>
                                          Reinvite
                                      </a>
                                  </DropdownItem>
                                :
                                ''
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    </td>
                </tr>
            );
        });
        const headers = heads.map((head, key) => {
            if (head) {
                const headerField = head.key;
                return ((head.sortable && !head.filterable) ?
                        <th key={key}>
                            {(sortField == head.key) ?

                                (sortOrder === 1) ?
                                    <a onClick={() => this.props.handleGridSort(headerField)}>
                                        <p className="header-text">{head.name}</p><span className="triangle-up"></span></a>
                                    :
                                    <a onClick={() => this.props.handleGridSort(headerField)}><p
                                        className="header-text">{head.name}</p><span
                                        className="triangle-down"></span></a>
                                :
                                 <a onClick={() => this.props.handleGridSort(headerField)}><p
                                    className="header-text">{head.name}</p>
                                    {(loggedUser.user.type === constant.userRole.admin  && head.key === 'facilityDisplayName') || <span className="diamond-narrow"></span>}
                                    </a>
                            }
                        </th>
                        :
                            (head.key === 'status') ?
                                <th key={key}>
                                    <TableStatusFilterComponent
                                        allFilters={head.filterValue}
                                        action={(list) => {
                                            this.handleStatusFilter(list)
                                        }}
                                        label={head.name}
                                    />
                                </th>
                                :
                                <th key={key}>
                                    <p className="header-text">{head.name}</p>
                                </th>
                )
            }
            return true;
        });
        return (
            <div className="row">
                <div className="col-md-12">
                    <Table striped className="custom-table loyal-table">
                        <thead>
                        <tr className="header-tr">
                            {headers}
                            <th className="text-center action"><p className="header-text action">Action</p></th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableRows}
                        </tbody>

                    </Table>
                    {
                        rows.length == 0 && this.props.common.loading === false ?
                          <div className="no-data-message">No results found</div> : null
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state
});

const mapDispatchToProps = {
    toggleLoading,
    getDashboardAverageTimeData,
    getDashboardData,
    getDashboardNewUserData,
    getDashboardProcedureDetailsData,
    getAllFacilities
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffTableComponent)