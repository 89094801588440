import React, {Component, Fragment} from 'react';
import moment from 'moment';
import { Card, CardBody, Col } from 'reactstrap';
import { connect } from 'react-redux';
import io from 'socket.io-client';

import FindParent from '../../Common/FindParent';
import SelectPet from '../../Common/SelectPet';
import { getParent } from '../../../../redux/actions/petActions';
import Create from './CreateAppointmentForm';
import { rescheduleAppointment, getAppointmentById, clearAppointmentStore } from '../../../../redux/actions/appointmentAction';
import MessageModal from "../../../../shared/components/Modals/MessageModal";
import {formatPhone} from "../../../../libs/commonHelper";
import config from "../../../../config/app.config";

const { SOCKET_URL } = config;
const socket = io(SOCKET_URL);
const defaultAvatar = `${process.env.PUBLIC_URL}/img/default.png`;

class RescheduleAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetch: false,
      selectedParent: null,
      selectedPet: '',
      shareUpdates: false,
      appointmentDate: null,
      appointmentTime: null,
      time: '',
      appointmentType: '',
      appointmentTypeOther: '',
      doctor: null,
      referringDoctor: null,
      pet: null,
      errors: {
        appointmentDate: null,
        time: null,
        appointmentType: null,
        appointmentTypeOther: null,
        doctor: null,
        referringDoctor: null
      },
      responseBox: {
        showResponse: false,
        responseType: '',
        responseAlertType: '',
        responseFooter: '',
        message: '',
        onSuccess: null
      },
      isCreateOpen: true,
      saveSuccess: false,
      oldData: {
        appointmentDate: null,
        time: '',
        appointmentType: '',
        appointmentTypeOther: '',
        doctor: '',
        referringDoctor:'',
        shareUpdates:'',
      }
    }

    this.setSelectedParent = this.setSelectedParent.bind(this);
    this.selectPet = this.selectPet.bind(this);
    this.unselectPet = this.unselectPet.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShareUpdates = this.handleShareUpdates.bind(this);
    this.handledateChange = this.handledateChange.bind(this);
    this.submit = this.submit.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.validate = this.validate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.rescheduleAppointment = this.rescheduleAppointment.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isDataChanged = this.isDataChanged.bind(this);
    this.resetData = this.resetData.bind(this);
  }

  componentDidMount() {
    this.props.getAppointmentById(this.props.appointmentId);
  }

  componentWillReceiveProps(np) {
    const { responseBox, isFetch } = this.state;
    let { pet } = this.state;
    if (np.appointments && np.appointments.getAppointment && !isFetch) {
     const appointment = np.appointments.getAppointment;
     const oldData = {
       appointmentDate: moment(appointment.dateTime).format('MM/DD/YYYY'),
       appointmentTime: moment(appointment.time, 'HH:mm'),
       time: moment(appointment.time, 'HH:mm').format('hh:mm A'),
       appointmentType: appointment.type,
       appointmentTypeOther: appointment.typeOther,
       doctor: appointment.doctor,
       referringDoctor: appointment.referringDoctor,
       shareUpdates: appointment.shareUpdates,
     }
     if (appointment.petData) {
       pet = appointment.petData;
     }

     this.setState({
       selectedParent: appointment.parent,
       selectedPet: appointment.pet,
       shareUpdates: appointment.shareUpdates,
       appointmentDate: moment(appointment.dateTime).format('MM/DD/YYYY'),
       appointmentTime: moment(appointment.time, 'HH:mm'),
       time: appointment.time,
       appointmentTypeOther: appointment.typeOther,
       appointmentType: appointment.type,
       doctor: appointment.doctor,
       referringDoctor: appointment.referringDoctor,
       isFetch: true,
       oldData,
       pet,
     });
    }

if (np.appointments && np.appointments.rescheduleAppointment && np.appointments.rescheduleAppointment.success === 'success') {
      responseBox.showResponse = true;
      responseBox.responseFooter = true;
      responseBox.responseType = 'alert';
      responseBox.message = `Appointment rescheduled`;
      responseBox.onSuccess = this.onClose;

      this.setState({
        saveSuccess: true,
        responseBox
      });
      const appointmentObj = np.appointments.rescheduleAppointment.appointment;
      appointmentObj.pet = {
        _id: np.appointments.rescheduleAppointment.appointment.pet
      }
      socket.emit('phaseUpdated',{ facilityId: np.appointments.rescheduleAppointment.appointment.facility, appointment: appointmentObj });
      setTimeout(() => {
        this.props.history.push('/new-appointment');
      }, 2000);
    }
  }

  handleChange(e){
    const fieldName = e.target.name;
    const state = this.state;
    state[fieldName] = e.target.value;
    if (fieldName === 'appointmentType') {
      state.appointmentTypeOther = '';
    }
    this.setState(state);
  }

  unselectPet(){
    this.setState({
      isCreateOpen: true
    });
  }

  selectPet(selectedPet){
    const { selectedParent } = this.state;
    if (selectedParent) {
      this.setState({
        selectedPet,
        isCreateOpen: true
      });
    }
  }

  setSelectedParent(selectedParent){
    this.setState({
      selectedParent,
    });
  }

  handleShareUpdates() {
    this.setState({
      shareUpdates: !this.state.shareUpdates
    });
  }

  handledateChange(appointmentDate) {
    this.setState({
      appointmentDate
    });
  }

  handleTimeChange = (time) => {
    if (time) {
      const appointmentTime = moment(time);
      this.setState({ appointmentTime });
    }
  };

  onChangeSelect(e, type, resetShareUpdates = false) {
    const state = this.state;
    console.log(e);
    if (!e) {
      state[type] = null;
      state.shareUpdates = false; 
    } else {
      if (type === 'referringDoctor') {
        state.shareUpdates = !resetShareUpdates;
      }

      state[type] = e.value;
    }
    this.setState(state);
  }

  validate() {
    const { selectedParent: parentId, selectedPet: petId, shareUpdates: shareUpdate,
      appointmentDate, time, appointmentType, appointmentTypeOther, doctor, referringDoctor, errors} = this.state;

    let dateTime = moment(appointmentDate);

    if(time){
      const dt = moment(appointmentDate).format('MM/DD/YYYY');
      const apDate = `${dt} ${time}`;
      dateTime = moment(apDate);
    }

    const difference = dateTime.diff(moment.now());

    if(!appointmentDate){
      errors.appointmentDate = "Appointment date cannot be empty";
      this.setState({
        errors
      });
      return true;
    } else if (!dateTime.isValid()) {
      errors.appointmentDate = "Appointment date is invalid";
      this.setState({
        errors
      });
      return true;
    } else if (difference < 0) {
      errors.appointmentDate = "Appointment cannot be a past time";
      this.setState({
        errors
      });
      return true;
    } else {
      errors.appointmentDate = null;
      this.setState({
        errors
      });
    }

    if(!time){
      errors.time = "Appointment time cannot be empty";
      this.setState({
        errors
      });
      return true;
    } else {
      errors.time = null;
      this.setState({
        errors
      });
    }

    if(!appointmentType){
      errors.appointmentType = "Appointment type cannot be empty";
      this.setState({
        errors
      });
      return true;
    } else {
      errors.appointmentType = null;
      this.setState({
        errors
      });
    }

    if(appointmentType === config.appointmentTypes.other && (!appointmentTypeOther || appointmentTypeOther.trim() === "")){
      errors.appointmentTypeOther = "Appointment type cannot be empty";
      this.setState({
        errors
      });
      return true;
    } else {
      errors.appointmentTypeOther = null;
      this.setState({
        errors
      });
    }
  }

  rescheduleAppointment(){
    const { shareUpdates, appointmentDate, time, appointmentType: type, doctor,
      referringDoctor, responseBox, appointmentTypeOther: typeOther, appointmentTime } = this.state;

    const other = type === config.appointmentTypes.other ? typeOther : '';

    const data = {
      appointmentId: this.props.appointmentId,
      appointmentDate: moment(appointmentDate).format('MM/DD/YYYY'),
      time: appointmentTime.format('hh:mm A'),
      type,
      typeOther: other.trim(),
      doctor,
      referringDoctor: referringDoctor === "" ? null : referringDoctor,
      shareUpdates,
    }
    this.onClose();
    this.props.rescheduleAppointment(data);
  }

  cancel() {
    const { selectedParent } = this.state;
    this.props.history.push({
      pathname: `client-list/profile/${selectedParent._id}`,
      activeTab: '3'
    });
  }

  getUserData(){
    const {selectedParent, appointmentDate, appointmentTime, pet} = this.state;

    return {
      appointmentDate,
      pet:pet.name,
      appointmentTime:  moment(appointmentTime,'HH:mm').format('hh:mm A'),
      parent: `${selectedParent.name}`,
    }
  }

  submit() {
    const { responseBox} = this.state;

    if(this.validate()){
      return;
    }

    responseBox.showResponse = true;
    responseBox.responseFooter = true;
    responseBox.responseType = 'confirm';
    responseBox.message = 'Do you want to go ahead and reschedule this appointment?';
    responseBox.onSuccess = this.rescheduleAppointment;
    responseBox.appointment = this.getUserData()

    this.setState({
      responseBox
    });
  }

  onClose(){
    const { responseBox } = this.state;

    responseBox.showResponse = false;
    responseBox.responseType = '';
    responseBox.responseAlertType = '';
    responseBox.responseFooter = '';
    responseBox.message = '';
    responseBox.appointment = null

    this.setState({
      responseBox
    });

    this.props.clearAppointmentStore();

  }

  isDataChanged() {
    const { oldData, shareUpdates, appointmentDate, time, appointmentType: type, doctor,
      referringDoctor, appointmentTypeOther: typeOther, appointmentTime} = this.state;

    const newData = {
      appointmentDate: moment(appointmentDate).format('MM/DD/YYYY'),
      appointmentTime: moment(appointmentTime, 'HH:mm'),
      time: moment(appointmentTime,'HH:mm').format('hh:mm A'),
      appointmentType: type,
      appointmentTypeOther: typeOther,
      doctor: doctor,
      referringDoctor,
      shareUpdates: shareUpdates,
    }

    return JSON.stringify(oldData) !== JSON.stringify(newData);
  }

  resetData(name){
    const state = this.state;

    switch (name) {
      case 'referringDoctor':
        state.referringDoctor = "";
        state.shareUpdates = false;
        break;
      case 'referringDoctorAndDoctor':
        state.referringDoctor = "";
        state.doctor = "";
        state.shareUpdates = false;
        break;
      case 'doctor':
        state.doctor = "";
        break;
    }
    this.setState(state);
  }

  render() {
    const { selectedParent, selectedPet, appointmentDate, appointmentTime,
      time, appointmentType, appointmentTypeOther, doctor, referringDoctor, shareUpdates, errors, responseBox, isCreateOpen, saveSuccess } = this.state;

    const createData = {
      shareUpdates,
      appointmentDate,
      appointmentTime,
      time,
      appointmentType,
      appointmentTypeOther,
      doctor,
      referringDoctor,
      errors,
      collapse: isCreateOpen
    };

    const parentImage = (selectedParent && selectedParent.avatar) ?
      `${config.PARENT_PROFILE_IMAGE_PATH}${selectedParent.avatar}` : defaultAvatar;

    const isDataChanged = this.isDataChanged();

    return (
      <Fragment>
        <MessageModal show={responseBox.showResponse}
                      type={responseBox.responseType}
                      alertType={responseBox.responseAlertType}
                      footer={responseBox.responseFooter}
                      onSuccess={responseBox.onSuccess}
                      onClose={this.onClose}
                      appointment={responseBox.appointment}
                      message={responseBox.message}/>
        <div className="create-appointment">
          <Col md={12} lg={12} >
            <Card>
              <CardBody>
                <label className={`heading-text ${saveSuccess && 'highlight-green'}`}>
                  {saveSuccess ? 'Appointment Successfully Rescheduled' : 'Reschedule Appointment'}
                </label>
                <div className="content-wrapper">
                  <div className="content-holder find-parent-wrapper">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Pet Parent</h3>
                      </div>
                    </div>
                    {
                      selectedParent &&
                      <div className="parent-collapse">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="parent-card">
                              <div className="row">
                                <div className="col-md-3">
                                  <img className="parent-image"
                                       src={parentImage}
                                       alt="avatar" />
                                </div>
                                <div className="col-md-9">
                                  <p className="parent-name">{selectedParent.name}</p>
                                  <p className="parent-email">{selectedParent.email}</p>
                                  <p className="parent-tel">{selectedParent.phone ? formatPhone(selectedParent.phone,  '(###) ###-####') : ''}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="offset-md-3 col-md-9">
                                  <div className="status-label parent-actions invited">
                                    {(selectedParent.loggedFirstTime) ? 'Joined' : 'Invited'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <SelectPet selectedParent={selectedParent} collapse={true}
                             title="Pet Details"
                             selectPet={this.selectPet}
                              unselectPet={this.unselectPet}
                             selectedPet={selectedPet} viewMode={true}/>
                  <Create handleChange={this.handleChange}
                          reschedule={true}
                          createData={createData}
                          handledateChange={this.handledateChange}
                          handleTimeChange={(e) => this.handleTimeChange(e)}
                          onChangeSelect={this.onChangeSelect}
                          handleShareUpdates={this.handleShareUpdates}
                          resetData={this.resetData}
                          appointmentDate={this.state.appointmentDate}
                          appointmentTime={this.state.appointmentTime}
                          viewMode={saveSuccess}/>
                  <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6 text-right">
                      {
                        (
                          !saveSuccess ?
                            <Fragment>
                              <button className={'btn loyal-btn-clear'} onClick={this.cancel}>Cancel</button>{' '}
                              <button disabled={!isDataChanged} className={'btn loyal-btn-save-update'} onClick={this.submit}>Reschedule</button>
                            </Fragment> :
                            <button className={'btn loyal-btn-save-update'} onClick={() => { this.props.history.push({
                              pathname: `client-list/profile/${selectedParent._id}`,
                              activeTab: '3'
                            })}}>View Appointments</button>
                        )
                      }
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Fragment>

    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  rescheduleAppointment,
  getAppointmentById,
  clearAppointmentStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleAppointment)
