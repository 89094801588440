import React, {Component, Fragment} from 'react';
import {FormGroup, Input, Col, Label} from 'reactstrap';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import DashboardFacilityFilterComponent from "../../shared/components/form/DashboardFacilityFilterComponent";

import constant  from '../../constants/constants'

export default class PhaseHistoryComponent extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      newUser: null,
      type: 'daily',
      phasesSelected: null,
      phases: [
        {
          _id: 1,
          displayName: 'Expected'
        },
        {
          _id: 2,
          displayName: 'Check-In'
        },
        {
          _id: 3,
          displayName: 'Diagnostics'
        },
        {
          _id: 4,
          displayName: 'Preparation'
        },
        {
          _id: 5,
          displayName: 'Procedure'
        },
        {
          _id: 6,
          displayName: 'Dental'
        },
        {
          _id: 7,
          displayName: 'Surgery'
        },
        {
          _id: 8,
          displayName: 'Recovery'
        },
        {
          _id: 9,
          displayName: 'Hospitalized'
        },
        {
          _id: 10,
          displayName: 'Boarding'
        },
        {
          _id: 11,
          displayName: 'Discharge'
        },
        {
          _id: 12,
          displayName: 'Completed'
        }
      ],
      data: []
    };
  }
  

  componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.chartData});
    
  }
  
  handleFilter = (params) => {
    this.setState({phasesSelected: params})
    this.props.handleHistoryPhaseChange(params)
  }
  
  handleType = (e) => {
    const type = e.target.value;
    this.setState({type}, () =>{
      this.props.handlePhaseHistoryType(type);
    });
  }
  
  loadLine = (params) => {
    const { data, phasesSelected } = this.state;
    if(data.length) {
      let strokes = data[0];
      delete strokes.name;
      strokes = Object.keys(strokes);
      return strokes.map( line => {
        const color = (constant.chartPhases[line]) ? constant.chartPhases[line].color : null;
        if(phasesSelected){
          const isExists = phasesSelected.includes(constant.chartPhases[line].id);
          if(isExists)
            return <Line type="linear" dataKey={line} stroke={color} fill={color} />
        } else {
          return <Line type="linear" dataKey={line} stroke={color} fill={color} />
        }
      
      })
    }
    
  }
  
  render() {
    const { phases, data } = this.state;
    const { processedDate } = this.props;
    return (
      <div className="total-average new-user phase-history">
        <div className="header-container">
          <h3>AVG Time Spent in Phases</h3>
          <FormGroup>
            <Input className="with-custom-arrow" type="select" name="select" id="exampleSelect" onChange={this.handleType}>
              <option value="daily">Daily (Last 30 days)</option>
              <option value="weekly">Weekly (Last 12 weeks)</option>
              <option value="monthly">Monthly (Last 6 months)</option>
            </Input>
          </FormGroup>
          <p className={'loyal-last-process-date'}>Last updated { processedDate }</p>
        </div>
        <div className="sub-header-container clearfix">
          <div className="wrapper">
            <div className="facility-wrapper">
              <Fragment>
                <Label>Phase</Label>
                <DashboardFacilityFilterComponent filterTypeTextPlural="Phases" filterTypeText="Phase" facilities={phases} handleFilter={this.handleFilter}/>
              </Fragment>
            </div>
          </div>
        </div>
        <div className="chart">
          <ResponsiveContainer width='100%' height={240}>
            <LineChart data={data} syncId="averageTimeSpent"
                       margin={{top: 10, right: 20, left: 0, bottom: 0}}>
              <CartesianGrid strokeDasharray="0 0" vertical={false}/>
              <XAxis dataKey="name"/>
              <YAxis/>
              <Tooltip />
              {this.loadLine()}
            </LineChart>
          </ResponsiveContainer>
        
        </div>
        <div className="total-average-wrapper">
          
          <div className="bullet-wrapper">
            <div className="bullets">
              <span key="expected"><span className="expected"></span>&nbsp;&nbsp; Expected</span>
              <span key="procedure"><span className="procedure"></span>&nbsp;&nbsp; Procedure</span>
              <span key="hospitalized"><span className="hospitalized"></span>&nbsp;&nbsp; Hospitalized</span>
            </div>
            <div className="bullets">
              <span key="checkin"><span className="checkin"></span>&nbsp;&nbsp; Check-In</span>
              <span key="dental"><span className="dental"></span>&nbsp;&nbsp; Dental</span>
              <span key="boarding"><span className="boarding"></span>&nbsp;&nbsp; Boarding</span>
            </div>
            <div className="bullets">
              <span key="diagnostic"><span className="diagnostic"></span>&nbsp;&nbsp; Diagnostic</span>
              <span key="surgery"><span className="surgery"></span>&nbsp;&nbsp; Surgery</span>
              <span key="discharge"><span className="discharge"></span>&nbsp;&nbsp; Discharge</span>
            </div>
            <div className="bullets">
              <span key="preparation"><span className="preparation"></span>&nbsp;&nbsp; Preparation</span>
              <span key="recovery"><span className="recovery"></span>&nbsp;&nbsp; Recovery</span>
              <span key="completed"><span className="completed"></span>&nbsp;&nbsp; Completed</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
