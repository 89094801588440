import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input } from 'reactstrap';
import StaffTableComponent from './StaffTableComponent';
import { connect } from 'react-redux';
import MaskedInput from 'react-maskedinput';
import GeneratePassword from 'generate-password';
import Pagination from '../../shared/components/pagination/Pagination';
import Error from '../../shared/components/form/Error';
import { getStaffList, getAllFacilities, getAllDesignations, addNewStaffMember, updateStaffStatus, reinviteStaff } from '../../redux/actions/staffActions';
import { toggleMessageModel, toggleLoading } from '../../redux/actions/commonActions';
import SearchIcon from "mdi-react/SearchIcon";
import PlusIcon from "mdi-react/PlusCircleOutlineIcon";
import ViewStaff from "./ViewStaff";
import CustomSelectComponent from "../../shared/components/form/CustomSelectComponent";
import MessageModal from "../../shared/components/Modals/MessageModal";
import localStorage from "../../libs/storageHelper";
import config from '../../config/app.config';
import HeadingText from "../../shared/components/form/HeadingText";
import responseMessages from "../../constants/responseMessages";
import commonHelper from '../../libs/commonHelper';

class Staff extends PureComponent {
  constructor() {
    super();
    this.heads = [
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'designation',
        name: 'Designation',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'emailPhone',
        name: 'Email/Phone',
        sortable: false,
        filterable: false,
      },
      {
        key: 'username',
        name: 'User Name',
        sortable: false,
        filterable: false,
      },
      {
        key: 'facilityDisplayName',
        name: 'Facility',
        sortable: true,
        filterable: false,
        capitalize: true,
      },
      {
        key: 'status',
        name: 'Status',
        sortable: false,
        filterable: true,
        filterValue: [{'active':'active'},{'inactive':'inactive'}],
        capitalize: true,
      },
    ];
    this.toggle = this.toggle.bind(this);

    const loggedUser = localStorage.getFromStorage('loggedUser').user;
    const facilities = (loggedUser.type === config.USER_TYPES.SUPER_ADMIN) ? [] : [loggedUser.facility];
    this.state = {
      staff: [],
      filters: {
        search: '',
        facilities,
        offset: 0,
        limit: config.DEFAULT_PAGINATION_LENGTH,
        total: 0,
        status:[],
        sortField: 'name',
        sortOrder: 1,
      },
      modal: false,
      facilities: {},
      designations: {},
      facilityOptions: [],
      designationOptions: [],
      allFacilityList: [],
      inputs: {
        firstName: '',
        lastName: '',
        email: '',
        facility: (loggedUser.type === config.USER_TYPES.SUPER_ADMIN) ? '' : loggedUser.facility,
        designation: '',
        username: '',
        phone: '',
        extension: '',
        password: '',
        privateCredentials: true
      },
      error: false,
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        facility: null,
        designation: null,
        username: null,
        phone: null,
        extension: null,
        privateCredentials: null
      },
      successResponse: null,
      errorResponse: null,
      refreshState: true,
      selectedId: null,
      selectedMember: {},
      viewPopup: false,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseAlertType: '',
        memberId: null,
        status: null,
        footer: false,
      },
      loggedUser,
    };
  };


  componentDidMount(){
    this.props.getStaffList(this.state.filters);
    this.props.getAllFacilities();
    this.props.getAllDesignations();
  };

  componentWillReceiveProps(np) {

    if (np.staff.staffList) {
      this.createRows(np.staff.staffList);
      this.heads[4].filterValue = np.staff.staffList.facilityList;
    }
    if (np.staff.facilityAll) {

      let allFacilityList = [], facilityOptions = [];
      if (np.staff.facilityAll.length > 0) {
        facilityOptions = np.staff.facilityAll.map((value) => {
          const status = (value.status === 'inactive');
          return (
              <option key={value.id} value={value.id} disabled={status}>{value.name}</option>
          );
        });
        facilityOptions.unshift(<option key='' value=''>Select</option>);
        allFacilityList = np.staff.facilityAll;
      }
      this.setState({facilities: np.staff.facilityAll, facilityOptions, allFacilityList});
    }
    if (np.staff.designationAll) {

      let designationOptions = [];
      if (np.staff.designationAll.length > 0) {
        designationOptions = np.staff.designationAll.map((value) => {
          return (
              <option key={value._id} value={value._id}>{value.name}</option>
          );
        });
        designationOptions.unshift(<option key='' value=''>Select</option>);
      }
      this.setState({designations: np.staff.designationAll, designationOptions});
    }
    if (np.staff.newStaffResponse) {
      const { inputs } = this.state;
      const successResponse =(inputs.privateCredentials === true)
        ? responseMessages.SUCCESS.MEMBER_INVITATION_SENT : responseMessages.SUCCESS.MEMBER_ADDED;
      this.setState({
        successResponse,
        errorResponse: null
      });
      setTimeout(() => {
        this.closeModal();
      }, config.POPUP_TIMEOUT_TIME);
    }
    if (np.staff.newStaffErrorResponse) {
      if (np.staff.newStaffErrorResponse.errorMessage) {
        this.setState({errorResponse: np.staff.newStaffErrorResponse.errorMessage, successResponse: null});
      }
    }
    if (np.staff.staffUpdateStatus) {
      const {confirmPopup} = this.state;
      let status = '';
      switch (confirmPopup.status) {
        case 'active':
          status = 'activated';
          break;
        case 'inactive':
          status = 'deactivated';
          break;
        case 'removed':
          status = 'removed';
          break;
        default:
          break;
      }
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `This profile is ${status} now`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      confirmPopup.footer = true;
      this.setState({confirmPopup}, function () {
        this.props.getStaffList(this.state.filters);
      });
    }
    if (np.staff.staffReinviteStatus) {
      const {confirmPopup} = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = 'Invitation sent successfully ';
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      confirmPopup.footer = true;
      this.setState({confirmPopup}, function () {
        this.props.getStaffList(this.state.filters);
      });
    }
    if (np.staff.updateStaffResponse) {
      this.props.getStaffList(this.state.filters);
    }
  }

  componentDidUpdate(np){
    if(np.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  formatPhone = (phoneNumber, pattern) => {
    phoneNumber = phoneNumber.toString().split('');
    return pattern.replace(/#/g, phoneNumber.shift());
  };

  createRows = (data) => {
    const staff = data.data.map((member) => {
      return (
        {
          name: member.name,
          firstName: member.firstName,
          lastName: member.lastName,
          designation: (member.designation)?member.designation:'N/A',
          designationId: member.designationId,
          email: member.email,
          emailPhone: (member.extension) ? (member.email)?member.email: 'N/A' + ' / ' + member.extension + '-' + member.phone : member.email ,
          phone: member.phone,
          extension: member.extension,
          username: member.username,
          privateCredentials: member.privateCredentials,
          facilityDisplayName: (member.facilityDisplayName)?member.facilityDisplayName:'N/A',
          facilityId: member.facilityId,
          joined: member.joined,
          status: (member.status).replace(/^\w/, c => c.toUpperCase()),
          action: '',
          id: member._id
        }
      );
    });

    const { filters } = this.state;
    filters.total = data.total;
    filters.limit = data.perPage;
    filters.offset = (data.page - 1) * data.perPage;
    this.setState({ staff, filters, viewPopup: false });
  };

  viewProfile = (selectedMember) => {
    this.setState({ selectedId: selectedMember.id, viewPopup: true, selectedMember });
  };

  nextPage = () => {
    const { filters } = this.state;
    filters.offset = parseInt(filters.offset + filters.limit);
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  prevPage = () => {
    const { filters } = this.state;
    filters.offset = parseInt(filters.offset - filters.limit);
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  handleSearch = (e) => {
    const search = e.target.value;
    const { filters } = this.state;
    filters.search = search;
    filters.offset = 0;
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  handleFilter = (list) => {
    const { filters } = this.state;
    filters.facilities = list;
    filters.offset = 0;
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  handleGridFilter = (header, value) => {
    const {filters} = this.state;
    if (header === 'facilities'){
      filters.facilities = value;
    } else {
      filters.status = value;
    }
    filters.offset = 0;
    this.setState({ filters}, () => {
      this.props.getStaffList(filters);
    });
  };

  handlePageLimit = (perPage) => {
    const {filters} = this.state;
    filters.limit = perPage;
    filters.offset = 0;
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  gotoPage = (i) => {
    const { filters } = this.state;
    filters.offset = parseInt(filters.limit * i);
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    if (inputs.privateCredentials === true) { //if private credentials, set email as user name
      inputs.username = inputs.email;
    }
    this.setState({
      inputs, refreshState
    });
  };

  onSubmit = () => {
    const { inputs } = this.state;
    if (inputs.privateCredentials === true) {
      inputs.password = GeneratePassword.generate({
        length: 10,
        numbers: true,
        symbols: false,
        strict: true
      });
    }
    if(!this.validate(false)){
      inputs.phone = (inputs.phone) ? inputs.phone.replace(/[^A-Z0-9]+/ig, '') : inputs.phone;
      this.props.toggleLoading(true);
      this.props.addNewStaffMember(inputs);
    }
  };

  generatePassword = () => {
    const password = GeneratePassword.generate({
      length: 10,
      numbers: true,
      symbols: false
    });
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs.password = password;
    this.setState({
      inputs, refreshState
    });
  };

  closeModal = () => {
    const inputs = {
      firstName: '',
      lastName: '',
      email: '',
      facility: '',
      designation: '',
      username: '',
      phone: '',
      extension: '',
      privateCredentials: true
    };
    this.setState({
      inputs, modal: false, successResponse: null, errorResponse: null,selectedId: null, viewPopup: false
    });
    this.props.getStaffList(this.state.filters);
  };

  validate = (element) => {
    const { inputs } = this.state;
    const onlyNumberRegex = /^\d+$/;
    const errors = {
      firstName: null,
      lastName: null,
      email: null,
      facility: null,
      designation: null,
      username: null,
      phone: null,
      extension: null,
      privateCredentials: null
    }
    let error = false;

    if(!inputs.firstName) { errors.firstName = 'First name cannot be empty'; error = true }
    else if(/\s/.test(inputs.firstName)) { errors.firstName = 'Invalid first name'; error = true}
    else if(inputs.firstName.length>50) { errors.firstName = 'Invalid first name'; error = true}

    if(!inputs.lastName) { errors.lastName = 'Last name cannot be empty'; error = true }
    else if(/\s/.test(inputs.lastName)) { errors.lastName = 'Invalid last name'; error = true}
    else if(inputs.lastName.length>50) { errors.firstName = 'Invalid last name'; error = true}

    if (inputs.privateCredentials === true) {
      if (!inputs.email) {
        errors.email = 'Email cannot be empty';
        error = true
      }
    }
    if (inputs.email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputs.email)) {
      errors.email = 'Invalid email';
      error = true
    }
    if(!inputs.facility) { errors.facility = 'Facility cannot be empty'; error = true }

    if(inputs.phone) {
      const rawNumber = inputs.phone.replace(/[^A-Z0-9]+/ig, '');
      if(rawNumber.length !== 10 || !onlyNumberRegex.test(rawNumber)) { errors.phone = 'Invalid phone number'; error = true }
    }

    if(inputs.extension) {
      if(inputs.extension.length > 5) { errors.extension = 'Invalid extension'; error = true }
      else if(!onlyNumberRegex.test(inputs.extension)) { errors.extension = 'Invalid extension'; error = true}
    }

    if(!inputs.designation) { errors.designation = 'Designation cannot be empty'; error = true }

    if(!inputs.username) { errors.username = 'Username cannot be empty'; error = true }
    else if(/\s/.test(inputs.username)) { errors.username = 'Invalid username'; error = true}

    if(!inputs.password) { errors.password = 'Password cannot be empty'; error = true }

    this.setState({errors, error, errorResponse: null, successResponse: null })
    return error;
  };

  toggle() {
    const error = false;
    const errors = {
      firstName: null,
      lastName: null,
      email: null,
      facility: null,
      designation: null,
      username: null,
      phone: null,
      extension: null,
      privateCredentials: null
    };
    const inputs = {
      firstName: '',
      lastName: '',
      email: '',
      facility: (this.state.loggedUser.type === config.USER_TYPES.SUPER_ADMIN) ? '' : this.state.loggedUser.facility,
      designation: '',
      username: '',
      phone: '',
      extension: '',
      password: GeneratePassword.generate({
        length: 10,
        numbers: true,
        symbols: false,
        strict: true
      }),
      privateCredentials: true
    };
    this.setState({
      modal: !this.state.modal, error, errors, inputs, successResponse: null, errorResponse: null,
      selectedId: null, viewPopup: false
    });
  };

  handleGridSort = (field) => {
    const { filters} = this.state;
    let order = (filters.sortOrder === 1)? -1 : 1;
    filters.sortField = field;
    filters.sortOrder = order;

    this.setState({filters}, () => {
      this.props.getStaffList(filters);
    });
  }

  handleActiveStatus = (status,id,firstName) =>{
    let statusMessage = '';
    let confirmType = 'change_status';
    switch (status) {
      case 'active':
        statusMessage = `Are you sure you want to activate this staff profile? `;
        break;
      case 'inactive':
        statusMessage = `Are you sure you want to deactivate this staff profile? `;
        break;
      case 'removed':
        statusMessage = `Are you sure you want to remove this staff profile? `;
        break;
        case 'reinvite':
        statusMessage = `Are you sure you want to reinvite ${firstName}? `;
          confirmType = 'reinvite';
        break;
    }
    const confirmPopup =  {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      staffId: id,
      footer: true,
      confirmType,
      status
    };
    this.setState({ confirmPopup,  showResponse: true });
  };

  onSuccess = () => {
    const { confirmPopup } = this.state;
    if(confirmPopup.confirmType === 'change_status') {
      this.props.updateStaffStatus(confirmPopup.staffId, {id: confirmPopup.staffId, status : confirmPopup.status });
    } else if (confirmPopup.confirmType === 'reinvite') {
      this.props.reinviteStaff({ id: confirmPopup.staffId });
    }

    confirmPopup.showResponse = false;
    this.setState({
      showResponse: false,
      confirmPopup
    })
  }

  closePopup = () => {

    const { confirmPopup } = this.state;
    let { refreshState } = this.state;
    confirmPopup.showResponse = false;
    refreshState = !refreshState;
    this.setState({ confirmPopup, refreshState });
  };

  render() {

    const { staff, inputs, errors, successResponse, errorResponse, loggedUser,
      viewPopup, selectedId, selectedMember, confirmPopup, filters,
      facilityOptions, designationOptions, allFacilityList } = this.state;
    const { handleSubmit } = this.props;
    let facilityList = [];
    if (allFacilityList.length > 0) {
      facilityList = allFacilityList;
    }
    let saveButtonText = 'Save and Invite';
    if (inputs.privateCredentials === false) {
      saveButtonText = 'Save';
    }
    const staticData = {facilityOptions, designationOptions};
    const form = (
      <Card>
        <CardBody>
          {successResponse !== null &&
          <div className="alert alert-success fade show" role="alert">
            <div className="alert__content"><p>{successResponse}</p></div>
          </div>
          }
          {errorResponse !== null &&
          <div className="alert alert-danger fade show" role="alert">
            <div className="alert__content"><p>{errorResponse}</p></div>
          </div>
          }
          <form className="form" onSubmit={handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">First Name <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="firstName"
                          type="text"
                          maxLength={50}
                          onChange = {this.onChangeFields}
                        />
                        {errors.firstName && <Error text={errors.firstName}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Last Name <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="lastName"
                          type="text"
                          maxLength={50}
                          onChange = {this.onChangeFields}
                        />
                        {errors.lastName && <Error text={errors.lastName}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email {(inputs.privateCredentials === true) && <span className='required'>*</span>}</span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="email"
                          type="text"
                          onChange = {this.onChangeFields}
                        />
                        {errors.email && <Error text={errors.email}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Facility <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <select name="facility" id="facility"
                                className="form-control form-select"
                                value={inputs.facility}
                                disabled={loggedUser.type !== config.USER_TYPES.SUPER_ADMIN}
                                onChange = {this.onChangeFields}>
                          {facilityOptions}
                        </select>
                        {errors.facility && <Error text={errors.facility}/>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Phone</span>
                        <div className="form__form-group-field">
                          <FormGroup>
                            <MaskedInput mask="(111) 111-1111"
                                         className="form-control"
                                         name="phone"
                                         type="text"
                                         placeholder={''}
                                         maxLength={10}
                                         onChange = {this.onChangeFields}/>
                            {errors.phone && <Error text={errors.phone}/>}
                          </FormGroup>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Ext:</span>
                        <div className="form__form-group-field">
                          <FormGroup>
                            <MaskedInput mask="11111"
                                         name="extension"
                                         type="text"
                                         maxLength={5}
                                         className="form-control ext"
                                         onChange = {this.onChangeFields}
                                         placeholder={''} />
                            {errors.extension && <Error text={errors.extension}/>}
                          </FormGroup>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Designation <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <select name="designation" id="designation"
                                className="form-control form-select"
                                onChange = {this.onChangeFields}>
                          {designationOptions}
                        </select>
                        {errors.designation && <Error text={errors.designation}/>}
                      </FormGroup>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">User Name <span className={'required'}>*</span></span>
                    <div className="form__form-group-field">
                      <FormGroup>
                        <input
                          className="form-control"
                          name="username"
                          type="text"
                          readOnly={inputs.privateCredentials === true}
                          value={inputs.username}
                          onChange = {this.onChangeFields}
                        />
                        {errors.username && <Error text={errors.username}/>}
                      </FormGroup>
                    </div>

                  </div>
                </div>
                <div className="col-md-4">
                  {(inputs.privateCredentials === false) &&
                    <div className="form__form-group">
                      <span className="form__form-group-label">Password <span className={'required'}>*</span></span>
                      <div className="form__form-group-field">
                        <FormGroup>
                          <input
                            className="form-control"
                            name="password"
                            type="text"
                            value={inputs.password}
                            onChange={this.onChangeFields}
                          />
                          {errors.password && <Error text={errors.password}/>}
                        </FormGroup>
                      </div>
                    </div>
                  }
                </div>
                <div className="col-md-4">
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 text-left">
                  <div className="checkbox-wrapper">
                    <label className="checkbox-container terms-and-conditions">
                      <input
                        id="privateCredentials"
                        className="custom-checkbox"
                        type="checkbox"
                        name="privateCredentials"
                        checked={inputs.privateCredentials}
                        onChange={this.onChangeFields}
                      />
                      <span className="checkmark"/>
                      <label className="form-check-label" htmlFor="privateCredentials">Private Credentials</label>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-right">
                  <Button className={'clear'} color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                  <Button className={'save-update'} color="primary" onClick={this.onSubmit}>{saveButtonText}</Button>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    );

    const modal = (
      <Modal isOpen={this.state.modal}
             size={'lg'}
             backdrop={'static'}
             className={'model-form'}>
        <ModalHeader className={'header-form'} >Add New Staff Member</ModalHeader>
        <ModalBody>
          {form}
         </ModalBody>
      </Modal>
    );
    const isSuperAdmin = commonHelper.isSuperAdmin();
    const heading = isSuperAdmin ? 'Staff' : 'Manage Staff';
    return (
      <Col md={12} lg={12} className="manage-staff-container admin-container">
        <Card>
          <CardBody>
            <HeadingText text={heading} />
            <div className="row search form">
              <div className="col-sm-6">

                <div className="form__form-group">
                  <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
                  <div className="form__form-group-field custom-shadow custom-border-radius">
                    <div className="form__form-group-icon form__form-group-icon--left form__form-group-search">
                      <SearchIcon />
                    </div>
                    <input
                      name="search"
                      id="search"
                      type="text"
                      onChange={this.handleSearch}
                      placeholder="Search by staff name, email, facility or designation"
                      className="form__custom-field form__custom-field--icon-left"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                {loggedUser.type === config.USER_TYPES.SUPER_ADMIN &&
                  <FormGroup>
                    <Label for="exampleSelect" className={'loyal-header-label'}>Filter by facility</Label>
                    <CustomSelectComponent allFilters={facilityList}
                                           filterTypeText="Facility"
                                           filterTypeTextPlural="Facilities"
                                           action={(list) => {
                                             this.handleFilter(list)
                                           }}/>
                  </FormGroup>
                }
              </div>
              <div className="col-sm-3">
                <FormGroup>
                  <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
                  <button className="btn btn-outline-primary pull-right top-panel-button"
                          onClick={this.toggle}> <PlusIcon /> Add New Staff Member</button>
                </FormGroup>
              </div>
            </div>

            <StaffTableComponent sortField={filters.sortField} sortOrder={filters.sortOrder}
                                    heads={this.heads} rows={staff}
                                    handleGridSort={(field) => this.handleGridSort(field)}
                                    handleGridFilter={(header, field) => this.handleGridFilter(header, field)}
                                    viewProfile = {(field) => this.viewProfile(field)}
                                    handleActiveStatus={(status,id, joined, firstName) => this.handleActiveStatus(status,id, joined, firstName)} />
            <Pagination nextPage={this.nextPage} prevPage={this.prevPage}
                        handlePageLimit={(limit) => this.handlePageLimit(limit)}
                        gotoPage={(i) => this.gotoPage(i)}
                        limit={filters.limit} offset={filters.offset} total={filters.total}/>

          </CardBody>
        </Card>
        {modal}
        <ViewStaff selectedId={selectedId} selectedMember={selectedMember} view={viewPopup} data={staticData}/>
        <MessageModal show={confirmPopup.showResponse}
                      type={confirmPopup.responseType}
                      alertType={confirmPopup.responseAlertType}
                      footer={confirmPopup.footer}
                      onSuccess={this.onSuccess}
                      onClose={this.closePopup}
                      message={confirmPopup.responseMessage}/>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getStaffList, getAllFacilities, getAllDesignations, addNewStaffMember, updateStaffStatus, toggleMessageModel, reinviteStaff, toggleLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Staff)
