import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Table from './table';
import Model from './model';
import { connect } from 'react-redux';
import { getSpecies, saveBreed, updateBreed, getBreeds, deleteBreed } from '../../redux/actions/breedAction';
import { toggleLoading } from '../../redux/actions/commonActions';
import constants from '../../constants/responseMessages';
import config from "../../config/app.config";

class Breed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      species: [],
      toggleForm: false,
      successMsg: null,
      breeds: [],
      breedRemove: null,
      updateData: null,
      filterData: {}
    }
  }

  componentDidMount() {
    this.props.getSpecies();
    this.props.getBreeds();
  }

  componentWillReceiveProps(np) {
    const {filterData} = this.state;
    if(np.breeds) {
      this.setState({species: np.breeds.species, breeds: np.breeds.breeds, breedRemove: null });

      if(np.breeds.breedAdded) {
        this.setState({ successMsg: constants.SUCCESS.BREED_ADD});
        this.props.getBreeds(filterData);
        setTimeout(() => {
          this.setState({ toggleForm: false})
        }, config.POPUP_TIMEOUT_TIME);
      }

      if(np.breeds.breedUpdated) {
        this.setState({ successMsg: constants.SUCCESS.BREED_UPDATE});
        this.props.getBreeds(filterData);
        setTimeout(() => {
          this.setState({ toggleForm: false})
        }, config.POPUP_TIMEOUT_TIME);
      }

      if(np.breeds.breedDeleted) {
        this.setState({ breedRemove: 1})
      }

      if(np.breeds.breedDeletedError) {
        this.setState({ breedRemove: 2})
      }

      if(np.breeds.breedUpdated) {
        this.setState({ successMsg: constants.SUCCESS.BREED_UPDATE});
        setTimeout(() => {
          this.setState({ toggleForm: false})
        }, config.POPUP_TIMEOUT_TIME);
      }
    }
  }

  toggleForm = (status, params = null) => {
    let isUpdate = params ? true: false;
    this.setState({toggleForm: status, isUpdate, updateData:params})
  }

  loadBreeds = (data) => {
    this.setState({filterData: data});
    this.props.getBreeds(data);
  }

  save = (params, isUpdate) => {
    this.props.toggleLoading(true);
    if(isUpdate) {
      this.props.updateBreed(params);
    } else {
      this.props.saveBreed(params);
    }

  }

  delete = (id) => {
    this.props.deleteBreed(id)
  }


  render() {
    const { species, toggleForm, successMsg, breeds, breedRemove, isUpdate, updateData } = this.state;

    return (
      <div className="manage-breed">
      <Col md={12} lg={12} >
        <Card>
          <CardBody>
              < Table
                species={species}
                breeds={breeds}
                save={this.save}
                toggleForm={this.toggleForm}
                loadBreeds={this.loadBreeds}
                delete={this.delete}
                breedRemove= {breedRemove}
              />
              {toggleForm && <Model
                species={species}
                toggleForm={this.toggleForm}
                save={this.save}
                success={successMsg}
                isUpdate = {isUpdate}
                updateData={updateData}
              />}
          </CardBody>
        </Card>
        </Col>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getSpecies,
  saveBreed,
  updateBreed,
  toggleLoading,
  getBreeds,
  deleteBreed
};

export default connect(mapStateToProps, mapDispatchToProps)(Breed)
