import React,{ Component } from 'react';
import ForgotPasswordForm from "./components/Form";
import BackButtonComponent from "../../../shared/components/BackButtonComponent";
import { sendForgetPasswordLink } from '../../../redux/actions/userActions';
import connect from "react-redux/es/connect/connect";
import localStorage from "../../../libs/storageHelper";
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

export class SendResetPasswordLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendResetLinkData: {}
    }
    this.sendLink = this.sendLink.bind(this);
  }

  componentWillReceiveProps(np){
    this.setState({ sendResetLinkData: np.user})
  }

  sendLink(state){
    const value = {
      email:state.email
    }
    this.props.sendForgetPasswordLink(value);
  }

  render(){
    return(
      <div className="account user-flow password-reset">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title clearfix">
                <span className="account__logo float-right">
                  <img src={icon} alt="" />
                </span>
                </h2>
              </div>
              <BackButtonComponent/>
              <h3 className="account__form-heading subheading">Forgot Password</h3>
              <h4 className="account__form-subheading">Enter your email address</h4>
              <ForgotPasswordForm handleSubmit={this.sendLink} sendResetLinkData={this.state.sendResetLinkData} />
            </div>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  sendForgetPasswordLink
};

export default connect(mapStateToProps, mapDispatchToProps)(SendResetPasswordLink)
