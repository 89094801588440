import React, { Component } from 'react'
import HedingText from '../../../shared/components/form/HeadingText';
import ResponseMessages from '../../../constants/responseMessages';
import bcrypt from 'bcryptjs';
import EyeIcon from 'mdi-react/EyeOffOutlineIcon';
import { Container, Row, Col, Tooltip, Button } from 'reactstrap';
import Error from '../../../shared/components/form/Error';
import constants from "../../../constants/constants";
const Question = `${process.env.PUBLIC_URL}/img/Shape.png`;

export default class ProfilePasswordResetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      errorOldPassword: null,
      errorPassword: null,
      errorConfirmPassword: null,
      oldPassword: '',
      password: ''
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(np) {
    if (np.profile) {
      this.setState({ currentPw: np.profile.password,  id: np.profile._id})
    }
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  showOldPassword(e) {
    e.preventDefault();
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    });
  }

  showNewPassword(e) {
    e.preventDefault();
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  }

  showCnfPassword(e) {
    e.preventDefault();
    this.setState({
      showCnfPassword: !this.state.showCnfPassword,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  validate = async() => {
    const { oldPassword, password, confirmPassword, currentPw } = this.state;

      let errorOldPassword = null
      let errorPassword = null
      let errorConfirmPassword = null
      let error = false;
      
      const checkPassword = await bcrypt.compare(oldPassword, currentPw);
      const newHashPassword = await bcrypt.compare(password, currentPw);
      
      if(!oldPassword) { errorOldPassword = ResponseMessages.ERRORS.OLD_PASSWORD_EMPTY; error = true }
      else if(!checkPassword) { errorOldPassword = ResponseMessages.ERRORS.PASSWORD_INVALID; error = true}
    const passwordRegEx = new RegExp(constants.PASSWORD_PATTERN);
    if(!password) { errorPassword = ResponseMessages.ERRORS.NEW_PASSWORD_EMPTY; error = true }
    else if( newHashPassword ) { errorPassword = ResponseMessages.ERRORS.SAME_PASSWORD; error = true}
    else if(!passwordRegEx.test(password)) { errorPassword = ResponseMessages.ERRORS.PASSWORD_PATTERN; error = true}

    if(!confirmPassword) { errorConfirmPassword = ResponseMessages.ERRORS.CONFIRM_PASSWORD_EMPTY; error = true }
    else if( password !== confirmPassword ) { errorConfirmPassword = ResponseMessages.ERRORS.PASSWORD_NOT_MATCH; error = true}

    this.setState({errorOldPassword, errorPassword, errorConfirmPassword})
    return error
  }

  onBlur = async() =>{
    const { oldPassword, currentPw } = this.state;

      let errorOldPassword = null;

      const checkPassword = await bcrypt.compare(oldPassword, currentPw);

    if(!oldPassword) { errorOldPassword = ResponseMessages.ERRORS.OLD_PASSWORD_EMPTY;}
    else if(!checkPassword) { errorOldPassword = ResponseMessages.ERRORS.PASSWORD_INVALID;}


    this.setState({ errorOldPassword })
  } 

  reset = async() => {
    
    const { password, id } = this.state;
    const valid = await this.validate();
    
    if (!valid) {
      // TODO: @hemantha: Major Security issue. Password has been hashed on frontend.
      const newHashPassword = await bcrypt.hash(password, 10);
      const data = {
        id,
        data: {
          password: newHashPassword
        }
      }
      this.props.resetPw(data);
    }
    
    
  }

  render() {
    const { errorOldPassword, errorPassword, errorConfirmPassword } = this.state
    return (
      <div className="profile-details-password-reset">
        <Container>
        <HedingText text="Reset Password" />
          <form className="form">
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Old Password</span>
                  <div className="form__form-group-field">
                    <input
                      name="oldPassword"
                      type={this.state.showOldPassword ? 'text' : 'password'}
                      placeholder="Current Password"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                    />
                    <button
                      className={`form__form-group-button form__form-group-icon--right${this.state.showOldPassword ? ' active' : ''}`}
                      onClick={e => this.showOldPassword(e)}
                      tabIndex = "-1"
                    ><EyeIcon />
                    </button>
                  </div>
                  {errorOldPassword && <Error text={errorOldPassword}/>}
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">New Password
                  <img alt="question" src={Question} id="Tooltip"/>
                  </span>
                  <Tooltip placement="top" isOpen={this.state.tooltipOpen} target="Tooltip" toggle={this.toggle}>
                  New password should be 8 characters long, should be mixed case and should have at least one number.
                  </Tooltip>
                  <div className="form__form-group-field">
                    <input
                      name="password"
                      type={this.state.showNewPassword ? 'text' : 'password'}
                      placeholder="New Password"
                      onChange={this.onChange}
                    />
                    <button
                      className={`form__form-group-button form__form-group-icon--right${this.state.showNewPassword ? ' active' : ''}`}
                      tabIndex = "-1"
                      onClick={e => this.showNewPassword(e)}
                    ><EyeIcon />
                    </button>
                  </div>
                  {errorPassword && <Error text={errorPassword}/>}
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Confirm New Password </span>
                  <div className="form__form-group-field">
                    <input
                      name="confirmPassword"
                      type={this.state.showCnfPassword ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      onChange={this.onChange}
                    />
                    <button
                      className={`form__form-group-button form__form-group-icon--right${this.state.showCnfPassword ? ' active' : ''}`}
                      tabIndex = "-1"
                      onClick={e => this.showCnfPassword(e)}
                    ><EyeIcon />
                    </button>
                  </div>
                  {errorConfirmPassword && <Error text={errorConfirmPassword}/>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={this.reset} color="primary">Reset</Button>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    )
  }
}

