import React, { Component } from 'react'
import HedingText from '../../../shared/components/form/HeadingText';
import { Button, Row, Col, Container } from 'reactstrap';
import Error from '../../../shared/components/form/Error';
import config from '../../../config/app.config';
import FacilityPopup from "../../facility/UpdateFacility";

const SUPER_ADMIN = config.USER_TYPES.SUPER_ADMIN;
const ADMIN = config.USER_TYPES.ADMIN;
const STAFF = config.USER_TYPES.STAFF;

export default class ProfileDetailsForm extends Component {

  constructor(props){
    super(props);

    this.state= {
      userType: null,
      errorFirstName: null,
      errorLastName: null,
      changed:false,
      showFacilityPopup: false
    }

  }

  componentDidMount(){
   
  }
  
  onChangeFields = (e) => {
    
    this.setState({
      [e.target.name]: e.target.value,
      changed: true
    })
  }

  onSubmit = () => {
    const { changed } = this.state;
    if(!this.validate() && changed){
      this.props.update(this.state);
      this.setState({ changed: false });
    }
    
  }

  validate = () => {
    const { firstName, lastName } = this.state;
      let errorFirstName = null
      let errorLastName = null
      let error = false;

    if(!firstName) { errorFirstName = 'First name cannot be empty'; error = true }
    else if(/\s/.test(firstName)) { errorFirstName = 'Invalid first name'; error = true}

    if(!lastName) { errorLastName = 'Last name cannot be empty'; error = true }
    else if(/\s/.test(lastName)) { errorLastName = 'Invalid last name'; error = true}

    this.setState({errorFirstName, errorLastName})
    return error
  }

  componentWillReceiveProps(np){

    if(np.profile) {
      const {
        firstName,
        lastName,
        email,
        type:
          userType,
        facility,
        designation,
        username,
        phone,
        extension,
        _id
      } = np.profile;

      this.setState({
        firstName,
        lastName,
        email,
        userType,
        facility,
        designation,
        username,
        phone,
        extension,
        _id
      })
    }
  }

  showFacility = () => {
    this.setState({ showFacilityPopup: true });
    
  }
  
  closeFacilityPopup = (params) => {
    this.setState({ showFacilityPopup: false });
  }
  
  render() {

    const { userType, showFacilityPopup, firstName, lastName, email, facility, designation, username, phone, extension, errorFirstName, errorLastName } = this.state;
    const facilityName = facility && facility.name;
    const facilityId = facility && facility._id;
    const designationName = designation && designation.name;
    let formatedPhone = phone;
 
    if(phone) {
      formatedPhone =  phone.replace(/[^\d]+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return (
      <div className="profile-details">
        <Container>
          <HedingText text="Basic Information" />
          <form className="form">
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">First Name<span className="loyal-required-star">*</span></span>
                  <div className="form__form-group-field">
                    <input
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange = {this.onChangeFields}
                      maxlength={50}
                      disabled = {userType === STAFF? true: false}
                    />
                  </div>
                  {errorFirstName && <Error text={errorFirstName}/>}
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Last Name<span className="loyal-required-star">*</span>
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange = {this.onChangeFields}
                      maxlength={50}
                      disabled = {userType === STAFF? true: false}
                    />
                  </div>
                  {errorLastName && <Error text={errorLastName}/>}
                </div>
              </Col>
              <Col>
              <HedingText text="Contact Information" className="contact"/>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      type="text"
                      placeholder=""
                      value={email}
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>


            { userType === SUPER_ADMIN && <Row>
              <Col>
                <Button onClick={this.onSubmit} color="primary">Update</Button>
              </Col>
            </Row>}

            { userType === ADMIN && 
              <div>
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Facility </span>
                      <div className="form__form-group-field">
                        <input
                          name="facility"
                          type="text"
                          placeholder=""
                          value={facilityName}
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                    
                    <Col>
                    <div onClick={this.showFacility} className="view-profile-text">
                      <span>View Facility </span>
                      <span className="lnr lnr-arrow-right"></span>
                    </div>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                  <Col>
                  <Button onClick={this.onSubmit} color="primary">Update</Button>
                  </Col>
                </Row>
              </div>
            }
            
            { userType === STAFF && <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Designation<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <input
                      name="designation"
                      type="text"
                      placeholder=""
                      value={designationName}
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Username<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <input
                      name="username"
                      type="text"
                      placeholder=""
                      value={username}
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <Row className="contact-row">
                  <Col  xs="8" >
                    <div className="form__form-group">
                      <span className="form__form-group-label">Phone<span className="loyal-required-star">*</span> </span>
                      <div className="form__form-group-field">
                      <input
                        name="phone"
                        type="text"
                        placeholder=""
                        value={formatedPhone}
                        disabled
                      />
                      </div>
                    </div>
                  </Col>
                  <Col  xs="4">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Ext:<span className="loyal-required-star">*</span> </span>
                      <div className="form__form-group-field">
                      <input
                        name="extention"
                        type="text"
                        placeholder=""
                        className="ext"
                        value={extension}
                        disabled
                      />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>}


          </form>
        </Container>

        {showFacilityPopup && <FacilityPopup 
          view={true} 
          fromProfile={true}
          closeFacilityPopup={this.closeFacilityPopup}
          selectedId={facilityId}/>}

      </div>
    )
  }
}



