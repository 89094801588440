import React, {Component} from 'react';
import { Modal} from 'reactstrap';
import connect from "react-redux/es/connect/connect";
import Moment from 'react-moment';
import moment from "moment";


class PetSuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  onClose = () => {
    this.props.onClose();
  }
  
  render() {
    const { message, show, utcOffset } = this.props;
    let checkImage = `${process.env.PUBLIC_URL}/img/check-line-icon.png`;
    const date = utcOffset ? moment.utc().seconds(utcOffset * 60).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
    const time = utcOffset ? moment.utc().seconds(utcOffset * 60).format('hh:mm A') : moment().format('hh:mm A');
    return (
      <Modal
        isOpen={show}
        className="modal-dialog modal-dialog--alert modal-dialog--header alert-modal ptb-success-popup">
        <div className="modal__body">
          <img className="" src={checkImage} alt="check"/>
          <p>{message && message.text} </p>
          
          <div className="footer-text">
            <span className="date">{message && date }</span><span>{message && time}</span>
          </div>
          
        </div>
        
       
      
      </Modal>
    );
  }
  
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PetSuccessModal)