import {
  FETCH_PETS,
  FETCH_PETS_ERROR,
  GET_PET_BY_ID,
  GET_PET_BY_ID_ERROR,
  FETCH_SPECIES,
  FETCH_BREEDS,
  FETCH_BREED_LIST,
  ADD_NEW_PARENT,
  ADD_NEW_PARENT_ERROR,
  CLEAR_CLIENT_PROPS,
  GET_PATIENT_LIST, ACTIVATE_DEACTIVATE_PET, ACTIVATE_DEACTIVATE_PET_ERROR,
  FETCH_PROFILE, FETCH_PROFILE_ERROR, ACTIVATE_DEACTIVATE_PARENT, ACTIVATE_DEACTIVATE_PARENT_ERROR,
  UPDATE_PARENT, UPDATE_PARENT_ERROR, CLEAR_PET_UPDATE_PROPS, UPDATE_PET, UPDATE_PET_ERROR,
  CLEAR_PET_PROFILE, CLEAR_PET_LIST, FETCH_PET_PROFILE, FETCH_PET_PROFILE_ERROR,
} from '../actions/petActions';

const initialState = {

}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PETS:
      return {
        ...state,
        petList: action.payload
      }
    case FETCH_PETS_ERROR:
      return {
        ...state,
        petList: []
      }
    case CLEAR_PET_LIST:
      return {
        ...state,
        petList: null
      }
    case FETCH_SPECIES:
      return {
        ...state,
        speciesList: action.payload
      }
    case FETCH_BREEDS:
      return {
        ...state,
        allBreedList: action.payload
      }
    case FETCH_BREED_LIST:
      return {
        ...state,
        breedList: action.payload
      }
    case ADD_NEW_PARENT:
      return {
        ...state,
        newParentResponse: action.payload,
        newParentErrorResponse: null,
      };
    case ADD_NEW_PARENT_ERROR:
      return {
        ...state,
        newParentErrorResponse: action.payload,
        newParentResponse: null,
      };
    case CLEAR_CLIENT_PROPS:
      return {
        ...state,
        newParentErrorResponse: null,
        newParentResponse: null,
        petList: null
      };
    case GET_PET_BY_ID:
      return {
        ...state,
        petSummary: action.payload
      }
    case GET_PET_BY_ID_ERROR:
      return {
        ...state,
        petSummary: []
      };
    case GET_PATIENT_LIST:
      return {
        ...state,
        patientList: action.payload,
        petUpdateStatus: null,
      };
    case ACTIVATE_DEACTIVATE_PET:
      return {
        ...state,
        petUpdateStatus: action.payload,
      }
    case ACTIVATE_DEACTIVATE_PET_ERROR:
      return {
        ...state,
        petUpdateStatus: action.payload,
      }
    case CLEAR_PET_UPDATE_PROPS:
      return {
        ...state,
        petUpdateStatus: null,
        petProfileUpdateResponse: null,
      }
    case ACTIVATE_DEACTIVATE_PARENT:
      return {
        ...state,
        parentUpdateStatus: action.payload,
      }
    case ACTIVATE_DEACTIVATE_PARENT_ERROR:
      return {
        ...state,
        parentUpdateStatus: action.payload,
      }
    case FETCH_PROFILE:
      return {
        ...state,
        profile: action.payload,
        parentUpdateStatus: null,
        parentProfileUpdateResponse: null,
      }
    case FETCH_PROFILE_ERROR:
      return {
        ...state,
        profile: action.payload,
        parentUpdateStatus: null,
        parentProfileUpdateResponse: null,
      }
    case CLEAR_PET_PROFILE:
      return {
        ...state,
        profile: null,
        parentUpdateStatus: null,
        parentProfileUpdateResponse: null,
      }
    case UPDATE_PARENT:
      return {
        ...state,
        parentProfileUpdateResponse: action.payload,
      }
    case UPDATE_PARENT_ERROR:
      return {
        ...state,
        parentProfileUpdateResponse: action.payload,
      }
    case UPDATE_PET:
      return {
        ...state,
        petProfileUpdateResponse: action.payload,
      }
    case UPDATE_PET_ERROR:
      return {
        ...state,
        petProfileUpdateResponse: action.payload,
      }
    case FETCH_PET_PROFILE:
      return {
        ...state,
        petProfileData: action.payload
      }
    case FETCH_PET_PROFILE_ERROR:
      return {
        ...state,
        petProfileData: null,
        petProfileDataError: action.payload
      }
    default:
      return state;
  }
}
