import { handleRequest } from '../services/APIService';

export const SAVE_DOCTOR = 'SAVE_DOCTOR';
export const SAVE_DOCTOR_FAILED = 'SAVE_DOCTOR_FAILED';
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';
export const UPDATE_DOCTOR_FAILED = 'UPDATE_DOCTOR_FAILED';
export const GET_DOCTORS = 'GET_DOCTORS';
export const GET_DOCTORS_FAILED = 'GET_DOCTORS_FAILED';
export const GET_DOCTORS_BY_FACILITY = 'GET_DOCTORS_BY_FACILITY';
export const GET_DOCTORS_BY_FACILITY_FAILED = 'GET_DOCTORS_BY_FACILITY_FAILED';
export const GET_DOCTOR_BY_ID = 'GET_DOCTOR_BY_ID';
export const GET_DOCTOR_BY_ID_FAILED = 'GET_DOCTOR_BY_ID_FAILED';
export const CHANG_STATUS = 'CHANG_STATUS';
export const CHANG_STATUS_FAILED = 'CHANG_STATUS_FAILED';
export const REINVITE = 'REINVITE';
export const REINVITE_FAILED = 'REINVITE_FAILED';
export const REF_DOCTOR_SUBSCRIPTION = 'REF_DOCTOR_SUBSCRIPTION';
export const REF_DOCTOR_SUBSCRIPTION_ERROR = 'REF_DOCTOR_SUBSCRIPTION_ERROR';


export const saveDoctor = (data) => dispatch => {
  handleRequest('post', `referring-doctor`, true, data, )
  .then( (results) => {
    return dispatch ({
      type: SAVE_DOCTOR,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: SAVE_DOCTOR_FAILED,
      payload: JSON.parse(error.response)
    });
  })
};

export const getDoctors = (data) => dispatch => {
  handleRequest('post', `referring-doctor/get`, true, data, )
  .then( (results) => {
    return dispatch ({
      type: GET_DOCTORS,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: GET_DOCTORS_FAILED,
      payload: error
    });
  })
};

export const getDoctorById = (id) => dispatch => {
  handleRequest('get', `referring-doctor/${id}`, true )
  .then( (results) => {
    return dispatch ({
      type: GET_DOCTOR_BY_ID,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: GET_DOCTOR_BY_ID_FAILED,
      payload: error
    });
  })
};

export const updateDoctor = (data, id) => dispatch => {
  handleRequest('put', `referring-doctor/${id}`, true, data )
  .then( (results) => {
    return dispatch ({
      type: UPDATE_DOCTOR,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: UPDATE_DOCTOR_FAILED,
      payload: error
    });
  })
};

export const updateStatus = (data) => dispatch => {
  handleRequest('post', `referring-doctor/change-status`, true, data )
  .then( (results) => {
    return dispatch ({
      type: CHANG_STATUS,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: CHANG_STATUS_FAILED,
      payload: error
    });
  })
};

export const reinvite = (data) => dispatch => {
  handleRequest('post', `referring-doctor/re-invite`, true, data )
  .then( (results) => {
    return dispatch ({
      type: REINVITE,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: REINVITE_FAILED,
      payload: error
    });
  })
};

export const getDoctorsByFacility = () => dispatch => {
  handleRequest('get', `referring-doctors/by-facility`, true )
    .then( (results) => {
      return dispatch ({
        type: GET_DOCTORS_BY_FACILITY,
        payload: results.data
      });
    })
    .catch( (error) => {
      return dispatch ({
        type: GET_DOCTORS_BY_FACILITY_FAILED,
        payload: error
      });
    })
};

export const refDoctorSubscription = (data) => dispatch => {
  handleRequest('post', `referring-doctors/subscription`, false, data).then((results) => {
    return dispatch ({
      type: REF_DOCTOR_SUBSCRIPTION,
      payload: results,
    });
  }).catch((error) => {
    return dispatch ({
      type: REF_DOCTOR_SUBSCRIPTION_ERROR,
      payload: JSON.parse(error.response),
    });
  });
};
