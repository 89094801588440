import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Card, CardBody, Col, Row, Label, Button} from 'reactstrap';
import SearchIcon from "mdi-react/SearchIcon";
import io from 'socket.io-client';
import Board from './Board';
import {getPetSummary} from '../../../redux/actions/petActions';
import {getByPhase, savePhaseMessage} from '../../../redux/actions/pTBActions';
import PhaseNotificationModel from './PhaseNotificationModel';
import {getPtbEvents, clearStore} from '../../../redux/actions/pTBActions'
import {toggleLoading} from '../../../redux/actions/commonActions'
import moment from 'moment';
import MessageModal from "./../../../shared/components/Modals/MessageModal";
import PetSuccessModal from "../../../shared/components/Modals/PetSuccessModal";
import config from '../../../config/app.config';

const { SOCKET_URL } = config;
const socket = io(SOCKET_URL, {transports: ['websocket']});

class PetTrackingBoard extends Component {

  constructor(props) {

    super(props);
    this.state = {
      toggleClass: 'minimize',
      showPetModel: false,
      petSummary: [],
      facility: null,
      events: [],
      phaseMessages: [],
      confirmPopup: {
        showResponse: false,
        responseMessage: {
          text: '',
          date: '',
          time: ''
        },
      },
      alertShow: false,
      alertMessage: '',
      search: ''
    }
  }

  componentDidMount() {
    window.Beacon('init', 'd808c23f-0e57-44c3-8130-9ca84e5c88c7');

    const {user} = this.props;
    if (user.profile && user.profile.facility) {
      socket.on(`${user.profile.facility._id}_updateDashboard`, (data) => {
        if(data.facilityId) {
          if(data.facilityId === user.profile.facility._id) {
            this.props.getPtbEvents(data.facilityId, user.profile.facility.utcOffset);
          }
        }
      });
      this.setState({facility: user.profile.facility});
      this.props.getPtbEvents(user.profile.facility._id, user.profile.facility.utcOffset);

    }
    setInterval(() => {
      const time = moment().format('hma');
      if (time.toString() === '120am') {
        const { facility } = this.state;
        this.props.getPtbEvents(facility._id, facility.utcOffset);
      }
    }, 30000);
  }


  componentWillReceiveProps(nextProps) {
    const {user, ptb, pets} = nextProps;
    const { petSummary, confirmPopup, facility } = this.state;
    if (nextProps) {
      if (ptb.phaseMessage && !ptb.ptbMessageSave) {
        const confirmPopup = {
          showResponse: false,
          responseMessage: {}
        };
        this.setState({
          phaseMessages: ptb.phaseMessage.phaseMessages,
          ptbMessages: ptb.phaseMessage.ptbMessages,
          confirmPopup
        })
      }
      if (ptb.ptbMessageSave) {
        this.setState({
          showPetModel: false
        });
      }
      if (user.profile && user.profile.facility) {
        this.setState({facility: user.profile.facility});
        if (!ptb.loaded || ptb.cardDrag) {
          if( ptb.cardDrag.activeStatus) {
            this.setState({showPetModel: false, alertShow: true, alertMessage: this.alertMessage(ptb.cardDrag)})
          }
         // socket.emit('phaseUpdated',user.profile.facility._id);
          this.props.getPtbEvents(user.profile.facility._id, user.profile.facility.utcOffset)
        }
      }

      if (ptb.appointment) {
        const name = (petSummary && petSummary.name) ? petSummary.name : '';
        const confirmPopup = {
          showResponse: true,
          responseMessage: {
            text: `Update sent to ${name}'s support network`,
            date: ptb.appointment.updatedAt,
            time: ptb.appointment.updatedAt
          },
        };
        this.setState({
          confirmPopup,
          showPetModel: false
        }, function () {
          setTimeout(() => {
            confirmPopup.showResponse = false;
            this.setState({ confirmPopup })
          }, config.POPUP_TIMEOUT_TIME);

          const appointmentObj = ptb.appointment;
          appointmentObj.pet = {
            _id: ptb.appointment.pet._id
          }
          socket.emit('phaseUpdated',{facilityId: user.profile.facility._id, appointment: appointmentObj });
          this.props.getPtbEvents(user.profile.facility._id, user.profile.facility.utcOffset);
          this.props.clearStore();
        });
      }


      this.setState({events: ptb.ptbEvents});

      if (pets && pets.petSummary) {
        this.setState({petSummary: pets.petSummary})
      }
    }
  }

  alertMessage(params) {

    const { petStatus, parentStatus, petName, parentName, ongoingAppointmentsStatus } = params;
    if (!ongoingAppointmentsStatus) {
      return `${petName} has an ongoing procedure. Please complete the ongoing procedure before starting a new procedure.`;
    } else if(!petStatus && !parentStatus) {
      return `${parentName} and ${petName} are inactive. Please activate both parent and pet to update the procedure.`;
    } else if(!parentStatus) {
      return `${parentName} is inactive. Please activate parent to update the procedure.`;
    } else {
      return `${petName} is inactive. Please activate pet to update the procedure.`;
    }
  }

  maximize = (params) => {

    this.setState({toggleClass: 'maximize'});
  }

  minimize = (params) => {

    this.setState({toggleClass: 'minimize'});
  }

  openPetModel = (cardId, metadata, laneId, drag) => {

    if(drag) {
      drag = {
        cardId,
        laneId,
        type: metadata.type
      }
    }

    this.setState({phaseId: laneId, appointmentId: cardId, showPetModel: true, drag}, function () {
      this.props.getPetSummary(metadata.petId);
      this.props.getByPhase({phaseId: laneId, appointmentId: cardId});
    });
  }

  closePTBModal = () => {
    this.setState({ showPetModel: false });
  }

  handleMessageSubmit = (messages, drag) => {
    const {appointmentId, phaseId} = this.state;
    this.setState({ showPetModel: true})
    this.props.savePhaseMessage({messages, phaseId, appointmentId, drag});

  }

  onClosePopup = () => {
    const {confirmPopup} = this.state;
    confirmPopup.showResponse = false;
    confirmPopup.responseMessage = null;

    this.setState({confirmPopup});
  };

  handleSearch = (e) => {
    this.setState({search: e.target.value});
  }

  search = () => {
    const {search, facility} = this.state;
    this.props.toggleLoading(true);
    this.props.getPtbEvents(facility._id, facility.utcOffset, search);
  }

  closePopup = () => {
    this.setState({alertShow: false})
  }

  render() {
    const {toggleClass, showPetModel, drag, petSummary, phaseMessages, ptbMessages, facility, events, confirmPopup, alertShow, alertMessage} = this.state;
    let logo = null;
    if (facility && facility.logo) {
      logo = `${config.FACILITY_PROFILE_IMAGE_PATH}/${facility.logo}`;
    }
    return (
      <div className="patient-tracking">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col className="search-wrap">
                  <div className="search form">

                    <div className="form__form-group">
                      <Label for="exampleSelect" className={'empty-label'}>&nbsp;</Label>
                      <div className="form__form-group-field custom-shadow custom-border-radius">
                        <div className="form__form-group-icon form__form-group-icon--left form__form-group-search">
                          <SearchIcon/>
                        </div>
                        <input
                          name="search"
                          id="search"
                          type="text"
                          onChange={this.handleSearch}
                          placeholder="Search by pet name parent name..."
                          className="form__custom-field form__custom-field--icon-left"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col  className="search-btn-wrap">
                  <Button className="btn-search" onClick={this.search}>Search</Button>
                  <img alt="" className="logo" src={logo}/>
                  <div className="icon-maximize"><span onClick={this.maximize} className="lnr lnr-frame-expand"></span>
                  </div>
                </Col>
              </Row>

              <Board
                toggleClass={toggleClass}
                minimize={this.minimize}
                openPetModel={this.openPetModel}
                events={events}
              />

            </CardBody>
          </Card>

          <PhaseNotificationModel
            show={showPetModel}
            key={56}
            petSummary={petSummary}
            phaseMessages={phaseMessages}
            ptbMessages={ptbMessages}
            facility={facility}
            drag={drag}
            handleMessageSubmit={(message, drag) => {
              this.handleMessageSubmit(message, drag)
            }}
            closePTBModal={() => {
              this.closePTBModal()
            }}
          />
        </Col>
        <PetSuccessModal show={confirmPopup.showResponse}
                         key={59}
                         utcOffset={facility && facility.utcOffset}
                         onClose={this.onClosePopup}
                         message={confirmPopup.responseMessage}/>

      <MessageModal
          show={alertShow}
          type={'alert'}
          alertType={'danger'}
          onClose={this.closePopup}
          footer='true'
          message={alertMessage}
        />
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = {
  getPetSummary,
  getByPhase,
  getPtbEvents,
  savePhaseMessage,
  toggleLoading,
  clearStore
};

export default connect(mapStateToProps, mapDispatchToProps)(PetTrackingBoard)
