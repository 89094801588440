import { handleRequest } from '../services/APIService';

export const FETCH_SPECIES = 'FETCH_SPECIES';
export const FETCH_SPECIES_FAILED = 'FETCH_SPECIES_FAILED';
export const FETCH_BREEDS = 'FETCH_BREEDS';
export const FETCH_BREEDS_FAILED = 'FETCH_BREEDS_FAILED';
export const SAVE_BREED = 'SAVE_BREED';
export const SAVE_BREED_FAILED = 'SAVE_BREED_FAILED';
export const UPDATE_BREED = 'UPDATE_BREED';
export const UPDATE_BREED_FAILED = 'UPDATE_BREED_FAILED';
export const DELETE_BREED = 'DELETE_BREED';
export const DELETE_BREED_FAILED = 'DELETE_BREED_FAILED';

export const getSpecies = (data) => dispatch => {
  handleRequest('get', `species`, true, data, )
  .then( (results) => {
    return dispatch ({
      type: FETCH_SPECIES,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: FETCH_SPECIES_FAILED,
      payload: error
    });
  })
};

export const getBreeds = (data = {}) => dispatch => {
  handleRequest('post', `breed-all`, true, data )
  .then( (results) => {
    return dispatch ({
      type: FETCH_BREEDS,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: FETCH_BREEDS_FAILED,
      payload: error
    });
  })
};

export const saveBreed = (data) => dispatch => {
  handleRequest('post', `breed`, true, data, )
  .then( (results) => {
    return dispatch ({
      type: SAVE_BREED,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: SAVE_BREED_FAILED,
      payload: error
    });
  })
};

export const updateBreed = (data) => dispatch => {
  handleRequest('put', `breed`, true, data, )
  .then( (results) => {
    return dispatch ({
      type: UPDATE_BREED,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: UPDATE_BREED_FAILED,
      payload: error
    });
  })
};

export const deleteBreed = (id) => dispatch => {
  handleRequest('delete', `breed/${id}`, true )
  .then( (results) => {
    return dispatch ({
      type: DELETE_BREED,
      payload: results.data
    });
  })
  .catch( (error) => {
    return dispatch ({
      type: DELETE_BREED_FAILED,
      payload: error
    });
  })
};