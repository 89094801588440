import React, { Component } from 'react'
import {  Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import Error from '../../shared/components/form/Error';
import Confirm from '../../shared/components/Modals/MessageModal';
import constants from '../../constants/constants';

export default class NewAdminModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      data: null,
      isPrimary: false,
      isUpdate: false,
      isConfirm: false,
      message: null,
      buttonText:'Save and Invite',
      email: '',
      errors:{
        firstName: null,
        lastName: null,
        email: null,
        facility: null,
      },
      successResponse: null,
      errorResponse: null,
      status: null
    }
  }

  componentDidMount() {

    if(this.props.data.updateData) {
      const { name, email, facility, id, facilityId, isPrimary, status } = this.props.data.updateData;
        let arr = name.split(' ');
      this.setState({
        firstName: arr[0],
        lastName: arr[1],
        email,
        facility,
        id,
        facilityId,
        isPrimary,
        status,
        isUpdate:true,
        buttonText:"Update"
      })
    }

    this.setState({ data:this.props.data })

  }

  componentWillReceiveProps(np) {
    if(np.data.isPrimaryAdminExists) {
      this.setState({ isConfirm: true, message: `Are you sure you want to change the primary admin to ${this.state.email} ?`})
    }

    if (np.data.isEmailExists) {
      this.setState({errorResponse: 'Email address already exists', successResponse: null});
    }
    if (np.successResponse) {
      this.setState({successResponse: np.successResponse, errorResponse: null});
    }
  }

  onSuccess = () => {
    this.setState({isPrimary: true, isConfirm: false});
  }

  loadFacility = (selected = null) => {
    if(this.state.data) {
      const { facilityAll } = this.state.data;

      return facilityAll.map( (option) => (
        <option value={option.id} selected={option.name === selected} disabled={option.status === constants.STATUS.INACTIVE}>{option.name}</option>
      ))
    }
  }

  onChangeCheckbox = (e) => {

    const checked = e.target.checked;
    const { facility, facilityId } = this.state;

    if(!facilityId) {
      const errors =  {
        facility: 'Facility cannot be empty',
      };
      this.setState({ errors });
      return false;
    } else {
      const errors =  {
        facility: null,
      };
      this.setState({ errors });
    }

    this.setState({ isPrimary: checked, isConfirm: false});

    if(checked) {
      this.props.onChangeCheckbox(facilityId);
    }
  }

  save = () => {
    const { firstName, lastName, email, isPrimary, facility,  isUpdate, id, facilityId } = this.state;
    const data = {
      firstName,
      lastName,
      email,
      isPrimary,
      facility: facilityId
    }
    if(!this.validate()) {
      if(isUpdate) {
        data.adminId = id;
        data.facility = facilityId
        delete data.email;
        this.props.update(data);
      } else {
        this.props.saveAndInvite(data);
      }

    }


  }

  validate() {
    const { firstName, lastName, email, facility, facilityId } = this.state;
    let error = false;

    const errors =  {
      firstName: null,
      lastName: null,
      email: null,
      facility: null,
    }

    if(!firstName) { errors.firstName = 'First name cannot be empty'; error = true }
    else if(/\s/.test(firstName)) { errors.firstName = 'Invalid first name'; error = true}
    else if(firstName.length>50) { errors.firstName = 'Invalid first name'; error = true}

    if(!lastName) { errors.lastName = 'Last name cannot be empty'; error = true }
    else if(/\s/.test(lastName) || lastName.length>50) { errors.lastName = 'Invalid last name'; error = true}

    if (email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      errors.email = 'Invalid email';
      error = true
    } else if(!email) { errors.email = 'Email cannot be empty'; error = true }
    if(facilityId === 0 || !facilityId) { errors.facility = 'Facility cannot be empty'; error = true }

    this.setState({ errors, isConfirm: false})
    return error;
  }


  onChangeFields = (e) => {
    this.setState({ [e.target.name]: e.target.value, isConfirm: false});
    if(e.target.name === 'facilityId'){
      this.setState({
        isPrimary: false
      });
    }
  }

  onClose = () => {
    this.setState({
      isConfirm: false,
      isPrimary: false
    })
  }


  render() {
    const { errors, isPrimary, firstName, lastName, email, facility,
      facilityId, isUpdate,message, isConfirm, buttonText, successResponse, errorResponse } = this.state;
    return (
      <div>
        <Confirm
          show={isConfirm}
          type="confirm"
          footer={true}
          message={message}
          onSuccess={this.onSuccess}
          onClose={this.onClose}
        />

        <Modal isOpen={true} toggle={true} className={'model-form modal-lg'}>
        <ModalHeader className={'header-form'} toggle={this.toggle}>{ !isUpdate && 'Add New '}Admin Profile</ModalHeader>
        <ModalBody>
          <div className="form">
            {successResponse !== null &&
            <div className="alert alert-success fade show" role="alert">
              <div className="alert__content"><p>{successResponse}</p></div>
            </div>
            }
            {errorResponse !== null &&
            <div className="alert alert-danger fade show" role="alert">
              <div className="alert__content"><p>{errorResponse}</p></div>
            </div>
            }
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">First Name<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <input
                      name="firstName"
                      type="text"
                      placeholder=""
                      value={firstName}
                      onChange = {this.onChangeFields}
                      maxLength={50}
                    />
                  </div>
                  {errors.firstName && <Error text={errors.firstName}/>}
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Last Name<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <input
                      name="lastName"
                      type="text"
                      placeholder=""
                      value={lastName}
                      onChange = {this.onChangeFields}
                      maxLength={50}
                    />
                  </div>
                  {errors.lastName && <Error text={errors.lastName}/>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      type="text"
                      placeholder=""
                      value={email}
                      onChange = {this.onChangeFields}
                      disabled={isUpdate? true: false}
                    />
                  </div>
                  {errors.email && <Error text={errors.email}/>}
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form__form-group-label">Facility<span className="loyal-required-star">*</span> </span>
                  <div className="form__form-group-field">
                    <select name="facilityId" id="facility"
                      className="form-control form-select" value={facilityId}
                      onChange = {this.onChangeFields} disabled={isUpdate? true: false} >
                      <option value="0">Select Facility</option>
                      {this.loadFacility(facility)}
                    </select>
                  </div>
                  {errors.facility && <Error text={errors.facility}/>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
              <label title={(this.state.disabled
              || (isUpdate && this.state.status === 'inactive')
              || (isUpdate && this.props.data.updateData.isPrimary)) && "You cannot change the primary admin here. If you want to change, please select another admin as the primary admin"}>
              <Checkbox
                checked={isPrimary? true: false}
                onChange={this.onChangeCheckbox}
                disabled={this.state.disabled
                || (isUpdate && this.state.status === 'inactive')
                || (isUpdate && this.props.data.updateData.isPrimary)}
              />
                &nbsp; Primary Admin
              </label>
              </Col>
              <Col>
              <div className="float-right">
                <Button className="loyal-btn-dark" onClick={this.props.toggleModal.bind(this,false)} color="primary">Cancel</Button>
                <Button onClick={this.save} color="primary">{buttonText}</Button>
              </div>
              </Col>
            </Row>
          </div>
         </ModalBody>
      </Modal>
      </div>
    )
  }
}
